import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-return-table-side-stripe',
  templateUrl: './return-table-side-stripe.component.html',
  styleUrls: ['./return-table-side-stripe.component.scss']
})
export class ReturnTableSideStripeComponent implements OnInit {
  _category: any;
  _color: any;

  constructor() { }

  ngOnInit() {
  }

  @Input() set category(value: any) {  
    this._category = value;
  }
 
  get category(): any {
      return this._category;
  }

  @Input() set color(value: any) {  
    this._color = value;
  }
 
  get color(): any {
      return this._color;
  }

  getColorClass() {
    if (this._color === 'blue') {
      return 'blue'
    }
    
    if (this._color === 'yellow') {
      return 'yellow'
    }
  }
}
