import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { RuntimeEnvLoaderService } from './runtime-env-loader.service';

@Injectable({

  providedIn: 'root'

})
export class FormulaService {

  adminUserGroupId: any;
  basePath: string;
  errorHandler: any;

  constructor(
    private http: HttpClient,
    private envLoader: RuntimeEnvLoaderService
  ) {
    this.basePath = envLoader.config.API_BASE_URL + envLoader.config.APP_KEY;
  }

  createFormula(data): Observable<any> {
    console.log("createFormula");
    const headers = new HttpHeaders().set('security-token', JSON.parse(localStorage.getItem('currentUser')).token);
    return this.http
      .post<any>(this.basePath + '/formulas', data, { headers })
      .pipe(
        map(response => {
          console.log("in map createFormula");

          return response.data;

        }),
        catchError(this.handleError)
      );
  }

  deleteFormula(id): Observable<any> {
    const headers = new HttpHeaders()
      .set('security-token', JSON.parse(localStorage.getItem('currentUser')).token);

    return this.http.delete<any>(this.basePath + '/formulas/' + id, { headers })
      .pipe(
        map((response => {
          return 200;
        })),
        catchError(this.handleError),
      );
  }

  findFormula (params = null): Observable<any> {
    const headers = new HttpHeaders()
      .set('security-token', JSON.parse(localStorage.getItem('currentUser')).token);
      return this.http.get<any>(this.basePath + '/formulas/find', { headers, observe: 'response', params })
      .pipe(
        map((response => {
          return response;
        })),
        catchError(this.handleError),
      );
  }

  getFormulas(params = null): Observable<any> {
    const headers = new HttpHeaders()
      .set('security-token', JSON.parse(localStorage.getItem('currentUser')).token);
    return this.http.get<any>(this.basePath + '/formulas', { headers, observe: 'response', params })
      .pipe(
        map((response => {
          return response;
        })),
        catchError(this.handleError),
      );
  }

  getSpecificDataForFormulaType(params = null): Observable<any> {
    console.log('made it to service');
    console.log(params);
    const headers = new HttpHeaders()
    .set('security-token', JSON.parse(localStorage.getItem('currentUser')).token);
    console.log('made it to before api call');
    return this.http.get<any>(this.basePath + '/formulas/data-based-on-type', { headers, observe: 'response', params })
    .pipe(
      map((response => {
        console.log('made it to after api call');
        return response;
        })),
        catchError(this.handleError),
      );
  }

  getFormulaDetails(id): Observable<any> {

    const headers = new HttpHeaders().set('security-token', JSON.parse(localStorage.getItem('currentUser')).token);

    return this.http
      .get<any>(this.basePath + '/formulas/' + id, { headers })
      .pipe(
        map(response => {

          return response.data;
        }),
        catchError(this.handleError)
      );
  }

  handleError(error) {
    let errorMessage = {};
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = {
        error: `${error.error.message}`
      };
    } else {
      // server-side error
      errorMessage = {
        code: `${error.status}`,
        message: `${error.statusText}`,
        errors: error.error.errors
      };
    }
    // window.alert(errorMessage);
    return throwError(errorMessage);
  }

  updateFormulaDetails(id, data): Observable<any> {
    const headers = new HttpHeaders()
      .set('security-token', JSON.parse(localStorage.getItem('currentUser')).token);

    return this.http.patch<any>(this.basePath + '/formulas/' + id, data, { headers })
      .pipe(
        map((response => {
          return response.data;
        })),
        catchError(this.handleError),
      );
  }
}
