<div class="headerContainer">
  <div class="removeAllFilterOptionsButtons">
    <!-- <h2>Historical Orders</h2> -->
    <!-- <a *ngIf="!this.fullScreenService.isFullScreen" class="clearAllFiltersBtn" (click)="fullScreen()"><i
                class="fa fa-expand"></i> Full Screen</a>
        <a class="clearAllFiltersBtn" (click)="removeAllFilters()"><i class="fa fa-eraser"></i> Clear All Filters</a> -->

    <!-- <a type="button" class="clearAllFiltersBtn close-footer" aria-label="Close" 
        (click)="closeFooter()">Hide Footer</a> -->

    <a class="clearAllFiltersBtn"
      ><i class="fa fa-angle-double-left"></i> Back to Portfolio</a
    >
  </div>
</div>

<div class="filter-collector">
  <section class="content-header">
    <h1>
      Strategies ({{ strategies.length }})&nbsp;
      <button
        (click)="strategyCreateNew()"
        routerLinkActive="active"
        class="btn btn-success btn-sm"
      >
        Create New
      </button>
      &nbsp;
      <button class="btn btn-primary btn-sm" (click)="selectAllStrategies()">
        Select All
      </button>
      &nbsp;
      <button class="btn btn-primary btn-sm" (click)="deselectAllStrategies()">
        Deselect All
      </button>
      &nbsp;
      <button
        class="btn btn-danger btn-sm"
        [disabled]="deleteList.length == 0"
        (click)="deleteSelectedStrategies()"
      >
        Delete</button
      >&nbsp; &nbsp;
      <!-- <a class="pull-right ">
            <app-search-box (searchValue)="searchBox($event)"></app-search-box>
        </a> -->
    </h1>
    <p style="margin-top: 5px; margin-bottom: 0px">
      Click on a strategy to see the details for that strategy.
    </p>
  </section>
</div>

<!-- List of Stratewgies -->
<div class="table-container">
  <div class="loader" *ngIf="loading">
    <img src="./assets/public_img_load.gif" />
  </div>
  <div class="table-responsive">
    <table>
      <thead>
        <tr>
          <th class="select-th">Select</th>
          <th class="symbol-th">Name</th>
          <th class="description-th">Description</th>
        </tr>
      </thead>

      <tbody *ngIf="strategies.length != 0">
        <tr *ngFor="let strategy of strategies">
          <td style="text-align: center">
            <input
              type="checkbox"
              id="delete"
              [checked]="strategy.checkStatus"
              value="{{ strategy.id }}"
              (change)="selectSelect($event)"
            />
          </td>
          <td>
            <a
              [ngClass]="{ disabled: loading == true }"
              class="link"
              (click)="strategyDetails(strategy.id)"
              >{{ strategy.name }}</a
            >
          </td>
          <td>{{ strategy.description }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<!-- Create New -->
<div class="form-container">
  <div class="form-responsive">
    <ng-container *ngIf="createNew == true">
      <h1>Create New Strategy</h1>
      <form [formGroup]="strategiesCreateForm">
        <div class="">
          <div class="input-container">
            <label><h4>Strategy Name</h4></label>
            <input
              [ngClass]="{
                err: err == true
              }"
              type="text"
              class="form-control"
              formControlName="strategy"
              required
              id="strategy-name"
              placeholder="Strategy Name"
            />
          </div>

          <div class="input-container">
            <label><h4>Description</h4></label>
            <input
              type="text"
              class="form-control"
              formControlName="description"
              id="description"
              placeholder="Description"
            />
          </div>

          <div class="input-container">
            <label><h4>Compute Min Cash Value in Portfolio?</h4></label>
            &nbsp;&nbsp;
            <input
              type="checkbox"
              formControlName="computeMin"
              name="computeMin"
              id="compute_min"
              [(ngModel)]="strategy.compute_portfolio_value_min"
            />
          </div>

          <input
            type="button"
            (click)="onStrategyCreateSubmit()"
            class="btn btn-success"
            value="Create Strategy"
            style="float: left"
          />
        </div>
      </form>
    </ng-container>

    <!-- Details & Edit -->
    <ng-container *ngIf="details == true || edit == true">
      <h1 *ngIf="details == true">Details for {{ strategy.name }}</h1>
      <h1 *ngIf="edit == true">Edit {{ strategy.name }}</h1>
      <a
        class="link"
        [ngClass]="{ disabled: loading == true }"
        (click)="strategyEdit()"
        >edit</a
      >
      <form [formGroup]="strategiesEditForm">
        <div class="">
          <div *ngIf="edit == true" class="input-container">
            <label for="strategy"><h4>Strategy Name</h4></label>
            <input
              type="text"
              class="form-control"
              formControlName="strategy"
              required
              id="strategy"
            />
          </div>

          <div class="input-container">
            <label for="description"><h4>Description</h4></label>
            <h5 *ngIf="details == true">{{ strategy.description }}</h5>
            <input
              *ngIf="edit == true"
              type="text"
              class="form-control"
              formControlName="description"
              id="description"
            />
          </div>

          <div class="input-container">
            <label for="computeMin"
              ><h4>Compute Min Cash Value in Portfolio?</h4></label
            >
            &nbsp;&nbsp;
            <input
              *ngIf="details == true"
              type="checkbox"
              formControlName="computeMin"
              id="compute_min"
              [checked]="strategy.compute_portfolio_value_min"
              [disabled]="strategy.disabled" 
            />
            <input
              *ngIf="edit == true"
              type="checkbox"
              formControlName="computeMin"
              id="compute_min"
              [checked]="strategy.compute_portfolio_value_min"
            />
          </div>

          <input
            *ngIf="edit == true"
            type="button"
            (click)="onStrategyEditSubmit(strategy.id)"
            class="btn btn-success"
            value="Save Changes"
            style="float: left"
          />
          <input
            *ngIf="edit == true"
            type="button"
            (click)="strategyDetails('cancel')"
            class="btn btn-danger"
            value="Cancel"
            style="float: right"
          />
        </div>
      </form>
    </ng-container>

    <ng-container> </ng-container>
  </div>
</div>
