<html>
    <div class="headerContainer">
        <div class="removeAllFilterOptionsButtons">
            <!-- <h2>Historical Orders</h2> -->
            <!-- <a *ngIf="!this.fullScreenService.globalVar" class="clearAllFiltersBtn" (click)="fullScreen()"><i
                class="fa fa-expand"></i> Full Screen</a>
                <a class="clearAllFiltersBtn" (click)="removeAllFilters()"><i class="fa fa-eraser"></i> Clear All Filters</a> -->
                
                <!-- <a type="button" class="clearAllFiltersBtn close-footer" aria-label="Close" 
                    (click)="closeFooter()">Hide Footer</a> -->
                    
                    <!-- <a class="clearAllFiltersBtn"><i class="fa fa-angle-double-left"></i> Back to Portfolio</a> -->
                </div>
            </div>
            
            <div class=filter-collector>
                <section class="content-header">
                    <h1>
                        Formulas&nbsp;
                        <button (click) = "createNewFormula()" routerLinkActive="active" class="btn btn-success btn-sm">Create New</button>
                        &nbsp;
                        <button class="btn btn-primary btn-sm" (click)="selectAllFormulas()">Select All</button> &nbsp;
                        <button class="btn btn-primary btn-sm" (click)="deselectAllFormulas()">Deselect All</button> &nbsp;
                        <button class="btn btn-danger btn-sm" [disabled]="deleteList.length==0" (click)="deleteSelectedFormulas()">
                            Delete </button>&nbsp;
                            &nbsp;
                            <!-- <a class="pull-right ">
                                <app-search-box (searchValue)="searchBox($event)"></app-search-box>
                            </a> -->
                    </h1>
                    <p style="margin-top: 5px;
            margin-bottom: 0px;">Click an a formula to see the details or edit.</p>
        </section>
    </div>

    <div class = "table-container">
        <div class="loader" *ngIf="loading">
            <img src="./assets/public_img_load.gif" />
        </div>
        <div class = "table-responsive">
            <table>
                <thead>
                    <tr>
                        <th class = "select-th"></th>
                        <th class = "symbol-th">Name</th>
                        <th class = "description-th">Type</th>
                        <th class = "description-th">Security Type</th>
                        <th class = "description-th">Formula</th>
                        <th class = "mark-th">Rule</th>
                        <th class = "mark-th">Override</th>
                        <th class = "description-th">Description</th>
                        <th class = "description-th">Output</th>
                        <th class = "description-th">Field</th>
                    </tr>
                </thead>
                <tbody *ngIf = "formulas.length != 0">
                    <tr *ngFor="let formula of formulas">
                        <td style="text-align: center;">
                            <input type="checkbox" [checked]="formula.checkStatus" id="delete"
                            value="{{formula.id}}" (change)="selectSelect($event)" />
                        </td>
                        <td><a [ngClass]="{'disabled' : loading == true}" class="link" (click) = "getFormulaDetails(formula.id)" >{{formula.name}}</a></td>
                        <td class = "type">{{formula.type}}</td>
                        <td class = "security_type">{{formula.security_type}}</td>
                        <td class = "formula">{{formula.formula}}</td>
                        <td title="{{formula.rule}}" class = "rule">{{formula.rule}}</td>
                        <td class = "override">{{formula.override}}</td>
                        <td title="{{formula.description}}">{{formula.description}}</td>
                        <td title="{{formula.description}}">{{formula.expected_output}}</td>
                        <td title="{{formula.description}}">{{formula.field}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    
    <div class = "form-container">
        <div class = "form-responsive">
            
            <ng-container *ngIf = "createNew == true"> <!-- *ngIf == "details == false" -->
                <h1>Create New Formula</h1>
                <form>
                    <!-- <div class="container-fluid"> -->
                        <div class="">
                            
                            <div class="input-container">
                                <label for="firstName"><h4>Name for Formula</h4></label>
                                <input (change)="setFormulaName($any($event.target).value)" type="text" class="form-control" formControlName="Name" required id="symbol" placeholder="Formula">
                            </div>
                            
                            <div class="input-container">
                                <label for="firstName"><h4>Description</h4></label>
                                <textarea type="text" class="form-control" formControlName="description" id="description" placeholder="Descrition"></textarea>
                            </div>
                            
                            <div class="flex-container">
                                <div class="flex-input category">
                                    <label for="firstName"><h4>Type</h4></label>
                                    <select (change) = setMainType($any($event.target).value) class="form-control" id="gender">
                                        <option value="" disabled selected>Select your option</option>
                                        <option value="Portfolio">Portfolio</option>
                                        <option value="Historical-Orders">Historical Orders</option>
                                    </select>
                                </div>

                                <div class="flex-input category">
                                    <label for="firstName"><h4>Field</h4></label>
                                    <select (click)="setFieldType($any($event.target).value)" class="form-control" id="gender">
                                        <option value="" disabled selected>Select your option</option>
                                        <option *ngFor="let field of typeData">{{field}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="flex-container">
                                <div class="flex-input category">
                                    <label for="firstName"><h4>Formula Type</h4></label>
                                    <select (click)="setFormulaType($any($event.target).value)" class="form-control" id="gender">
                                        <option value="" disabled selected>Select your option</option>
                                        <option>Number</option>
                                        <option>Days</option>
                                        <option>Date</option>
                                    </select>
                                </div>

                                <div class="flex-input category">
                                    <label for="firstName"><h4>Output Type</h4></label>
                                    <select (click)="setOutputType($any($event.target).value)" class="form-control" id="gender">
                                        <option value="" disabled selected>Select your option</option>
                                        <option>Dollar ($)</option>
                                        <option>Percent (%)</option>
                                        <option>Number</option>
                                    </select>
                                </div>
                            </div>
                            
                            <div class="rules-container">
                                <div>
                                    <p *ngIf="isType == false">**Select a type before setting rules and formula</p>
                                    <label for="firstName"><h4>Rules</h4></label>
                                </div>
                                <div>
                                    <div class="flex-input category">
                                        <div class="check-box-container">
                                            <div class="input-check-container">
                                                <input (change)="setSecurityType('call')" class="input-option" type="checkbox">
                                                <label for="vehicle1"> Call</label>
                                            </div>
                                            <div class="input-check-container">
                                                <input (change)="setSecurityType('put')" class="input-option" type="checkbox">
                                                <label for="vehicle2"> Put</label>
                                            </div>
                                            <div class="input-check-container">
                                                <input (change)="setSecurityType('stock')" class="input-option" type="checkbox">
                                                <label for="vehicle3"> Stock</label>
                                            </div>
                                            <div class="input-check-container">
                                                <input (change)="setSecurityType('formulaOverride')" class="input-option" type="checkbox">
                                                <label for="vehicle3"> Formula Override</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex-container rule-options-container">
                                    <div class="flex-input category">
                                        <label>Select a token to Evaluate</label>
                                        <div class="token-container">
                                            <select (click)="addVariable($any($event.target).value, 'field')" class="form-control" id="gender">
                                                <option value="" disabled selected>Select your option</option>
                                                <option *ngFor="let field of typeData">{{field}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    
                                    <div class="flex-input category">
                                        <label>Select an Operator</label>
                                        <select (click) = "addVariable($any($event.target).value)" class="form-control" id="gender">
                                            <option value="" disabled selected>Select your option</option>
                                            <option value=">">Greater Than</option>
                                            <option value=">=">Greater Than Or Equal To</option>
                                            <option value="<">Less Than</option>
                                            <option value="<=">Less Than Or Equal To</option>
                                            <option value="==">Equal To</option>
                                            <option value="!=">Not Equal To</option>
                                            <hr>
                                            <option value="==">Date Is</option>
                                            <option value="<">Date Is Before</option>
                                            <option value=">">Date Is After</option>
                                            <hr>
                                            <option value="==">Text Contains</option>
                                            <option value="!=">Text Does Not Contain</option>
                                            <option value="== ' '">Is Empty</option>
                                            <option value="!= ' '">Is Not Empty</option>
                                        </select>
                                    </div>

                                    <div class="flex-input category">
                                        <label>Select a Value</label>
                                        <div class="token-container">
                                            <select class="form-control" id="gender">
                                                <option value="" disabled selected>Select your option</option>
                                                <option>Security Type</option>
                                                <option>Strategy</option>
                                                <option>Category</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <textarea #ruleTextArea (blur)="findCaretPosition('rule')" spellcheck="false" type="text" class="form-control" id="formula" placeholder="Set Formula"></textarea>
                                </div>
                                <div class="flex-space-apart">
                                    <div class="operation-buttons">
                                        <a (click) = "addVariable('*')" class="formula-button"><i class="fa fa-times" aria-hidden="true"></i></a>
                                        <a style="font-weight:900" (click) = "addVariable('/')" class="formula-button"><i class="fa fa-divide" aria-hidden="true"></i>/</a>
                                        <a (click) = "addVariable('+')" class="formula-button"><i class="fa fa-plus" aria-hidden="true"></i></a>
                                        <a (click) = "addVariable('-')" class="formula-button"><i class="fa fa-minus" aria-hidden="true"></i></a>
                                        <a (click) = "addVariable('(')" class="formula-button"><i class="fa" aria-hidden="true"></i><b>(</b></a>
                                        <a (click) = "addVariable(')')" class="formula-button"><i class="fa" aria-hidden="true"></i><b>)</b></a>
                                    </div>
                                    <div class = "operation-buttons">
                                        <a class="formula-button" (click) = "addVariable('&&')"><b>And</b></a>
                                        <a class="formula-button" (click) = "addVariable('||')"><b>Or</b></a>
                                    </div>
                                </div>
                                <p>{{rule}}</p>
                            </div>
                            
                            <div class="input-container">
                                <div>
                                    <label for="firstName"><h4>Formula</h4></label>
                                </div>
                                
                                <div class = "flex-container formula-management">
                                    <div class="flex-input token-container">
                                        <label>Add a variable</label>
                                        <select (click)="addVariable($any($event.target).value, 'field')" class="form-control" id="gender">
                                            <option value="" disabled selected>Select your option</option>
                                            <option *ngFor="let field of typeData">{{field}}</option>
                                        </select>
                                    </div>
                                    <div class="flex-input token-container">
                                        <label>Add a user made formula</label>
                                        <select (click)="addVariable($any($event.target).value, 'formula')" [(ngModel)]="userFormulaSelected" class="form-control" id="gender">
                                            <option value="" disabled selected>Select your option</option>
                                            <option *ngFor="let formula of formulas" [ngValue]="formula" value="{{formula.field}}">{{formula.name}}</option>
                                        </select>
                                    </div>
                                    <div class="flex-input token-container">
                                        <label>Add a math or finacial formula</label>
                                        <select (click)="addVariable($any($event.target).value)" class="form-control" id="gender">
                                            <option class="option-label" value="" disabled selected>Select your option</option>
                                            <hr>
                                            <option class="option-label" value="" disabled>Financial</option>
                                            <option value="PV(rate=[REPLACE ME], periods=[REPLACE], payment=[REPLACE], future value=[REPLACE], beginning=[false])">PV</option>
                                            <hr>
                                            <option class="option-label" value="" disabled>Special</option>
                                            <option value="otherLeg()">Other Leg</option>
                                            <option value="maxGain()">Max Gain Table</option>
                                            <hr>
                                            <option class="option-label" value="" disabled>Math</option>
                                            <option value="round()">Round</option>
                                            <option value="ABS()">ABS</option>
                                        </select>
                                    </div>
                                </div>

                                <div>
                                    <textarea #formulaTextArea (blur)="findCaretPosition('formula')" spellcheck="false" type="text" class="form-control" id="formula" placeholder="Set Formula"></textarea>
                                </div>
                                <div class="operation-buttons">
                                    <a (click) = "addVariable('*')" class="formula-button"><i class="fa fa-times" aria-hidden="true"></i></a>
                                    <a style="font-weight:900" (click) = "addVariable('/')" class="formula-button"><i class="fa fa-divide" aria-hidden="true"></i>/</a>
                                    <a (click) = "addVariable('+')" class="formula-button"><i class="fa fa-plus" aria-hidden="true"></i></a>
                                    <a (click) = "addVariable('-')" class="formula-button"><i class="fa fa-minus" aria-hidden="true"></i></a>
                                    <a (click) = "addVariable('(')" class="formula-button"><i class="fa" aria-hidden="true"></i><b>(</b></a>
                                    <a (click) = "addVariable(')')" class="formula-button"><i class="fa" aria-hidden="true"></i><b>)</b></a>
                                </div>
                            </div>

                            <!-- <div class="flex-container">
                                <div class="flex-input">
                                    <label for="firstName"><h4>Mark</h4></label>
                                    <h5>Mark will fill on Symbol Creation</h5>
                                </div>
                            </div> -->
                            
                            <input type="button" (click)="onFormulaSubmit()" class=" btn btn-success submit" value="Create Formula"
                            style="float: left;">
                            
                        </div>
                    </form>
                </ng-container>



                <ng-container *ngIf = "details == true"> <!-- *ngIf == "details == false" -->
                    <h1>Details for {{formulaDetails.name}} Formula</h1>
                    <h1 *ngIf = "edit == true">Edit {{formulaDetails.name}}</h1>
                    <a class="link" [ngClass]="{'disabled' : loading == true}" (click) = "formulaEdit()">edit</a>
                    <div class="">
                        
                        <div class="input-container">
                            <label for="description"><h4>Description</h4></label>
                            <h5 *ngIf = "details == true">{{formulaDetails.description}}</h5>
                        </div>
                        
                        <div class="flex-container">
                            <div class="flex-input category">
                                <label for="firstName"><h4>Type</h4></label>
                                <h5 *ngIf = "details == true">{{formulaDetails.type}}</h5>
                            </div>

                            <div class="flex-input category">
                                <label for="firstName"><h4>Field</h4></label>
                                <h5 *ngIf = "details == true">{{formulaDetails.field}}</h5>
                            </div>
                        </div>
                        <div class="flex-container">
                            <div class="flex-input category">
                                <label for="firstName"><h4>Formula Type</h4></label>
                                <h5 *ngIf = "details == true">{{formulaDetails.formula_type}}</h5>
                            </div>

                            <div class="flex-input category">
                                <label for="firstName"><h4>Output Type</h4></label>
                                <h5 *ngIf = "details == true">{{formulaDetails.expected_output}}</h5>
                            </div>
                        </div>
                        
                        <div>
                            <label for="firstName"><h4>Rules</h4></label>
                            <div>
                                <div class="flex-input category">
                                    <div class="check-box-container">
                                        <div class="input-check-container">
                                            <ng-container *ngIf="callDetails > -1" >
                                                <i class="fa blue fa-check"></i>
                                            </ng-container>
                                            <label for="vehicle1">Call</label>
                                        </div>
                                        <div class="input-check-container">
                                            <ng-container *ngIf="putDetails > -1" >
                                                <i class="fa blue fa-check"></i>
                                            </ng-container>
                                            <label for="vehicle2">Put</label>
                                        </div>
                                        <div class="input-check-container">
                                            <ng-container *ngIf="stockDetails > -1" >
                                                <i class="fa blue fa-check"></i>
                                            </ng-container>
                                            <label for="vehicle3">Stock</label>
                                        </div>
                                        <div class="input-check-container">
                                            <ng-container *ngIf="callDetails > -1" >
                                                <i class="fa blue fa-check"></i>
                                            </ng-container>
                                            <label for="vehicle3">Formula Override</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h5 *ngIf = "details == true">{{formulaDetails.rule}}</h5>
                        </div>

                        <div>
                            <label for="firstName"><h4>Formula</h4></label>
                            <h5 *ngIf = "details == true">{{formulaDetails.formula}}</h5>
                        </div>
                    </div>
                </ng-container>



                <ng-container *ngIf = "edit == true"> <!-- *ngIf == "details == false" -->
                    <h1>Edit {{formulaDetails.name}} Formula</h1>
                    <form>
                        <!-- <div class="container-fluid"> -->
                            <div class="">
                                
                                <div class="input-container">
                                    <label for="firstName"><h4>Name for Formula</h4></label>
                                    <input value = "{{formulaDetails.name}}" (change)="setFormulaName($any($event.target).value)" type="text" class="form-control" required id="name" placeholder="Formula">
                                </div>
                                
                                <div class="input-container">
                                    <label for="firstName"><h4>Description</h4></label>
                                    <textarea value = "{{formulaDetails.description}}" type="text" class="form-control" id="description" placeholder="Descrition"></textarea>
                                </div>
                                
                                <div class="flex-container">
                                    <div class="flex-input category">
                                        <label for="firstName"><h4>Type</h4></label>
                                        <select value = "{{formulaDetails.type}}" (change) = setMainType($any($event.target).value) class="form-control" id="type">
                                            <option value="" disabled selected>Select your option</option>
                                            <option value="Portfolio">Portfolio</option>
                                            <option value="Historical-Orders">Historical Orders</option>
                                        </select>
                                    </div>
    
                                    <div class="flex-input category">
                                        <label for="firstName"><h4>Field</h4></label>
                                        <select value = "{{formulaDetails.field}}" (click)="setFieldType($any($event.target).value)" class="form-control" id="field">
                                            <option value="" disabled selected>Select your option</option>
                                            <option *ngFor="let field of typeData">{{field}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="flex-container">
                                    <div class="flex-input category">
                                        <label for="firstName"><h4>Formula Type</h4></label>
                                        <select value = "{{formulaDetails.formula_type}}" (click)="setFormulaType($any($event.target).value)" class="form-control" id="formula-type">
                                            <option value="" disabled selected>Select your option</option>
                                            <option>Number</option>
                                            <option>Days</option>
                                            <option>Date</option>
                                        </select>
                                    </div>
    
                                    <div class="flex-input category">
                                        <label for="firstName"><h4>Output Type</h4></label>
                                        <select value = "{{formulaDetails.expected_output}}" (click)="setOutputType($any($event.target).value)" class="form-control" id="formula-output">
                                            <option value="" disabled selected>Select your option</option>
                                            <option>Dollar ($)</option>
                                            <option>Percent (%)</option>
                                            <option>Number</option>
                                        </select>
                                    </div>
                                </div>
                                
                                <div class="rules-container">
                                    <div>
                                        <p *ngIf="isType == false">**Select a type before setting rules and formula</p>
                                        <label for="firstName"><h4>Rules</h4></label>
                                    </div>
                                    <div>
                                        <div class="flex-input category">
                                            <div class="check-box-container">
                                                <div class="input-check-container">
                                                    <input [checked]="callDetails > -1" (change)="setSecurityType('call')" class="input-option" type="checkbox">
                                                    <label for="vehicle1"> Call</label>
                                                </div>
                                                <div class="input-check-container">
                                                    <input [checked]="putDetails > -1" (change)="setSecurityType('put')" class="input-option" type="checkbox">
                                                    <label for="vehicle2"> Put</label>
                                                </div>
                                                <div class="input-check-container">
                                                    <input [checked]="stockDetails > -1" (change)="setSecurityType('stock')" class="input-option" type="checkbox">
                                                    <label for="vehicle3"> Stock</label>
                                                </div>
                                                <div class="input-check-container">
                                                    <input (change)="setSecurityType('formulaOverride')" class="input-option" type="checkbox">
                                                    <label for="vehicle3"> Formula Override</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex-container rule-options-container">
                                        <div class="flex-input category">
                                            <label>Please Select a token to Evaluate</label>
                                            <div class="token-container">
                                                <select (click)="addVariable($any($event.target).value, 'field')" class="form-control" id="gender">
                                                    <option value="" disabled selected>Select your option</option>
                                                    <option *ngFor="let field of typeData">{{field}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        
                                        <div class="flex-input category">
                                            <label>Please Select an Operator</label>
                                            <select (click) = "addVariable($any($event.target).value)" class="form-control" id="gender">
                                                <option value="" disabled selected>Select your option</option>
                                                <option value=">">Greater Than</option>
                                                <option value=">=">Greater Than Or Equal To</option>
                                                <option value="<">Less Than</option>
                                                <option value="<=">Less Than Or Equal To</option>
                                                <option value="==">Equal To</option>
                                                <option value="!=">Not Equal To</option>
                                                <hr>
                                                <option value="==">Date Is</option>
                                                <option value="<">Date Is Before</option>
                                                <option value=">">Date Is After</option>
                                                <hr>
                                                <option value="==">Text Contains</option>
                                                <option value="!=">Text Does Not Contain</option>
                                                <option value="== ' '">Is Empty</option>
                                                <option value="!= ' '">Is Not Empty</option>
                                            </select>
                                        </div>
    
                                        <div class="flex-input category">
                                            <label>Please Select a Value</label>
                                            <div class="token-container">
                                                <select class="form-control" id="gender">
                                                    <option value="" disabled selected>Select your option</option>
                                                    <option>Security Type</option>
                                                    <option>Strategy</option>
                                                    <option>Category</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
    
                                    <div>
                                        <textarea value = "{{formulaDetails.rule}}" #ruleTextArea (blur)="findCaretPosition('text')" spellcheck="false" type="text" class="form-control" id="rule" placeholder="Set Rules"></textarea>
                                    </div>
                                    <div class="flex-space-apart">
                                        <div class="operation-buttons">
                                            <a (click) = "addVariable('*')" class="formula-button"><i class="fa fa-times" aria-hidden="true"></i></a>
                                            <a (click) = "addVariable('/')" class="formula-button"><i class="fa fa-divide" aria-hidden="true"></i></a>
                                            <a (click) = "addVariable('+')" class="formula-button"><i class="fa fa-plus" aria-hidden="true"></i></a>
                                            <a (click) = "addVariable('-')" class="formula-button"><i class="fa fa-minus" aria-hidden="true"></i></a>
                                            <a (click) = "addVariable('(')" class="formula-button"><i class="fa" aria-hidden="true"></i><b>(</b></a>
                                            <a (click) = "addVariable(')')" class="formula-button"><i class="fa" aria-hidden="true"></i><b>)</b></a>
                                        </div>
                                        <div class = "operation-buttons">
                                            <a class="formula-button" (click) = "addVariable('&&')"><b>And</b></a>
                                            <a class="formula-button" (click) = "addVariable('||')"><b>Or</b></a>
                                        </div>
                                    </div>
                                    <p>{{rule}}</p>
                                </div>
                                
                                <div class="input-container">
                                    <div>
                                        <label for="firstName"><h4>Formula</h4></label>
                                    </div>
                                    
                                    <div class = "flex-container formula-management">
                                        <div class="flex-input token-container">
                                            <label>Add a variable</label>
                                            <select (click)="addVariable($any($event.target).value, 'field')" class="form-control" id="gender">
                                                <option value="" disabled selected>Select your option</option>
                                                <option *ngFor="let field of typeData">{{field}}</option>
                                            </select>
                                        </div>
                                        <div class="flex-input token-container">
                                            <label>Add a user made formula</label>
                                            <select (click)="addVariable($any($event.target).value, 'formula')" [(ngModel)]="userFormulaSelected" class="form-control" id="gender">
                                                <option value="" disabled selected>Select your option</option>
                                                <option *ngFor="let formula of formulas" [ngValue]="formula" value="{{formula.field}}">{{formula.name}}</option>
                                            </select>
                                        </div>
                                        <div class="flex-input token-container">
                                            <label>Add a math or finacial formula</label>
                                            <select (click)="addVariable($any($event.target).value)" class="form-control" id="gender">
                                                <option value="" disabled selected>Select your option</option>
                                                <hr>
                                                <option class="option-label" value="" disabled>Financial</option>
                                                <option value="PV(rate=[REPLACE ME], periods=[REPLACE], payment=[REPLACE], future value=[REPLACE], beginning=[false])">PV</option>
                                                <hr>
                                                <option class="option-label" value="" disabled>Special</option>
                                                <option value="otherLeg()">Other Leg</option>
                                                <option value="maxGain()">Max Gain Table</option>
                                                <hr>
                                                <option class="option-label" value="" disabled>Math</option>
                                                <option value="round()">Round</option>
                                                <option value="ABS()">ABS</option>
                                            </select>
                                        </div>
                                    </div>
    
                                    <div>
                                        <textarea value = "{{formulaDetails.formula}}" #formulaTextArea (blur)="findCaretPosition('formula')" spellcheck="false" type="text" class="form-control" id="formula" placeholder="Set Formula"></textarea>
                                    </div>
                                    <div class="operation-buttons">
                                        <a (click) = "addVariable('*')" class="formula-button"><i class="fa fa-times" aria-hidden="true"></i></a>
                                        <a (click) = "addVariable('/')" class="formula-button"><i class="fa fa-divide" aria-hidden="true"></i></a>
                                        <a (click) = "addVariable('+')" class="formula-button"><i class="fa fa-plus" aria-hidden="true"></i></a>
                                        <a (click) = "addVariable('-')" class="formula-button"><i class="fa fa-minus" aria-hidden="true"></i></a>
                                        <a (click) = "addVariable('(')" class="formula-button"><i class="fa" aria-hidden="true"></i><b>(</b></a>
                                        <a (click) = "addVariable(')')" class="formula-button"><i class="fa" aria-hidden="true"></i><b>)</b></a>
                                    </div>
                                </div>
    
                                <!-- <div class="flex-container">
                                    <div class="flex-input">
                                        <label for="firstName"><h4>Mark</h4></label>
                                        <h5>Mark will fill on Symbol Creation</h5>
                                    </div>
                                </div> -->
                                
                                <input type="button" (click)="onFormulaSubmitUpdate(formulaDetails.id)" class=" btn btn-success submit" value="Update Formula"
                                style="float: left;">
                                <input type="button" (click)="cancel()" class=" btn btn-success submit" value="Cancel"
                                style="float: right;">
                                
                            </div>
                        </form>
                    </ng-container>
                
                <ng-container *ngIf = "formulas.length == 0">
                    
                </ng-container>
            </div>
        </div>
</html>