import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

import { Subscription } from "rxjs";

import { NgbDate } from "@ng-bootstrap/ng-bootstrap";

import { FilterResult, FilterService } from "../filter.service";

@Component({
  selector: 'app-table-header-date',
  templateUrl: './table-header-date.component.html',
  styleUrls: ['./table-header-date.component.scss']
})
export class TableHeaderDateComponent implements OnInit {
  public _column: any;
  @Input() set column(value: any) {  
    this._column = value;
  }
  get column(): any {
      return this._column;
  }

  /* For Loading, we can disable checkmarks */
  public _loading: any;
  @Input() set loading(value: any) {  
    this._loading = value;
  }
  get loading(): any {
      return this._loading;
  }

  @Output() filterEvent:EventEmitter<any> = new EventEmitter();
  columnChange:EventEmitter<any> = new EventEmitter();
  historicalOrders: any;
  subGetEssentialData: Subscription;
  dbCriteria: any;
  sort = null;
  
  exactDate: any;
  lowerBound: any;
  upperBound: any;

  isYellow = false;
  allFilters: any;
  filterSub: any;
  multiDateView: boolean = false;
  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate;
  toDate: NgbDate | null = null;
  showRange: boolean = false;

  showRangeClick() {
    this.showRange = !this.showRange;
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  constructor(private filterService: FilterService) {}

  ngOnInit() {
    this.dbCriteria = this._column.dbName;
    this.historicalOrders = [];
    this.filterEventOccured();
  }

  ngOnDestroy() {
    this.isYellow = false;
    if (this.filterSub) {
      this.filterSub.unsubscribe();
    }
  }

  filterEventOccured() {
    this.filterSub = this.filterService.filtersSetupObservable.subscribe((data: FilterResult) => {
      this.isYellow = false;
      if (!data) {
        return;
      }

      this.allFilters = data.allFilters ?? [];
      for (var i = 0; i < this.allFilters.length; i++) {
        if (this.allFilters[i].field === this._column.dbName) {
          this.isYellow = true;
        }
      }

      if (!this.isYellow) {
        this.fromDate = null;
        this.toDate = null;
        this.exactDate = "";    
      }
    })
  }
  /* Call this function when a change occurs in the column...changing any value in the object */
  onChange(column) {
    this.columnChange.emit(column);
  }

  hideColumn() {
    this.column.hidden = true;
    this.onChange(this.column);
  }

  clearInputs() {
    this.column.filters.date = null;
    this.column.filters.greaterThan = null;
    this.column.filters.lessThan = null;

    this.exactDate = "";
    this.lowerBound = "";
    this.upperBound = "";
  }

  formatDate(date) {
    if (!date) { return null; }
    return date.year + "/" + date.month + "/" + date.day;
  }

  multipleDateView() {
    this.multiDateView = true;
  }

  singleDateView() {
    this.multiDateView = false;
  }

  setFilters() {
    this.filterService.removeFiltersByField(this._column.dbName);
    let exactDate = this.formatDate(this.exactDate);
    let greaterThan = this.formatDate(this.fromDate);
    let lessThan = this.formatDate(this.toDate);

    this.column.filters.date = exactDate; 
    this.column.filters.greaterThan = greaterThan;
    this.column.filters.lessThan = lessThan;

    this.filterService.filterColumn(this.column);
    this.clearInputs();
  }

  trackByFn(index) {
    return index;
  }

  filterIsSet() {
    if (this._column.filters.date) {
      return true;
    }

    return false;
  }

}