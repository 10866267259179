<button class="btn selector" (click)="open(mymodal)" style="padding-bottom: inherit;"><i style="vertical-align:bottom;"
    class="fa fa-pencil fa-lg" aria-hidden="true"></i></button>

<ng-template #mymodal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title"><Strong>{{titel}}</Strong></h4>
  </div>
  <div class="modal-body">
    <div style="text-align: center;">
      <img [src]="imageSrc || imagePlaceholder"  class="img-fluid" alt="your image"  width="60%">
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-danger pull-left" id="closeBtn" (click)="modal.dismiss('Cross click')"
      data-dismiss="modal">Close</button>
    <input class="btn btn-primary" type="button" id="loadFileXml" value="Upload New Image"
      onclick="document.getElementById('file').click();" />
    <input type="file" accept="image/bmp,image/jpg, image/jpeg,image/png,image/tiff" (change)="readURL($event)" style="display:none;" id="file" name="file" />
  </div>
</ng-template>