import {
  DataTableColumn,
  DataTableColumnDisplayType,
  DataTableColumnFooterDisplayType,
  HeaderFilterType,
} from "src/app/interfaces/data-table-column";

export class ExposureReportTableConfiguration {
  defaultFilters = {
    contains: "",
    greaterThan: "",
    lessThan: "",
  };

  public getColumns(): DataTableColumn[] {
    return [
      {
        columnName: "Ticker",
        dbName: "ticker",
        displayType: DataTableColumnDisplayType.Ticker,
        hidden: false,
        filteredList: false,
        filters: {},
        footerDisplayType: DataTableColumnFooterDisplayType.Hidden,
        footerValue: "",
        headerFilterType: HeaderFilterType.FilteredList,
        headerHoverText: "Ticker",
        isFiltered: false,
        sort: null,
      },
      {
        columnName: "Today",
        dbName: "today",
        displayType: DataTableColumnDisplayType.Currency,
        hidden: false,
        filteredList: false,
        filters: this.defaultFilters,
        footerDisplayType: DataTableColumnFooterDisplayType.Sum,
        footerValue: "",
        headerFilterType: HeaderFilterType.Default,
        headerHoverText: "Today's Exposure",
        isFiltered: false,
        sort: null,
      },
      {
        columnName: "Past 7 Days",
        dbName: "past_7_days",
        displayType: DataTableColumnDisplayType.Currency,
        hidden: false,
        filteredList: false,
        filters: this.defaultFilters,
        footerDisplayType: DataTableColumnFooterDisplayType.Sum,
        footerValue: "",
        headerFilterType: HeaderFilterType.Default,
        headerHoverText: "Past 7 Days Exposure",
        isFiltered: false,
        sort: null,
      },
      {
        columnName: "Past 30 Days",
        dbName: "past_30_days",
        displayType: DataTableColumnDisplayType.Currency,
        hidden: false,
        filteredList: false,
        filters: this.defaultFilters,
        footerDisplayType: DataTableColumnFooterDisplayType.Sum,
        footerValue: "",
        headerFilterType: HeaderFilterType.Default,
        headerHoverText: "Past 30 Days Exposure",
        isFiltered: false,
        sort: null,
      },
      {
        columnName: "YTD",
        dbName: "ytd",
        displayType: DataTableColumnDisplayType.Currency,
        hidden: false,
        filteredList: false,
        filters: this.defaultFilters,
        footerDisplayType: DataTableColumnFooterDisplayType.Sum,
        footerValue: "",
        headerFilterType: HeaderFilterType.Default,
        headerHoverText: "Year To Date Exposure",
        isFiltered: false,
        sort: null,
      },
      {
        columnName: (new Date().getFullYear() - 1).toString(),
        dbName: "year_minus_1",
        displayType: DataTableColumnDisplayType.Currency,
        hidden: false,
        filteredList: false,
        filters: this.defaultFilters,
        footerDisplayType: DataTableColumnFooterDisplayType.Sum,
        footerValue: "",
        headerFilterType: HeaderFilterType.Default,
        headerHoverText:
          (new Date().getFullYear() - 1).toString() + "'s Exposure",
        isFiltered: false,
        sort: null,
      },
      {
        columnName: (new Date().getFullYear() - 2).toString(),
        dbName: "year_minus_2",
        displayType: DataTableColumnDisplayType.Currency,
        hidden: false,
        filteredList: false,
        filters: this.defaultFilters,
        footerDisplayType: DataTableColumnFooterDisplayType.Sum,
        footerValue: "",
        headerFilterType: HeaderFilterType.Default,
        headerHoverText:
          (new Date().getFullYear() - 2).toString() + "'s Exposure",
        isFiltered: false,
        sort: null,
      },
      {
        columnName: (new Date().getFullYear() - 3).toString(),
        dbName: "year_minus_3",
        displayType: DataTableColumnDisplayType.Currency,
        hidden: false,
        filteredList: false,
        filters: this.defaultFilters,
        footerDisplayType: DataTableColumnFooterDisplayType.Sum,
        footerValue: "",
        headerFilterType: HeaderFilterType.Default,
        headerHoverText:
          (new Date().getFullYear() - 3).toString() + "'s Exposure",
        isFiltered: false,
        sort: null,
      },
    ];
  }
}
