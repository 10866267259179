<div>
    <div class="form-container">
        <form>
            <div style="margin-top: 10px" *ngIf="record" class="form-title">
                <b>Account Name: </b>  {{record.trade_account_name}}
            </div>

            <div style="margin-top: 10px"></div>

            <div>
                <div style="margin-top: 10px" class="form-group">
                    <label>Close Date: </label>
                    <div class="dropdown">         
                        <div>
                            <input class="form-control" placeholder="mm-dd-yyyy" #date value="{{close}}"
                            name="dp" ngbDatepicker #d1="ngbDatepicker" >
                        </div>
                        <div class="input-group-append">
                            <button class="date btn-outline-secondary calendar fa fa-calendar" (click)="d1.toggle()" type="button" value="{{close}}"></button>
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <label for="quantity">Quantity: </label>
                    <input type="quantity" 
                           class="form-control" 
                           id="quantity" 
                           aria-describedby="quantity" 
                           [(ngModel)]="quantity"
                           [ngModelOptions]="{standalone: true}">
                </div>
            </div>
            <div>
                <div style="margin-top: 25px" class="form-group-btns">
                    <button class="btn btn-primary" (click)="closeLeg(d1)">Close</button>
                    <button class="btn btn-secondary btn-delete" (click)="delete()">Delete
                        <div *ngIf="loading" class="spinner-container">
                            <div role="status" class="spinner-border">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    </button>
                </div>
                
            </div>
            <hr>
            <div class="cancel-btns">
                <button class="btn btn-secondary" (click)="cancel()">Cancel</button>
            </div>
        </form>
    </div>
</div>
