import { Injectable } from '@angular/core';
import { RuntimeEnvLoaderService } from 'src/app/services/runtime-env-loader.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  private options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
  constructor(private envService: RuntimeEnvLoaderService, private httpClient: HttpClient) { }

  sort(event, field, sort) {

    let className = event.target.classList[1];
    const element = document.querySelector('#sort-i-' + field);
    const allElements = document.querySelectorAll('table i.fa');

    allElements.forEach(allElement => {
      allElement.classList.remove('disabled');
      allElement.classList.add('disabled');
    });

    element.classList.remove(className, 'disabled');
    const sortField = field;
    let sortOrder;

    if (sort == 'asc') {
      sortOrder = 'desc';
      className = 'fa-sort-amount-desc';

    } else if (sort == 'desc') {
      sortOrder = 'asc';
      className = 'fa-sort-amount-asc';
    }
    element.classList.add(className);

    const returnData = {
      sortField,
      sortOrder
    };
    return returnData;
  }

  getAWSPresignedUrl() {
    return this.httpClient.get<any>(`${this.envService.config.API_BASE_URL}${this.envService.config.APP_KEY}/aws-s3-presigned-urls`, this.options);
  }

  uploadFileToAWS(url, file) {
    return this.httpClient.put<any>(url, file);
  }

  createFile(data) {
    return this.httpClient.post<any>(`${this.envService.config.API_BASE_URL}${this.envService.config.APP_KEY}/files`, data, this.options);
  }

  getFile(fileId) {
    return this.httpClient.get<any>(`${this.envService.config.API_BASE_URL}${this.envService.config.APP_KEY}/files/` + fileId, this.options);
  }
}
