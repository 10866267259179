import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { UserService } from 'src/app/services/app/user/user.service';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/services/app/user/app.service';
import stateList from '../../../constants/user/state-list';
import { UserRole } from '../../../constants/user/user-role';
import { isString } from 'util';
import { Router } from '@angular/router';
import { Subscription, forkJoin } from 'rxjs';

@Component({
    selector: 'app-create-user',
    templateUrl: './create-user.component.html',
    styleUrls: ['./create-user.component.scss']
})
export class CreateUserComponent implements OnInit {

    adminDisable = false;
    employeeDisable = false;
    getAwsPresignedUrls: any;
    imgFile: any;
    profileImg: any;
    submitted = false;
    statesList: any;
    userId: any;
    userRoleId = [];
    viewNote = false;
    roleRequired = false;
    loading = false;
    selectedImageSrc: any;
    currentUserDetails: any;
    logUserData: any;
    userLog: any;
    logginUserRoleIds = [];
    newValueToPasswordField: any;
    viewSelectText = true;
    subGetLoggingData: Subscription;

    userCreateForm: FormGroup;

    constructor(
        private userService: UserService,
        private toastr: ToastrService,
        private appService: AppService,
        private router: Router,
    ) {
        this.profileImg = '../assets/img/test3.jpeg';
        this.currentUserDetails = localStorage.getItem('currentUser');
    }

    get f() { return this.userCreateForm.controls; }

    ngOnInit() {
        this.getEssentialData();
    }

    ngOnDestroy() {
        if (this.subGetLoggingData != undefined) {
            this.subGetLoggingData.unsubscribe();
        }
    }

    getEssentialData() {
        this.statesList = stateList;

        this.userCreateForm = new FormGroup({
            firstName: new FormControl('', [Validators.required, this.noWhitespace]),
            lastName: new FormControl('', [Validators.required, this.noWhitespace]),
            email: new FormControl('', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{1,}$')]),
            address1: new FormControl(''),
            address2: new FormControl(''),
            city: new FormControl(''),
            state: new FormControl(''),
            zip: new FormControl('', [Validators.minLength(5), Validators.pattern('^[0-9]*$')]),
            phone1: new FormControl('', [Validators.minLength(10), Validators.pattern('^[0-9]*$')]),
            notes: new FormControl({ value: '', disabled: true }),
            password: new FormControl('', [Validators.minLength(6)]),
        });

        this.checkLogUserId();
    }

    isClickState(event) {
        if (event.target.value != '') {
            this.viewSelectText = false;
        }

    }

    checkLogUserId() {
        this.logUserData = JSON.parse(this.currentUserDetails);

        const getloggedUser = this.userService.getUserDetails(this.logUserData.id);
        this.subGetLoggingData = forkJoin([getloggedUser]).subscribe((logUser) => {
            this.userLog = logUser[0];

            this.userLog.user_roles.forEach(element => {
                if (element.id == UserRole.admin) {
                    this.logginUserRoleIds.push(UserRole.admin);
                } else if (element.id == UserRole.employee) {
                    this.logginUserRoleIds.push(UserRole.employee);
                }
            });
            const idxOfAdmin = this.logginUserRoleIds.indexOf(UserRole.admin);
            const idxOfEmployee = this.logginUserRoleIds.indexOf(UserRole.employee);

            if (idxOfAdmin != -1) {
                this.userCreateForm.controls.notes.enable();
            } else if (idxOfEmployee != -1) {
                this.userCreateForm.controls.notes.disable();
            } else if (idxOfEmployee != -1 && idxOfAdmin != -1) {
                this.userCreateForm.controls.notes.enable();
            }
        });
    }

    public noWhitespace(control: FormControl) {
        return (isString(control.value) && control.value.trim() == '') ?
            { required: true } :
            null;
    }

    imageSelect(file) {
        if (file.length === 0) {
            return;
        }
        this.imgFile = file[0];

        const reader = new FileReader();
        reader.readAsDataURL(file[0]);
        reader.onload = (_event) => {
            this.profileImg = reader.result;
            this.selectedImageSrc = reader.result;
        };
    }

    toasterDisplay(show, msg, status, type) {
        if (show == true) {
            if (type == 'success') {
                // this.router.navigate(['user-list']);
                this.toastr.success(msg, status);
            } else if (type == 'error') {
                this.toastr.error(msg, status);
            } else if (type == 'info') {
                this.toastr.info(msg, status);
            } else if (type == 'warning') {
                this.toastr.warning(msg, status);
            }
        }
    }

    niceBytes(sizeInBytes) {
        const sizeInMB = (sizeInBytes / (1024 * 1024)).toFixed(2);
        return sizeInMB;
    }

    checkAdminChecked(event) {
        if (event.target.checked == true) {
            this.userRoleId.push(UserRole.admin);

        } else if (event.target.checked == false) {
            const idx = this.userRoleId.indexOf(UserRole.admin);

            if (idx != -1) {
                this.userRoleId.splice(idx, 1);
            }
        }
        if (event.target.checked == true) {
            this.employeeDisable = true;
            this.viewNote = true;
            this.roleRequired = false;
        } else if (event.target.checked == false) {
            this.employeeDisable = false;
            this.viewNote = false;
            this.roleRequired = true;
        }

    }

    checkEmployeeChecked(event) {
        if (event.target.checked == true) {
            this.userRoleId.push(UserRole.employee);

        } else if (event.target.checked == false) {
            const idx = this.userRoleId.indexOf(UserRole.employee);

            if (idx != -1) {
                this.userRoleId.splice(idx, 1);
            }
        }

        if (event.target.checked == true) {
            this.adminDisable = true;
            this.roleRequired = false;
        } else if (event.target.checked == false) {
            this.adminDisable = false;
            this.roleRequired = true;
        }

    }

    randomPassword() {
        let length = 10,
            charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789',
            retVal = '';
        for (let i = 0, n = charset.length; i < length; ++i) {
            retVal += charset.charAt(Math.floor(Math.random() * n));
        }
        return retVal;
    }

    generatePW() {
        this.userCreateForm.controls.password.setValue(this.randomPassword());
    }

    onSubmit() {
        this.submitted = true;

        const form = this.userCreateForm;
        if (!form.valid || this.userRoleId.length == 0) {
            this.submitted = true;
            if (this.userRoleId.length == 0) {
                this.roleRequired = true;
            }
            return;
        }
        this.loading = true;
        const credentions = form.value;

        var phone_number = credentions.phone1;
        phone_number = addDashes(phone_number);

        function addDashes(phone_number)
        {
            phone_number = phone_number.replace(/\D[^\.]/g, "");
            phone_number = phone_number.slice(0,3)+"-"+phone_number.slice(3,6)+"-"+phone_number.slice(6);
            return phone_number
        }

        var submitData = {};
        if (credentions.password == '') {
            this.newValueToPasswordField = this.randomPassword();
            const submitData = {
                first_name: credentions.firstName,
                last_name: credentions.lastName,
                email: credentions.email,
                phone_number: phone_number,
                address_1: credentions.address1,
                address_2: credentions.address2,
                city: credentions.city,
                state: credentions.state,
                zip: credentions.zip,
                notes: credentions.notes,
                password: this.newValueToPasswordField,
                user_role_ids: JSON.stringify(this.userRoleId),
            };

        } else if (credentions.password != '') {
            const submitData = {
                first_name: credentions.firstName,
                last_name: credentions.lastName,
                email: credentions.email,
                phone_number: phone_number,
                address_1: credentions.address1,
                address_2: credentions.address2,
                city: credentions.city,
                state: credentions.state,
                zip: credentions.zip,
                notes: credentions.notes,
                password: credentions.password,
                user_role_ids: JSON.stringify(this.userRoleId),
            };
        }

        this.userService.createUser(submitData).subscribe(
            (result: any) => {
                this.loading = true;
                this.userId = result.id;

                if (this.imgFile == undefined) {
                    this.loading = false;
                    this.toasterDisplay(true, 'User Account successfully created.', 'Successfull!', 'success');
                    this.router.navigate(['user-list']);
                } else if (this.imgFile != undefined) {
                    this.loading = true;

                    this.appService.getAWSPresignedUrl().subscribe((responseOfAwsPresignedUrl) => {

                        this.getAwsPresignedUrls = responseOfAwsPresignedUrl.data;
                        const url = this.getAwsPresignedUrls.url;

                        this.appService.uploadFileToAWS(url, this.imgFile).subscribe(
                            result => {

                                const fileName = this.getAwsPresignedUrls.file_name;

                                const fileData = {
                                    s3_location: fileName.replace('uploads/', ''),
                                    name: this.imgFile.name,
                                    file_type: this.imgFile.type,
                                    size: this.niceBytes(this.imgFile.size)
                                };

                                this.userService.updateUserFiles(this.userId, fileData).subscribe(
                                    result => {

                                        const updateClientDetails = { avatar_file_id: result.id };

                                        this.userService.updateUserDetails(this.userId, updateClientDetails).subscribe(
                                            result => {
                                                if (result != '') {
                                                    this.loading = false;
                                                    this.toasterDisplay(true, 'User Account successfully created.', 'Successfull!', 'success');
                                                    this.router.navigate(['user-list']);
                                                }
                                            });

                                    },
                                    error => {
                                        this.loading = false;
                                        this.toasterDisplay(true, 'File didn\'t update correctlly.', 'Fail!', 'error');
                                    });

                            },
                            error => {
                                this.loading = false;
                                this.toasterDisplay(true, 'File didn\'t upload to AWS correctlly.', 'Fail!', 'error');
                            });
                    });
                }
            });

    }
}
