import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {
  resetForm: FormGroup;
  submitted = false;
  loading = false;
  constructor(private formBuilder: FormBuilder, private authService: AuthenticationService, public router: Router) { }
  get f() { return this.resetForm.controls; }

  ngOnInit() {
    this.resetForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  onSubmit() {
    this.submitted = true;
    if (!this.resetForm.invalid) {

      const email = this.resetForm.value.email;
      this.loading = true;
      this.authService.resetPassword(email).subscribe(res => {
        this.loading = false;
        Swal.fire({
          text: 'Password reset link emailed! Please check your inbox',
          icon: 'success'
        }).then(() => {
          this.router.navigate(['/']);
        });

      }, () => {
        this.loading = false;
      });

    } else {
      return false;
    }
  }
}
