import { Component, OnInit, Output, EventEmitter, Input, SimpleChanges, OnChanges } from '@angular/core';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit, OnChanges {

  @Input() totalCount: any;
  @Output() navigate: EventEmitter<number> = new EventEmitter();
  @Output() pageCount: EventEmitter<number> = new EventEmitter();

  countA = 0;
  isPagination = false;
  page: any;
  showCount: any;
  showCounts = [];
  totalPages = [];

  constructor(
  ) {
    this.page = 1;
    const currentLength = (localStorage.getItem('page-count')) ?
      localStorage.getItem('page-count') : String(environment.DEFAULT_SORT.USERS.PAGE_COUNT[0]);
    this.countA = parseInt(currentLength);
    this.showCounts = environment.DEFAULT_SORT.USERS.PAGE_COUNT;
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.totalCount.currentValue != null) {
      this.page = changes.totalCount.currentValue.page;
      this.generatePages(changes.totalCount.currentValue);
    }
  }

  generatePages(params) {
    if (params != undefined) {

      this.totalPages = [];
      const totalCount = Number(params.totalCount);
      const countPerPage = Number(params.countPerPage);
      let starting;
      let ending;

      const arrayCount = Math.ceil(totalCount / countPerPage) + 1;
      for (let index = 1; index < arrayCount; index++) {
        this.totalPages.push(index);
      }

      if (totalCount > countPerPage) {
        this.isPagination = true;
        this.totalCount = totalCount;

        if (this.page == 1) {
          starting = this.page;
          ending = countPerPage;

        } else if (this.page > 1) {
          starting = ((this.page - 1) * countPerPage) + 1;
          ending = this.page * countPerPage;
        }
        if (ending > totalCount) {
          ending = totalCount;
        }
      } else if (totalCount == 0) {
        this.isPagination = false;
        starting = 0;
        ending = totalCount;
        this.totalCount = totalCount;
      } else {
        this.isPagination = false;
        starting = 1;
        ending = totalCount;
        this.totalCount = totalCount;
      }
    }
  }

  pageClick(page) {
    this.navigate.emit(page);
  }

  next(page) {
    let nextPage;
    if (page < this.totalPages.length) {
      nextPage = page + 1;
    } else if (page == this.totalPages.length) {
      nextPage = page;
    }
    this.pageClick(nextPage);
  }

  previous(page) {
    let previousPage;
    if (page > 1) {
      previousPage = page - 1;
    } else if (page == 1) {
      previousPage = page;
    }
    this.pageClick(previousPage);
  }

  pageLength(pageLength) {
    if (pageLength.target.value != null) {
      localStorage.setItem('page-count', pageLength.target.value);
      this.pageCount.emit(pageLength.target.value);
    } else {
      this.pageCount.emit(Number(this.showCounts.pop));
    }
  }
}
