import { DataTableColumn } from "src/app/interfaces/data-table-column";
import { AuthenticationService } from "src/app/services/authentication.service";
import { FullScreenService } from "src/app/services/full-screen.service";

import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-filter-header",
  templateUrl: "./filter-header.component.html",
  styleUrls: ["./filter-header.component.scss"],
})
export class FilterHeaderComponent implements OnInit {
  @Input() tableColumns: Array<DataTableColumn>;
  @Input() headerMessage: String;
  @Input() pageTitle: String;

  @Output() onFullScreen = new EventEmitter<boolean>();
  @Output() onFiltersToggle = new EventEmitter<boolean>();
  @Output() onClearAllFilters = new EventEmitter<boolean>();
  @Output() onHideFooter = new EventEmitter<boolean>();

  filtersCollapsed = false;

  constructor(
    private router: Router,
    public fullScreenService: FullScreenService,
    public authService: AuthenticationService
  ) {}

  ngOnInit() {}

  fullScreen() {
    this.onFullScreen.emit(true);
  }

  toggleFiltersCollapse() {
    this.filtersCollapsed = !this.filtersCollapsed;
    this.onFiltersToggle.emit(true);
  }

  showAllColumns() {
    for (const col of this.tableColumns) {
      col.hidden = false;
    }
  }

  showSpecificColumn(dbName) {
    for (const col of this.tableColumns) {
      if (col.dbName == dbName) {
        col.hidden = !col.hidden;
      }
    }
  }

  clearAllFilters() {
    this.onClearAllFilters.emit(true);
  }

  closeFooter() {
    this.onHideFooter.emit(true);
  }

  logout() {
    const isSimulating: boolean =
      localStorage.getItem("isSimulating") === "true";

    if (isSimulating) {
      this.authService.logoutFromSimulation();
      window.location.reload();
    } else {
      this.authService.logout();
      this.router.navigate(["/login"]);
    }
    this.fullScreenService.isFullScreen = false;
  }
}
