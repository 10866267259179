<ng-container *ngIf= "!column.hidden" class="historicalTableHead">
    {{column.columnName}}
    <div>
        <div class = "d-inline-block" ngbDropdown>
            <div class="header-contents"> 
                <button 
                    [ngClass]="{'is-filtered': isYellow}"
                    class="fa fa-filter dropdown-btn"
                    id="dropdownBasic1"
                    ngbDropdownToggle >
                </button>

                <app-sort 
                    [loading]="_loading" 
                    [column]="_column"
                ></app-sort>
            </div>

            <div 
                #drop 
                ngbDropdownMenu 
                aria-labelledby="dropdownBasic1" 
                class="dropdown-container">
                <div class="dropdown">
                    <div 
                        class="filter" 
                        *ngFor="let item of _column.filters | keyvalue; let i = index; trackBy: trackByFn"> 
                        <label for="'input'+i" class="label">{{formatFilters(item.key)}} </label>
                        <input  
                            class="input"
                            [attr.name]="'input'+i"
                            [attr.id]="'input'+i"
                            type="text" 
                            [(ngModel)]="item.value" 
                            (ngModelChange)="filterChange($event, item)"/>
                    </div>
                </div>
                <div class="btns">
                    <button class="filter-btn" type = "submit" (click)="hideColumn()">Hide Column</button>
                    <button ngbDropdownToggle class="filter-btn apply-btn" type = "submit" (click)="setFilters()">Apply Filter</button>
                </div>
            </div>
        </div>
    </div>
</ng-container>