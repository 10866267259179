<div *ngIf="isCustomLayout" class="no-layout">
  <router-outlet></router-outlet>
</div>
<div *ngIf="!isCustomLayout">
  <div [class]="application">
    <app-topmenu *ngIf="!this.fullScreenService.isFullScreen"></app-topmenu>
  </div>
  <div>
    <div class="content-wrapper">
      <router-outlet></router-outlet>
    </div>
  </div>
  <!-- <app-footer [class]="application"></app-footer> -->
</div>
