import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/services/app/user/user.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import Swal from 'sweetalert2';

import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: "app-notifications",
  templateUrl: "./notifications.component.html",
  styleUrls: ["./notifications.component.scss"],
})
export class NotificationsComponent implements OnInit {
  notifications: any;
  subGetNotes: any;
  loading: any = false;
  newNote: any;
  newNoteText: any;
  notificationDate: any;
  lastRow: any;
  allSelectedArr: any;
  selectedVal: any;
  backupNotifications: any;
  query: any;

  constructor(
    private notificationsService: NotificationsService,
    private toastr: ToastrService,
    private authService: AuthenticationService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.getNotes();
  }

  ngOnDestroy() {}

  select(val) {
    this.selectedVal = val;
    this.getAllSelected();
  }

  formatDate(date) {
    if (!date) {
      return null;
    }
    return date.year + "/" + date.month + "/" + date.day;
  }

  selectAll() {
    for (var i = 0; i < this.notifications.length; i++) {
      this.notifications[i].check = true;
    }
  }

  deselectAll() {
    for (var i = 0; i < this.notifications.length; i++) {
      this.notifications[i].check = false;
    }
  }

  getAllSelected() {
    this.allSelectedArr = [];
    for (var i = 0; i < this.notifications.length; i++) {
      if (this.notifications[i].check) {
        this.allSelectedArr.push(this.notifications[i].id);
      }
    }
  }

  search(s: any) {
    this.query = s;
  }

  editNote(note) {
    note.edit = true;
  }

  newNotification() {
    this.newNote = true;
  }

  formatNotesToJSON(notes) {
    for (var i = 0; i < notes.length; i++) {
      notes[i].name = JSON.parse(notes[i].name);
    }
  }

  getNotes() {
    let cUser = JSON.parse(localStorage.getItem("currentUser"));
    this.loading = true;
    this.subGetNotes = this.notificationsService
      .getNotifications(cUser["id"])
      .subscribe((result) => {
        this.notifications = result.body.data;
        this.backupNotifications = result.body.data;
        this.loading = false;
        this.newNote = false;
        this.formatNotesToJSON(this.notifications);
        this.markNotesRead();
      });
  }

  markNotesRead() {
    let notes = [];
    for (var i = 0; i < this.notifications.length; i++) {
      if (!this.notifications[i].is_read) {
        notes.push(this.notifications[i].id);
      }
    }

    let params = new HttpParams().set("noteIds", notes.join(", "));

    this.notificationsService.editNotifications(params).subscribe((result) => {
      this.loading = false;
    });
  }

  saveNote(note) {
    this.loading = true;
    note.edit = false;
    let params = new HttpParams().set("name", note.name).set("noteId", note.id);

    let t = this.notificationsService
      .editNotifications(params)
      .subscribe((result) => {
        this.loading = false;
      });
  }

  selectDays(days) {
    /* If user selects all days, use the backup notifications to restore notifications */
    if (days === "all") {
      this.notifications = this.backupNotifications;
      return;
    }
    /* Create date object based on days, and compare to date in notifications, setting notifications to 
    [] and then pushing into it */
    this.notifications = [];
    let notifications = this.backupNotifications;

    var d = new Date();
    d.setDate(d.getDate() - days);

    for (var i = 0; i < notifications.length; i++) {
      let createdAtDate = new Date(notifications[i].created_at);

      if (d < createdAtDate) {
        this.notifications.push(notifications[i]);
      }
    }
  }

  deleteNotes() {
    this.loading = true;
    Swal.fire({
      title: "Are you sure?",
      text: "This will delete the selected messages",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ok",
    }).then((result) => {
      for (var i = 0; i < this.allSelectedArr.length; i++) {
        this.notificationsService
          .deleteNotifications(this.allSelectedArr[i])
          .subscribe((result) => {
            this.toastr.success("Message Deleted");
            this.loading = false;
            this.getNotes();
          });
      }
    });
  }
}
