<div class="container-fluid custom-layout-container">
    <div class="row">
        <div class="col-md-offset-4 col-md-4">
            <div class="login-container">
                <div class="loader" *ngIf="loading">
                    <img src="./assets/public_img_load.gif" />
                </div>
                <img src="./assets/PRISM_Logo_Hz_-_white.png" class="img-responsive" />
              <div class="card card-white">
                <div class="card-header">
                    <h4 class="strong">Forgot your password ?</h4>
                    <h4>Enter your email and we'll send a password reset link.</h4>
                </div>
                <div class="card-body">
                        <form [formGroup]="resetForm" (ngSubmit)="onSubmit()">
                            <div class="form-group has-feedback">
                                <input formControlName="email" autocomplete="true" type="email" class="form-control form-control-lg rounded-0" placeholder="Email">
                                <span class="glyphicon glyphicon-envelope form-control-feedback"></span>
                                <div class="invalid-feedback" *ngIf="submitted && f.email.errors">
                                    <div *ngIf="f.email.errors.required">User email is required</div>
                                    <div *ngIf="f.email.errors.email">User email is invalid</div>
                                </div>
                            </div>
                            
                            <div class="form-footer">
                                <div class="row">
                                    <div class="col-xs-12">
                                        <button type="submit" class="btn btn-success " id="btnLogin">Reset Password</button>
                                        <a [routerLink]="['/login']" class="reset-link">Login</a>
                                    </div>
                                </div>
                                
                            </div>
                        </form>
                </div>
                <!--/card-block-->
            </div>
            <!-- /form card login -->
            
            
            </div>
        </div>
    </div>
</div>