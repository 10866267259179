import { Subject } from "rxjs";

import { Injectable } from "@angular/core";

export interface FilterResult {
  allFilters: any;
  bubbleList: any;
}
@Injectable()
export class FilterService {
  private filterAppliedSubject = new Subject<FilterResult>();
  private bubbleClosedSubject = new Subject<FilterResult>();
  private filtersLoadedSubject = new Subject<FilterResult>();

  public bubbleClosedObservable = this.bubbleClosedSubject.asObservable();
  public filtersSetupObservable = this.filtersLoadedSubject.asObservable();
  public filterAppliedObservable = this.filterAppliedSubject.asObservable();

  // We need to save the table data so we can get the distinct values for the filtered lists
  private filteredTableData = [];
  public filters = [];
  public bubbleList = [];

  public setupFiltersForTable(tableData, filters = null) {
    this.filters = filters;
    this.filteredTableData = tableData ?? [];
    this.bubbleList = this.createFilterBubbleList(filters);

    // Notify the subscribers (filteredLists) that they need to get the new distinct values for their filtered column
    this.filtersLoadedSubject.next({
      allFilters: this.filters,
      bubbleList: this.bubbleList,
    });
  }

  public bubbleClosed(remainingFilters) {
    this.filters = remainingFilters;
    this.bubbleList = this.createFilterBubbleList(remainingFilters);
    this.bubbleClosedSubject.next({
      allFilters: this.filters,
      bubbleList: this.bubbleList,
    });

    // I am calling this also now because we dont reload the data when the bubble is closed, so we need to update the filtered list distinct values
    this.filtersLoadedSubject.next({
      allFilters: this.filters,
      bubbleList: this.bubbleList,
    });
  }

  public applyFilter(allFilters) {
    this.filters = allFilters;
    this.bubbleList = this.createFilterBubbleList(allFilters);

    this.filterAppliedSubject.next({
      allFilters: this.filters,
      bubbleList: this.bubbleList,
    });
  }

  /* Helper function, just returns ONLY filtered list filters that have chech = true, or ONLY normal filters that are already applied */
  public removeUncheckedFilteredLists() {
    this.filters = this.filters.filter(
      (data) => (data.filteredList && data.check) || !data.filteredList
    );
  }

  /* Removes all filters that match the field */
  public removeFiltersByField(field) {
    this.filters = this.filters.filter((data) => data.field != field);
    return this.filters;
  }

  /* Called once per filter to add a filter to allFilters */
  public addFilter(filter) {
    let filterPresent = false;

    if (!this.filters) {
      this.filters = [];
      this.filters.push(filter);
    }

    /* When adding a filter, we just have to check that it's not already there before we add it.  If it is, we replace it rather
    than adding a duplicate */
    for (var i = 0; i < this.filters.length; i++) {
      let isFilteredList = filter.filteredList;

      /* Criteria to replace filter if it's not a Filtered List */
      if (!isFilteredList) {
        if (
          this.filters[i].field == filter.field &&
          this.filters[i].operator == filter.operator
        ) {
          if (this.filters[i].operator != "date") {
            this.filters[i] = filter;
            filterPresent = true;
          }
        }
      }
    }

    if (!filterPresent) {
      this.filters.push(filter);
    }
  }

  filterList(column) {
    if (!this.filters) {
      this.filters = [];
    }

    /* Remove all filters that are currently there for filtered list of this dbName */
    this.filters = this.filters.filter((e) => e.field != column.dbName);

    for (var i = 0; i < column.filters.length; i++) {
      let filter = {
        field: column.dbName,
        operator: "contains",
        value: column.filters[i].value,
        filteredList: true,
        check: column.filters[i].check,
      };

      this.filters.push(filter);
    }

    this.bubbleList = this.createFilterBubbleList(this.filters);

    this.filterAppliedSubject.next({
      allFilters: this.filters,
      bubbleList: this.bubbleList,
    });
  }

  public filterColumn(column) {
    for (const key in column.filters) {
      let filter = {};

      /* If It's a filtered list, and it's checked */
      if (column.filteredList) {
        filter = {
          field: column.dbName,
          operator: "contains",
          value: column.filters[key].value,
          filteredList: true,
          check: column.filters[key].check,
        };

        this.addFilter(filter);
        /* If it's not a filtered list, and it has an input value from the user */
      } else if (!column.filteredList && column.filters[key]) {
        filter = {
          field: column.dbName,
          operator: key,
          value: column.filters[key],
        };

        this.addFilter(filter);
      }
    }

    this.removeUncheckedFilteredLists();
    this.applyFilter(this.filters);
  }

  /* Simple function to get contains, greaterThan, lessThan formatting for the bubbles */
  public getOperatorTypes(operator) {
    if (operator == "greaterThan") {
      return ">";
    } else if (operator == "lessThan") {
      return "<";
    } else if (operator == "contains") {
      return "=";
    } else if (operator == "date") {
      return "=";
    } else {
      return "=";
    }
  }

  public createFilterBubbleList(allFilters) {
    if (!allFilters) {
      return;
    }

    let bubbles = [];
    for (var i = 0; i < allFilters.length; i++) {
      let bubbleName = allFilters[i].field;
      let value = allFilters[i].value;

      if (bubbles[bubbleName]) {
        bubbles[bubbleName] +=
          " , " + this.getOperatorTypes(allFilters[i].operator) + " " + value;
      } else {
        bubbles[bubbleName] =
          this.getOperatorTypes(allFilters[i].operator) + " " + value;
      }
    }

    return bubbles;
  }

  /*
    Plan on expanding this as a general function to convert string names.
  */
  public formatColumnNames(filters) {
    for (var f of filters) {
      /* OPTION TYPE COLUMN */
      if (f.value === "Call Option") {
        f.value = "Call";
      } else if (f.value === "Put Option") {
        f.value = "Put";

        /* IS PARTIAL COLUMN */
      } else if (f.value === "Y") {
        f.value = "YES";
      } else if (f.value === "N") {
        f.value = "NO";
      }
    }

    return filters;
  }

  public getDistinctValuesForFilteredLists(column: string): Array<any> {
    let distinctValues = this.filteredTableData
      .map((row) => row[column])
      .filter((value, index, self) => self.indexOf(value) === index)
      .sort()
      .map((value) => {
        return {
          value: value,
        };
      });

    distinctValues = distinctValues.filter((value) => {
      return (
        value.value != null && value.value != "null" && value.value != undefined
      );
    });

    return distinctValues;
  }

  public filter(data: any, filters: any): any[] {
    let filteredData = data;

    if (!filters) {
      return filteredData;
    }

    // The filters that have multiple selections need to be filtered with the OR operator
    const filteredLists = filters.filter((filter) => {
      return filter.filteredList;
    });

    filteredData = this.processFilteredListFilters(filteredData, filteredLists);

    // The individual filters that have a single selection need to be filtered with the AND operator
    const individualFilters = filters.filter((filter) => {
      return filteredLists.includes(filter) === false;
    });

    filteredData = this.processIndividualFilters(
      filteredData,
      individualFilters
    );

    this.setupFiltersForTable(filteredData, this.filters);

    return filteredData;
  }

  private processIndividualFilters(filteredData: any, individualFilters: any) {
    individualFilters.forEach((filter) => {
      if (
        filter.operator === "date" ||
        (filter.value.indexOf("/") > 0 && !isNaN(Date.parse(filter.value)))
      ) {
        // Date filter
        const filterDate = new Date(filter.value);
        const filterDateUTCMillis = this.getUTCMillisForDate(filterDate);

        if (filter.operator === "greaterThan") {
          filteredData = filteredData.filter((dataItem) => {
            const dataItemDateValue = new Date(dataItem[filter.field]);
            let dataItemDateUTCMillis =
              this.getUTCMillisForDate(dataItemDateValue);
            return dataItemDateUTCMillis >= filterDateUTCMillis;
          });
        } else if (filter.operator === "lessThan") {
          filteredData = filteredData.filter((dataItem) => {
            const dataItemDateValue = new Date(dataItem[filter.field]);
            let dataItemDateUTCMillis =
              this.getUTCMillisForDate(dataItemDateValue);
            return dataItemDateUTCMillis <= filterDateUTCMillis;
          });
        } else if (filter.operator === "date") {
          filteredData = filteredData.filter((dataItem) => {
            if (dataItem[filter.field] == null) {
              return false;
            }

            const dataItemDateValue = new Date(
              dataItem[filter.field].split(" ")[0]
            );
            let dataItemDateUTCMillis =
              this.getUTCMillisForDate(dataItemDateValue);
            return filterDateUTCMillis === dataItemDateUTCMillis;
          });
        }
      } else if (filter.operator === "contains") {
        filteredData = filteredData.filter((dataItem) => {
          return String(dataItem[filter.field])
            .toLowerCase()
            .includes(String(filter.value).toLowerCase());
        });
      } else if (filter.operator === "greaterThan") {
        filteredData = filteredData.filter((dataItem) => {
          return dataItem[filter.field] > filter.value;
        });
      } else if (filter.operator === "lessThan") {
        filteredData = filteredData.filter((dataItem) => {
          return dataItem[filter.field] < filter.value;
        });
      } else {
        filteredData = filteredData.filter((dataItem) => {
          return (
            String(dataItem[filter.field]).toLowerCase() ==
            String(filter.value).toLowerCase()
          );
        });
      }
    });

    return filteredData;
  }

  private processFilteredListFilters(filteredData: any, filteredLists: any) {
    const uniqueFilteredListFields = [
      ...new Set(filteredLists.map((filter) => filter.field)),
    ];

    uniqueFilteredListFields.forEach((filterField) => {
      let fieldFilters = filteredLists.filter((filter) => {
        return filter.field === filterField;
      });

      filteredData = filteredData.filter((order) => {
        for (let fieldFilter of fieldFilters) {
          // Check each field to see if we find a match in our list of fields to compare otherwise return false
          if (
            String(order[fieldFilter.field]).toLowerCase() ==
            String(fieldFilter.value).toLowerCase()
          ) {
            return true;
          }
        }

        return false;
      });
    });

    return filteredData;
  }

  // TODO: (Michael) I need to add this to the Date prototype so I can use this method anywhere
  getUTCMillisForDate(date: Date) {
    return Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate()
    );
  }
}
