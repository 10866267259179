import { Component, Input, OnInit } from "@angular/core";

import { SortService } from "../../historical-orders/sort.service";

@Component({
  selector: "app-sort",
  templateUrl: "./sort.component.html",
  styleUrls: ["./sort.component.scss"],
})
export class SortComponent implements OnInit {
  public _loading: any;
  @Input() set loading(value: any) {
    this._loading = value;
  }
  get loading(): any {
    return this._loading;
  }

  public _column: any;
  @Input() set column(value: any) {
    this._column = value;
  }
  get column(): any {
    return this._column;
  }

  sort: any = {};

  constructor(public sortService: SortService) {}

  ngOnInit() {
    this.sortSubscribe();
  }

  sortColumn(direction) {
    if (this._loading) {
      return;
    }

    this.sortService.sortColumnByDirection(this.column, direction);
  }

  /* Match it up by DB-name so we can get the latest sort working */
  sortSubscribe() {
    this.sortService.sortChangedObservable.subscribe((lastSorted) => {
      if (lastSorted["sortBy"] == this._column.dbName) {
        this.sort["sortOrder"] = lastSorted["sortOrder"];
      } else {
        this.sort["sortOrder"] = "";
      }
    });
  }
}
