import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { RuntimeEnvLoaderService } from "./runtime-env-loader.service";
import { map, catchError } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class NotificationsService {
  basePath: string;
  currNotification: any;
  timeZone = "America/Chicago";

  constructor(
    private http: HttpClient,
    private envLoader: RuntimeEnvLoaderService
  ) {
    this.basePath = envLoader.config.API_BASE_URL + envLoader.config.APP_KEY;
  }

  setCurrNotification(notification) {
    this.currNotification = notification;
  }

  getCurrNotification() {
    return this.currNotification;
  }

  getNotifications(userId: any): Observable<any> {
    let path = "/users/" + userId + "/messages";
    const headers = new HttpHeaders().set(
      "security-token",
      JSON.parse(localStorage.getItem("currentUser")).token
    );
    return this.http
      .get<any>(this.basePath + path, { headers, observe: "response" })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError(this.handleError)
      );
  }

  editNotifications(data): Observable<any> {
    const headers = new HttpHeaders().set(
      "security-token",
      JSON.parse(localStorage.getItem("currentUser")).token
    );
    return this.http
      .post<any>(this.basePath + "/message", data, { headers })
      .pipe(
        map((response) => {
          return response.data;
        }),
        catchError(this.handleError)
      );
  }

  deleteNotifications(id): Observable<any> {
    const headers = new HttpHeaders().set(
      "security-token",
      JSON.parse(localStorage.getItem("currentUser")).token
    );

    return this.http
      .delete<any>(this.basePath + "/messages/" + id, { headers })
      .pipe(
        map((response) => {
          return 200;
        }),
        catchError(this.handleError)
      );
  }

  editScheduledJob(id, data): Observable<any> {
    const headers = new HttpHeaders().set(
      "security-token",
      JSON.parse(localStorage.getItem("currentUser")).token
    );
    return this.http
      .patch<any>(this.basePath + "/scheduled-jobs/" + id, data, { headers })
      .pipe(
        map((response) => {
          return response.data;
        }),
        catchError(this.handleError)
      );
  }

  getScheduledJobs(): Observable<any> {
    const headers = new HttpHeaders().set(
      "security-token",
      JSON.parse(localStorage.getItem("currentUser")).token
    );
    return this.http
      .get<any>(this.basePath + "/scheduled-jobs", {
        headers,
        observe: "response",
      })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError(this.handleError)
      );
  }

  newScheduledJob(data): Observable<any> {
    const headers = new HttpHeaders().set(
      "security-token",
      JSON.parse(localStorage.getItem("currentUser")).token
    );
    return this.http
      .post<any>(this.basePath + "/scheduled-jobs", data, { headers })
      .pipe(
        map((response) => {
          return response.data;
        }),
        catchError(this.handleError)
      );
  }

  handleError(error) {
    return throwError("Error");
  }
}
