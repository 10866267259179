<header class="main-header">
  <!-- Logo -->
  <div class="topmenu-contain">
    <a href="index2.html" class="logo">
      <!-- mini logo for sidebar mini 50x50 pixels -->
      <!-- <span class="logo-mini"><b>S</b></span> -->
      <!-- logo for regular state and mobile devices -->
      <img
        src="../../../assets/Domek - wht - transparent.png"
        class="load-img img-fluid"
      />
    </a>
    <!-- Header Navbar: style can be found in header.less -->
    <nav class="navbar navbar-static-top navbar-static-top-custom-style">
      <!-- Sidebar toggle button-->
      <p
        style="
          font-size: 28px;
          vertical-align: middle;
          margin-left: 250px;
          display: contents;
          color: white;
        "
      >
        <b>{{ pageTitle }}</b>
      </p>
      <a href="#" class="sidebar-toggle" data-toggle="push-menu" role="button">
        <span class="sr-only">Toggle navigation</span>
      </a>

      <div class="navbar-custom-menu" style="max-height: 65px">
        <div class="navigation-buttions">
          <ul class="nav navbar-nav">
            <li class="treeview">
              <a class="nav-buttons" routerLink="/">
                <span class="icon"
                  ><i class="fa fa-dashboard"></i> Dashboard</span
                >
              </a>
            </li>

            <li class="treeview" (click)="openAddNotificationDialog()">
              <a class="nav-buttons">
                <span class="icon"
                  ><i class="fa fa-bell"></i> Add Notification</span
                >
              </a>
            </li>

            <li class="treeview" [routerLinkActive]="['active-item']">
              <a class="nav-buttons portfolio-button" routerLink="/reports">
                <span class="icon"
                  ><i class="fa fa-files-o" aria-hidden="true"></i>
                  Reports</span
                >
              </a>
              <a class="portfolio-dropdown dropdown">
                <span class="icon"
                  ><i class="fa fa-caret-down" aria-hidden="true"></i>
                  <div class="dropdown-content">
                    <a
                      class="dropdown-item"
                      (click)="pageChange('Exposure Report')"
                      [routerLink]="['/reports/exposure-report']"
                      ><i class="fa fa-film" aria-hidden="true"></i> Exposure
                      Report</a
                    >
                  </div>
                </span>
              </a>
            </li>

            <!-- (click)="openSettingsManagementInterface(settingsManagementInterface)" -->
            <li class="treeview" [routerLinkActive]="['active-item']">
              <a class="nav-buttons portfolio-button">
                <span class="icon"
                  ><i class="fa fa-gear" aria-hidden="true"></i>
                  Settings/Management</span
                >
              </a>
              <a class="portfolio-dropdown dropdown">
                <span class="icon"
                  ><i class="fa fa-caret-down" aria-hidden="true"></i>
                  <div class="dropdown-content">
                    <a
                      class="dropdown-item"
                      (click)="pageChange('Formulas')"
                      [routerLink]="['/', 'formula-management']"
                      ><i class="fa fa-calculator" aria-hidden="true"></i>
                      Formula Management</a
                    >
                    <a
                      class="dropdown-item"
                      [routerLink]="['/', 'max-gain-chart']"
                      ><i class="fa fa-chart-bar" aria-hidden="true"></i> Max
                      Gain Chart
                    </a>
                    <a class="dropdown-item" [routerLink]="['/', 'messages']"
                      ><i class="fa fa-comment-alt" aria-hidden="true"></i>
                      Message History
                    </a>
                    <a
                      class="dropdown-item"
                      (click)="pageChange('Strategies')"
                      [routerLink]="['/', 'strategies']"
                      ><i class="fa fa-bullseye" aria-hidden="true"></i>
                      Portfolio Strategies</a
                    >
                    <a class="dropdown-item" (click)="openTDAAuthURL()"
                      ><i class="fa fa-link" aria-hidden="true"></i> Re-link
                      TDAmeritrade</a
                    >
                    <a
                      class="dropdown-item"
                      [routerLink]="['/', 'scheduled-messages']"
                    >
                      <i class="fa fa-clock" aria-hidden="true"></i>
                      Scheduled Notifications
                    </a>
                    <a
                      class="dropdown-item"
                      (click)="pageChange('Symbols')"
                      [routerLink]="['/', 'symbols']"
                      ><i class="fa fa-certificate" aria-hidden="true"></i>
                      Symbols</a
                    >
                    <a
                      class="dropdown-item"
                      (click)="pageChange('Users')"
                      [routerLink]="['/', 'user-list']"
                      ><i class="fa fa-user-circle-o" aria-hidden="true"></i>
                      User Management</a
                    >
                  </div>
                </span>
              </a>
            </li>
            <li
              class="treeview"
              (click)="pageChange('Historical Orders')"
              [routerLink]="['/', 'historical-orders']"
              [routerLinkActive]="['active-item']"
            >
              <a class="nav-buttons">
                <span class="icon"
                  ><i class="fa fa-history" aria-hidden="true"></i> Historical
                  Orders</span
                >
              </a>
            </li>

            <li
              class="treeview"
              (click)="pageChange('Portfolio')"
              [routerLink]="['/', 'portfolio']"
              [routerLinkActive]="['active-item']"
            >
              <a class="nav-buttons">
                <span class="icon"
                  ><i class="fa fa-book" aria-hidden="true"></i> Portfolio</span
                >
              </a>
            </li>

            <li
              class="treeview"
              (click)="pageChange('Return Tables')"
              [routerLink]="['/', 'return-tables']"
              [routerLinkActive]="['active-item']"
            >
              <a class="nav-buttons">
                <span class="icon"
                  ><i class="fa fa-th" aria-hidden="true"></i> Return
                  Tables</span
                >
              </a>
            </li>
            <li>
              <a class="nav-buttons signout">
                <span (click)="logout()"
                  ><i class="fa fa-sign-out"></i> Sign out</span
                >
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>

  <div class="header"></div>
</header>
