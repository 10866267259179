import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-image-selector',
  templateUrl: './image-selector.component.html',
  styleUrls: ['./image-selector.component.scss']
})
export class ImageSelectorComponent implements OnInit {

  @Output() imageValue: EventEmitter<any> = new EventEmitter();
  titel: string;
  imageId: any;
  closeResult: string;
  s3_location: any;
  isDataLoaded = false;
  imageSrc: any;
  imagePlaceholder: any;

  constructor(
    private modalService: NgbModal
  ) {
  }

  ngOnInit() {
    this.imagePlaceholder = './assets/img/placeholder.png';
    this.titel = 'Select Image';
  }

  open(content) {
    

    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  readURL(event: any): void {
    if (event.target.files && event.target.files[0]) {
      this.imageValue.emit(event.target.files);
      document.getElementById('closeBtn').click();
      this.titel = 'Selected image';
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = e => this.imageSrc = reader.result;
      reader.readAsDataURL(file);
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
