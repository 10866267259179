import { Component, Input, OnInit, ViewChild } from "@angular/core";

import { NotificationsService } from "src/app/services/notifications.service";
import { ToastrService } from "ngx-toastr";
import { UserService } from "src/app/services/app/user/user.service";

import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { NotificationOperationsService } from "../../notifications/notification-operations.service";
import { UserNotificationTableComponent } from "../../table-components/user-notification-table/user-notification-table.component";

@Component({
  selector: "app-percent-value-notifications",
  templateUrl: "./percent-value-notifications.component.html",
  styleUrls: ["./percent-value-notifications.component.scss"],
})
export class PercentValueNotificationsComponent implements OnInit {
  notificationType: any = "Value Notification";
  percentTimespan: any = "Today Only";
  selectedComparisonForValueNotification: any = "Less Than";

  usersUndefinedError: any;
  spreadUsers: any;
  users: any;
  percentMovesUp: any;
  percentMovesDown: any;
  noteUntilDate: any;
  jobMessage: any = "";
  noteEqualsValue: any;
  loading: any;
  spreadNoteDate: any;
  spreadNoteMessage: any = "";
  showPopulateMovesUpError: any;
  usersLoading: any = false;
  spreadNoteDateError: boolean = false;
  spreadNoteMessageError: boolean = false;
  percentUpDownMatchError: boolean = false;

  @ViewChild(UserNotificationTableComponent, { static: false })
  userNotificationTableComponent: UserNotificationTableComponent;

  public _rightClicked: any;
  @Input() set rightClicked(value: any) {
    this._rightClicked = value;
  }
  get rightClicked(): any {
    return this._rightClicked;
  }

  public _cellValueArr: any;
  @Input() set cellValueArr(value: any) {
    this._cellValueArr = value;
  }

  get cellValArr(): any {
    return this._cellValueArr;
  }

  public _noteModal: any;
  @Input() set noteModal(value: any) {
    this._noteModal = value;
  }

  get noteModal(): any {
    return this._noteModal;
  }

  constructor(
    private notificationsService: NotificationsService,
    private toastr: ToastrService,
    private userService: UserService,
    private modalService: NgbModal,
    public notificationOperationsService: NotificationOperationsService
  ) {}

  ngOnInit() {
    this.getUsers();
  }

  getUsers() {
    this.usersLoading = true;
    this.userService.getUserList().subscribe((data) => {
      this.users = data.body.data;
      this.usersLoading = false;
    });
  }

  clickUserTable($event) {
    this.spreadUsers = $event;
  }

  setPercentTimespan(val) {
    this.percentTimespan = val;
  }

  setSelected(val) {
    this.selectedComparisonForValueNotification = val;
  }

  /* Convert MM/DD/YYYY to YYYY-MM-DD */
  formatDateYYYYMMDD(date) {
    return new Date(date).toLocaleDateString("fr-CA");
  }

  parseCellName() {
    return this.formatCellNames(this._cellValueArr[2]);
  }

  parseCellValue() {
    return this._cellValueArr[3];
  }

  parseLegNum() {
    return parseInt(this._cellValueArr[1]) + 1;
  }

  isNum() {
    if (this._cellValueArr[5] == "num") {
      return true;
    }

    return false;
  }

  isPercent() {
    if (this._cellValueArr[5] == "%") {
      return "%";
    }

    return "";
  }

  isDollar() {
    if (this._cellValueArr[5] == "$") {
      return "$";
    }

    return "";
  }

  /* Formatter helper function for columns */
  formatCellNames(cellName) {
    if (cellName === "move") {
      return "Move";
    } else if (cellName === "udl") {
      return "UDL";
    } else if (cellName === "strike") {
      return "Strike";
    }

    return cellName;
  }

  parseComparisonOperator(comp) {
    if (comp === "Less Than") {
      return "<";
    } else if (comp === "Greater Than") {
      return ">";
    }
  }

  parseTimezone(date) {
    let timezone = "AMERICA/CHICAGO";
    let strTime = date.toLocaleString("en-US", {
      timeZone: `${timezone}`,
    });

    return strTime;
  }

  createParamsForPercentNote() {
    let columnVal = this._cellValueArr[3];
    if (this._cellValueArr[5] == '%') {
      columnVal = columnVal / 100;
    }

    let users = this.userNotificationTableComponent.usersForChecks.filter(
      (u) => u.send_sms || u.send_email
    );

    let mathForPercents =
      this.notificationOperationsService.createMathForPercents(
        this.percentMovesUp,
        this.percentMovesDown,
        this._cellValueArr[3],
        this._cellValueArr[5]
      );

    let untilDate = "";
    if (this.percentTimespan == "Until Date") {
      if (this.noteUntilDate) {
        untilDate =
          this.noteUntilDate.year +
          "-" +
          this.noteUntilDate.month +
          "-" +
          this.noteUntilDate.day;
        untilDate = this.parseTimezone(untilDate);
      }
    } else if (this.percentTimespan == "Today Only") {
      untilDate = this.parseTimezone(new Date());
      untilDate = this.formatDateYYYYMMDD(untilDate);
    }

    // Format expiration.
    let expirationDate =
      this._rightClicked.legs[this._cellValueArr[1]].expiration_date;

    expirationDate = expirationDate.slice(0, -9);

    let jsonOfJob = {
      percent_moved: 0,
      up_incriment: mathForPercents.upIncriment,
      down_incriment: mathForPercents.downIncriment,
      move_down_target: mathForPercents.targetMovesDown,
      move_up_target: mathForPercents.targetMovesUp,
      initial_value: columnVal,
      previous_value: columnVal,
      percent_moves_up: this.percentMovesUp,
      percent_moves_down: this.percentMovesDown,
      ticker: this._rightClicked.symbol,
      expiration:
        this._rightClicked.legs[this._cellValueArr[1]].expiration_date,
      account: this._rightClicked.trade_account_name,
      strike_1: this._rightClicked.legs[0].strike,
      strike_2: this._rightClicked.legs[1].strike,
      security_type_1: this._rightClicked.legs[0].security_type,
      security_type_2: this._rightClicked.legs[1].security_type,
      message: this.jobMessage,
      time_span: this.percentTimespan,
      moves_up: this.percentMovesUp,
      moves_down: this.percentMovesDown,
      date_until: untilDate,
      db_column_name: this._cellValueArr[2],
      portfolio_item_id: this._rightClicked.portfolio_item_id,
      portfolio_leg_id:
        this._rightClicked.legs[this._cellValueArr[1]].portfolio_leg_id,
      leg: this._cellValueArr[1],
      users: users,
    };

    var params = {
      details_long: JSON.stringify(jsonOfJob),
      details_1: 0,
      app_id: 1,
      every_minutes: "1",
      frequency: "every_minutes",
      next_run: "2021-12-14 20:40:05",
      name: "create_notification_by_column_percent",
    };

    return params;
  }

  createParamsForValueNote() {
    let users = this.userNotificationTableComponent.usersForChecks.filter(
      (u) => u.send_sms || u.send_email
    );

    let comparison = this.parseComparisonOperator(
      this.selectedComparisonForValueNotification
    ); 

    /* For Percents */
    let columnVal = this._cellValueArr[3];
    let userEnteredVal = this.noteEqualsValue;
    if (this._cellValueArr[5] == '%') {
      columnVal = columnVal / 100;
      userEnteredVal = userEnteredVal / 100;
    }

    let jsonOfJob = {
      time_span: "Recurring",
      ticker: this._rightClicked.symbol,
      expiration:
        this._rightClicked.legs[this._cellValueArr[1]].expiration_date,
      account: this._rightClicked.trade_account_name,
      strike_1: this._rightClicked.legs[0].strike,
      strike_2: this._rightClicked.legs[1].strike,
      security_type_1: this._rightClicked.legs[0].security_type,
      security_type_2: this._rightClicked.legs[1].security_type,
      message: this.jobMessage,
      comparison: comparison,
      previous_value: columnVal,
      initial_value: columnVal,
      target_value: userEnteredVal,
      db_column_name: this._cellValueArr[2],
      portfolio_item_id: this._rightClicked.portfolio_item_id,
      portfolio_leg_id:
        this._rightClicked.legs[this._cellValueArr[1]].portfolio_leg_id,
      leg: this._cellValueArr[1],
      users: users,
    };

    var params = {
      details_long: JSON.stringify(jsonOfJob),
      details_1: 0,
      app_id: 1,
      every_minutes: "1",
      frequency: "every_minutes",
      next_run: "2021-12-14 20:40:05",
      name: "create_notification_by_column_value",
    };

    return params;
  }

  createParamsForSpreadNote() {
    let users = this.userNotificationTableComponent.usersForChecks.filter(
      (u) => u.send_sms || u.send_email
    );

    this.loading = true;
    let date =
      this.spreadNoteDate.year +
      "-" +
      this.spreadNoteDate.month +
      "-" +
      this.spreadNoteDate.day;

    let jsonOfJob = {
      message: this.jobMessage,
      time_span: date,
      account: this._rightClicked.trade_account_name,
      portfolio_item_id: this._rightClicked.portfolio_item_id,
      portfolio_leg_id:
        this._rightClicked.legs[this._cellValueArr[1]].portfolio_leg_id,
      users: users,
      strike_1: this._rightClicked.legs[0].strike,
      strike_2: this._rightClicked.legs[1].strike,
      security_type_1: this._rightClicked.legs[0].security_type,
      security_type_2: this._rightClicked.legs[1].security_type,
      expiration: this._rightClicked.legs[0].expiration_date,
      ticker: this._rightClicked.symbol,
    };

    var params = {
      details_long: JSON.stringify(jsonOfJob),
      details_1: 0,
      app_id: 1,
      remaining: "1",
      every_minutes: "1",
      frequency: "every_minutes",
      next_run: date,
      name: "create_notification_by_spread",
    };

    return params;
  }

  createNotification() {
    this.usersUndefinedError = false;

    let users = this.userNotificationTableComponent.usersForChecks.filter(
      (u) => u.send_sms || u.send_email
    );



    if (!Array.isArray(users) || !users.length) {
      this.usersUndefinedError = true;
      return;
    }

    this.showPopulateMovesUpError = false;
    let params = {};

    if (this.notificationType === "Percent Notification") {
      if (!this.percentMovesUp && !this.percentMovesDown) {
        this.showPopulateMovesUpError = true;
        return;
      }
      if (this.percentMovesUp && this.percentMovesDown) {
        if (this.percentMovesUp != this.percentMovesDown) {
          this.percentUpDownMatchError = true;
          return;
        }
      }

  
      params = this.createParamsForPercentNote();
    } else if (this.notificationType === "Value Notification") {
      params = this.createParamsForValueNote();
    } else if (this.notificationType === "Spread Notification") {
      this.spreadNoteDateError = false;
      this.spreadNoteMessageError = false;

      if (!this.spreadNoteDate) {
        this.spreadNoteDateError = true;
        return;
      }

      if (!this.jobMessage) {
        this.spreadNoteMessageError = true;
        return;
      }

      params = this.createParamsForSpreadNote();
    }

    this.loading = true;
    this.notificationsService.newScheduledJob(params).subscribe((result) => {
      this.loading = false;
      this.toastr.success("Created New Notification");
      this.modalService.dismissAll();
    });
  }
}
