import Echo from "laravel-echo";
import { AddNotificationDialogComponent } from "src/app/dashboard/notifications/add-notification-dialog/add-notification-dialog.component";
import { AuthenticationService } from "src/app/services/authentication.service";
import { FullScreenService } from "src/app/services/full-screen.service";
import { RuntimeEnvLoaderService } from "src/app/services/runtime-env-loader.service";

import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-topmenu",
  templateUrl: "./topmenu.component.html",
  styleUrls: ["./topmenu.component.scss"],
})
export class TopmenuComponent implements OnInit {
  navbarStyle = "navbar navbar-static-top navbar-static-top-custom-style";
  userRole: string;
  pageTitle: string = null;
  hideSearchFilter: boolean = false;
  closeResult: string;
  generalSettings = false;
  maxGainTableSettings = false;
  formulaSettings = false;
  notifications: any;
  numNotifications: any = 3;
  unreadCount: any = 0;

  constructor(
    public router: Router,
    private authService: AuthenticationService,
    public fullScreenService: FullScreenService,
    private envService: RuntimeEnvLoaderService,
    public dialog: MatDialog,
    private modalService: NgbModal
  ) {
    if (!localStorage.getItem("search-filter")) {
      localStorage.setItem("search-filter", "off");
    } else {
      if (localStorage.getItem("search-filter") == "on") {
        localStorage.setItem("search-filter", "on");
        // this.eventEmitterService.init();
      }
    }
  }

  ngOnInit() {
    this.realTimeData();
  }

  realTimeData() {
    let echo = new Echo({
      broadcaster: "pusher",
      key: "dorian", // hard code
      wsHost: this.envService.config.WEBSOCKET_HOST,
      wssHost: this.envService.config.WEBSOCKET_HOST,
      enabledTransports: ["ws", "wss"],
      wssPort: this.envService.config.WEBSOCKET_PORT,
      wsPort: this.envService.config.WEBSOCKET_PORT,
      forceTLS: false,
      useTLS: false,
      disableStats: true,
    });

    echo.channel("channel-note").listen("EventNewNote", (data) => {});
  }

  logout() {
    const isSimulating: boolean =
      localStorage.getItem("isSimulating") === "true";
    if (isSimulating) {
      this.authService.logoutFromSimulation();
      window.location.reload();
    } else {
      this.authService.logout();
      this.router.navigate(["/login"]);
    }
  }

  searchPanel() {}

  pageChange(page) {
    this.pageTitle = page;
  }

  openTDAAuthURL() {
    window.open(this.envService.config.TDA_AUTH_URL, "_blank");
  }

  openAddNotificationDialog() {
    const dialogRef = this.dialog.open(AddNotificationDialogComponent, {
      minHeight: "calc(100vh - 90px)",
      height: "auto",
    });
  }

  openSettingsManagementInterface(content) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title", size: "lg" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  openMaxGainTableSettings(s) {
    this.maxGainTableSettings = !this.maxGainTableSettings;
  }

  openFormulaSettings(s) {
    this.formulaSettings = !this.formulaSettings;
  }
}
