import { BehaviorSubject, Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { RuntimeEnvLoaderService } from "./runtime-env-loader.service";

@Injectable({
  providedIn: "root",
})
export class PortfolioService {
  adminUserGroupId: any;
  basePath: string;
  errorHandler: any;

  private _legDeletedEvent = new BehaviorSubject<any>(false);

  public legDeleted = this._legDeletedEvent.asObservable();

  constructor(
    private http: HttpClient,
    private envLoader: RuntimeEnvLoaderService
  ) {
    this.basePath = envLoader.config.API_BASE_URL + envLoader.config.APP_KEY;
  }

  public legDeletedEvent(leg) {
    this._legDeletedEvent.next(leg);
  }

  createPortfolioItem(submitData): Observable<any> {
    const headers = new HttpHeaders().set(
      "security-token",
      JSON.parse(localStorage.getItem("currentUser")).token
    );
    return this.http
      .post<any>(this.basePath + "/portfolio-items", submitData, { headers })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError(this.handleError)
      );
  }

  openPortfolioItem(params: any) {
    const headers = new HttpHeaders().set(
      "security-token",
      JSON.parse(localStorage.getItem("currentUser")).token
    );
    return this.http
      .get<any>(this.basePath + "/portfolio/open", {
        headers,
        observe: "response",
        params,
      })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError(this.handleError)
      );
  }

  closePortfolioItem(params: any) {
    const headers = new HttpHeaders().set(
      "security-token",
      JSON.parse(localStorage.getItem("currentUser")).token
    );
    return this.http
      .get<any>(this.basePath + "/portfolio/close", {
        headers,
        observe: "response",
        params,
      })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError(this.handleError)
      );
  }
  deletePortfolioItem(params: any) {
    const headers = new HttpHeaders().set(
      "security-token",
      JSON.parse(localStorage.getItem("currentUser")).token
    );
    return this.http
      .get<any>(this.basePath + "/portfolio/delete", {
        headers,
        observe: "response",
        params,
      })
      .pipe(
        map((response) => {
          console.log("LEG DELETED EVENT!");
          console.log(response);

          this.legDeletedEvent(response);
          return response;
        }),
        catchError(this.handleError)
      );
  }

  createLegOnExistingSpread(submitData): Observable<any> {
    const headers = new HttpHeaders().set(
      "security-token",
      JSON.parse(localStorage.getItem("currentUser")).token
    );
    return this.http
      .post<any>(this.basePath + "/portfolio/leg", submitData, { headers })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError(this.handleError)
      );
  }

  createPortfolioRecord(submitData): Observable<any> {
    const headers = new HttpHeaders().set(
      "security-token",
      JSON.parse(localStorage.getItem("currentUser")).token
    );
    return this.http
      .post<any>(this.basePath + "/portfolio", submitData, { headers })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError(this.handleError)
      );
  }

  getPortfolioClosedOpen(params = null, closedStatus): Observable<any> {
    const headers = new HttpHeaders().set(
      "security-token",
      JSON.parse(localStorage.getItem("currentUser")).token
    );
    return this.http
      .get<any>(this.basePath + "/portfolio", {
        headers,
        observe: "response",
        params,
      })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError(this.handleError)
      );
  }

  getPortfolio(params = null): Observable<any> {
    const headers = new HttpHeaders().set(
      "security-token",
      JSON.parse(localStorage.getItem("currentUser")).token
    );
    return this.http
      .get<any>(this.basePath + "/portfolio", {
        headers,
        observe: "response",
        params,
      })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError(this.handleError)
      );
  }

  getPortfolioRecordHistory(id): Observable<any> {
    const headers = new HttpHeaders().set(
      "security-token",
      JSON.parse(localStorage.getItem("currentUser")).token
    );
    return this.http
      .get<any>(this.basePath + "/portfolio/history/" + id, {
        headers,
        observe: "response",
      })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError(this.handleError)
      );
  }

  editPortfolioRecord(id, data): Observable<any> {
    const headers = new HttpHeaders().set(
      "security-token",
      JSON.parse(localStorage.getItem("currentUser")).token
    );

    return this.http
      .patch<any>(this.basePath + "/portfolio/" + id, data, { headers })
      .pipe(
        map((response) => {
          console.log("made it back");
          return response.data;
        }),
        catchError(this.handleError)
      );
  }

  handleError(error) {
    return throwError("Error");
  }
}
