<div class="home-top-white-bg">
    <div class="row">
        <div class="offset-md-3 col-md-6 col-sm-12">
            <div class="home-top">
                <img src="../../../assets/DomekGroupLOGO_0617.jpg" class="img-responsive" />
                <h4>Administrator Main Menu</h4>
                
                <h5>Welcome {{user.first_name}} {{user.last_name}}</h5>
                <p>Please make a selection from the menu at the left</p>
    
                
            </div>
    
        </div>
    
    </div>
</div>

<div class="row">
    <div class="col-md-12 no-padding">
        <img src="../../../assets/stockbanner-cropped.jpg" class="img-responsive" />
    </div>
</div>
