import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HistoricalOrdersReusableService {

  constructor() { }
  
  public formatColumnName(str) {
    if (str === 'max_gain') {
      return "Max Gain";
    } else if (str === 'expiration_days_away') {
      return "Days Away";
    } else if (str === 'quantity') { 
      return "Quantity";
    } else if (str === 'strike_1') { 
      return "Strike 1";
    } else if (str === 'strike_2') { 
      return "Strike 2";
    } else if (str === 'cost') {
      return "Cost";
    } else if (str === 'exposure') { 
      return "Exposure";
    } else if (str === 'date') { 
      return "Date"; 
    } else if (str === 'quality') {
      return "Quality";
    } else if (str === 'at_10k') {
      return "At"; 
    } else if (str === 'target_return_current') { 
      return "TR (Current)";
    } else if (str === 'target_percent') {
      return "TR (Percent)";
    } else if (str === 'at_100_percent') { 
      return "AT 100 Percent";
    } else if (str === 'at_95_percent') {
      return "AT 95 Percent";
    } else if (str === 'at_90_percent') {
      return "AT 90 Percent"; 
    } else if (str === 'move_since') { 
      return "Move Since"; 
    } else if (str === 'price') {
      return "Price";
    } else if (str === 'price_at_upload') { 
      return "Price At Upload"; 
    } else if (str === 'price_at_fill') {
      return "Price At Fill";
    } else if (str === 'symbol') {
      return "Symbol";
    } else if (str === 'target_return_fill') {
      return "TR (fill)"
    }

    return str;
  }


}
