import { ToastrService } from "ngx-toastr";
import { forkJoin, Subscription } from "rxjs";
import { UserRole } from "src/app/constants/user/user-role";
import { UserService } from "src/app/services/app/user/user.service";
import { AuthenticationService } from "src/app/services/authentication.service";
import { FullScreenService } from "src/app/services/full-screen.service";
import { RuntimeEnvLoaderService } from "src/app/services/runtime-env-loader.service";
import { SymbolsService } from "src/app/services/symbols.service";
import Swal from "sweetalert2";
import { isString } from "util";

import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-symbols",
  templateUrl: "./symbols.component.html",
  styleUrls: ["./symbols.component.scss"],
})
export class SymbolsComponent implements OnInit {
  allColumns = [];

  column = {};

  historicalOrdersForSearch: any;
  filtersCollapsed = false;

  collapse = false;

  filterCriteria = [];
  subGetEssentialData: Subscription;
  subGetLoggingData: Subscription;
  subGetEssentialSymbolData: Subscription;
  isAdmin = false;
  usersCount: any;
  searchValue = "";
  closeResult: string;

  symbols = [];

  fullscreen: boolean;
  historicalOrdersAll: any;

  user: any;
  userId: any;

  footerHidden: any = false;

  logUserData: any;
  userLog: any;
  currentUserDetails: string;
  logginUserRoleIds = [];
  createNew: boolean = true;
  details: boolean = false;
  edit: boolean = false;

  symbolsCreateForm: FormGroup;
  symbolsEditForm: FormGroup;
  submitted: boolean;
  loading: boolean;
  symbol: any;
  deleteList = [];

  constructor(
    private router: Router,
    private envService: RuntimeEnvLoaderService,
    private userService: UserService,
    private symbolsService: SymbolsService,
    public authService: AuthenticationService,
    private modalService: NgbModal,
    public fullScreenService: FullScreenService,
    private toastr: ToastrService
  ) {
    this.currentUserDetails = localStorage.getItem("currentUser");
  }

  get f() {
    return this.symbolsCreateForm.controls;
  }

  ngOnInit() {
    this.loading = true;
    console.log("get essential data function call");
    this.getEssentialData();
    console.log(this.symbolsCreateForm);
    this.getSymbolsData();
  }

  getSymbolsData() {
    // const sortBy = this.sortObj.sortBy;
    // const sortOrder = this.sortObj.sortOrder;

    // let search = null;
    // if (this.allFilters.fields.length) {
    //   search = JSON.stringify(this.allFilters.fields);
    // }

    // let params = new HttpParams()

    this.subGetEssentialData = this.symbolsService
      .getSymbols()
      .subscribe((result) => {
        console.log("Res");
        console.log(result.body);
        result.body.data.forEach((user) => {
          user.checkStatus = false;
        });
        this.symbols = result.body.data;
        console.log(this.symbols);
        this.loading = false;
      });
  }

  getEssentialData() {
    console.log("hello from get getEssentialData");
    // this.statesList = stateList;

    this.symbolsCreateForm = new FormGroup({
      symbol: new FormControl("", [Validators.required, this.noWhitespace]),
      description: new FormControl(""),
      type: new FormControl(""),
      category: new FormControl(""),
      mark: new FormControl("", [Validators.pattern("^[0-9]*$")]),
      ask_price: new FormControl("", [Validators.pattern("^[0-9]*$")]),
      last_price: new FormControl("", [Validators.pattern("^[0-9]*$")]),
    });

    console.log("this is symbolsCreateForm");
    console.log(this.symbolsCreateForm);
    this.checkLogUserId();
  }

  checkLogUserId() {
    this.logUserData = JSON.parse(this.currentUserDetails);

    const getloggedUser = this.userService.getUserDetails(this.logUserData.id);
    this.subGetLoggingData = forkJoin([getloggedUser]).subscribe((logUser) => {
      this.userLog = logUser[0];

      this.userLog.user_roles.forEach((element) => {
        if (element.id == UserRole.admin) {
          this.logginUserRoleIds.push(UserRole.admin);
        } else if (element.id == UserRole.employee) {
          this.logginUserRoleIds.push(UserRole.employee);
        }
      });
      const idxOfAdmin = this.logginUserRoleIds.indexOf(UserRole.admin);
      const idxOfEmployee = this.logginUserRoleIds.indexOf(UserRole.employee);

      this.userService.setUser(logUser);
    });
  }

  public noWhitespace(control: FormControl) {
    return isString(control.value) && control.value.trim() == ""
      ? { required: true }
      : null;
  }

  fullScreen() {
    this.fullScreenService.isFullScreen = !this.fullScreenService.isFullScreen;
  }

  // closeFooter() {
  //   this.footerHidden = !this.footerHidden;
  // }

  showAllColumns() {
    for (const col of this.allColumns) {
      col.hidden = false;
    }
  }

  showSpecificColumns(dbName) {
    for (const col of this.allColumns) {
      if (col.dbName == dbName) {
        if (col.hidden == true) {
          col.hidden = false;
        } else {
          col.hidden = true;
        }
      }
    }
  }

  selectSelect(event) {
    this.symbols.forEach((symbol) => {
      if (symbol.id == event.target.value) {
        symbol.checkStatus = !symbol.checkStatus;
        if (symbol.checkStatus == true) {
          console.log(symbol);
          console.log(symbol.id);
          this.deleteList.push(symbol.id);
          console.log(this.deleteList);
        } else {
          const idx = this.deleteList.indexOf(symbol.id);
          if (idx != -1) {
            this.deleteList.splice(idx, 1);
            console.log(this.deleteList);
            symbol.checkStatus = false;
          }
        }
      }
    });
  }

  selectAllSymbols() {
    this.deleteList = [];
    this.symbols.forEach((symbol) => {
      symbol.checkStatus = true;
      this.deleteList.push(symbol.id);
      console.log(this.deleteList);
    });
  }

  deselectAllSymbols() {
    this.symbols.forEach((symbol) => {
      symbol.checkStatus = false;
      this.deleteList = [];
      console.log(this.deleteList);
    });
  }

  deleteSelectedSymbols() {
    this.loading = true;
    let totalRow = this.deleteList.length;
    Swal.fire({
      title: "Are you sure?",
      text: "This will delete the " + totalRow + " selected records.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ok",
    }).then((result) => {
      if (result.value) {
        const symbolIds = [];
        this.symbols.forEach((symbol) => {
          if (symbol.checkStatus == true) {
            const logUser = JSON.parse(localStorage.getItem("currentUser"));
            if (logUser.id != symbol.id) {
              symbolIds.push(
                this.symbolsService.deleteSymbol(Number(symbol.id))
              );
            } else {
              totalRow -= 1;
            }
          }
        });
        this.subGetEssentialData = forkJoin(symbolIds).subscribe(
          (response) => {
            if (response[0] == "200") {
              this.deleteList = [];
              this.toastr.success(totalRow + " rows", "Deleted Successfully");
              this.getSymbolsData();
            }
          },
          (err) => {
            this.toastr.error(totalRow + " rows", "Deleted Unsuccessful");
          }
        );
      }
    });
  }

  symbolCreateNew() {
    console.log("symbol create");
    this.createNew = true;
    console.log("createNew");
    console.log(this.createNew);
    this.details = false;
    console.log("details");
    console.log(this.details);
    this.edit = false;
    console.log("edit");
    console.log(this.edit);
  }

  symbolDetails(symbolId) {
    if (symbolId == "cancel") {
      this.loading = false;
    } else {
      this.loading = true;
    }
    console.log("symbol details");
    this.details = true;
    console.log("details");
    console.log(this.details);
    this.createNew = false;
    console.log("createNew");
    console.log(this.createNew);
    this.edit = false;
    console.log("edit");
    console.log(this.edit);

    console.log(symbolId);
    const getSymbolData = this.symbolsService.getSymbolDetails(symbolId);

    console.log(getSymbolData);
    this.subGetEssentialSymbolData = forkJoin([getSymbolData]).subscribe(
      (response) => {
        // this.test = true;
        this.symbol = response[0];
        console.log(response[0]);
        console.log(this.symbol);
        this.loading = false;
      }
    );
  }

  symbolEdit() {
    console.log("symbol edit");
    this.edit = true;
    console.log("edit");
    console.log(this.edit);
    this.createNew = false;
    console.log("createNew");
    console.log(this.createNew);
    this.details = false;
    console.log("createNew");
    console.log(this.createNew);

    this.symbolsEditForm = new FormGroup({
      symbol: new FormControl({ value: this.symbol.symbol, disabled: false }, [
        Validators.required,
        Validators.max(10),
        this.noWhitespace,
      ]),
      description: new FormControl({
        value: this.symbol.description,
        disabled: false,
      }),
      type: new FormControl({ value: this.symbol.type, disabled: false }),
      category: new FormControl({
        value: this.symbol.category,
        disabled: false,
      }),
    });
  }

  onSymbolCreateSubmit() {
    this.loading = true;
    this.submitted = true;

    const form = this.symbolsCreateForm;
    // if (!form.valid || this.userRoleId.length == 0) {
    //     this.submitted = true;
    //     if (this.userRoleId.length == 0) {
    //         this.roleRequired = true;
    //     }
    //     return;
    // }
    this.loading = true;
    const credentions = form.value;

    const submitData = {
      symbol: credentions.symbol,
      description: credentions.description,
      type: credentions.type,
      category: credentions.category,
    };
    console.log("hello subbmitted data");
    console.log(submitData);
    this.symbolsService.createSymbol(submitData).subscribe((result: any) => {
      this.loading = true;
      this.userId = result.id;
      this.toastr.success(submitData.symbol + " ticker successfully created");
      this.getSymbolsData();
    });
  }

  onSymbolEditSubmit(symbolId) {
    console.log(symbolId);
    console.log(this.symbol.symbol);
    this.submitted = true;

    const form = this.symbolsEditForm;
    // if (!form.valid || this.userRoleId.length == 0) {
    //     this.submitted = true;
    //     if (this.userRoleId.length == 0) {
    //         this.roleRequired = true;
    //     }
    //     return;
    // }
    this.loading = true;
    const credentions = form.value;

    const submitData = {
      symbol: credentions.symbol,
      description: credentions.description,
      type: credentions.type,
      category: credentions.category,
    };
    console.log("hello subbmitted data");
    console.log(submitData);
    this.symbolsService
      .updateSymbolDetails(symbolId, submitData)
      .subscribe((result: any) => {
        this.loading = true;
        this.userId = result.id;
        this.getSymbolsData();
      });
  }

  logout() {
    const isSimulating: boolean =
      localStorage.getItem("isSimulating") === "true";

    if (isSimulating) {
      this.authService.logoutFromSimulation();
      window.location.reload();
    } else {
      this.authService.logout();
      this.router.navigate(["/login"]);
    }
    this.fullScreenService.isFullScreen = false;
  }
}
