import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { RuntimeEnvLoaderService } from './runtime-env-loader.service';

@Injectable({
  providedIn: "root",
})
export class SymbolsService {
  adminUserGroupId: any;
  basePath: string;
  errorHandler: any;

  constructor(
    private http: HttpClient,
    private envLoader: RuntimeEnvLoaderService
  ) {
    this.basePath = envLoader.config.API_BASE_URL + envLoader.config.APP_KEY;
  }

  getSymbols(params = null): Observable<any> {
    const headers = new HttpHeaders().set(
      "security-token",
      JSON.parse(localStorage.getItem("currentUser")).token
    );
    return this.http
      .get<any>(this.basePath + "/symbols", {
        headers,
        observe: "response",
        params,
      })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError(this.handleError)
      );
  }

  createSymbol(data): Observable<any> {
    console.log("createSymbol");
    const headers = new HttpHeaders().set(
      "security-token",
      JSON.parse(localStorage.getItem("currentUser")).token
    );
    return this.http
      .post<any>(this.basePath + "/symbols", data, { headers })
      .pipe(
        map((response) => {
          console.log("in map createSymbol");

          return response.data;
        }),
        catchError(this.handleError)
      );
  }

  getSymbolDetails(id): Observable<any> {
    const headers = new HttpHeaders().set(
      "security-token",
      JSON.parse(localStorage.getItem("currentUser")).token
    );

    return this.http
      .get<any>(this.basePath + "/symbols/" + id, { headers })
      .pipe(
        map((response) => {
          return response.data;
        }),
        catchError(this.handleError)
      );
  }

  updateSymbolDetails(id, data): Observable<any> {
    const headers = new HttpHeaders().set(
      "security-token",
      JSON.parse(localStorage.getItem("currentUser")).token
    );

    return this.http
      .patch<any>(this.basePath + "/symbols/" + id, data, { headers })
      .pipe(
        map((response) => {
          return response.data;
        }),
        catchError(this.handleError)
      );
  }

  deleteSymbol(id): Observable<any> {
    const headers = new HttpHeaders().set(
      "security-token",
      JSON.parse(localStorage.getItem("currentUser")).token
    );

    return this.http
      .delete<any>(this.basePath + "/symbols/" + id, { headers })
      .pipe(
        map((response) => {
          return 200;
        }),
        catchError(this.handleError)
      );
  }

  handleError(error) {
    let errorMessage = {};
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = {
        error: `${error.error.message}`,
      };
    } else {
      // server-side error
      errorMessage = {
        code: `${error.status}`,
        message: `${error.statusText}`,
        errors: error.error.errors,
      };
    }
    // window.alert(errorMessage);
    return throwError(errorMessage);
  }
}
