<html>
  <div class="headerContainer">
    <div class="removeAllFilterOptionsButtons">
      <!-- <h2>Historical Orders</h2> -->
      <!-- <a *ngIf="!this.fullScreenService.isFullScreen" class="clearAllFiltersBtn" (click)="fullScreen()"><i
                class="fa fa-expand"></i> Full Screen</a>
                <a class="clearAllFiltersBtn" (click)="removeAllFilters()"><i class="fa fa-eraser"></i> Clear All Filters</a> -->

      <!-- <a type="button" class="clearAllFiltersBtn close-footer" aria-label="Close" 
                    (click)="closeFooter()">Hide Footer</a> -->

      <a class="clearAllFiltersBtn"
        ><i class="fa fa-angle-double-left"></i> Back to Portfolio</a
      >
    </div>
  </div>

  <div class="filter-collector">
    <section class="content-header">
      <h1>
        Tickers ({{ symbols.length }})&nbsp;
        <button
          (click)="symbolCreateNew()"
          routerLinkActive="active"
          class="btn btn-success btn-sm"
        >
          Create New
        </button>
        &nbsp;
        <button class="btn btn-primary btn-sm" (click)="selectAllSymbols()">
          Select All
        </button>
        &nbsp;
        <button class="btn btn-primary btn-sm" (click)="deselectAllSymbols()">
          Deselect All
        </button>
        &nbsp;
        <button
          class="btn btn-danger btn-sm"
          [disabled]="deleteList.length == 0"
          (click)="deleteSelectedSymbols()"
        >
          Delete</button
        >&nbsp; &nbsp;
        <!-- <a class="pull-right ">
                                <app-search-box (searchValue)="searchBox($event)"></app-search-box>
                            </a> -->
      </h1>
      <p style="margin-top: 5px; margin-bottom: 0px">
        Click an a ticker to see the details for that ticker.
      </p>
    </section>
  </div>

  <div class="table-container">
    <div class="loader" *ngIf="loading">
      <img src="./assets/public_img_load.gif" />
    </div>
    <div class="table-responsive">
      <table>
        <thead>
          <tr>
            <th class="select-th">Select</th>
            <th class="symbol-th">Symbol</th>
            <th class="description-th">Description</th>
            <th class="description-th">Type</th>
            <th class="description-th">Category</th>
            <th class="mark-th">Mark</th>
          </tr>
        </thead>
        <tbody *ngIf="symbols.length != 0">
          <tr *ngFor="let symbol of symbols">
            <td style="text-align: center">
              <input
                type="checkbox"
                [checked]="symbol.checkStatus"
                id="delete"
                value="{{ symbol.id }}"
                (change)="selectSelect($event)"
              />
            </td>
            <td>
              <a
                [ngClass]="{ disabled: loading == true }"
                class="link"
                (click)="symbolDetails(symbol.id)"
                >{{ symbol.symbol }}</a
              >
            </td>
            <td>{{ symbol.description }}</td>
            <td class="ask-price-td">{{ symbol.type }}</td>
            <td class="last-price-td">{{ symbol.category }}</td>
            <td class="mark-td usd">${{ symbol.mark | number: "1.2-2" }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="form-container">
    <div class="form-responsive">
      <ng-container *ngIf="createNew == true">
        <!-- *ngIf == "details == false" -->
        <h1>Create New Ticker Symbol</h1>
        <form [formGroup]="symbolsCreateForm">
          <!-- <div class="container-fluid"> -->
          <div class="">
            <div class="input-container">
              <label for="firstName"><h4>Symbol for Ticker</h4></label>
              <input
                type="text"
                class="form-control"
                formControlName="symbol"
                required
                id="symbol"
                placeholder="Symbol"
              />
            </div>

            <div class="input-container">
              <label for="firstName"><h4>Description</h4></label>
              <input
                type="text"
                class="form-control"
                formControlName="description"
                id="description"
                placeholder="Descrition"
              />
            </div>

            <div class="flex-container">
              <div class="flex-input type">
                <label for="firstName"><h4>Type</h4></label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="type"
                  id="type"
                  placeholder="Type"
                />
              </div>

              <div class="flex-input category">
                <label for="firstName"><h4>Category</h4></label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="category"
                  id="category"
                  placeholder="Category"
                />
              </div>
            </div>

            <div class="flex-container">
              <div class="flex-input">
                <label for="firstName"><h4>Mark</h4></label>
                <h5>Mark will fill on Symbol Creation</h5>
              </div>
            </div>

            <input
              type="button"
              (click)="onSymbolCreateSubmit()"
              class="btn btn-success"
              value="Create Symbol"
              style="float: left"
            />
          </div>
        </form>
      </ng-container>

      <ng-container *ngIf="details == true || edit == true">
        <h1 *ngIf="details == true">Details for {{ symbol.symbol }}</h1>
        <h1 *ngIf="edit == true">Edit {{ symbol.symbol }}</h1>
        <a
          class="link"
          [ngClass]="{ disabled: loading == true }"
          (click)="symbolEdit()"
          >edit</a
        >
        <form [formGroup]="symbolsEditForm">
          <!-- <div class="container-fluid"> -->
          <div class="">
            <div *ngIf="edit == true" class="input-container">
              <label for="symbol"><h4>Symbol for Ticker</h4></label>
              <input
                type="text"
                class="form-control"
                formControlName="symbol"
                id="symbol"
              />
            </div>

            <div class="input-container">
              <label for="description"><h4>Description</h4></label>
              <h5 *ngIf="details == true">{{ symbol.description }}</h5>
              <input
                *ngIf="edit == true"
                type="text"
                class="form-control"
                formControlName="description"
                id="description"
              />
            </div>

            <div class="flex-container">
              <div class="flex-input type">
                <label for="firstName"><h4>Type</h4></label>
                <h5 *ngIf="details == true">{{ symbol.type }}</h5>
                <input
                  *ngIf="edit == true"
                  type="text"
                  class="form-control"
                  formControlName="type"
                  id="type"
                />
              </div>

              <div class="flex-input category">
                <label for="firstName"><h4>Category</h4></label>
                <h5 *ngIf="details == true">{{ symbol.category }}</h5>
                <input
                  *ngIf="edit == true"
                  type="text"
                  class="form-control"
                  formControlName="category"
                  id="category"
                />
              </div>
            </div>

            <div class="flex-container">
              <div class="flex-input">
                <label for="mark"><h4>Mark</h4></label>
                <h5 *ngIf="details == true || edit == true">
                  ${{ symbol.mark | number: "1.2-2" }}
                </h5>
                <!-- <input *ngIf = "edit == true" type="text" class="form-control" id="mark" placeholder="Mark"> -->
              </div>
            </div>
            <input
              *ngIf="edit == true"
              type="button"
              (click)="onSymbolEditSubmit(symbol.id)"
              class="btn btn-success"
              value="Save Changes"
              style="float: left"
            />
            <input
              *ngIf="edit == true"
              type="button"
              (click)="symbolDetails('cancel')"
              class="btn btn-danger"
              value="Cancel"
              style="float: right"
            />
          </div>
        </form>
      </ng-container>

      <ng-container *ngIf="symbols.length == 0"> </ng-container>
    </div>
  </div>
</html>
