<div class="form-group">
  <div
    class="d-inline-block dropdown-note-type"
    ngbDropdown
    #mydrop3="ngbDropdown"
  >
    <button
      class="btn btn-primary mr-2 clearAllFiltersBtn"
      id="dropdownManual"
      ngbDropdownAnchor
      (focus)="mydrop3.open()"
    >
      {{ notificationType }}
    </button>
    <div ngbDropdownMenu aria-labelledby="dropdownManual">
      <button (click)="notificationType = 'Value Notification'" ngbDropdownItem>
        Value Notification
      </button>
      <button
        (click)="notificationType = 'Percent Notification'"
        ngbDropdownItem
      >
        Percent Notification
      </button>
      <button
        (click)="notificationType = 'Spread Notification'"
        ngbDropdownItem
      >
        Spread Notification
      </button>
    </div>
  </div>

  <hr />

  <!-- VALUE NOTIFICATION SWITCH -->
  <div
    *ngIf="notificationType === 'Value Notification'"
    class="table-values-container"
  >
    <div class="btn-container">
      <span class="leg-header">{{ parseCellName() }}: </span>
      <span class="cell-dollar">{{ isDollar() }}</span>
      <span *ngIf="isNum()" class="leg-cell-value">
        {{ parseCellValue() }}</span
      >
      <span *ngIf="!isNum()" class="leg-cell-value">
        {{ parseCellValue() | number: "1.2-5" }}</span
      >
      <span class="cell-percent">{{ isPercent() }}</span>
      <div class="leg-num">(Leg {{ parseLegNum() }})</div>
      <div class="d-inline-block" ngbDropdown #mydrop1="ngbDropdown">
        <button
          class="btn btn-primary mr-2 note-equals-btn"
          id="dropdownManual"
          ngbDropdownAnchor
          (focus)="mydrop1.open()"
        >
          {{ selectedComparisonForValueNotification }}
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownManual">
          <button (click)="setSelected('Less Than')" ngbDropdownItem>
            Is Less Than
          </button>
          <button (click)="setSelected('Greater Than')" ngbDropdownItem>
            Is Greater Than
          </button>
        </div>
      </div>
      <input
        type="val"
        class="note-equals-input"
        [(ngModel)]="noteEqualsValue"
      />
    </div>
  </div>

  <!-- PERCENT NOTIFICATION SWITCH -->
  <div
    *ngIf="notificationType === 'Percent Notification'"
    class="table-values-container"
  >
    <div class="btn-container">
      <span class="leg-header">{{ parseCellName() }}: </span>
      <span class="cell-dollar">{{ isDollar() }}</span>
      <span class="leg-cell-value">
        {{ parseCellValue() | number: "1.2-5" }}</span
      >
      <span class="cell-percent">{{ isPercent() }}</span>
      <div class="leg-num-percent">(Leg {{ parseLegNum() }})</div>

      <div class="moves-up-down-container">
        <label class="moves-label">Moves Up</label>
        <input
          type="value"
          name="sms"
          [(ngModel)]="percentMovesUp"
          class="percent-moves-input"
        />
      </div>

      <span class="value-padding">%</span>

      <div class="moves-up-down-container ml-20">
        <label class="moves-label">Moves Down</label>
        <input
          type="value"
          name="sms"
          class="percent-moves-input"
          [(ngModel)]="percentMovesDown"
        />
      </div>
      <span class="value-padding">%</span>

      <div
        class="d-inline-block time-span-dropdown"
        ngbDropdown
        #timespandropdown="ngbDropdown"
      >
        <button
          class="btn btn-primary mr-2"
          id="dropdownManual"
          ngbDropdownAnchor
          (focus)="timespandropdown.open()"
        >
          {{ percentTimespan }}
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownManual">
          <button (click)="setPercentTimespan('Today Only')" ngbDropdownItem>
            Today Only
          </button>
          <button
            (click)="setPercentTimespan('Until Triggered')"
            ngbDropdownItem
          >
            Until Triggered
          </button>
          <button (click)="setPercentTimespan('Recurring')" ngbDropdownItem>
            Recurring
          </button>
          <button (click)="setPercentTimespan('Until Date')" ngbDropdownItem>
            Until Date
          </button>
        </div>
      </div>

      <div *ngIf="percentTimespan === 'Until Date'" class="percent-calendar">
        <div>
          <input
            class="form-control date-input"
            placeholder="mm-dd-yyyy"
            name="dp"
            ngbDatepicker
            #d1="ngbDatepicker"
            [(ngModel)]="noteUntilDate"
          />
        </div>
        <div class="percent-cal input-group-append">
          <button
            class="date btn-outline-secondary calendar fa fa-calendar note-cal"
            (click)="d1.toggle()"
            type="button"
          ></button>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="notificationType === 'Spread Notification'">
    <p class="spread-note-header">
      {{ rightClicked.trade_account_name }}
      {{ rightClicked.symbol }}
      {{ rightClicked.legs[0].expiration_date[0] | date: "mediumDate" }}
      {{ rightClicked.legs[0].price }} / {{ rightClicked.legs[1].price }}
      {{ rightClicked.legs[0].security_type }} /
      {{ rightClicked.legs[1].security_type }}
    </p>
  </div>

  <small class="user-input-error" *ngIf="percentUpDownMatchError">
    Moves Up and Moves Down must match. Otherwise lease one blank for movement tracking in one direction
  </small>
  <small class="user-input-error" *ngIf="showPopulateMovesUpError">
    Enter a value for Moves Up or Moves Down
  </small>


  <div class="textbox-user-area">
    <div class="value-notification-container">
      <app-user-notification-table
        (clickCheckEvent)="clickUserTable($event)"
      ></app-user-notification-table>
      <div class="space"></div>

    </div>

    <div>
      <small *ngIf="usersUndefinedError" class="user-undefined-error">Assign at least one user for the notification</small>
    </div>

    <div class="notification-add-message">
      <p class="leg-header-message">Add a Message</p>
      <textarea [(ngModel)]="jobMessage" class="value-text-area"></textarea>
      <small *ngIf="spreadNoteMessageError" class="user-undefined-error">Add a message</small>
    </div>
  </div>

  <div
    *ngIf="notificationType === 'Spread Notification'"
    class="table-values-container"
  >
    <div class="date-btn-container">
      <div class="col-flex-container">
        <p class="notification-date-header">Notification Date:</p>
        <input
          class="date-picker-input"
          placeholder="mm-dd-yyyy"
          name="dp"
          ngbDatepicker
          #d1="ngbDatepicker"
          [(ngModel)]="spreadNoteDate"
        />
        <button
          class="date btn-outline-secondary calendar fa fa-calendar note-cal"
          (click)="d1.toggle()"
          type="button"
          value="{{ spreadNoteDate }}"
        ></button>
      </div>
    </div>

    <div>
      <small *ngIf="spreadNoteDateError" class="user-enter-date-error"
        >Enter a date</small
      >
    </div>
  </div>

  <button
    [disabled]="usersLoading || loading"
    class="btn btn-primary btn-success create-note"
    (click)="createNotification()"
  >
    Create Notification
  </button>
</div>
