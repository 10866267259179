import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { RuntimeEnvLoaderService } from './runtime-env-loader.service';

@Injectable({

  providedIn: 'root'

})
export class SettingsManagementService {

  adminUserGroupId: any;
  basePath: string;
  errorHandler: any;

  constructor(
    private http: HttpClient,
    private envLoader: RuntimeEnvLoaderService
  ) {
    this.basePath = envLoader.config.API_BASE_URL + envLoader.config.APP_KEY;
  }

  getMaxGainValues(params = null): Observable<any> {
    const headers = new HttpHeaders()
      .set('security-token', JSON.parse(localStorage.getItem('currentUser')).token);
    return this.http.get<any>(this.basePath + '/settings-management/options-sell-targets', { headers, observe: 'response', params })
      .pipe(
        map((response => {
          return response;
        })),
        catchError(this.handleError),
      );
  }

  handleError(error) {
    

    let errorMessage = {};
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = {
        error: `${error.error.message}`
      };
    } else {
      // server-side error
      errorMessage = {
        code: `${error.status}`,
        message: `${error.statusText}`,
        errors: error.error.errors
      };
    }
    // window.alert(errorMessage);
    return throwError(errorMessage);
  }
}
