import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class NotificationOperationsService {
  constructor() {}

  /* CODE FOR DATES FOR NOTIFICATIONS */

  getFormattedDates(timeSpan, date) {
    let untilDate = "";
    if (timeSpan == "Until Date") {
      if (date) {
        untilDate = date.year + "-" + date.month + "-" + date.day;
        untilDate = this.parseTimezone(untilDate);
      }
    } else if (timeSpan == "Today Only") {
      untilDate = this.parseTimezone(new Date());
      untilDate = this.formatDateYYYYMMDD(untilDate);
    }

    return untilDate;
  }

  formatDateYYYYMMDD(date) {
    return new Date(date).toLocaleDateString("fr-CA");
  }

  parseTimezone(date) {
    // let timezone = this.notificationsService.timeZone;
    let timezone = "AMERICA/CHICAGO";
    let strTime = date.toLocaleString("en-US", {
      timeZone: `${timezone}`,
    });

    return strTime;
  }

  testDates() {}

  /* CODE FOR MATH FOR PERCENTS */

  createMathForPercents(percentMovesUp, percentMovesDown, tickerMark, type = null) {
    let upIncriment = null;
    let targetMovesUp = null;
    if (percentMovesUp) {
      upIncriment =
        parseFloat(percentMovesUp) * 0.01 * Math.abs(parseFloat(tickerMark));
      upIncriment.toFixed(2);
      
      targetMovesUp = parseFloat(tickerMark) + upIncriment;
      targetMovesUp.toFixed(2);
    }

    let downIncriment = null;
    let targetMovesDown = null;
    if (tickerMark) {
      downIncriment =
        parseFloat(percentMovesDown) * 0.01 * Math.abs(parseFloat(tickerMark));
      downIncriment.toFixed(2);
      
      targetMovesDown = parseFloat(tickerMark) - downIncriment;
      targetMovesDown.toFixed(2);
    }

    if (type == '%') {
      return {
        targetMovesDown: targetMovesDown/100,
        targetMovesUp: targetMovesUp/100,
        upIncriment: upIncriment/100,
        downIncriment: downIncriment/100,
      }
    } else {
        return {
          targetMovesDown: targetMovesDown,
          targetMovesUp: targetMovesUp,
          upIncriment: upIncriment,
          downIncriment: downIncriment, 
      }
    }

  }

  /*
    --Test Cases--

    test)   tickerMark = 150, percentMovesUp = .2, percentMovesDown = .5
    answer) targetMovesUp = 150.3, targetMovesDown = 149.25
            up_incriment = .3, down_incriment = .75

    test)   tickerMark = 75, percentMovesUp = 20, percentMovesDown = 2
    answer) targetMovesUp = 90, targetMovesDown = 73.5
            up_incriment = 15, down_incriment = 1.5
  */

  public testMath() {
    let a = this.createMathForPercents(0.2, 0.5, 150);

    if (
      a.targetMovesDown == 149.25 &&
      a.targetMovesUp == 150.3 &&
      a.upIncriment == 0.3 &&
      a.downIncriment == 0.75
    ) {
      console.log("Test Case A Passed");
    }

    console.log("A is");
    console.log(a);

    let b = this.createMathForPercents(20, 2, 75);

    if (
      b.targetMovesUp == 90 &&
      b.targetMovesDown == 73.5 &&
      b.upIncriment == 15 &&
      b.downIncriment == 1.5
    ) {
      console.log("Test Case B Passed");
    }

    console.log("B is");
    console.log(b);
  }
}
