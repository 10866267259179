import { Component, OnInit } from '@angular/core';
import { Input } from '@angular/core'; // First, import Input

@Component({
  selector: 'app-sum-footer',
  templateUrl: './sum-footer.component.html',
  styleUrls: ['./sum-footer.component.scss']
})
export class SumFooterComponent implements OnInit {
  @Input() sums: string; 

  constructor() { }

  ngOnInit() {
  }

}
