import { BehaviorSubject } from "rxjs";

import { Injectable } from "@angular/core";

@Injectable()
export class SortService {
  /* sortEvent triggers an event in a component */
  private sortChangedSubject = new BehaviorSubject<boolean>(false);

  /* We can subscribe to sortObservable to get the latest sort changes */
  public sortChangedObservable = this.sortChangedSubject.asObservable();

  /* We can save the last sorted column */
  public _lastSorted: any = {
    sortBy: "",
    sortOrder: "",
  };

  /* We set a default sort */
  public defaultSortBy = "date";
  public defaultSortOrder = "desc";

  constructor() {}

  /* We can clear the last sorted column */
  clearLastSorted(sortBy: string = "date", sortOrder: string = "desc") {
    this._lastSorted.sortBy = sortBy;
    this._lastSorted.sortOrder = sortOrder;
  }

  userClickedAscOrDescTwice(direction, column) {
    if (
      this._lastSorted.sortBy === column.dbName &&
      this._lastSorted.sortOrder === direction
    ) {
      return true;
    }

    return false;
  }

  sortColumnByDirection(column, direction) {
    if (!column) {
      return;
    }

    /* WE NEED TO FIX THESE COLUMNS IN BACK-END, FOR NOW, DISABLE IN FRONT-END TO PREVENT BREAKS */
    if (this.userClickedAscOrDescTwice(direction, column)) {
      this._lastSorted.sortBy = this.defaultSortBy;
      this._lastSorted.sortOrder = this.defaultSortOrder;
    } else {
      if (column.dbName) {
        this._lastSorted.sortBy = column.dbName;
      } else {
        this._lastSorted.sortBy = column;
      }

      this._lastSorted.sortOrder = direction;
    }

    this.sortChangedSubject.next(this._lastSorted);
  }

  sort(data: any[], sortObject: any): any[] {
    if (!sortObject || !sortObject.sortBy || !sortObject.sortOrder) {
      return data;
    }

    // NOTE: [].concat() is used to create a copy of the array to fire change detection in angular
    if (
      sortObject.sortBy.indexOf("date") >= 0 ||
      sortObject.sortBy.indexOf("_at") >= 0
    ) {
      data = [].concat(
        data.sort((a, b) => {
          if (!a[sortObject.sortBy] || !b[sortObject.sortBy]) {
            return 0;
          }

          const aDate = new Date(a[sortObject.sortBy].split(" ")[0]);
          const bDate = new Date(b[sortObject.sortBy].split(" ")[0]);
          const aMillis = this.getUTCMillisForDate(aDate);
          const bMillis = this.getUTCMillisForDate(bDate);

          if (sortObject.sortOrder === "asc") {
            return aMillis - bMillis;
          }
          return bMillis - aMillis;
        })
      );
    } else {
      let stringSort = false;

      data.forEach((item) => {
        // Check everything except the null values
        if (
          item[sortObject.sortBy] != null &&
          !this.isNumber(item[sortObject.sortBy])
        ) {
          stringSort = true;
        }
      });

      if (stringSort) {
        data = [].concat(
          data.sort((a, b) => {
            if (sortObject.sortOrder === "asc") {
              return String(a[sortObject.sortBy]).toLowerCase() >
                String(b[sortObject.sortBy]).toLowerCase()
                ? 1
                : -1;
            } else {
              return String(a[sortObject.sortBy]).toLowerCase() <
                String(b[sortObject.sortBy]).toLowerCase()
                ? 1
                : -1;
            }
          })
        );
      } else {
        data = [].concat(
          data.sort((a, b) => {
            if (sortObject.sortOrder === "asc") {
              return a[sortObject.sortBy] - b[sortObject.sortBy];
            } else {
              return b[sortObject.sortBy] - a[sortObject.sortBy];
            }
          })
        );
      }
    }

    this._lastSorted.sortBy = sortObject.sortBy;
    this._lastSorted.sortOrder = sortObject.sortOrder;

    // Alert the subscribers that the sort has changed so they can update their view if their sort has changed
    this.sortChangedSubject.next(sortObject);

    return data;
  }

  isNumber(n) {
    return !isNaN(parseFloat(n)) && !isNaN(n - 0);
  }

  getUTCMillisForDate(date: Date) {
    return Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate()
    );
  }
}
