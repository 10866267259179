import { HttpParams } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, Subscription } from 'rxjs';
import { UserService } from 'src/app/services/app/user/user.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { FormulaService } from 'src/app/services/formulas-service.service';
import { FullScreenService } from 'src/app/services/full-screen.service';
import { RuntimeEnvLoaderService } from 'src/app/services/runtime-env-loader.service';
import { SymbolsService } from 'src/app/services/symbols.service';
import { ConstantPool } from '@angular/compiler';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-formula-management',
  templateUrl: './formula-management.component.html',
  styleUrls: ['./formula-management.component.scss']
})
export class FormulaManagementComponent implements OnInit {

  @ViewChild('formulaTextArea') formulaBuilder: ElementRef;
  @ViewChild('ruleTextArea') ruleBuilder: ElementRef;


  allColumns = [];

  column = {};

  filtersCollapsed = false;

  collapse = false;

  filterCriteria = [];
  subGetEssentialData: Subscription;
  subGetEssentialFormulaData: Subscription;
  subGetLoggingData: Subscription;
  subGetEssentialSymbolData: Subscription;
  isAdmin = false;
  usersCount: any;
  searchValue = '';
  closeResult: string;

  formulas = [];
  typeData = [];
  formulaDetails: any;
  
  fullscreen: boolean;
  
  user: any;
  userId: any;
  
  footerHidden: any = false;
  
  logUserData: any;
  userLog: any;
  currentUserDetails: string;
  logginUserRoleIds = [];
  createNew:boolean = true;
  details:boolean = false;
  edit: boolean = false;

  formulaName: any;
  description:any;
  formulaType: any;
  fieldType: any;
  outputType: any;
  call: boolean;
  put: boolean;
  stock: boolean;
  formulaOverride: boolean;
  type: any;
  rule: any;
  formula:any;

  callDetails: any;
  putDetails: any;
  stockDetails: any;

  isType: boolean = false;

  formulaCreateForm: FormGroup;
  formulaEditForm:FormGroup;
  submitted: boolean;
  loading: boolean;
  symbol: any;
  deleteList = [];

  userFormulaSelected: any;

  selectionStart = 0;
  selectionEnd = 0;
  textType: string;

  constructor(private router: Router,
    private envService: RuntimeEnvLoaderService,
    private userService: UserService,
    private formulaService: FormulaService,
    public authService: AuthenticationService,
    private modalService: NgbModal,
    public fullScreenService: FullScreenService,
    private toastr: ToastrService,
    ) {
      this.currentUserDetails = localStorage.getItem('currentUser');
  }

  ngOnInit() {
    this.type = null;
    this.call = false;
    this.put = false;
    this.stock = false;
    this.formulaOverride = false;
    this.getFormulaData();
    // this.getTypeData();
  }

  createNewFormula() {
    this.details = false;
    this.createNew = true;
    this.edit = false;
  }

  formulaEdit() {
    this.details = false;
    this.createNew = false;
    this.edit = true;
  }

  getFormulaData() {
    // const sortBy = this.sortObj.sortBy;
    // const sortOrder = this.sortObj.sortOrder;

    // let search = null;
    // if (this.allFilters.fields.length) {
    //   search = JSON.stringify(this.allFilters.fields);
    // }

    // let params = new HttpParams()

    this.subGetEssentialData = this.formulaService.getFormulas().subscribe(
      result => {
        console.log("Res");
        console.log(result.body);
        result.body.data.forEach(formula => {
          formula.checkStatus = false;

          // if (formula.call && formula.put) {
          //   formula.rule = formula.rule.replace("({{security_type}} == 'CALL' && {{security_type}} == 'PUT') && ", '');
          //   formula.rule = formula.rule.replace("({{security_type}} == 'CALL' && {{security_type}} == 'PUT')", '');
          //   console.log(formula.rule);
          // }
          // if (this.stockDetails && this.putDetails) {
          //   formula.rule = formula.rule.replace("({{security_type}} == 'CALL' && {{security_type}} == 'STOCK') && ", '');
          //   formula.rule = formula.rule.replace("({{security_type}} == 'CALL' && {{security_type}} == 'STOCK')", '');
          //   console.log(formula.rule);
          // }
          // if (this.stockDetails && this.callDetails) {
          //   formula.rule = formula.rule.replace("({{security_type}} == 'PUT' && {{security_type}} == 'STOCK') && ", '');
          //   formula.rule = formula.rule.replace("({{security_type}} == 'PUT' && {{security_type}} == 'STOCK')", '');
          //   console.log(formula.rule);
          // }
          // if (this.callDetails > -1) {
          //   this.call = true;
          //   formula.rule = formula.rule.replace("({{security_type}} == 'CALL') && ", '');
          //   formula.rule = formula.rule.replace("({{security_type}} == 'CALL')", '');
          //   console.log(formula.rule);
          // }
          // if (this.putDetails > -1) {
          //   this.put = true;
          //   formula.rule = formula.rule.replace("({{security_type}} == 'PUT') && ", '');
          //   formula.rule = formula.rule.replace("({{security_type}} == 'PUT')", '');
          // }
          // if (this.stockDetails > -1) {
          //   this.stock = true;
          //   formula.rule = formula.rule.replace("({{security_type}} == 'STOCK') && ", '');
          //   formula.rule = formula.rule.replace("({{security_type}} == 'STOCK')", '');
          // }


        });
        this.formulas = result.body.data;
        this.loading=false;
      });
  }

  getFormulaDetails(formulaId) {
    if (formulaId == 'cancel') {
      this.loading = false;
    }
    else {
      this.loading =true;
    }
    this.details = true;
    this.createNew = false;
    this.edit = false;


    const getFormulaData = this.formulaService.getFormulaDetails(formulaId);
    this.subGetEssentialSymbolData = forkJoin([getFormulaData]).subscribe((response) => {
      // this.test = true;
      var ruleStr = response[0].rule;
      this.getTypeData(response[0].type);
      this.callDetails = ruleStr.search("CALL");
      this.putDetails = ruleStr.search("PUT");
      this.stockDetails = ruleStr.search("STOCK");
      if (this.callDetails && this.putDetails) {
        response[0].rule = response[0].rule.replace("({{security_type}} == 'CALL' && {{security_type}} == 'PUT') && ", '');
        response[0].rule = response[0].rule.replace("({{security_type}} == 'CALL' && {{security_type}} == 'PUT')", '');
      }
      if (this.stockDetails && this.putDetails) {
        response[0].rule = response[0].rule.replace("({{security_type}} == 'CALL' && {{security_type}} == 'STOCK') && ", '');
        response[0].rule = response[0].rule.replace("({{security_type}} == 'CALL' && {{security_type}} == 'STOCK')", '');
      }
      if (this.stockDetails && this.callDetails) {
        response[0].rule = response[0].rule.replace("({{security_type}} == 'PUT' && {{security_type}} == 'STOCK') && ", '');
        response[0].rule = response[0].rule.replace("({{security_type}} == 'PUT' && {{security_type}} == 'STOCK')", '');
      }
      if (this.callDetails > -1) {
        this.call = true;
        response[0].rule = response[0].rule.replace("({{security_type}} == 'CALL') && ", '');
        response[0].rule = response[0].rule.replace("({{security_type}} == 'CALL')", '');
      }
      if (this.putDetails > -1) {
        response[0].rule = response[0].rule.replace("({{security_type}} == 'PUT') && ", '');
        response[0].rule = response[0].rule.replace("({{security_type}} == 'PUT')", '');
        this.put = true;
      }
      if (this.stockDetails > -1) {
        response[0].rule = response[0].rule.replace("({{security_type}} == 'STOCK') && ", '');
        response[0].rule = response[0].rule.replace("({{security_type}} == 'STOCK')", '');
        this.stock = true;
      }
      this.formulaDetails = response[0];
      this.loading=false;
    });
  }

  selectSelect(event) {
    this.formulas.forEach(formula => {
      if (formula.id == event.target.value) {
        formula.checkStatus = !formula.checkStatus;
        if (formula.checkStatus == true) {
          this.deleteList.push(formula.id);
        } else {
          const idx = this.deleteList.indexOf(formula.id);
          if (idx != -1) {
            this.deleteList.splice(idx, 1);
            formula.checkStatus = false;
          }
        }
      }
    });
  }

  selectAllFormulas() {
    this.deleteList = [];
    this.formulas.forEach(formula => {
      formula.checkStatus = true;
      this.deleteList.push(formula.id);
    });
  }

  deselectAllFormulas() {
    this.formulas.forEach(formula => {
      formula.checkStatus = false;
      this.deleteList = [];
    });
  }

  deleteSelectedFormulas() {
    this.loading = true;
    let totalRow = this.deleteList.length;
    Swal.fire({
      title: 'Are you sure?',
      text: 'This will delete the ' + totalRow + ' selected records.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ok'
    }).then((result) => {
      if (result.value) {
        const formulaIds = [];
        this.formulas.forEach(formula => {
          if (formula.checkStatus == true) {
            const logUser = JSON.parse(localStorage.getItem('currentUser'));
            if (logUser.id != formula.id) {
              formulaIds.push(this.formulaService.deleteFormula(Number(formula.id)));
            } else {
              totalRow -= 1;
            }
          }
        });
        this.subGetEssentialData = forkJoin(formulaIds).subscribe(
          response => {
            if (response[0] == '200') {
              this.deleteList = [];
              this.toastr.success(totalRow + ' rows', 'Deleted Successfully');
              this.getFormulaData();
            }
          }, err => {
            this.toastr.error(totalRow + ' rows', 'Deleted Unsuccessful');
            
          });
      }
    });
  }

  setMainType(type) {
    this.type = type;
    this.getTypeData(type);
    this.isType = true;
  }

  getTypeData(type) {
    var lowerCaseType = type.toLowerCase();
    
    // var lowerCaseType = 'portfolio';

    let params = new HttpParams()
      .set('type', lowerCaseType)
      // return;
    
      this.subGetEssentialFormulaData = this.formulaService.getSpecificDataForFormulaType(params).subscribe(
      result => {
      
        this.typeData = result.body.data;
        this.typeData = (Object.values(this.typeData));
        this.loading=false;
      });
  }

  setFormulaName(formulaType) {
    this.formulaName = formulaType;
  }

  setFormulaType(formulaType) {
    this.formulaType = formulaType;
  }

  setFieldType(fieldType) {
    console.log(fieldType);
    this.fieldType = fieldType;
    console.log(this.fieldType);
  }

  setOutputType(outputType) {
    this.outputType = outputType;
  }

  setSecurityType(securityType) {
    if(securityType == 'call') {
      this.call = !this.call;
    }
    if(securityType == 'put') {
      this.put = !this.put;
    }
    if(securityType == 'stock') {
      this.stock = !this.stock;
    }
    if(securityType == 'formulaOverride') {
      this.formulaOverride = !this.formulaOverride;
    }
  }

  findCaretPosition(textType) {
    if (textType == 'formula') {
      this.textType = 'formula';
      this.selectionStart = this.formulaBuilder.nativeElement.selectionStart;
      this.selectionEnd = this.formulaBuilder.nativeElement.selectionEnd;
    }
    else if (textType == 'rule') {
      this.textType = 'rule';
      this.selectionStart = this.ruleBuilder.nativeElement.selectionStart;
      this.selectionEnd = this.ruleBuilder.nativeElement.selectionEnd;
    }
    else {
      this.textType = null;
    }
  }

  addVariable(value, type = null) {
    let textField = null;
    let valueToAdd = null;

    if (this.textType == 'formula') {
      textField = this.formulaBuilder.nativeElement.value;
    }
    else if (this.textType == 'rule') {
      textField = this.ruleBuilder.nativeElement.value;
    }
    if (type == 'field' || type == 'formula') {
      valueToAdd = "{{" + value + "}}";
    }
    else {
      valueToAdd = value;
    }

    let output = null;
    if(this.selectionStart != this.selectionEnd) {
      let positionStart = this.selectionStart;
      let positionEnd = this.selectionEnd;
      if (value == '(' || value  == ')') {
        output = [textField.slice(0, positionStart), '(', textField.slice(positionStart)].join('');
        output = [output.slice(0, positionEnd + 1), ')', output.slice(positionEnd + 1)].join('');
      }
      else {
        output = [textField.slice(0, positionStart), valueToAdd, textField.slice(positionEnd)].join('');
      }
    }
    else if (this.selectionStart == null) {
      output = textField + valueToAdd;
    }
    else {
      let position = this.selectionStart;
      output = [textField.slice(0, position), valueToAdd, textField.slice(position)].join('');
    }
    if (this.textType == 'rule') {
      this.ruleBuilder.nativeElement.value = output;
    }
    else if (this.textType == 'formula') {
      this.formulaBuilder.nativeElement.value = output;
    }
    this.selectionStart = null;
    this.selectionEnd = null;
  }

  onFormulaSubmit() {
    let formulaValue = this.formulaBuilder.nativeElement.value;
    let ruleValue = this.ruleBuilder.nativeElement.value;
    let descriptionValue = (<HTMLInputElement>document.getElementById("description")).value;
    console.log("formula name: " + this.formulaName);
    console.log("formula description: " + descriptionValue);
    console.log("type: " + this.type);
    console.log("formula type: " + this.formulaType);
    console.log("formula output: " + this.outputType);
    console.log("call: " + this.call);
    console.log("put: " + this.put);
    console.log("stock: " + this.stock);
    console.log("formula override: " + this.formulaOverride);
    console.log("formula (.getelementbyid): " + formulaValue);
    console.log("rule (getelementbyid): " + ruleValue);
    this.loading = true;
    this.submitted = true;
    this.loading = true;

    let finalRule = "";

    console.log(finalRule);
    if(this.call && this.put && this.stock) {
      finalRule = "";
    }
    else if(this.call && this.put) {
      finalRule += "({{security_type}} == 'CALL' && {{security_type}} == 'PUT')";
    }
    else if(this.call && this.stock) {
      finalRule += "({{security_type}} == 'CALL' && {{security_type}} == 'STOCK')";
    }
    else if(this.put && this.stock) {
      finalRule += "({{security_type}} == 'PUT' && {{security_type}} == 'STOCK')";
    }
    else if(this.call) {
      finalRule += "({{security_type}} == 'CALL')";
    }
    else if(this.put) {
      finalRule += "({{security_type}} == 'PUT')";
    }
    else if(this.stock) {
      finalRule += "({{security_type}} == 'STOCK')";
    }

    if(finalRule != "" && ruleValue.match(/^ *$/) === null) {
      finalRule += " && ";
    }

    finalRule += ruleValue;
    console.log(finalRule);

    let override = 0;
    if (this.formulaOverride) {
      override = 1
    }

    const submitData = {
      name: this.formulaName,
      field: this.fieldType,
      description: descriptionValue,
      type: this.type,
      formula_type: this.formulaType,
      field_override: override,
      rule: finalRule,
      formula: formulaValue,
      expected_output: this.outputType
    };
    this.formulaService.createFormula(submitData).subscribe(
        (result: any) => {
            this.loading = true;
            this.userId = result.id;
            this.toastr.success(submitData.name + ' formula successfully created');
            this.getFormulaData();
        });
  }

  onFormulaSubmitUpdate(formulaId) {
    let formulaValue = this.formulaBuilder.nativeElement.value;
    let ruleValue = this.ruleBuilder.nativeElement.value;
    let descriptionValue = (<HTMLInputElement>document.getElementById("description")).value;
    let nameValue = (<HTMLInputElement>document.getElementById("name")).value;
    let typeValue = (<HTMLInputElement>document.getElementById("type")).value;
    let fieldValue = (<HTMLInputElement>document.getElementById("field")).value;
    let formulaTypeValue = (<HTMLInputElement>document.getElementById("formula-type")).value;
    let formulaOutputValue = (<HTMLInputElement>document.getElementById("formula-output")).value;
    this.loading = true;
    this.submitted = true;
    this.loading = true;

    let finalRule = "";
    if(this.call && this.put && this.stock) {
      finalRule = "";
    }
    else if(this.call && this.put) {
      finalRule += "({{security_type}} == 'CALL' && {{security_type}} == 'PUT')";
    }
    else if(this.call && this.stock) {
      finalRule += "({{security_type}} == 'CALL' && {{security_type}} == 'STOCK')";
    }
    else if(this.put && this.stock) {
      finalRule += "({{security_type}} == 'PUT' && {{security_type}} == 'STOCK')";
    }
    else if(this.call) {
      finalRule += "({{security_type}} == 'CALL')";
    }
    else if(this.put) {
      finalRule += "({{security_type}} == 'PUT')";
    }
    else if(this.stock) {
      finalRule += "({{security_type}} == 'STOCK')";
    }

    if(finalRule != "" && ruleValue.match(/^ *$/) === null) {
      finalRule += " && ";
    }

    finalRule += ruleValue;

    let override = 0;
    if (this.formulaOverride) {
      override = 1
    }

    const submitData = {
      name: nameValue,
      field: fieldValue,
      description: descriptionValue,
      type: typeValue,
      formula_type: formulaTypeValue,
      field_override: override,
      rule: finalRule,
      formula: formulaValue,
      expected_output: formulaOutputValue
    };
    this.formulaService.updateFormulaDetails(formulaId, submitData).subscribe(
        (result: any) => {
            this.loading = true;
            this.userId = result.id;
            this.toastr.success(submitData.name + ' formula successfully updated');
            this.getFormulaData();
        });
  }

  cancel(){
    
  }

}
