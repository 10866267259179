<div *ngIf="this.fullScreenService.isFullScreen" class="full-screen-head">
  <div class="full-screen-info full-screen-page-title">
    <p>{{ pageTitle }}</p>
  </div>
  <div class="full-screen-info full-screen-show-filters-btn">
    <p *ngIf="!filtersCollapsed && this.fullScreenService.isFullScreen">
      <i class="fa fa-angle-double-right"></i
      ><a (click)="toggleFiltersCollapse()"> View Tools & Filters </a
      ><i class="fa fa-angle-double-left"></i>
    </p>
    <p *ngIf="filtersCollapsed && this.fullScreenService.isFullScreen">
      <i class="fa fa-angle-double-down"></i
      ><a (click)="toggleFiltersCollapse()"> View Tools & Filters </a
      ><i class="fa fa-angle-double-down"></i>
    </p>
  </div>
  <div class="full-screen-info full-screen-controls">
    <p class="full-screen-head-button">
      <a
        ><span (click)="fullScreen()"
          ><i class="fa fa-compress"></i> Exit Full Screen</span
        ></a
      >
    </p>
    <p class="full-screen-head-button">
      <a
        ><span (click)="logout()"
          ><i class="fa fa-sign-out"></i> Sign out</span
        ></a
      >
    </p>
  </div>
</div>

<div
  *ngIf="
    !this.fullScreenService.isFullScreen ||
    (filtersCollapsed && this.fullScreenService.isFullScreen)
  "
  [ngClass]="{
    'filter-head': !this.fullScreenService.isFullScreen,
    'filter-head-full-screen':
      filtersCollapsed && this.fullScreenService.isFullScreen
  }"
>
  <div class="header-container">
    <div class="remove-filter-btn-options">
      <span class="header-message">
        {{ headerMessage }}
      </span>

      <a
        *ngIf="
          !this.fullScreenService.isFullScreen &&
          this.onFullScreen.observers.length > 0
        "
        class="header-btn"
        (click)="fullScreen()"
        ><i class="fa fa-expand"></i> Full Screen</a
      >
      <button
        *ngIf="this.onClearAllFilters.observers.length > 0"
        class="header-btn"
        (click)="clearAllFilters()"
      >
        <i class="fa fa-eraser"></i> Clear All Filters
      </button>

      <a
        *ngIf="this.onHideFooter.observers.length > 0"
        type="button"
        class="header-btn close-footer"
        aria-label="Close"
        (click)="closeFooter()"
        >Hide Footer</a
      >

      <div ngbDropdown *ngIf="this.onFiltersToggle.observers.length > 0">
        <a id="dropdownBasic1" class="header-btn" ngbDropdownToggle
          >Show Columns <i class="fa fa-caret-down"></i
        ></a>
        <div
          class="dropdown-container"
          ngbDropdownMenu
          aria-labelledby="dropdownBasic1"
        >
          <div class="fixed-button">
            <a
              class="header-btn show-all-columns-button"
              (click)="showAllColumns()"
            >
              Show All Columns</a
            >
          </div>
          <div class="overflow">
            <ng-container *ngFor="let column of tableColumns">
              <p *ngIf="column.columnName.length">
                <a (click)="showSpecificColumn(column.dbName)">
                  <span>
                    <i
                      class="fa fa-check"
                      [ngClass]="{ 'check-icon': column.hidden == false }"
                    ></i>
                  </span>
                  {{ column.columnName }}
                </a>
              </p>
              <hr class="hr-list-bottom" />
            </ng-container>
          </div>
        </div>
      </div>

      <div
        *ngIf="filtersCollapsed && !this.fullScreenService.isFullScreen"
        class="hide-all-filters-btn"
      >
        <i
          class="header-btn"
          (click)="toggleFiltersCollapse()"
          class="fa fa-caret-left fa-2x"
        ></i>
      </div>
      <div
        *ngIf="!filtersCollapsed && !this.fullScreenService.isFullScreen"
        class="hide-all-filters-btn"
      >
        <i
          class="header-btn"
          (click)="toggleFiltersCollapse()"
          class="fa fa-caret-down fa-2x"
        ></i>
      </div>
    </div>
  </div>
</div>
