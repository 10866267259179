<div class="bubble">
    {{getFilterBubbleName(filterName)}}  {{formatBubbleNames(filterValue)}}
    
    <div>
        <button 
            [disabled]="loading"
            (click)="closeBubbleEvent()" 
            class="fa fa-times removeFilter">
        </button>
    </div>
</div>
