const timeList = [
                    {'time' : '9AM', 'hour' : 9},
                    {'time' : '10AM', 'hour' : 10},
                    {'time' : '11AM', 'hour' : 11},
                    {'time' : '12PM', 'hour' : 12},
                    {'time' : '1PM', 'hour' : 13},
                    {'time' : '2PM', 'hour' : 14},
                    {'time' : '3PM', 'hour' : 15},
                    {'time' : '4PM', 'hour' : 16},
                    {'time' : '5PM', 'hour' : 17},
                    {'time' : '6PM', 'hour' : 18},
                    {'time' : '7PM', 'hour' : 19},
                    {'time' : '8PM', 'hour' : 20},
                    {'time' : '9PM', 'hour' : 21},
                    {'time' : '10PM', 'hour' : 22},
                    {'time' : '11PM', 'hour' : 23},
                    {'time' : '12AM', 'hour' : 24},
                    {'time' : '1AM', 'hour' : 1},
                    {'time' : '2AM', 'hour' : 2},
                    {'time' : '3AM', 'hour' : 3},
                    {'time' : '4AM', 'hour' : 4},
                    {'time' : '5AM', 'hour' : 5},
                    {'time' : '6AM', 'hour' : 6},
                    {'time' : '7AM', 'hour' : 7},
                    {'time' : '8AM', 'hour' : 8}
                ];

export default timeList;