import { ActivatedRoute, Router } from "@angular/router";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";

import { AppService } from "src/app/services/app/user/app.service";
import { AuthenticationService } from "src/app/services/authentication.service";
import { forkJoin, Subscription } from "rxjs";
import { isString } from "util";
import Swal from "sweetalert2";
import { ToastrService } from "ngx-toastr";
import { UserService } from "src/app/services/app/user/user.service";

import { NgbDate } from "@ng-bootstrap/ng-bootstrap";

import stateList from "../../../constants/user/state-list";
import timeList from "../../../constants/user/time-list";
import { UserRole } from "../../../constants/user/user-role";

@Component({
  selector: "app-user-details",
  templateUrl: "./user-details.component.html",
  styleUrls: ["./user-details.component.scss"],
})
export class UserDetailsComponent implements OnInit, OnDestroy {
  adminCheck = false;
  currentUserDetails: any;
  employeeCheck = false;
  getAwsPresignedUrls: any;
  imgFile: any;
  isDataLoaded = false;
  isDeleteBtnVisible = true;
  profileImg: any;
  resetEmail: any;
  submitted = false;
  statesList: any;
  subGetUserFile: Subscription;
  subGetEssentialData: Subscription;
  subGetLoggingData: Subscription;
  test = false;
  toAdminCheck = false;
  toEmployeeCheck = false;
  user: any;
  userDetailForm: FormGroup;
  userId: any;
  userData: any;
  viewNote = false;
  roleRequired = false;
  selectedImageSrc: any;
  loading = false;
  logUserData: any;
  userLog: any;
  userRoleDisable = false;
  isEmployee = false;
  viewSelectText = false;
  sms: any;
  email: any;
  userRoleId = [];
  userGroupId = [];
  logginUserRoleIds = [];

  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate;
  toDate: NgbDate;
  showRange: boolean = false;
  timesList: any;

  timeOne: any;
  timeTwo: any;
  secondTimesList: any;

  constructor(
    private userService: UserService,
    private toastr: ToastrService,
    private appService: AppService,
    private authService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.route.params.subscribe((result) => {
      this.userId = result.id;
    });
    this.profileImg = "../assets/img/test3.jpeg";
    this.currentUserDetails = localStorage.getItem("currentUser");
  }

  get f() {
    return this.userDetailForm.controls;
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  showRangeClick() {
    this.showRange = !this.showRange;
  }

  ngOnInit() {
    this.getEssentialData();
  }

  ngOnDestroy() {
    if (this.subGetUserFile != undefined) {
      this.subGetUserFile.unsubscribe();
    }
    if (this.subGetEssentialData != undefined) {
      this.subGetEssentialData.unsubscribe();
    }
    if (this.subGetLoggingData != undefined) {
      this.subGetLoggingData.unsubscribe();
    }
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  getEssentialData() {
    this.submitted = false;

    if (this.userId == JSON.parse(this.currentUserDetails).id) {
      this.isDeleteBtnVisible = false;
    }

    this.timesList = timeList;
    this.secondTimesList = timeList;
    this.statesList = stateList;
    const getUserData = this.userService.getUserDetails(this.userId);
    this.subGetEssentialData = forkJoin([getUserData]).subscribe((response) => {
      this.test = true;
      this.user = response[0];
      this.user.phone_number = this.user.phone_number.replace(/\D/g, "");
      this.resetEmail = this.user.email;

      if (this.user.avatar_file_id != null) {
        this.subGetUserFile = this.userService
          .getUserFile(this.user.id, this.user.avatar_file_id)
          .subscribe((fileResponse) => {
            if (fileResponse != undefined) {
              this.profileImg = fileResponse.s3_location;
            }
          });
      }

      this.userDetailForm = new FormGroup({
        timeOne: new FormControl({ value: this.user.timeOne, disabled: false }),
        timeTwo: new FormControl({ value: this.user.timeTwo, disabled: false }),
        notifications_off: new FormControl({
          value: this.user.notifications_off,
          disabled: false,
        }),
        prefer_sms: new FormControl({
          value: this.user.prefer_sms,
          disabled: false,
        }),
        prefer_email: new FormControl({
          value: this.user.prefer_email,
          disabled: false,
        }),
        firstName: new FormControl(
          { value: this.user.first_name, disabled: false },
          [Validators.required, this.noWhitespace]
        ),
        lastName: new FormControl(
          { value: this.user.last_name, disabled: false },
          [Validators.required, this.noWhitespace]
        ),
        email: new FormControl({ value: this.user.email, disabled: false }, [
          Validators.required,
          Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{1,}$"),
        ]),
        address1: new FormControl({
          value: this.user.address_1,
          disabled: false,
        }),
        address2: new FormControl({
          value: this.user.address_2,
          disabled: false,
        }),
        city: new FormControl({ value: this.user.city, disabled: false }),
        state: new FormControl({ value: this.user.state, disabled: false }),
        zip: new FormControl({ value: this.user.zip, disabled: false }, [
          Validators.minLength(5),
          Validators.pattern("^[0-9]*$"),
        ]),
        phone1: new FormControl(
          { value: this.user.phone_number, disabled: false },
          [Validators.minLength(10), Validators.pattern("^[0-9]*$")]
        ),
        notes: new FormControl({ value: this.user.notes, disabled: true }),
      });

      this.userDetailForm.patchValue({
        timeOne: this.user.notification_start_time,
      });
      this.userDetailForm.patchValue({
        timeTwo: this.user.notification_end_time,
      });
      // Setting some default patch values.
      this.userDetailForm.patchValue({
        prefer_sms: this.user.notification_preferred_sms == "1" ? true : false,
      });
      this.userDetailForm.patchValue({
        prefer_email:
          this.user.notification_preferred_email == "1" ? true : false,
      });
      this.userDetailForm.patchValue({
        notifications_off: this.user.notifications_off == "1" ? true : false,
      });

      if (this.user.state == null) {
        this.viewSelectText = true;
      }

      this.checkLogUserId();

      this.user.user_roles.forEach((element) => {
        if (element.id == UserRole.admin || this.isEmployee == true) {
          this.viewNote = true;
          this.adminCheck = true;
          this.userRoleId.push(UserRole.admin);
        } else if (element.id == UserRole.employee) {
          this.employeeCheck = true;
          this.userRoleId.push(UserRole.employee);
        }
      });

      this.user.user_groups.forEach((element) => {
        this.userGroupId.push(element.id);
      });
    });
  }

  public noWhitespace(control: FormControl) {
    return isString(control.value) && control.value.trim() == ""
      ? { required: true }
      : null;
  }

  isClickState(event) {
    if (event.target.value != "") {
      this.viewSelectText = false;
    }
  }

  isClickTime(event) {
    this.secondTimesList = [
      { time: "9AM", hour: 9 },
      { time: "10AM", hour: 10 },
      { time: "11AM", hour: 11 },
      { time: "12PM", hour: 12 },
      { time: "1PM", hour: 13 },
      { time: "2PM", hour: 14 },
      { time: "3PM", hour: 15 },
      { time: "4PM", hour: 16 },
      { time: "5PM", hour: 17 },
      { time: "6PM", hour: 18 },
      { time: "7PM", hour: 19 },
      { time: "8PM", hour: 20 },
      { time: "9PM", hour: 21 },
      { time: "10PM", hour: 22 },
      { time: "11PM", hour: 23 },
      { time: "12AM", hour: 24 },
      { time: "1AM", hour: 1 },
      { time: "2AM", hour: 2 },
      { time: "3AM", hour: 3 },
      { time: "4AM", hour: 4 },
      { time: "5AM", hour: 5 },
      { time: "6AM", hour: 6 },
      { time: "7AM", hour: 7 },
      { time: "8AM", hour: 8 },
    ];
    let index = this.secondTimesList.findIndex(
      (item) => item.hour == this.userDetailForm.value.timeOne
    );

    var secondListIndex = 0;
    while (index != secondListIndex) {
      var move = this.secondTimesList[secondListIndex];
      secondListIndex += 1;
      this.secondTimesList.unshift();
      this.secondTimesList.push(move);
    }
    this.secondTimesList = this.secondTimesList.slice(index + 1);
  }

  checkLogUserId() {
    this.logUserData = JSON.parse(this.currentUserDetails);

    const getloggedUser = this.userService.getUserDetails(this.logUserData.id);
    this.subGetLoggingData = forkJoin([getloggedUser]).subscribe((logUser) => {
      this.userLog = logUser[0];

      this.userLog.user_roles.forEach((element) => {
        if (element.id == UserRole.admin) {
          this.logginUserRoleIds.push(UserRole.admin);
        } else if (element.id == UserRole.employee) {
          this.logginUserRoleIds.push(UserRole.employee);
        }
      });
      const idxOfAdmin = this.logginUserRoleIds.indexOf(UserRole.admin);
      const idxOfEmployee = this.logginUserRoleIds.indexOf(UserRole.employee);

      if (idxOfAdmin != -1) {
        this.isEmployee = false;
        this.userDetailForm.controls.notes.enable();
      } else if (idxOfEmployee != -1) {
        this.userDetailForm.controls.notes.disable();
        this.isEmployee = true;
        this.userDetailForm.controls.firstName.disable();
        this.userDetailForm.controls.lastName.disable();
        this.userDetailForm.controls.email.disable();
        this.userDetailForm.controls.address1.disable();
        this.userDetailForm.controls.address2.disable();
        this.userDetailForm.controls.city.disable();
        this.userDetailForm.controls.state.disable();
        this.userDetailForm.controls.zip.disable();
        this.userDetailForm.controls.phone1.disable();
        this.userDetailForm.controls.notes.disable();
      } else if (idxOfEmployee != -1 && idxOfAdmin != -1) {
        this.isEmployee = false;
        this.userDetailForm.controls.notes.enable();
      }
    });
  }

  imageSelect(file) {
    this.imgFile = file[0];
    if (file.length === 0) {
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file[0]);
    reader.onload = (_event) => {
      this.profileImg = reader.result;
      this.selectedImageSrc = reader.result;
    };
  }

  niceBytes(sizeInBytes) {
    const sizeInMB = (sizeInBytes / (1024 * 1024)).toFixed(2);
    return sizeInMB;
  }

  toasterDisplay(show, msg, status, type) {
    if (show == true) {
      if (type == "success") {
        // this.router.navigate(['user-list']);
        this.toastr.success(msg, status);
      } else if (type == "error") {
        this.toastr.error(msg, status);
      } else if (type == "info") {
        this.toastr.info(msg, status);
      } else if (type == "warning") {
        this.toastr.warning(msg, status);
      }
    }
  }

  onSubmit() {
    this.submitted = true;
    const form = this.userDetailForm;

    if (!form.valid || this.userRoleId.length == 0) {
      this.submitted = true;
      if (this.userRoleId.length == 0) {
        this.roleRequired = true;
      }
      return;
    }
    this.loading = true;
    const credentials = form.value;

    if (!credentials.prefer_email) {
      credentials;
    }

    var phone_number = credentials.phone1;
    phone_number = addDashes(phone_number);

    function addDashes(phone_number) {
      phone_number = phone_number.replace(/\D[^\.]/g, "");
      phone_number =
        phone_number.slice(0, 3) +
        "-" +
        phone_number.slice(3, 6) +
        "-" +
        phone_number.slice(6);
      return phone_number.value;
    }
    const submitData = {
      notification_preferred_email: credentials.prefer_email ? "1" : "0",
      notification_preferred_sms: credentials.prefer_sms ? "1" : "0",
      notification_start_time: credentials.timeOne,
      notification_end_time: credentials.timeTwo,
      notifications_off: credentials.notifications_off ? "1" : "0",
      first_name: credentials.firstName,
      last_name: credentials.lastName,
      email: credentials.email,
      phone_number: phone_number,
      address_1: credentials.address1,
      address_2: credentials.address2,
      city: credentials.city,
      state: credentials.state,
      zip: credentials.zip,
      notes: credentials.notes,
      user_role_ids: JSON.stringify(this.userRoleId),
      user_group_ids: JSON.stringify(this.userGroupId),
    };

    this.userService
      .updateUserDetails(this.userId, submitData)
      .subscribe((result: any) => {
        this.userDetailForm.patchValue({
          prefer_sms: result.notification_preferred_sms == "1" ? true : false,
        });
        this.userDetailForm.patchValue({
          prefer_email:
            result.notification_preferred_email == "1" ? true : false,
        });
        this.userDetailForm.patchValue({
          notifications_off: result.notifications_off == "1" ? true : false,
        });

        this.loading = true;
        this.userId = result.id;

        if (this.imgFile == undefined) {
          this.loading = false;
          this.toasterDisplay(
            true,
            "User successfully updated.",
            "Successfull!",
            "success"
          );
          this.getEssentialData();
        } else if (this.imgFile != undefined) {
          this.loading = true;
          this.appService
            .getAWSPresignedUrl()
            .subscribe((responseOfAwsPresignedUrl) => {
              this.getAwsPresignedUrls = responseOfAwsPresignedUrl.data;
              const url = this.getAwsPresignedUrls.url;

              this.appService.uploadFileToAWS(url, this.imgFile).subscribe(
                (result) => {
                  const fileName = this.getAwsPresignedUrls.file_name;

                  const fileData = {
                    s3_location: fileName.replace("uploads/", ""),
                    name: this.imgFile.name,
                    file_type: this.imgFile.type,
                    size: this.niceBytes(this.imgFile.size),
                  };

                  this.userService
                    .updateUserFiles(this.userId, fileData)
                    .subscribe(
                      (result) => {
                        const updateClientDetails = {
                          avatar_file_id: result.id,
                        };
                        this.userService
                          .updateUserDetails(this.userId, updateClientDetails)
                          .subscribe((result) => {
                            if (result != "") {
                              this.loading = false;
                              this.toasterDisplay(
                                true,
                                "User Account successfully updated.",
                                "Successfull!",
                                "success"
                              );
                              this.getEssentialData();
                            }
                          });
                      },
                      (error) => {
                        this.loading = false;
                        this.toasterDisplay(
                          true,
                          "File didn't update correctlly.",
                          "Fail!",
                          "error"
                        );
                      }
                    );
                },
                (error) => {
                  this.loading = false;
                  this.toasterDisplay(
                    true,
                    "File didn't upload to AWS correctlly.",
                    "Fail!",
                    "error"
                  );
                }
              );
            });
        }
      });
  }

  resetPW() {
    Swal.fire({
      html: '<ul class="swal_wo">Do you want to Reset Password?</ul>',
      // type: 'success',
      showCancelButton: true,
      confirmButtonColor: "#5cb85c",
      confirmButtonText: "Yes",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancel",
    }).then((buttonResult) => {
      if (buttonResult.value == true) {
        localStorage.setItem("resetUI", this.userId);
        this.router.navigate(["update-password"]);
      } else {
      }
    });
  }

  checkEmployeeChecked(event) {
    if (event.target.checked == false) {
      const idx = this.userRoleId.indexOf(UserRole.employee);
      if (idx != -1) {
        this.userRoleId.splice(idx, 1);
      }
    } else if (event.target.checked == true) {
      this.userRoleId.push(UserRole.employee);
    }

    if (event.target.checked == true) {
      this.toAdminCheck = true;
      this.roleRequired = false;
    } else {
      this.toAdminCheck = false;
      if (this.userRoleId.length == 0) {
        this.roleRequired = true;
      }
    }
  }

  checkAdminChecked(event) {
    if (event.target.checked == true) {
      this.viewNote = true;
      this.userRoleId.push(UserRole.admin);
    } else if (event.target.checked == false) {
      this.viewNote = false;
      const idx = this.userRoleId.indexOf(UserRole.admin);
      if (idx != -1) {
        this.userRoleId.splice(idx, 1);
      }
    }

    if (event.target.checked == true) {
      // this.userDetailForm.controls.notes.enable();
      this.toEmployeeCheck = true;
      this.roleRequired = false;
    } else {
      // this.userDetailForm.controls.notes.disable();
      this.toEmployeeCheck = false;
      if (this.userRoleId.length == 0) {
        this.roleRequired = true;
      }
    }
  }

  deleteUser() {
    Swal.fire({
      html: "This will delete this user profile. They will no longer be able to access the system. Do you want to proceed?",
      confirmButtonColor: "#008d4c",
      cancelButtonColor: "#f71000",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      showCancelButton: true,
    }).then((buttonResult) => {
      if (buttonResult.value == true) {
        this.userService.deleteUser(this.userId).subscribe((result) => {
          this.toastr.success(
            "User Account successfully deleted",
            "Successfull!"
          );
        });
        this.router.navigate(["user-list"]);
      } else {
      }
    });
  }
}
