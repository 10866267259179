import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

import { FilterResult, FilterService } from "../filter.service";

@Component({
  selector: 'app-table-header',
  templateUrl: './table-header.component.html',
  styleUrls: ['./table-header.component.scss']
})
export class TableHeaderComponent implements OnInit {
  allFilters: any;
  _column: any;
  columnChange:EventEmitter<any> = new EventEmitter();
  filtered = false;
  filterSub: any;
  isYellow = false;

  @Output() searchEvent:EventEmitter<any> = new EventEmitter();
  @Output() filterEvent:EventEmitter<any> = new EventEmitter();
  
  @Input() set column(value: any) {  
    this._column = value; 
  }
 
  get column(): any {
     return this._column;
  }

  /* For Loading, we can disable checkmarks */
  public _loading: any;

  @Input() set loading(value: any) {  
    this._loading = value;
  }
  
  get loading(): any {
      return this._loading;
  }

  filterIsApplied: boolean; // true if filter is applied.

  constructor(private filterService: FilterService) { 
  }

  ngOnInit() {
    this.subscribeToFilterEvent();
  }

  ngOnDestroy() {
    this.isYellow = false;

    if (this.filterSub) {
      this.filterSub.unsubscribe();
    }
  }

  subscribeToFilterEvent() {
    this.filterSub = this.filterService.filtersSetupObservable.subscribe((data: FilterResult) => {
      this.isYellow = false;
      
      if (!data) {
        return;
      }

      this.allFilters = data.allFilters ?? [];

      for (var i = 0; i < this.allFilters.length; i++) {
        if (this.allFilters[i].field === this._column.dbName) {
          this.isYellow = true;
        }
      }
    })
  }

  /* Call this function when a change occurs in the column...changing any value in the object */
  onChange() {
    this.columnChange.emit(this._column);
  }

  hideColumn() {
    this._column.hidden = true;
    this.onChange();
  }

  formatFilters(key) {
    if (key === "greaterThan") {
      return "Greater Than ";
    } else if (key == "lessThan") {
      return "Less Than ";
    } else if (key == "contains") {
      return "Contains ";
    }
  }

  searchFilter($event) {
    this._column.search = $event;
    this.searchEvent.emit(this._column);
  }

  // Event is Value
  filterChange($event, filter) {
    this._column.filters[filter.key] = $event;
  }

  filterIsSet() {
    if (this.column.filters.contains || this.column.filters.greaterThan || this.column.filters.lessThan) {
      return true;
    }

    return false;
  }

  setFilters() {
    this.filterService.filterColumn(this.column);
  }

  trackByFn(index, treatment) {
    return index;
  }
}
