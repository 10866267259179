// import { AppService } from 'src/app/services/app/app.service';
import { Component, OnInit, OnDestroy, Input } from "@angular/core";
// import { AuthService } from '../../services/auth/auth.service';
// import { UserRoleThemeService } from 'src/app/services/app/user-role-theme.service';
import { Subscription } from "rxjs";
import { User } from "src/app/data-structures/user";
import { Router } from "@angular/router";
import { AuthenticationService } from "src/app/services/authentication.service";
// import { UserService } from 'src/app/services/app/user/user.service';

@Component({
  selector: "app-sidemenu-left-colapse",
  templateUrl: "./sidemenu-left-colapse.component.html",
  styleUrls: ["./sidemenu-left-colapse.component.scss"],
})
export class SidemenuLeftColapseComponent implements OnInit, OnDestroy {
  @Input("user") user: User;

  route: string = "/";

  subUserDtails: Subscription;

  isSimulating = false;

  isShow = true;

  constructor(
    public router: Router,
    public authService: AuthenticationService
  ) {
    this.route = this.router.url;
    this.router.events.subscribe((param: any) => {
      this.route = this.router.url;
    });
  }

  ngOnInit() {
    this.route = this.router.url;
    this.router.events.subscribe((param: any) => {
      this.route = this.router.url;
    });

    this.isSimulating = this.authService.isSimulating.value;
  }

  ngOnDestroy() {}

  colapseMenuTogle() {
    this.isShow = !this.isShow;
  }
}
