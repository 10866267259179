<html>
    <div class="max-gain-container">

        <h5>Max Gain Table</h5>
        <p style="margin-top: 5px;
    margin-bottom: 0px;">This chart will edit percetage ranges Exit Price uses to calculate - Portfolio. Order Should generate when price is within 15 percentage points of target</p>
<!-- <button *ngIf = "maxGainTableEdit == false" (click) = "editMaxGainTable()">Edit</button>
<button *ngIf = "maxGainTableEdit == true && maxGainTableAddRowData == false" (click) = "addMaxGainTableRow()">Add new Max Gain Table Row Condition</button>
<button *ngIf = "maxGainTableEdit == true && maxGainTableAddRowData == true" (click) = "addMaxGainTableRow()">Cancel Add new Max Gain Table Row Condition</button>
<button *ngIf = "maxGainTableEdit == true" (click) = "saveMaxGainTable()">Save</button> -->
<table>
    <thead>
        <tr>
            <th>Current Gain Range</th>
            <th>Target% of Max Gain</th>
        </tr>
    </thead>
    <tbody>
        <div *ngIf="loading" class="spinner-container">
            <div role="status" class="spinner-border">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <ng-container *ngIf = "maxGainTableEdit == true && maxGainTableAddRowData == true">
            <tr>
                <td class="numDataCell usd">
                    <input [(ngModel)] = "newCurrentGain">
                </td>
                <td class="numDataCell usd">
                    <input [(ngModel)] = "newTargetPercentMaxGain">
                </td>
            </tr>
        </ng-container>
        <ng-container *ngIf = "maxGainTableEdit == false">
            <tr *ngFor = "let gain of maxGainTableValues">
                <td class="numDataCell usd">
                    {{gain.current_gain | number: '1.2-2'}}%
                </td>
                <td class="numDataCell usd">
                    {{gain.target_percent_of_max_gain | number: '1.2-2'}}%
                </td>
            </tr>
        </ng-container>
        <ng-container *ngIf = "maxGainTableEdit == true">
            <tr *ngFor = "let gain of maxGainTableValues">
                <td class="numDataCell usd">
                    <input value={{gain.current_gain}} (change) = saveMaxGain() [(ngModel)] = "currentGain">
                </td>
                <td class="numDataCell usd">
                    <input value={{gain.target_percent_of_max_gain}} (change) = saveMaxGain() [(ngModel)] = "targetPercentMaxGain">
                </td>
                <td class="numDataCell usd">
                    <button (click) = "deleteMaxGainTableRow()">Delete</button>
                </td>
            </tr>
        </ng-container>
    </tbody>
</table>
</div>
</html>
