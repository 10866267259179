export const environment = {
  production: false,
  DEFAULT_SORT: {
    USERS: {
      SORT_BY: 'last_name',
      SORT_ORDER: 'asc',
      PAGE_COUNT: [10, 15, 20, 25, 50]
    },
    GROUP: {
      SORT_BY: 'name',
      SORT_ORDER: 'asc',
      PAGE_COUNT: [10, 15, 20, 25, 50]
    },
  },
  pusher: {
    key: '4895a6a5e4626a3afdfb',
    cluster: 'mt1',
  }
};
