<div class = "colapse">
  <!-- Left side column. contains the logo and sidebar -->
  <aside class="main-sidebar">
    <!-- sidebar: style can be found in sidebar.less -->
    <section class="sidebar">
      <!-- Sidebar user panel -->
      <div class="user-panel">
        <div class="pull-left image">
          <p><span><i class="fa fa-user-circle-o" aria-hidden="true"></i></span></p>
        </div>
        <div class="pull-left info" [ngClass]="(isSimulating)?'info-with-small-text':'info-normal'">
          <small>Logged in As <br/></small>
        </div>
      </div>
      <!-- sidebar menu: : style can be found in sidebar.less -->
  
      <ul class="sidebar-menu" data-widget="tree" id="admin-menu">
        <li class="treeview" [ngClass]="{'active-item': route === '/'}">
          <a routerLink= "/">
            <span class="icon"><i class="fa fa-dashboard"></i></span>
          </a>
        </li>
  
        <li class="treeview" [routerLink]="['/','user-list']" [routerLinkActive]="['active-item']">
          <a>
            <span class="icon"><i class="fa fa-user-circle-o" aria-hidden="true"></i></span>
          </a>
        </li>
        
        <li class="treeview" [routerLink]="['/','historical-orders']" [routerLinkActive]="['active-item']">
          <a>
            <span class="icon"><i class="fa fa-history" aria-hidden="true"></i></span>
          </a>
        </li>
        
      </ul>
      
      <div class="bottom-logo">
        <p>Powered by</p>
        <img src="./assets/DS__Stacked_White@2x.png" class="img-responsive" />
      </div>
      
    </section>
    <!-- /.sidebar -->
    </aside>
</div>