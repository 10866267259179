<html>
  <div class="filter-collector">
    <section class="content-header">
      <h1 class="btn-toolbar">
        <button
          [disabled]="loading"
          (click)="selectAll()"
          class="btn btn-success btn-sm"
        >
          Select All
        </button>
        <button
          [disabled]="loading"
          (click)="deselectAll()"
          class="btn btn-success btn-sm"
        >
          Deselect All
        </button>
        <!-- <button
        [disabled]="loading"
        (click)="toolbarBtn('create')"
        class="btn btn-success btn-sm"
      >
        Create Notification
      </button> -->
        <button
          [disabled]="loading"
          (click)="toolbarBtn('delete')"
          class="btn btn-success btn-sm"
        >
          Delete Notification
        </button>
        <!-- <button
        [disabled]="loading || multiJobselected"
        (click)="toolbarBtn('edit')"
        class="btn btn-success btn-sm"
      >
        Edit Notification
      </button> -->
      </h1>
    </section>
  </div>

  <div class="table-responsive">
    <table class="table table-stripped">
      <div *ngIf="loading" class="spinner-container">
        <div role="status" class="spinner-border">
          <span class="sr-only">Loading...</span>
        </div>
      </div>

      <ng-container *ngIf="!loading">
        <thead>
          <tr>
            <th></th>
            <th>Alert Type</th>
            <th>Ticker</th>
            <th>Column</th>
            <th>Starting Value</th>
            <th style="width: 8%">Trigger</th>
            <th style="width: 40%">Message</th>
            <th>Portfolio Id</th>
            <th>Time Span</th>
            <th>Created At</th>
            <th>Created By</th>
            <th>Recipents</th>
            <th style="width: 5%">Opt In/Out</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let job of jobs">
            <td>
              <input
                type="checkbox"
                id="delete"
                value="{{ job.id }}"
                (change)="selectSelect(job, job.id)"
                [(ngModel)]="job.check"
              />
            </td>
            <td class="job-row">
              {{ getJobName(job) }}
            </td>
            <td class="job-row">
              <span *ngIf="job?.details_long?.symbol">
                {{ job?.details_long?.symbol }}
              </span>
              <span *ngIf="job?.details_long?.db_column_name">
                {{ job?.details_long?.ticker }}
              </span>
            </td>
            <td>
              {{ job?.details_long?.name }}
            </td>
            <td>
              {{ getStartingValue(job) }}
            </td>
            <td>
              <ng-container
                *ngIf="
                  job.details_long.percent_moves_up ||
                  job.details_long.percent_moves_down
                "
              >
                <ng-container
                  *ngIf="
                    job.details_long.percent_moves_up != '' &&
                    job.details_long.percent_moves_down != ''
                  "
                >
                  <span
                    *ngIf="
                      job.details_long.percent_moves_down ==
                      job.details_long.percent_moves_up
                    "
                  >
                    + / - {{ job.details_long.percent_moves_down }}%
                  </span>
                  <span
                    *ngIf="
                      job.details_long.percent_moves_down !=
                      job.details_long.percent_moves_up
                    "
                  >
                    + {{ job.details_long.percent_moves_up }}% / -
                    {{ job.details_long.percent_moves_down }}%
                  </span>
                </ng-container>
                <ng-container
                  *ngIf="
                    (job.details_long.percent_moves_up != '' &&
                      job.details_long.percent_moves_down == '') ||
                    (job.details_long.percent_moves_up == '' &&
                      job.details_long.percent_moves_down != '')
                  "
                >
                  <span *ngIf="job.details_long.percent_moves_up != ''">
                    + {{ job.details_long.percent_moves_up }}%
                  </span>
                  <span *ngIf="job.details_long.percent_moves_down != ''">
                    - {{ job.details_long.percent_moves_down }}%
                  </span>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="job.details_long.target_value">
                <span *ngIf="job.details_long.comparison == '>'">
                  Greater Than
                  <span *ngIf="job.details_long.unit == '$'">$</span
                  >{{ job.details_long.target_value
                  }}<span *ngIf="job.details_long.unit == '%'">%</span>
                </span>
                <span *ngIf="job.details_long.comparison == '<'">
                  Less Than
                  <span *ngIf="job.details_long.unit == '$'">$</span
                  >{{ job.details_long.target_value
                  }}<span *ngIf="job.details_long.unit == '%'">%</span>
                </span>
              </ng-container>
            </td>
            <td class="job-row">
              {{ job?.details_long?.message }}
            </td>
            <td>
              {{ job?.details_long?.portfolio_item_id }}
            </td>
            <td class="job-row">
              {{ getTimespan(job) }}
            </td>
            <td class="job-row">
              {{ job?.created_at | date: "mediumDate" }}
            </td>
            <td class="job-row">
              {{ job.created_by.first_name }} {{ job.created_by.last_name }}
            </td>
            <td class="job-row">
              {{ getRecipientList(job) }}
            </td>
            <ng-container *ngIf="job.isRecipient">
              <td class="job-row">
                <a
                  class="opt-in-out-button"
                  (click)="optOutNotification(job.id, job)"
                  >Opt Out</a
                >
              </td>
            </ng-container>
            <ng-container *ngIf="!job.isRecipient">
              <td class="job-row">
                <a
                  class="opt-in-out-button"
                  (click)="optInNotification(job.id, job)"
                  >Opt In</a
                >
              </td>
            </ng-container>
          </tr>
        </tbody>
      </ng-container>
    </table>
  </div>

  <div class="note-area-container">
    <div *ngIf="lastSelected" class="lastSelected">
      <div class="operator-container">
        <h3>{{ lastSelected.key }}</h3>
        <div class="d-inline-block" ngbDropdown #myDrop="ngbDropdown">
          <button
            class="btn btn-outline-primary mr-2"
            id="dropdownManual"
            ngbDropdownAnchor
            (focus)="myDrop.open()"
          >
            {{ selectedVal }}
          </button>
          <div ngbDropdownMenu aria-labelledby="dropdownManual">
            <button (click)="select('Equals')" ngbDropdownItem>Equals</button>
            <button (click)="select('Less Than')" ngbDropdownItem>
              Less Than
            </button>
            <button (click)="select('Greater Than')" ngbDropdownItem>
              Greater Than
            </button>
          </div>
        </div>
      </div>

      <div class="select-operator-class">
        <label>Operation Selected: </label> {{ selectedVal }}
      </div>
      <div>
        <label>{{ selectedVal }}: </label>
        <input
          [(ngModel)]="operationInput"
          class="floating-input-area"
          type="text"
        />
      </div>
    </div>
    <div *ngIf="editNote">
      <h4 style="margin-top: 20px">Edit Note</h4>
      <div class="edit-message-container">
        <h4>Message</h4>
        <textarea [(ngModel)]="selectedJob.details_long" class="new-note-body">
                        {{ selectedJob.details_long }}
                    </textarea
        >
      </div>

      <div class="edit-calendar">
        <input
          class="form-control"
          placeholder="mm-dd-yyyy"
          name="dp"
          ngbDatepicker
          #d1="ngbDatepicker"
          [(ngModel)]="editDate"
        />
        <button
          class="date-btn btn-outline-secondary calendar fa fa-calendar"
          (click)="d1.toggle()"
          type="button"
          value="{{editDate}"
        ></button>
      </div>

      <button
        [disabled]="loading"
        class="btn btn-success"
        (click)="saveEditNote()"
      >
        Save Notification
      </button>
    </div>
  </div>
</html>
