import { Component, Input, OnInit } from "@angular/core";

import { HistoricalOrdersReusableService } from "../../historical-orders/historical-orders-reusable.service";
import { FilterResult, FilterService } from "../filter.service";

@Component({
  selector: "app-filter-bubble",
  templateUrl: "./filter-bubble.component.html",
  styleUrls: ["./filter-bubble.component.scss"],
})
export class FilterBubbleComponent implements OnInit {
  /* Name of Filter, "strike_1", "strike_2", etc */
  public _filterName: any;

  @Input() set filterName(value: any) {
    this._filterName = value;
  }

  get filterName(): any {
    return this._filterName;
  }

  /* Value of Filter, a string of one or more filter values, i.e "DFA FDA DTD" */
  public _filterValue: any;

  @Input() set filterValue(value: any) {
    this._filterValue = value;
  }

  get filterValue(): any {
    return this._filterValue;
  }

  /* Value of Filter, a string of one or more filter values, i.e "DFA FDA DTD" */
  public _operator: any;

  @Input() set operator(value: any) {
    this._operator = value;
  }

  get operator(): any {
    return this._operator;
  }

  // allFilters: any;
  loading: any;
  filterSub: any;

  constructor(
    public filterService: FilterService,
    public filterHelperService: HistoricalOrdersReusableService
  ) {}

  ngOnInit() {}

  ngOnDestroy() {
    if (this.filterSub) {
      this.filterSub.unsubscribe();
    }
  }

  getFilterBubbleName(colName) {
    return this.filterHelperService.formatColumnName(colName);
  }

  getOperator() {}

  formatBubbleNames(str) {
    if (str == "= YES") {
      return "= Y";
    } else if (str === "= NO") {
      return "= N";
    }

    return str;
  }

  closeBubbleEvent() {
    let that = this;

    /* Remove the filter that has been closed from allFilters */
    let remainingFilters = this.filterService.filters.filter(function (filter) {
      return filter.field != that._filterName;
    });

    /* Send the event to the Filtering Service */
    this.filterService.bubbleClosed(remainingFilters);
  }
}
