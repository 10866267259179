import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RuntimeEnvLoaderService } from '../../runtime-env-loader.service';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  adminUserGroupId: any;
  basePath: string;
  errorHandler: any;
  user: any;

  constructor(
    private http: HttpClient,
    private envLoader: RuntimeEnvLoaderService
  ) {
    this.basePath = envLoader.config.API_BASE_URL + envLoader.config.APP_KEY;
  }

  setUser(user: any) {
    this.user = user;
  }

  getUser() {
    return this.user;
  }

  setUserFilters(id) {
    const headers = new HttpHeaders().set('security-token', JSON.parse(localStorage.getItem('currentUser')).token);
    const filters = {field: "account_id", operator: "contains", value: "1", check: true};
    return this.http.patch<any>(this.basePath + '/users/' + id, filters, { headers })
    .pipe(
      map((response => {        
        return response.data;
      })),
      catchError(this.handleError),
    );
  }

  getUserList(params = null): Observable<any> {
    const headers = new HttpHeaders()
      .set('security-token', JSON.parse(localStorage.getItem('currentUser')).token);
    return this.http.get<any>(this.basePath + '/users', { headers, observe: 'response', params })
      .pipe(
        map((response => {
          return response;
        })),
        catchError(this.handleError),
      );
  }

  getUserDetails(id): Observable<any> {

    const headers = new HttpHeaders().set('security-token', JSON.parse(localStorage.getItem('currentUser')).token);

    return this.http
      .get<any>(this.basePath + '/users/' + id, { headers })
      .pipe(
        map(response => {
          return response.data;
        }),
        catchError(this.handleError)
      );
  }

  updateUserDetails(id, data): Observable<any> {
    const headers = new HttpHeaders()
      .set('security-token', JSON.parse(localStorage.getItem('currentUser')).token);

    return this.http.patch<any>(this.basePath + '/users/' + id, data, { headers })
      .pipe(
        map((response => {
          return response.data;
        })),
        catchError(this.handleError),
      );
  }

  updateUserFiles(id, data): Observable<any> {
    const headers = new HttpHeaders()
      .set('security-token', JSON.parse(localStorage.getItem('currentUser')).token);

    return this.http.post<any>(this.basePath + '/files', data, { headers })
      .pipe(
        map((response => {
          return response.data;
        })),
        catchError(this.handleError),
      );
  }

  getUserFile(userId, fileId): Observable<any> {
    const headers = new HttpHeaders().set('security-token', JSON.parse(localStorage.getItem('currentUser')).token);

    return this.http
      .get<any>(this.basePath + '/files/' + fileId, {
        headers
      })
      .pipe(
        map(response => {
          return response.data;
        }),
        catchError(this.handleError)
      );
  }

  deleteUser(id): Observable<any> {
    const headers = new HttpHeaders()
      .set('security-token', JSON.parse(localStorage.getItem('currentUser')).token);

    return this.http.delete<any>(this.basePath + '/users/' + id, { headers })
      .pipe(
        map((response => {
          return 200;
        })),
        catchError(this.handleError),
      );
  }

  createUser(data): Observable<any> {
    const headers = new HttpHeaders().set('security-token', JSON.parse(localStorage.getItem('currentUser')).token);
    return this.http
      .post<any>(this.basePath + '/users/', data, { headers })
      .pipe(
        map(response => {
          return response.data;
        }),
        catchError(this.handleError)
      );
  }

  handleError(error) {
    

    let errorMessage = {};
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = {
        error: `${error.error.message}`
      };
    } else {
      // server-side error
      errorMessage = {
        code: `${error.status}`,
        message: `${error.statusText}`,
        errors: error.error.errors
      };
    }
    // window.alert(errorMessage);
    return throwError(errorMessage);
  }


}
