import { Component, OnInit } from '@angular/core';
import { LayoutModule, LayoutService } from 'angular-admin-lte';
import { BoxModule } from 'angular-admin-lte';
import { AuthenticationService } from './services/authentication.service';
import { Router } from '@angular/router';
import { User } from './data-structures/user';
import { FullScreenService } from './services/full-screen.service';
import * as io from 'socket.io-client';
import Echo from 'laravel-echo'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  user: User;
  application = 'show';
  public isCustomLayout = false;
  fullscreen: boolean;
  private socket: any;


  constructor(
    private layoutService: LayoutService,
    public authService: AuthenticationService,
    private router: Router,
    public fullScreenService: FullScreenService
    ) {
      this.authService.currentUser.subscribe((user: User) => {
        this.user = user;
      });

      //this.socket = io('http://127.0.0.1:5005');
    }

  ngOnInit() {
    this.authService.currentUser.subscribe((user: User) => {
      this.user = user;
    });

    this.layoutService.isCustomLayout.subscribe((value: boolean) => {
      this.isCustomLayout = value;
    });

    // let echo = new Echo({
    //   broadcaster: 'socket.io',
    //   host: 'http://seekers-backend.com:6001',
    //   auth: { headers: { Authorization: 1 } }
    // })
    // echo.private('user.' + 1)
    // .listen('MessageEvent', (e) => {
    //   // Do stuff
    // })
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['/login']);
  }
}
