<ng-container *ngIf="!column.hidden" class="historicalTableHead">
  {{ column.columnName }}
  <div>
    <div class="d-inline-block" ngbDropdown>
      <div class="header-contents">
        <button
          [ngClass]="{ 'is-filtered': isYellow }"
          class="fa fa-filter dropdown-btn"
          id="dropdownBasic1"
          ngbDropdownToggle
        ></button>

        <app-sort [loading]="_loading" [column]="_column"></app-sort>
      </div>

      <div
        #drop
        ngbDropdownMenu
        aria-labelledby="dropdownBasic1"
        class="dropdown-container"
      >
        <div>
          <label>Exact Date: </label>
          <div class="date-container">
            <div>
              <input
                class="form-control"
                placeholder="mm-dd-yyyy"
                name="dp"
                ngbDatepicker
                #d1="ngbDatepicker"
                [(ngModel)]="exactDate"
                readonly
              />
            </div>
            <div class="input-group-append">
              <button
                class="date btn-outline-secondary calendar fa fa-calendar"
                (click)="d1.toggle()"
                type="button"
                value="{{ exactDate }}"
              ></button>
            </div>
          </div>

          <hr />

          <div
            style="
              margin-top: 5px;
              margin-bottom: 5px;
              display: flex;
              justify-content: center;
            "
          >
            <button
              class="date btn-outline-secondary calendar fa fa-calendar range-btn"
              (click)="showRangeClick()"
              type="button"
            >
              Add Date Range
            </button>
          </div>

          <ngb-datepicker
            *ngIf="showRange"
            #dp
            (dateSelect)="onDateSelection($event)"
            [displayMonths]="2"
            [dayTemplate]="t"
            outsideDays="hidden"
          >
          </ngb-datepicker>
          <ng-template #t let-date let-focused="focused">
            <span
              class="custom-day"
              [class.focused]="focused"
              [class.range]="isRange(date)"
              [class.faded]="isHovered(date) || isInside(date)"
              (mouseenter)="hoveredDate = date"
              (mouseleave)="hoveredDate = null"
            >
              {{ date.day }}
            </span>
          </ng-template>

          <hr />

          <div *ngIf="fromDate && toDate">
            <pre>From: {{ formatDate(fromDate) | json }} </pre>
            <pre>To: {{ formatDate(toDate) | json }} </pre>
          </div>

          <div class="btns">
            <button
              ngbDropdownToggle
              class="filter-btn"
              type="submit"
              (click)="hideColumn()"
            >
              Hide Column
            </button>
            <button
              ngbDropdownToggle
              class="filter-btn apply-btn"
              type="submit"
              (click)="setFilters()"
            >
              Apply Filter
            </button>
          </div>
        </div>
      </div>
      <span class="sortIcon" style="cursor: pointer"></span>
    </div>
  </div>
</ng-container>
