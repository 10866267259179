<div class="footer">
    <p class="totals">| Totals |</p>

    <!-- <span class="space"></span>
    <span><span class="sumHeader">Days: </span> {{sums.expiration_days_away}} </span>
    <span><span class="sumHeader">Qty: </span> {{sums.quantity}} </span>
    <span><span class="sumHeader">Strike 1: </span> {{sums.strike_1}} </span>
    <span><span class="sumHeader">Strike 2: </span> {{sums.strike_2}} </span>
    <span><span class="sumHeader">Cost: </span> {{sums.cost}} </span>
    <span><span class="sumHeader">Exposure: </span> {{sums.exposure}} </span>
    <span><span class="sumHeader">Price @ Upload: </span> {{sums.price_at_upload}} </span>
    <span><span class="sumHeader">Price @ Fill: </span> {{sums.price_at_fill}} </span> -->
</div>