import {
  APP_INITIALIZER,
  Injectable,
  NgModule,
  Pipe,
  PipeTransform,
} from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { BrowserModule } from "@angular/platform-browser";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import {
  MAT_DIALOG_DEFAULT_OPTIONS,
  MatDialogModule,
} from "@angular/material/dialog";
import { MatTooltipModule } from "@angular/material/tooltip";
import { ScrollingModule } from "@angular/cdk/scrolling";

import * as _ from "lodash";
import { LayoutModule } from "angular-admin-lte";
import { ToastrModule } from "ngx-toastr";

import {
  NgbDateParserFormatter,
  NgbModule,
  NgbPopoverModule,
} from "@ng-bootstrap/ng-bootstrap";

import { AddNotificationDialogComponent } from "./dashboard/notifications/add-notification-dialog/add-notification-dialog.component";
import { AppComponent } from "./app.component";
import { AppMaterialModule } from "./app-material/app-material.module";
import { AppRoutingModule } from "./app-routing.module";
import { CreateUserComponent } from "./dashboard/users/create-user/create-user.component";
import { DataTableComponent } from "./core/table/data-table/data-table.component";
import { ExposureReportComponent } from "./dashboard/reports/exposure-report/exposure-report.component";
import { FilterBubbleComponent } from "./dashboard/table-components/filter-bubble/filter-bubble.component";
import { FilterHeaderComponent } from "./core/filter-header/filter-header.component";
import { FilterService } from "./dashboard/table-components/filter.service";
import { FixedHeaderDirective } from "./fixed-header.directive";
import { FooterComponent } from "./core/footer/footer.component";
import { FormulaManagementComponent } from "./dashboard/management/formula-management/formula-management.component";
import { HistoricalOrdersComponent } from "./dashboard/historical-orders/historical-orders.component";
import { HomeComponent } from "./dashboard/home/home.component";
import { ImageSelectorComponent } from "./core/image-selector/image-selector.component";
import { LoginComponent } from "./user/login/login.component";
import { MaxGainChartComponent } from "./dashboard/management/max-gain-chart/max-gain-chart/max-gain-chart.component";
import { NewPasswordComponent } from "./user/new-password/new-password.component";
import { NgbDateFRParserFormatter } from "./ngb-date-fr-parser-formatter";
import { NotificationsComponent } from "./dashboard/management/notifications/notifications.component";
import { PaginationComponent } from "./core/pagination/pagination.component";
import { PasswordResetComponent } from "./user/password-reset/password-reset.component";
import { PercentValueNotificationsComponent } from "./dashboard/table-components/percent-value-notifications/percent-value-notifications.component";
import { PopoverComponent } from "./dashboard/table-components/popover/popover.component";
import { PortfolioComponent } from "./dashboard/portfolio/portfolio.component";
import { ReportsComponent } from "./dashboard/reports/reports.component";
import { ReportService } from "./services/report/report.service";
import { ReturnTableSideStripeComponent } from "./dashboard/return-tables/return-table-side-stripe/return-table-side-stripe.component";
import { RightClickComponent } from "./dashboard/table-components/right-click/right-click.component";
import { RuntimeEnvLoaderService } from "./services/runtime-env-loader.service";
import { ScheduledMessagesComponent } from "./dashboard/scheduled-messages/scheduled-messages.component";
import { SearchBoxComponent } from "./core/search-box/search-box.component";
import { SearchComponent } from "./dashboard/table-components/search/search.component";
import { SearchPipe } from "./search.pipe";
import { SidemenuLeftColapseComponent } from "./core/sidemenu-left colapse/sidemenu-left-colaspe.component";
import { SidemenuLeftComponent } from "./core/sidemenu-left/sidemenu-left.component";
import { SortComponent } from "./dashboard/table-components/sort/sort.component";
import { SortService } from "./dashboard/historical-orders/sort.service";
import { SpreadNotificationsComponent } from "./dashboard/table-components/spread-notifications/spread-notifications.component";
import { StrategiesComponent } from "./dashboard/management/strategies/strategies.component";
import { SumFooterComponent } from "./dashboard/sum-footer/sum-footer.component";
import { SymbolsComponent } from "./dashboard/management/symbols/symbols.component";
import { TableHeaderComponent } from "./dashboard/table-components/table-header/table-header.component";
import { TableHeaderDateComponent } from "./dashboard/table-components/table-header-date/table-header-date.component";
import { TableHeaderFilteredListComponent } from "./dashboard/table-components/table-header-filtered-list/table-header-filtered-list.component";
import { TimesPipe } from "./dashboard/table-components/times.pipe";
import { TokenInterceptor } from "./interceptors/token-interceptor";
import { TopmenuComponent } from "./core/topmenu/topmenu.component";
import { UpdatePasswordComponent } from "./dashboard/users/update-password/update-password.component";
import { UserCustomFormulasComponent } from "./dashboard/management/user-custom-formulas/user-custom-formulas.component";
import { UserDetailsComponent } from "./dashboard/users/user-details/user-details.component";
import { UserListComponent } from "./dashboard/users/user-list/user-list.component";
import { UserNotificationTableComponent } from "./dashboard/table-components/user-notification-table/user-notification-table.component";

@Pipe({
  name: "uniqFilter",
  pure: false,
})
@Injectable()
export class UniquePipe implements PipeTransform {
  transform(items: any[], args: any[]): any {
    // filter items array, items which match and return true will be kept, false will be filtered out

    return _.uniqBy(items, args);
  }
}

@Pipe({
  name: "fullTextSearch",
  pure: false,
})
export class FullTextSearchPipe implements PipeTransform {
  constructor() {}

  transform(value: any, query: string, field: string): any {
    return query
      ? value.reduce((prev, next) => {
          var lowerQ = query.toLowerCase();
          var lower = next[field].toLowerCase();

          if (lower.includes(lowerQ) || lower.includes()) {
            prev.push(next);
          }
          return prev;
        }, [])
      : value;
  }
}

const appInitializer = (envService: RuntimeEnvLoaderService) => {
  return () => {
    return envService.loadAppConfig();
  };
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    PasswordResetComponent,
    NewPasswordComponent,
    TopmenuComponent,
    SidemenuLeftComponent,
    SidemenuLeftColapseComponent,
    FooterComponent,
    UserListComponent,
    PaginationComponent,
    SearchBoxComponent,
    ImageSelectorComponent,
    CreateUserComponent,
    UserDetailsComponent,
    UpdatePasswordComponent,
    HistoricalOrdersComponent,
    PortfolioComponent,
    SumFooterComponent,
    UniquePipe,
    FullTextSearchPipe,
    TableHeaderComponent,
    TableHeaderFilteredListComponent,
    TableHeaderDateComponent,
    SearchComponent,
    SymbolsComponent,
    StrategiesComponent,
    SortComponent,
    FilterBubbleComponent,
    PopoverComponent,
    RightClickComponent,
    TimesPipe,
    ReturnTableSideStripeComponent,
    AddNotificationDialogComponent,
    MaxGainChartComponent,
    UserCustomFormulasComponent,
    NotificationsComponent,
    FormulaManagementComponent,
    ScheduledMessagesComponent,
    SearchPipe,
    UserNotificationTableComponent,
    ExposureReportComponent,
    ReportsComponent,
    FilterHeaderComponent,
    PercentValueNotificationsComponent,
    SpreadNotificationsComponent,
    DataTableComponent,
    FixedHeaderDirective,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    ScrollingModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    MatTooltipModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    AppMaterialModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    NgbPopoverModule,
    NgbModule,
    LayoutModule.forRoot({
      skin: "blue",
      layout: "normal",
    }),
    FormsModule,
    ToastrModule.forRoot(),
    MatDialogModule,
  ],
  providers: [
    RuntimeEnvLoaderService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [RuntimeEnvLoaderService],
    },
    { provide: NgbDateParserFormatter, useClass: NgbDateFRParserFormatter },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    UniquePipe,
    FullTextSearchPipe,
    SortService,
    FilterService,
    ReportService,
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: { minWidth: 800, minHeight: 600, hasBackdrop: true },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
