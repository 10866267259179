<html>
    <h5>Create A Formula</h5>
    <p style="margin-top: 5px;
    margin-bottom: 0px;">Create a custom formula</p>
    <div>
        <form>
            <div>
                <label>Formula Name</label>
                <input>
            </div>
            <div>
                <label>Formula Calculation</label>
                <input>
            </div>
        </form>
    </div>
</html>
