import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { query } from '@angular/animations';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss']
})
export class NewPasswordComponent implements OnInit {

  resetForm: FormGroup;
  submitted = false;
  passwordMismatch = false;
  email: string;
  password: string;
  token: string;
  loading = false;

  constructor(private formBuilder: FormBuilder, private authService: AuthenticationService, public router: Router, private activeRoute: ActivatedRoute) {

   }

  get f() { return this.resetForm.controls; }

  ngOnInit() {

    this.activeRoute.queryParams.subscribe(query => {
      this.token = query.token;
      
    });

    this.resetForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(6)]],
      passwordConfirm: ['', [Validators.required]]
    });

  }

  onSubmit() {
    this.submitted = true;
    if (!this.resetForm.invalid) {

      const newPassword = this.resetForm.value.password;
      const passwordConfirm = this.resetForm.value.passwordConfirm;

      if (newPassword === passwordConfirm) {
          const data = {
            token: this.token,
            new_password: newPassword,
            new_password_confirmation: passwordConfirm
          };

          this.loading = true;

          this.authService.setNewPassword(data).subscribe(res => {
            this.authService.clear();
            this.loading = false;
            Swal.fire({
              text: 'Password has been changed!',
              icon: 'success'
            }).then(() => {
              this.loading = false;
              this.router.navigate(['/']);
            });

          }, () => {
            this.loading = false;
            Swal.fire({
              text: 'There is something wrong, please try the link in your email again.',
              icon: 'error'
            });
          });
      } else {
        this.passwordMismatch = true;
        return false;
      }

    } else {
      return false;
    }
  }

}

