import { ToastrService } from "ngx-toastr";
import { Observable, OperatorFunction } from "rxjs";
import { debounceTime, distinctUntilChanged, map } from "rxjs/operators";
import { UserService } from "src/app/services/app/user/user.service";
import { NotificationsService } from "src/app/services/notifications.service";
import { SymbolsService } from "src/app/services/symbols.service";
import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialogRef } from "@angular/material/dialog";
import { UserNotificationTableComponent } from "../../table-components/user-notification-table/user-notification-table.component";
import { NotificationOperationsService } from "../notification-operations.service";
import { ConstantPool } from "@angular/compiler";
import { AppService } from "src/app/services/app.service";

@Component({
  selector: "app-add-notification-dialog",
  templateUrl: "./add-notification-dialog.component.html",
  styleUrls: ["./add-notification-dialog.component.scss"],
})
export class AddNotificationDialogComponent implements OnInit {
  loading: any;
  percentTimespan: any;
  percentMovesUp: any = "";
  percentMovesDown: any = "";
  tickerObj: any;
  notificationType: any = "Ticker";
  tickerSubtype: any = "Percent Notification";
  selectedComparisonForValueNotification: any = "Less Than";
  message: any = "";
  noteUntilDate: any;
  userEnteredTicker: any;
  userEnteredTickerStr: string;
  noteEqualsValue: any;
  dateNoteMessage: any;
  users: any;
  usersUndefinedError: any = false;
  addMovesUpMovesDownPercentErrorMsg: any;

  dateForDateNotification: any;

  /* ERRORS for Percent Tickers */
  addTickerPercentError: boolean;
  addMovesUpMovesDownPercentError: boolean;
  addDateNoteError: boolean;

  /* ERRORS for Value Tickers */
  addTickerValueError: boolean;
  addComparisonValueError: boolean;

  percentUpDownMatchError: boolean = false;

  /* Helper array containing only symbols */
  symbols: any;

  /* ALL the ticker data */
  tickerData: any;

  /* USERS LOADING! */
  usersLoading: any;

  addDateError: any = false;

  tickerRequiredError: boolean = false;

  /* Ticker Error Message */
  userTickerErrorMsg: any = "";

  userTickerError: any = false;

  @ViewChild(UserNotificationTableComponent, { static: false })
  userNotificationTableComponent: UserNotificationTableComponent;

  public _rightClicked: any;

  @Input() set rightClicked(value: any) {
    this._rightClicked = value;
  }
  get rightClicked(): any {
    return this._rightClicked;
  }

  constructor(
    public appService: AppService,
    private toastr: ToastrService,
    public symbolsService: SymbolsService,
    public notificationsService: NotificationsService,
    public userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    public dialogRef: MatDialogRef<AddNotificationDialogComponent>,
    public notificationOperationsService: NotificationOperationsService
  ) {}

  ngOnInit() {
    this.getUsers();
    this.getSymbolsData();
    this.percentTimespan = "Today Only";
    this.notificationType = "Ticker";
  }

  addNewTicker() {
    this.router.navigate(["/symbols"]);
    this.dialogRef.close();
  }

  clickUserTable($event) {
    this.users = $event;
  }

  /* Helper function that assigns the entire Ticker Object that the user typed */
  getTickerObjectFromName(ticker) {}

  setSelected(val) {
    this.selectedComparisonForValueNotification = val;
  }

  errorCheckPercents() {
    this.addMovesUpMovesDownPercentErrorMsg = "";
    this.addTickerPercentError = false;
    this.addMovesUpMovesDownPercentError = false;
    this.userTickerError = false;
    let hasError = false;

    if (!this.userEnteredTickerStr) {
      this.addTickerPercentError = true;
      hasError = true;
    }

    // We require at least one (percent moves up, and percent moves down)
    if (!this.percentMovesDown && !this.percentMovesUp) {
      this.addMovesUpMovesDownPercentError = true;
      this.addMovesUpMovesDownPercentErrorMsg =
        "Please enter at least one number";
      hasError = true;
    }

    if (this.percentMovesUp && this.percentMovesDown) {
      if (this.percentMovesUp != this.percentMovesDown) {
        this.addMovesUpMovesDownPercentError = true;
        this.addMovesUpMovesDownPercentErrorMsg =
          "Moves Up and Moves Down must match. Otherwise lease one blank for movement tracking in one direction";
        hasError = true;
      }
    }

    if (isNaN(this.percentMovesDown) || isNaN(this.percentMovesUp)) {
      this.addMovesUpMovesDownPercentError = true;
      this.addMovesUpMovesDownPercentErrorMsg = "Input must be a number";
      hasError = true;
    }

    if (!this.userEnteredTicker.mark || isNaN(this.userEnteredTicker.mark)) {
      this.userTickerError = true;
      this.userTickerErrorMsg = "Ticker must have a starting value";
      hasError = true;
    }

    return hasError;
  }

  errorCheckValue() {
    this.addTickerValueError = false;
    this.addComparisonValueError = false;
    let hasError = false;

    if (!this.userEnteredTickerStr) {
      this.addTickerValueError = true;
      hasError = true;
    }

    // We require at least one (percent moves up, and percent moves down)
    if (!this.noteEqualsValue) {
      this.addComparisonValueError = true;
      hasError = true;
    }

    return hasError;
  }

  getUsers() {
    this.loading = true;
    this.userService.getUserList().subscribe((data) => {
      this.users = data.body.data;
      this.loading = false;
    });
  }

  parseTimezone(date) {
    // let timezone = this.notificationsService.timeZone;
    let timezone = "AMERICA/CHICAGO";
    let strTime = date.toLocaleString("en-US", {
      timeZone: `${timezone}`,
    });

    return strTime;
  }

  /* Convert MM/DD/YYYY to YYYY-MM-DD */
  formatDateYYYYMMDD(date) {
    return new Date(date).toLocaleDateString("fr-CA");
  }

  parse() {
    let untilDate = "";
    if (this.percentTimespan == "Until Date") {
      if (this.noteUntilDate) {
        untilDate =
          this.noteUntilDate.year +
          "-" +
          this.noteUntilDate.month +
          "-" +
          this.noteUntilDate.day;
        untilDate = this.parseTimezone(untilDate);
      }
    } else if (this.percentTimespan == "Today Only") {
      untilDate = this.parseTimezone(new Date());
      untilDate = this.formatDateYYYYMMDD(untilDate);
    }
  }

  createParamsForPercentNote() {
    let users = this.userNotificationTableComponent.usersForChecks.filter(
      (u) => u.send_sms || u.send_email
    );

    let mathForPercents =
      this.notificationOperationsService.createMathForPercents(
        this.percentMovesUp,
        this.percentMovesDown,
        this.userEnteredTicker.mark
      );

    let untilDate = "";
    if (this.percentTimespan == "Until Date") {
      if (this.noteUntilDate) {
        untilDate =
          this.noteUntilDate.year +
          "-" +
          this.noteUntilDate.month +
          "-" +
          this.noteUntilDate.day;
        untilDate = this.parseTimezone(untilDate);
      }
    } else if (this.percentTimespan == "Today Only") {
      untilDate = this.parseTimezone(new Date());
      untilDate = this.formatDateYYYYMMDD(untilDate);
    }

    let jsonOfJob = {
      percent_moved: 0,
      up_incriment: mathForPercents.upIncriment,
      down_incriment: mathForPercents.downIncriment,
      move_down_target: mathForPercents.targetMovesDown,
      move_up_target: mathForPercents.targetMovesUp,
      percent_moves_up: this.percentMovesUp,
      percent_moves_down: this.percentMovesDown,
      time_span: this.percentTimespan,
      previous_value: this.userEnteredTicker.mark,
      initial_value: this.userEnteredTicker.mark,
      initial_mark: this.userEnteredTicker.mark,
      mark: this.userEnteredTicker.mark,
      ticker: this.userEnteredTicker,
      symbol: this.userEnteredTicker.symbol,
      type: this.userEnteredTicker.type,
      description: this.userEnteredTicker.description,
      category: this.userEnteredTicker.category,
      ticker_id: this.userEnteredTicker.id,
      users: users,
      date_until: untilDate,
      message: this.message,
    };

    var params = {
      details_long: JSON.stringify(jsonOfJob),
      details_1: 0,
      app_id: 1,
      every_minutes: "1",
      frequency: "every_minutes",
      next_run: "2021-12-14 20:40:05",
      name: "create_notification_by_ticker_percent",
    };

    return params;
  }

  parseComparisonOperator(comp) {
    if (comp === "Less Than") {
      return "<";
    } else if (comp === "Greater Than") {
      return ">";
    }
  }

  clickUserTableForDate($event) {
    this.users = $event;
  }

  createParamsForValueNote() {
    let comparison = this.parseComparisonOperator(
      this.selectedComparisonForValueNotification
    );

    let users = this.userNotificationTableComponent.usersForChecks.filter(
      (u) => u.send_sms || u.send_email
    );

    let jsonOfJob = {
      time_span: "Recurring",
      moves_up: this.percentMovesUp,
      comparison: comparison,
      moves_down: this.percentMovesDown,
      previous_value: this.userEnteredTicker.mark,
      initial_value: this.userEnteredTicker.mark,
      initial_mark: this.userEnteredTicker.mark,
      mark: this.userEnteredTicker.mark,
      target_value: this.noteEqualsValue,
      ticker: this.userEnteredTicker,
      symbol: this.userEnteredTicker.symbol,
      type: this.userEnteredTicker.type,
      description: this.userEnteredTicker.description,
      category: this.userEnteredTicker.category,
      ticker_id: this.userEnteredTicker.id,
      users: users,
      message: this.message,
    };

    var params = {
      details_long: JSON.stringify(jsonOfJob),
      details_1: 0,
      app_id: 1,
      every_minutes: "1",
      frequency: "every_minutes",
      next_run: "2021-12-14 20:40:05",
      name: "create_notification_by_ticker_value",
    };

    return params;
  }

  createTickerNotification() {
    if (this.tickerSubtype == "Value Notification") {
      if (this.errorCheckValue()) {
        return;
      }

      let params = this.createParamsForValueNote();
      this.createScheduledJob(params);
    } else if (this.tickerSubtype == "Percent Notification") {
      if (this.errorCheckPercents()) {
        return;
      }

      let params = this.createParamsForPercentNote();
      this.createScheduledJob(params);
    }
  }

  getSymbolsData() {
    this.loading = true;
    this.symbolsService.getSymbols().subscribe((result) => {
      this.tickerData = result.body.data;
      this.symbols = this.tickerData.map((v) => v.symbol);
      this.loading = false;
    });
  }

  setPercentTimespan(timespan) {
    this.percentTimespan = timespan;
  }

  createScheduledJob(params) {
    this.usersUndefinedError = false;

    let users = this.userNotificationTableComponent.usersForChecks.filter(
      (u) => u.send_sms || u.send_email
    );

    if (!Array.isArray(users) || !users.length) {
      this.usersUndefinedError = true;
      return;
    }

    this.loading = true;

    this.notificationsService.newScheduledJob(params).subscribe((result) => {
      this.loading = false;
      this.toastr.success("Created New Notification");
      this.dialogRef.close(); // After creating notification, close modal.
      if (this.appService.scheduledNotificationTableLoadedGlobal) {
        this.router.navigate(["/scheduled-messages"]).then(() => {
          window.location.reload();
        });
      }
    });
  }

  createDateNotification() {
    this.addDateError = false;

    let users = this.userNotificationTableComponent.usersForChecks.filter(
      (u) => u.send_sms || u.send_email
    );

    if (!this.dateForDateNotification) {
      this.addDateError = true;
      return;
    }
    if (this.dateNoteMessage == null || this.dateNoteMessage == "") {
      this.addDateNoteError = true;
      return;
    }

    let date =
      this.dateForDateNotification.year +
      "-" +
      this.dateForDateNotification.month +
      "-" +
      this.dateForDateNotification.day;

    let jsonOfJob = {
      message: this.dateNoteMessage,
      users: users,
    };

    var params = {
      details_long: JSON.stringify(jsonOfJob),
      details_1: 0,
      app_id: 1,
      remaining: "1",
      every_minutes: "1",
      frequency: "every_minutes",
      next_run: date,
      name: "create_notification_by_date",
    };

    this.createScheduledJob(params);
  }

  tickerSelectedEvent($event) {
    this.loading = true;

    this.symbolsService.getSymbols().subscribe((result) => {
      this.tickerData = result.body.data;
      this.symbols = this.tickerData.map((v) => v.symbol);
      this.loading = false;

      this.userEnteredTicker = this.tickerData.filter(
        (d) => d.symbol == $event.item
      )[0];
    });
  }

  /* Autocomplete code.  Taken directly from NG-Bootstrap documentation */
  search: OperatorFunction<string, readonly string[]> = (
    text$: Observable<string>
  ) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((term) =>
        term.length < 1
          ? []
          : this.symbols
              .filter((v) => v.toLowerCase().indexOf(term.toLowerCase()) > -1)
              .slice(0, 10)
      )
    );
}
