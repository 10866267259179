<div class="pull-left">
    Show
    <select class="showCount" (change)="pageLength($event)">
        <option *ngFor="let count of showCounts" [selected]="count==countA"  [value]="count" >{{count}}</option>
    </select>
    entries
</div>

<div class="pull-left">{{showCount}}</div>
<div class="pull-right" *ngIf="isPagination">
  <div class="dataTables_paginate paging_simple_numbers">
    <a [class.disabled]="page==1" class="paginate_button previous" (click)="previous(page)">Previous</a>
    <span>
      <a *ngFor="let singlePage of totalPages" (click)="pageClick(singlePage)">
        <span [class.current]="singlePage==page" class="paginate_button">{{singlePage}}</span>
      </a>
    </span>
    <a [class.disabled]="page==totalPages.length" class="paginate_button next" (click)="next(page)">Next</a>
  </div>
</div>