import * as FileSaver from "file-saver";
import Echo from "laravel-echo";
import { ToastrService } from "ngx-toastr";
import { forkJoin, Subscription } from "rxjs";
import { UserRole } from "src/app/constants/user/user-role";
import { UserService } from "src/app/services/app/user/user.service";
import { AuthenticationService } from "src/app/services/authentication.service";
import { FullScreenService } from "src/app/services/full-screen.service";
import { HistoricalOrdersService } from "src/app/services/historical-orders.service";
import { RuntimeEnvLoaderService } from "src/app/services/runtime-env-loader.service";
import { UtilsService } from "src/app/services/utils.service";
import Swal from "sweetalert2";

import { HttpParams } from "@angular/common/http";
import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import {
  ModalDismissReasons,
  NgbDateStruct,
  NgbModal
} from "@ng-bootstrap/ng-bootstrap";

import {
  FilterResult,
  FilterService
} from "../table-components/filter.service";
import { SortService } from "./sort.service";

(window as any).global = window;
declare var require: any;

declare global {
  interface Window {
    io: any;
  }

  interface Window {
    Echo: any;
  }
}

@Component({
  selector: "app-user-list",
  templateUrl: "./historical-orders.component.html",
  styleUrls: ["./historical-orders.component.scss"],
})
export class HistoricalOrdersComponent implements OnInit {
  percent_target = [
    {
      pt: 100,
    },
    {
      pt: 4,
    },
  ];

  selectedArchiveTypeName: string;
  selectedArchiveTypeValue: string;

  allFilters: any = [];
  footerSums: any = { quantity: 0, exposure: 0 };
  footerAvgs: any = { cost: 0 };
  footerHighLow: any = { moveNeededHigh: 0, moveNeededLow: 0 };

  model: NgbDateStruct;
  date: { year: number; month: number };
  count: any;
  archiveList = new Array();
  getInstallersList: any;
  sortObj: any;
  totalCount: any;

  // All of the historical orders in the database regardless of filters or archive status
  // We will use this for filtering
  originalData: any = [];

  historicalOrders: any;
  historicalOrdersTemp: any;
  currentUserDetails: any;
  logUserData: any;
  userLog: any;
  loginUserRoleIds = [];
  checkDisableBtn = false;
  atThisPrice = 10000;
  loading: boolean;

  // NEW REFACTORING ... have one variable for each column.
  dateColumn: any;

  // NEW REFACTORING ... have one variable, an object (shoudnt this be an array? - we cant push objects into objects), that 'holds' all columns.
  allColumns = [];
  column = {};

  incomingFill = false;
  historicalOrdersForSearch: any;
  filtersCollapsed = false;
  filterCriteria = [];
  subGetEssentialData: Subscription;
  subGetLoggingData: Subscription;
  isAdmin = false;
  usersCount: any;
  searchValue = "";
  closeResult: string;

  /* NEW ALEX COLUMNS */
  daysColumn: any;
  quantityColumn: any;
  strikeOneColumn: any;
  strikeTwoColumn: any;
  costColumn: any;
  exposureColumn: any;
  moveNeededColumn: any;
  maxGainColumn: any;
  qualityColumn: any;
  at10kColumn: any;
  targetReturnFillColumn: any;
  targetReturnCurrentColumn: any;
  targetPercentColumn: any;
  at100PercentColumn: any;
  at95PercentColumn: any;
  at90PercentColumn: any;
  priceAtUploadColumn: any;
  priceAtFillColumn: any;
  moveSinceColumn: any;
  tableHeaderFilterListFilters: any;
  tickerColumn: any;
  acctColumn: any;
  optionTypeColumn: any;
  tableNumColumn: any;
  typeColumn: any;
  expirationColumn: any;
  categoryColumn: any;
  partialFillColumn: any;
  percentInterestColumn: any;
  fullscreen: boolean;
  historicalOrdersAll: any;
  user: any;
  userId: any;
  lastReturnCount: any;
  footerHidden: any = false;
  realTimeDataSub: any;

  bubbleList: any;
  bubbles: boolean;

  tableName: string;

  sortBy: any;
  sortOrder: any;
  sortSubscription: any;

  filterAppliedSubscription: any;
  bubbleClosedSubscription: any;
  echo: any;

  constructor(
    private router: Router,
    private envService: RuntimeEnvLoaderService,
    private userService: UserService,
    private historicalOrdersService: HistoricalOrdersService,
    public authService: AuthenticationService,
    private modalService: NgbModal,
    public fullScreenService: FullScreenService,
    public sortService: SortService,
    public filterService: FilterService,
    private toastr: ToastrService,
    private utilsService: UtilsService,
    private changeDetector: ChangeDetectorRef
  ) {
    // Default to get newest dates first.
    this.sortObj = {
      sortBy: "",
      sortOrder: "",
    };

    this.currentUserDetails = localStorage.getItem("currentUser");
  }

  joinWebsocket() {
    this.echo = new Echo({
      broadcaster: "pusher",
      key: "dorian", // hard code
      wsHost: this.envService.config.WEBSOCKET_HOST,
      wssHost: this.envService.config.WEBSOCKET_HOST,
      enabledTransports: ["ws", "wss"],
      wssPort: this.envService.config.WEBSOCKET_PORT,
      wsPort: this.envService.config.WEBSOCKET_PORT,
      forceTLS: false,
      useTLS: false,
      disableStats: true,
    });

    this.echo
      .channel("channel-historical-orders")
      .listen("EventNewFill", (data) => {
        let message = JSON.parse(data.message);
        let fillIds = message.fill_ids;
        this.incomingFill = true;

        this.getRealTimeData(0, fillIds);
      });
  }

  leaveWebsocket() {
    if (this.echo) {
      this.echo.leaveChannel("channel-historical-orders");
    }
  }

  ngOnInit() {
    this.joinWebsocket();
    this.selectedArchiveTypeValue = "show_current";
    this.selectedArchiveTypeName = "Current Records";

    // This should be - case for routes
    this.tableName = "historical-orders";
    this.userId = this.authService.getUserId();
    this.initColumns();
    this.allFilters = [];
    this.count = localStorage.getItem("page-count"); // Just want the data right now
    this.historicalOrders = [];

    this.getData(0, true, true, "");

    this.userService.setUserFilters(this.userId).subscribe();
    this.subscribeToSortChangedObservable();
    this.subscribeToFilterAppliedObservable();
    this.subscribeToBubbleClosedObservable();
  }

  ngOnDestroy() {
    this.leaveWebsocket();
    this.bubbleList = [];
    this.allFilters = [];
    this.bubbles = false;

    if (this.filterAppliedSubscription) {
      this.filterAppliedSubscription.unsubscribe();
    }
    if (this.sortSubscription) {
      this.sortSubscription.unsubscribe();
    }
    if (this.bubbleClosedSubscription) {
      this.bubbleClosedSubscription.unsubscribe();
    }
    if (this.realTimeDataSub) {
      this.realTimeDataSub.unsubscribe();
    }
  }

  exportToExcel() {
    let tenK = "";
    let params = new HttpParams().set("at_10k", "10000").set("format", "csv");

    this.historicalOrdersService
      .getHistoricalOrders(params)
      .subscribe((result) => {
        let res = {
          contentType: result.body.data.contentType,
          base64FileData: result.body.data.base64FileData,
        };

        FileSaver.saveAs(
          new Blob([atob(res.base64FileData)], { type: res.contentType }),
          "history-report.csv"
        );
      });
  }

  subscribeToFilterAppliedObservable() {
    this.filterAppliedSubscription =
      this.filterService.filterAppliedObservable.subscribe(
        (data: FilterResult) => {
          if (!data) {
            this.allFilters = [];
            return;
          }

          this.bubbleList = data.bubbleList;
          this.allFilters = data.allFilters;
          this.bubbles = true;

          this.sortAndFilter();
        }
      );
  }

  subscribeToBubbleClosedObservable() {
    this.bubbleClosedSubscription =
      this.filterService.bubbleClosedObservable.subscribe(
        (data: FilterResult) => {
          if (!data) {
            return;
          }

          this.allFilters = data.allFilters;
          this.bubbleList = data.bubbleList;

          this.sortAndFilter();
        }
      );
  }

  subscribeToSortChangedObservable() {
    this.sortSubscription = this.sortService.sortChangedObservable.subscribe(
      (lastSorted) => {
        if (
          !lastSorted || // If this sort is the same as the one we have then dont do anything
          (this.sortObj.sortBy == lastSorted["sortBy"] &&
            this.sortObj.sortOrder == lastSorted["sortOrder"])
        ) {
          return;
        }

        this.sortObj.sortBy = lastSorted["sortBy"];
        this.sortObj.sortOrder = lastSorted["sortOrder"];

        this.sortAndFilter();
      }
    );
  }

  sortAndFilter() {
    document.body.classList.add("wait");

    setTimeout(() => {
      const fields = {
        ho_archived: this.selectedArchiveTypeValue,
        ho_filters: JSON.stringify(this.allFilters),
        ho_sort_by: this.sortObj.sortBy,
        ho_sort_order: this.sortObj.sortOrder,
      };

      this.utilsService.saveUserFields(fields).subscribe();

      this.originalData = this.sortService.sort(
        this.originalData,
        this.sortObj
      );

      this.bubbleList = this.filterService.createFilterBubbleList(
        this.allFilters
      );

      let archiveTypeCheckedData = this.originalData;

      // Do the archive type filtering here since this is a custom filter
      if (this.selectedArchiveTypeValue == "show_archived") {
        archiveTypeCheckedData = this.originalData.filter((order) => {
          return order.archived_at != null;
        });
      } else if (this.selectedArchiveTypeValue == "show_current") {
        archiveTypeCheckedData = this.originalData.filter((order) => {
          return order.archived_at == null;
        });
      }

      this.historicalOrders = this.filterService.filter(
        archiveTypeCheckedData,
        this.allFilters
      );

      this.createFooterSumsObject();
      this.createFooterAvgsObject();
      this.createFooterHighLowObject();

      document.body.classList.remove("wait");
    });
  }

  ngAfterViewInit() {
    this.user = this.userService.getUser();
  }

  getAt10k(tenK) {
    if (!tenK) {
      return;
    }

    this.atThisPrice = tenK;

    // this.getData(0, true, false, this.showArchived);
    // TODO: (Michael) what is this supposed to be doing? Maybe we can just filter here right?
  }

  /* Helper function for optional lists */
  getOptionalLists(col) {
    if (col == "partial_fill") {
      return [{ value: "Y" }, { value: "N" }];
    }
  }

  initColumns() {
    function createCol(
      name,
      dbName,
      hidden,
      filters,
      sort,
      isFiltered,
      filteredList
    ) {
      return {
        columnName: name,
        dbName: dbName,
        hidden: hidden,
        filters: filters,
        sort: sort,
        isFiltered: isFiltered,
        filteredList: filteredList,
      };
    }

    function filterTypeOne() {
      return {
        contains: "",
        greaterThan: "",
        lessThan: "",
      };
    }

    function filterTypeTwo() {
      return {
        contains: "",
      };
    }

    // Date
    // Acct
    this.acctColumn = createCol(
      "Acct",
      "account_name",
      false,
      filterTypeTwo(),
      this.sortObj.sortBy === "account_id" ? this.sortObj : null,
      false,
      true
    );
    // Ticker
    this.tickerColumn = createCol(
      "Ticker",
      "symbol",
      false,
      [],
      this.sortObj.sortBy === "symbol" ? this.sortObj : null,
      false,
      true
    );

    // optionType
    this.optionTypeColumn = createCol(
      "Option Type",
      "security_type",
      false,
      [],
      this.sortObj.sortBy === "security_type" ? this.sortObj : null,
      false,
      true
    );

    this.daysColumn = createCol(
      "Days",
      "expiration_days_away",
      false,
      filterTypeOne(),
      this.sortObj.sortBy === "expiration_days_away" ? this.sortObj : null,
      false,
      false
    );
    // Expiration
    this.quantityColumn = createCol(
      "Qty",
      "quantity",
      false,
      filterTypeOne(),
      this.sortObj.sortBy === "quantity" ? this.sortObj : null,
      false,
      false
    );

    this.strikeOneColumn = createCol(
      "Strike 1",
      "strike_1",
      false,
      filterTypeOne(),
      this.sortObj.sortBy === "strike_1" ? this.sortObj : null,
      false,
      false
    );

    this.strikeTwoColumn = createCol(
      "Strike 2",
      "strike_2",
      false,
      filterTypeOne(),
      this.sortObj.sortBy === "strike_2" ? this.sortObj : null,
      false,
      false
    );

    this.costColumn = createCol(
      "Cost",
      "cost",
      false,
      filterTypeOne(),
      this.sortObj.sortBy === "cost" ? this.sortObj : null,
      false,
      false
    );

    this.exposureColumn = createCol(
      "Exposure",
      "exposure",
      false,
      filterTypeOne(),
      this.sortObj.sortBy === "exposure" ? this.sortObj : null,
      false,
      false
    );

    this.moveNeededColumn = createCol(
      "Move Needed",
      "move_needed",
      false,
      filterTypeOne(),
      this.sortObj.sortBy === "move_needed" ? this.sortObj : null,
      false,
      false
    );

    this.maxGainColumn = createCol(
      "Max Gain",
      "max_gain",
      false,
      filterTypeOne(),
      this.sortObj.sortBy === "max_gain" ? this.sortObj : null,
      false,
      false
    );

    // Table#
    this.tableNumColumn = createCol(
      "Table #",
      "table_number",
      false,
      [],
      this.sortObj.sortBy === "table_number" ? this.sortObj : null,
      false,
      true
    );

    this.qualityColumn = createCol(
      "Quality",
      "target_return_quality",
      false,
      filterTypeOne(),
      this.sortObj.sortBy === "quality" ? this.sortObj : null,
      false,
      false
    );

    // Type
    this.typeColumn = createCol(
      "Type",
      "type",
      false,
      [],
      this.sortObj.sortBy === "type" ? this.sortObj : null,
      false,
      true
    );

    // Category
    this.categoryColumn = createCol(
      "Category",
      "category",
      false,
      [],
      this.sortObj.sortBy === "category" ? this.sortObj : null,
      false,
      true
    );

    this.at10kColumn = createCol(
      "",
      "at_10k",
      false,
      filterTypeOne(),
      this.sortObj.sortBy === "at_10k" ? this.sortObj : null,
      false,
      false
    );

    this.targetReturnFillColumn = createCol(
      "TR (fill)",
      "target_return_fill",
      false,
      filterTypeOne(),
      this.sortObj.sortBy === "target_return_fill" ? this.sortObj : null,
      false,
      false
    );

    this.targetReturnCurrentColumn = createCol(
      "TR (current)",
      "target_return_current",
      false,
      filterTypeOne(),
      this.sortObj.sortBy === "target_return_current" ? this.sortObj : null,
      false,
      false
    );

    this.targetPercentColumn = createCol(
      "% Target",
      "target_percent",
      false,
      filterTypeOne(),
      this.sortObj.sortBy === "target_percent" ? this.sortObj : null,
      false,
      false
    );

    this.at100PercentColumn = createCol(
      "@ 100%",
      "at_100_percent",
      false,
      filterTypeOne(),
      this.sortObj.sortBy === "at_100_percent" ? this.sortObj : null,
      false,
      false
    );

    this.at95PercentColumn = createCol(
      "@ 95%",
      "at_95_percent",
      false,
      filterTypeOne(),
      this.sortObj.sortBy === "at_95_percent" ? this.sortObj : null,
      false,
      false
    );

    this.at90PercentColumn = createCol(
      "@ 90%",
      "at_90_percent",
      false,
      filterTypeOne(),
      this.sortObj.sortBy === "at_90_percent" ? this.sortObj : null,
      false,
      false
    );

    this.priceAtUploadColumn = createCol(
      "Price @ Upload",
      "price_at_upload",
      false,
      filterTypeOne(),
      this.sortObj.sortBy === "price_at_upload" ? this.sortObj : null,
      false,
      false
    );

    this.priceAtFillColumn = createCol(
      "Price @ Fill",
      "price_at_fill",
      false,
      filterTypeOne(),
      this.sortObj.sortBy === "price_at_fill" ? this.sortObj : null,
      false,
      false
    );

    // Partial Fill
    this.partialFillColumn = createCol(
      "Partial Fill",
      "is_partial",
      false,
      [],
      this.sortObj.sortBy === "is_partial" ? this.sortObj : null,
      false,
      true
    );

    this.moveSinceColumn = createCol(
      "Move Since",
      "move_since",
      false,
      filterTypeOne(),
      this.sortObj.sortBy === "move_since" ? this.sortObj : null,
      false,
      false
    );

    this.dateColumn = createCol(
      "Date",
      "date",
      false,
      {},
      this.sortObj.sortBy === "date" ? this.sortObj : null,
      false,
      false
    );

    this.expirationColumn = createCol(
      "Expiration Date",
      "expiration_date",
      false,
      {},
      this.sortObj.sortBy === "expiration_date" ? this.sortObj : null,
      false,
      true
    );

    this.percentInterestColumn = createCol(
      "Percent Interest",
      "interest_percent",
      false,
      {},
      this.sortObj.sortBy === "interest_percent" ? this.sortObj : null,
      false,
      true
    );

    this.allColumns.push(this.daysColumn);
    this.allColumns.push(this.expirationColumn);
    this.allColumns.push(this.dateColumn);
    this.allColumns.push(this.quantityColumn);
    this.allColumns.push(this.strikeOneColumn);
    this.allColumns.push(this.strikeTwoColumn);
    this.allColumns.push(this.costColumn);
    this.allColumns.push(this.exposureColumn);
    this.allColumns.push(this.moveNeededColumn);
    this.allColumns.push(this.maxGainColumn);
    this.allColumns.push(this.qualityColumn);
    this.allColumns.push(this.at10kColumn);
    this.allColumns.push(this.targetReturnFillColumn);
    this.allColumns.push(this.targetReturnCurrentColumn);
    this.allColumns.push(this.targetPercentColumn);
    this.allColumns.push(this.at100PercentColumn);
    this.allColumns.push(this.at95PercentColumn);
    this.allColumns.push(this.at90PercentColumn);
    this.allColumns.push(this.priceAtUploadColumn);
    this.allColumns.push(this.priceAtFillColumn);
    this.allColumns.push(this.moveSinceColumn);
    this.allColumns.push(this.acctColumn);
    this.allColumns.push(this.tickerColumn);
    this.allColumns.push(this.optionTypeColumn);
    this.allColumns.push(this.tableNumColumn);
    this.allColumns.push(this.typeColumn);
    this.allColumns.push(this.categoryColumn);
    this.allColumns.push(this.partialFillColumn);
    this.allColumns.push(this.percentInterestColumn);

    this.allColumns.sort(function (a, b) {
      if (a.columnName < b.columnName) {
        return -1;
      }
      if (b.columnName < a.columnName) {
        return 1;
      }
      return 0;
    });
  }

  searchEvent($event) {
    this.historicalOrders = this.historicalOrdersForSearch;

    let i = this.historicalOrders.length;
    while (i--) {}
  }

  fullScreen() {
    this.fullScreenService.isFullScreen = !this.fullScreenService.isFullScreen;
    if (this.fullScreenService.isFullScreen) {
      this.filtersCollapsed = false;
    }
  }

  /* We need to get the subsets for the columns with filtered lists.
    Returns the Filtered List for the Table Filter Headers.
    Ticker (symbol)
    Type (type)
    Table # (table_number)
    Partial FIll (target_return_fill)
  */

  getFilteredListSubsets(value) {
    const filterList = [];
    const hash = {};

    for (let i = 0; i < this.historicalOrdersAll.length; i++) {
      const hashIndex = this.historicalOrdersAll[i][value];

      if (!hash[hashIndex]) {
        filterList.push(this.historicalOrdersAll[i][value]);
        hash[hashIndex] = true;
      }
    }
    return filterList;
  }

  setChecksIfPresent(val) {
    for (var i = 0; i < this.allFilters.fields.length; i++) {
      if (this.allFilters.fields[i].value === val) {
        return this.allFilters.fields[i].check;
      }
    }
    return false;
  }

  sortAlpha(list, value) {
    list = list.sort(function (a, b) {
      if (a.value < b.value) {
        return -1;
      }
      if (a.value > b.value) {
        return 1;
      }
      return 0;
    });
    return list;
  }

  selectSelect(event) {
    this.historicalOrders.forEach((historicalOrder) => {
      if (historicalOrder.fill_id_1 == event.target.value) {
        historicalOrder.checkStatus = !historicalOrder.checkStatus;
        if (historicalOrder.checkStatus == true) {
          this.archiveList.push(historicalOrder.fill_id_1);
          this.archiveList.push(historicalOrder.fill_id_2);
        } else {
          const idx1 = this.archiveList.indexOf(historicalOrder.fill_id_1);
          if (idx1 != -1) {
            this.archiveList.splice(idx1, 1);
            historicalOrder.checkStatus = false;
          }
          const idx2 = this.archiveList.indexOf(historicalOrder.fill_id_2);
          if (idx2 != -1) {
            this.archiveList.splice(idx2, 1);
            historicalOrder.checkStatus = false;
          }
        }
      }
    });
  }

  selectAllLoadedHistoricalOrders() {
    this.archiveList = [];
    this.historicalOrders.forEach((historicalOrder) => {
      historicalOrder.checkStatus = true;
      this.archiveList.push(historicalOrder.fill_id_1);
      this.archiveList.push(historicalOrder.fill_id_2);
    });
  }

  deselectAllLoadedHistoricalOrders() {
    this.historicalOrders.forEach((historicalOrder) => {
      historicalOrder.checkStatus = false;
      this.archiveList = [];
    });
  }

  archiveSelectedHistoricalOrders() {
    let totalRow = this.archiveList.length / 2;
    Swal.fire({
      title: "Are you sure?",
      text: "This will archive the " + totalRow + " selected records.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ok",
    }).then((result) => {
      if (result.value) {
        const historicalOrderIds = [];
        this.historicalOrders.forEach((historicalOrder) => {
          if (historicalOrder.checkStatus == true) {
            const logUser = JSON.parse(localStorage.getItem("currentUser"));
            if (logUser.id != historicalOrder.id) {
              historicalOrderIds.push(
                this.historicalOrdersService.archiveHistoricalOrders(
                  Number(historicalOrder.fill_id_1)
                )
              );
              historicalOrderIds.push(
                this.historicalOrdersService.archiveHistoricalOrders(
                  Number(historicalOrder.fill_id_2)
                )
              );
            } else {
              totalRow -= 1;
            }
          }
        });
        this.subGetEssentialData = forkJoin(historicalOrderIds).subscribe(
          (response) => {
            if (response[0] == "200") {
              this.archiveList = [];
              this.toastr.success(
                totalRow + " rows",
                "Records Archived Successfully"
              );
              // TODO: (Michael) See what I need to do instead of calling getData again.
              // this.getData(0, true, false, this.showArchived);
            }
          },
          (err) => {
            this.toastr.error(totalRow + " rows", "Archive Unsuccessful");
          }
        );
      }
    });
  }

  setSelectedArchiveType(archiveTypeValue) {
    this.selectedArchiveTypeValue = archiveTypeValue;

    if (archiveTypeValue == "show_current") {
      this.selectedArchiveTypeName = "Current Records";
    } else if (archiveTypeValue == "show_archived") {
      this.selectedArchiveTypeName = "Archived Records";
    } else {
      this.selectedArchiveTypeName = "All Records";
      this.selectedArchiveTypeValue = "show_all";
    }

    this.sortAndFilter();
  }

  unarchiveHistoricalOrder(historicalOrder) {
    Swal.fire({
      title: "Are you sure?",
      text: "This will unarchive this record.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ok",
    }).then((result) => {
      if (result.value) {
        const historicalOrderIds = [];
        historicalOrderIds.push(
          this.historicalOrdersService.unarchiveHistoricalOrders(
            Number(historicalOrder.fill_id_1)
          )
        );
        historicalOrderIds.push(
          this.historicalOrdersService.unarchiveHistoricalOrders(
            Number(historicalOrder.fill_id_2)
          )
        );
        this.subGetEssentialData = forkJoin(historicalOrderIds).subscribe(
          (response) => {
            if (response[0] == "200") {
              this.archiveList = [];
              this.toastr.success("Record Unarchived Successfully");
              // TODO: (Michael) See what I need to do instead of calling getData again.
              // this.getData(0, true, false, this.showArchived);
            }
          },
          (err) => {
            this.toastr.error("Unarchive Unsuccessful");
          }
        );
      }
    });
  }

  // Creates footerSums object for sum-footer component.
  createFooterSumsObject() {
    let daysSum = 0;
    let qtySum = 0;
    let strike1Sum = 0;
    let strike2Sum = 0;
    let costSum = 0;
    let exposureSum = 0;
    let priceUploadSum = 0;
    let priceFillSum = 0;
    let moveNeeded = 0;

    for (let i = 0; i < this.historicalOrders.length; i++) {
      if (typeof this.historicalOrders[i].expiration_days_away === "number") {
        daysSum += this.historicalOrders[i].expiration_days_away;
      }
      if (typeof this.historicalOrders[i].expiration_days_away === "number") {
        qtySum += this.historicalOrders[i].quantity;
      }
      if (typeof this.historicalOrders[i].expiration_days_away === "number") {
        strike1Sum += this.historicalOrders[i].strike_1;
      }
      if (typeof this.historicalOrders[i].expiration_days_away === "number") {
        strike2Sum += this.historicalOrders[i].strike_2;
      }
      if (typeof this.historicalOrders[i].expiration_days_away === "number") {
        costSum += this.historicalOrders[i].cost;
      }
      if (typeof this.historicalOrders[i].expiration_days_away === "number") {
        exposureSum += this.historicalOrders[i].exposure;
      }
      if (typeof this.historicalOrders[i].expiration_days_away === "number") {
        priceUploadSum += this.historicalOrders[i].price_at_upload;
      }
      if (typeof this.historicalOrders[i].expiration_days_away === "number") {
        priceFillSum += this.historicalOrders[i].price_at_fill;
      }
      if (typeof this.historicalOrders[i].expiration_days_away === "number") {
        moveNeeded += this.historicalOrders[i].move_needed;
      }
    }

    this.footerSums = {
      expiration_days_away: daysSum,
      quantity: qtySum,
      strike_1: strike1Sum,
      strike_2: strike2Sum,
      cost: costSum,
      exposure: exposureSum,
      price_at_upload: priceUploadSum,
      price_at_fill: priceFillSum,
      move_needed: moveNeeded,
    };
  }

  createFooterAvgsObject() {
    let daysSum = 0;
    let qtySum = 0;
    let strike1Sum = 0;
    let strike2Sum = 0;
    let costSum = 0;
    let exposureSum = 0;
    let priceUploadSum = 0;
    let priceFillSum = 0;
    let moveNeededSum = 0;
    let percentTargetSum = 0;

    let daysAvg = 0;
    let qtyAvg = 0;
    let strike1Avg = 0;
    let strike2Avg = 0;
    let costAvg = 0;
    let exposureAvg = 0;
    let priceUploadAvg = 0;
    let priceFillAvg = 0;
    let moveNeededAvg = 0;
    let percentTargetAvg = 0;

    for (let i = 0; i < this.historicalOrders.length; i++) {
      daysSum += this.historicalOrders[i].expiration_days_away;
      qtySum += this.historicalOrders[i].quantity;
      strike1Sum += this.historicalOrders[i].strike_1;
      strike2Sum += this.historicalOrders[i].strike_2;
      costSum += this.historicalOrders[i].cost;
      exposureSum += this.historicalOrders[i].exposure;
      priceUploadSum += this.historicalOrders[i].price_at_upload;
      priceFillSum += this.historicalOrders[i].price_at_fill;

      if (typeof this.historicalOrders[i].move_needed === "number") {
        moveNeededSum += this.historicalOrders[i].move_needed;
      }

      if (typeof this.historicalOrders[i].target_percent === "number") {
        percentTargetSum += this.historicalOrders[i].target_percent;
      }

      daysAvg = daysSum / this.historicalOrders.length;
      qtyAvg = qtySum / this.historicalOrders.length;
      strike1Avg = strike1Sum / this.historicalOrders.length;
      strike2Avg = strike2Sum / this.historicalOrders.length;
      costAvg = costSum / this.historicalOrders.length;
      exposureAvg = exposureSum / this.historicalOrders.length;
      priceUploadAvg = priceUploadSum / this.historicalOrders.length;
      priceFillAvg = priceFillSum / this.historicalOrders.length;
      moveNeededAvg = moveNeededSum / this.historicalOrders.length;
      percentTargetAvg = percentTargetSum / this.historicalOrders.length;
    }

    this.footerAvgs = {
      expiration_days_away: daysAvg,
      quantity: qtyAvg,
      strike_1: strike1Avg,
      strike_2: strike2Avg,
      cost: costAvg,
      exposure: exposureAvg,
      price_at_upload: priceUploadAvg,
      price_at_fill: priceFillAvg,
      move_needed: moveNeededAvg,
      target_percent: percentTargetAvg,
    };
  }

  createFooterHighLowObject() {
    if (!this.historicalOrders.length) {
      return;
    }

    let moveNeededMax = this.historicalOrders[0].move_needed;
    let moveNeededMin = this.historicalOrders[0].move_needed;

    for (let i = 0; i < this.historicalOrders.length; i++) {
      if (typeof this.historicalOrders[i].move_needed === "number") {
        if (moveNeededMax < this.historicalOrders[i].move_needed) {
          moveNeededMax = this.historicalOrders[i].move_needed;
        }
        if (moveNeededMin > this.historicalOrders[i].move_needed) {
          moveNeededMin = this.historicalOrders[i].move_needed;
        }
      }

      this.footerHighLow = {
        moveNeededHigh: moveNeededMax,
        moveNeededLow: moveNeededMin,
      };
    }
  }

  checkLogUserId() {
    this.logUserData = JSON.parse(this.currentUserDetails);

    const getloggedUser = this.userService.getUserDetails(this.logUserData.id);
    this.subGetLoggingData = forkJoin([getloggedUser]).subscribe((logUser) => {
      this.userLog = logUser[0];

      this.userLog.user_roles.forEach((element) => {
        if (element.id == UserRole.admin) {
          this.loginUserRoleIds.push(UserRole.admin);
        } else if (element.id == UserRole.employee) {
          this.loginUserRoleIds.push(UserRole.employee);
        }
      });
      const idxOfAdmin = this.loginUserRoleIds.indexOf(UserRole.admin);
      const idxOfEmployee = this.loginUserRoleIds.indexOf(UserRole.employee);

      if (idxOfAdmin != -1) {
        this.checkDisableBtn = false;
      } else if (idxOfEmployee != -1) {
        this.checkDisableBtn = true;
      } else if (idxOfEmployee != -1 && idxOfAdmin != -1) {
        this.checkDisableBtn = false;
      }

      this.userService.setUser(logUser);
    });
  }

  closeFooter() {
    // Commenting this out until we figure out how to auto adjust the viewport
    // this.footerHidden = !this.footerHidden;
  }

  showAllColumns() {
    for (const col of this.allColumns) {
      col.hidden = false;
    }
  }

  showSpecificColumns(dbName) {
    for (const col of this.allColumns) {
      if (col.dbName == dbName) {
        if (col.hidden == true) {
          col.hidden = false;
        } else {
          col.hidden = true;
        }
      }
    }
  }

  removeFiltersIfPresent(columnName) {
    let i = this.allFilters.fields.length;
    while (i--) {
      if (this.allFilters.fields[i].field == columnName) {
        this.allFilters.fields.splice(i, 1);
      }
    }
  }

  removeAllFilters() {
    this.allFilters = [];
    this.bubbleList = [];
    this.filterService.filters = [];
    this.filterService.bubbleList = [];
    this.historicalOrders = this.originalData;
    this.sortService.clearLastSorted();
    this.sortObj.sortOrder = "desc";
    this.sortObj.sortBy = "date";
    this.sortService.sortColumnByDirection(
      this.sortObj.sortBy,
      this.sortObj.sortOrder
    );
    this.sortAndFilter();
    this.filterService.bubbleClosed([]);
  }

  /* Clear all filters for a Column.  Useful before applying a new set of filters to a column */
  clearFiltersForColumn(dbName) {
    this.allFilters.fields = this.allFilters.fields.filter(function (filter) {
      if (dbName != filter.field) {
        return filter;
      }
    });
  }

  /*
    Filtered Lists have a separate attribute on the allFilters object, so we can iterate through it on the front-end
    If passed a dbName (for example, 'symbol') this will remove the symbol attribute, and thus clear all the bubbles
  */
  /* Needed when the page is loaded and filters already exist */
  addAllFilterListBubbles(allFilters) {
    var filters = allFilters.fields;

    /* This will create the attributes on allFilters for the Filter List bubbles */

    let that = this;
    filters.map(function (filter) {
      if (filter.check) {
        if (!that.allFilters[filter.field]) {
          that.allFilters[filter.field] = [];
        }

        // This will create the 'symbol',
        that.allFilters[filter.field].push(filter.value);
      }
    });
  }

  /* Add all the values of the filters to a separate list for filtering ease. */
  addFilterListsBubblesForColumn(filtersToAdd, dbName) {
    /* Clear all the bubbles before adding more */
    this.allFilters[dbName] = [];

    /* Go through the Array of Objects, return all the Values (SDK, TBD, BDF, SDS, etc...) */
    let newFilterBubbles = filtersToAdd
      .filter((f) => f.check)
      .map((f) => f.value);

    this.allFilters[dbName].push(...newFilterBubbles);
  }

  toggleFiltersCollapse() {
    if (this.filtersCollapsed == true) {
      this.filtersCollapsed = false;
    } else if (this.filtersCollapsed == false) {
      this.filtersCollapsed = true;
    }
  }

  at10k(num) {
    this.atThisPrice = num;
  }

  openYahooData(content, symbol) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title", size: "lg" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
    var url =
      this.envService.config.API_BASE_URL +
      "/url-framer.php?url=https://finance.yahoo.com/quote/" +
      symbol +
      "/";

    document.getElementById("modal-basic-title").innerHTML =
      "Live Data: " + symbol;
    document.getElementById("ticker-live-data").setAttribute("src", url);
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  logout() {
    const isSimulating: boolean =
      localStorage.getItem("isSimulating") === "true";

    if (isSimulating) {
      this.authService.logoutFromSimulation();
      window.location.reload();
    } else {
      this.authService.logout();
      this.router.navigate(["/login"]);
    }
    this.fullScreenService.isFullScreen = false;
  }

  getRealTimeData(tenK, fillIds) {
    if (!tenK) {
      tenK = 10000;
    }

    let params = new HttpParams()
      .set("at_10k", tenK)
      .set("clear", "0")
      .set("search", "")
      .set("all", "null")
      .set("fill-ids", fillIds);

    this.realTimeDataSub = this.historicalOrdersService
      .getHistoricalOrders(params)
      .subscribe((result) => {
        this.incomingFill = false;

        let newRow = result.body.data.original.data[0];

        if (result.body.data.original.data.length) {
          newRow = result.body.data.original.data[0];
        } else {
          return;
        }

        newRow["newRow"] = true;

        this.historicalOrders.unshift(newRow);

        this.createFooterSumsObject();
        this.createFooterAvgsObject();
        this.createFooterHighLowObject();

        this.sortObj = {
          sortBy: result.body.data.original.sorts.sortBy,
          sortOrder: result.body.data.original.sorts.sortOrder,
        };

        let filtersFromDB = result.body.data.original.filters;
        this.allFilters = filtersFromDB;
        this.filterService.setupFiltersForTable(
          this.originalData,
          filtersFromDB
        );
      });
  }

  getData(tenK, showLoading = true, init, showArchived) {
    if (showLoading) {
      this.loading = true;
    }

    if (!tenK) {
      tenK = 10000;
    }

    let params = new HttpParams().set("at_10k", tenK).set("init", init);

    this.historicalOrdersService
      .getHistoricalOrders(params)
      .subscribe((result) => {
        this.lastReturnCount = result.body.data.original.data.length;

        result.body.data.original.data.forEach((historicalOrder) => {
          historicalOrder.checkStatus = false;
        });

        this.incomingFill = false;
        this.originalData = result.body.data.original.data;
        this.allFilters = JSON.parse(result.body.data.original.filters);

        this.sortObj = {
          sortBy: result.body.data.original.sort_by,
          sortOrder: result.body.data.original.sort_order,
        };

        // This will also call sortAndFilter() so dont call it twice here
        this.setSelectedArchiveType(result.body.data.original.archived);

        this.filterService.setupFiltersForTable(
          this.historicalOrders,
          this.allFilters
        );

        this.loading = false;
      });
  }

  // This is a function that returns 0 to the sort of the bubble list. For some reason ngFor defaults to sort
  // alphabetically. *ngFor="let filter of bubbleList | keyvalue: returnZero". Adding returnZero will leave the
  // array in the correct order.
  returnZero(): number {
    return 0;
  }
}
