import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { RuntimeEnvLoaderService } from "./runtime-env-loader.service";

@Injectable({
  providedIn: "root",
})
export class ScheduledJobsService {
  basePath: string;

  constructor(
    private http: HttpClient,
    private envLoader: RuntimeEnvLoaderService
  ) {
    this.basePath = envLoader.config.API_BASE_URL + envLoader.config.APP_KEY;
  }

  editScheduledJob(data, id): Observable<any> {
    const headers = new HttpHeaders().set(
      "security-token",
      JSON.parse(localStorage.getItem("currentUser")).token
    );
    return this.http
      .patch<any>(this.basePath + "/scheduled-jobs/" + id, data, { headers })
      .pipe(
        map((response) => {
          return response.data;
        }),
        catchError(this.handleError)
      );
  }

  deleteScheduledJob(id): Observable<any> {
    const headers = new HttpHeaders().set(
      "security-token",
      JSON.parse(localStorage.getItem("currentUser")).token
    );

    return this.http
      .delete<any>(this.basePath + "/scheduled-jobs/" + id, { headers })
      .pipe(
        map((response) => {
          return 200;
        }),
        catchError(this.handleError)
      );
  }

  getScheduledJobs(): Observable<any> {
    const headers = new HttpHeaders().set(
      "security-token",
      JSON.parse(localStorage.getItem("currentUser")).token
    );
    return this.http
      .get<any>(this.basePath + "/scheduled-jobs", {
        headers,
        observe: "response",
      })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError(this.handleError)
      );
  }

  getNotificationScheduledJobs(): Observable<any> {
    const headers = new HttpHeaders().set(
      "security-token",
      JSON.parse(localStorage.getItem("currentUser")).token
    );
    return this.http
      .get<any>(this.basePath + "/notifications/get-all-notification-jobs", {
        headers,
        observe: "response",
      })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError(this.handleError)
      );
  }

  createScheduledJob(data): Observable<any> {
    const headers = new HttpHeaders().set(
      "security-token",
      JSON.parse(localStorage.getItem("currentUser")).token
    );
    return this.http
      .post<any>(this.basePath + "/scheduled-jobs", data, { headers })
      .pipe(
        map((response) => {
          return response.data;
        }),
        catchError(this.handleError)
      );
  }

  handleError(error) {
    return throwError(error);
  }
}
