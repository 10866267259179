import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { RuntimeEnvLoaderService } from './runtime-env-loader.service';

@Injectable({
  providedIn: 'root'
})
export class StrategiesService {
  
  adminUserGroupId: any;
  basePath: string;
  errorHandler: any;

  constructor(
    private http: HttpClient,
    private envLoader: RuntimeEnvLoaderService
  ) {
    this.basePath = envLoader.config.API_BASE_URL + envLoader.config.APP_KEY;
  }

  getStrategies(params = null): Observable<any> {
    const headers = new HttpHeaders()
      .set('security-token', JSON.parse(localStorage.getItem('currentUser')).token);
    return this.http.get<any>(this.basePath + '/strategies', { headers, observe: 'response', params })
      .pipe(
        map((response => {
          return response;
        })),
        catchError(this.handleError),
      );
  }

  createStrategy(data): Observable<any> {
    console.log("createStrategy");
    const headers = new HttpHeaders().set('security-token', JSON.parse(localStorage.getItem('currentUser')).token);
    return this.http
      .post<any>(this.basePath + '/strategies', data, { headers })
      .pipe(
        map(response => {

          return response.data;

        }),
        catchError(this.handleError)
      );
  }

  getHistoricalOrders(params = null): Observable<any> {
    const headers = new HttpHeaders()
      .set('security-token', JSON.parse(localStorage.getItem('currentUser')).token);
    return this.http.get<any>(this.basePath + '/historical-orders', { headers, observe: 'response', params })
      .pipe(
        map((response => {
          return response;
        })),
        catchError(this.handleError),
      );
  }

  getStrategyDetails(id): Observable<any> {
    var params = null;
    const headers = new HttpHeaders().set('security-token', JSON.parse(localStorage.getItem('currentUser')).token);
      return this.http.get<any>(this.basePath + '/strategies/' + id, { headers, observe: 'response', params })
      .pipe(
        map((response => {
          return response;
        })),
        catchError(this.handleError),
      );
  }

  updateStrategyDetails(id, data): Observable<any> {
    const headers = new HttpHeaders()
      .set('security-token', JSON.parse(localStorage.getItem('currentUser')).token);

    return this.http.patch<any>(this.basePath + '/strategies/' + id, data, { headers })
      .pipe(
        map((response => {
          return response.data;
        })),
        catchError(this.handleError),
      );
  }

  deleteStrategy(id): Observable<any> {
    const headers = new HttpHeaders()
      .set('security-token', JSON.parse(localStorage.getItem('currentUser')).token);

    return this.http.delete<any>(this.basePath + '/strategies/' + id, { headers })
      .pipe(
        map((response => {
          return 200;
        })),
        catchError(this.handleError),
      );
  }

  handleError(error) {
    

    let errorMessage = {};
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = {
        error: `${error.error.message}`
      };
    } else {
      // server-side error
      errorMessage = {
        code: `${error.status}`,
        message: `${error.statusText}`,
        errors: error.error.errors
      };
    }
    // window.alert(errorMessage);
    return throwError(errorMessage);
  }
}
