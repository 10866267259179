import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { RuntimeEnvLoaderService } from './runtime-env-loader.service';
import { User } from '../data-structures/user';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  public userId: any;
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  public isSimulating: BehaviorSubject<boolean>;
  private options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };

  constructor(private envService: RuntimeEnvLoaderService, private httpClient: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
    if (localStorage.getItem('isSimulating')) {
      this.isSimulating = new BehaviorSubject<boolean>(true);
    } else {
      this.isSimulating = new BehaviorSubject<boolean>(false);
    }
   }

   public getUserId() {
     return this.userId;
   }

   private setUserId(user) {
     this.userId = user.id;
   }

   public get currentUserValue(): User {
     return this.currentUserSubject.value;
   }

   public login(email: string, password: string): Observable<any> {
     return this.httpClient.post<any>(`${this.envService.config.API_BASE_URL}${this.envService.config.APP_KEY}/login`, {email, password}, this.options).pipe(
        map(data => {
          const user: User = {...data.data.user};
          user.token = data.data.security_token.token;
          user.userRoles = {...data.data.user_roles};
          user.authorizations = {...data.data.authorizations};
          localStorage.setItem('currentUser', JSON.stringify(user));
          this.currentUserSubject.next(user);

          this.setUserId(user);
          return user;
        })
     );
   }

   logout() {
     localStorage.removeItem('currentUser');
     this.currentUserSubject.next(null);
   }

   resetPassword(email: string): Observable<any> {
    return this.httpClient.post<any>(`${this.envService.config.API_BASE_URL}${this.envService.config.APP_KEY}/reset-password`, {email}, this.options);
   }

   setNewPassword(data: any): Observable<any> {
    return this.httpClient.post<any>(`${this.envService.config.API_BASE_URL}${this.envService.config.APP_KEY}/set-new-password`, data, this.options);
   }

   public simulateUser(userId: number): Observable<any> {
    return this.httpClient.post<any>(`${this.envService.config.API_BASE_URL}${this.envService.config.APP_KEY}/simulate-user/${userId}`, {}, this.options).pipe(
      map(data => {
        const user: User = {...data.data.user};
        user.token = data.data.security_token.token;
        user.userRoles = {...data.data.user_roles};
        user.authorizations = {...data.data.authorizations};
        localStorage.setItem('currentUser', JSON.stringify(user));
        localStorage.setItem('_user', JSON.stringify(this.currentUserSubject.value));
        localStorage.setItem('isSimulating', 'true');
        this.isSimulating.next(true);
        this.currentUserSubject.next(user);
        return user;
      })
   );
   }

   public logoutFromSimulation() {
      const originalUser: User = JSON.parse(localStorage.getItem('_user'));
      localStorage.removeItem('isSimulating');
      localStorage.removeItem('_user');
      localStorage.setItem('currentUser', JSON.stringify(originalUser));
      this.currentUserSubject.next(originalUser);
      this.isSimulating.next(false);
   }

   clear() {
     localStorage.removeItem('currentUser');
     localStorage.removeItem('password');
     localStorage.removeItem('email');
   }
}
