<div class="wrap">

    <section class="content-header">
        <h1 *ngIf="users">
            Users ({{usersCount}})
            <button [disabled]="checkDisableBtn" routerLink="user-create" routerLinkActive="active" class="btn btn-success btn-sm">Create New</button>
            &nbsp;
            &nbsp;
            <button class="btn btn-primary btn-sm" (click)="selectAllUsers()">Select All</button> &nbsp;
            <button class="btn btn-primary btn-sm" (click)="DeselectAllUsers()">Deselect All</button> &nbsp;
            &nbsp;
            <button class="btn btn-danger btn-sm" [disabled]="deleteList.length==0" (click)="deleteSelectedUsers()">
                Delete </button>
        <a class="pull-right ">
            <app-search-box (searchValue)="searchBox($event)"></app-search-box>
        </a>
    </h1>
    <p style="margin-top: 5px;
    margin-bottom: 0px;">Click an a user's first or last name to view the details for that user.</p>
</section>

<section class="content">
    <div class="row">
        <div class="col-md-12">
            <div class="box box-solid">
                <div class="box-body">
                    
                    <app-pagination [totalCount]="totalCount" (pageCount)="pageLength($event)"
                    (navigate)="next($event)"></app-pagination>
                    
                    <br /><br />
                    <div class="table-responsive">
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th class="text-center">Select </th>
                                    <th>First name
                                        <span class="pull-right" style="cursor: pointer;"
                                        (click)="sort($event,'first_name',sortOrder)">
                                        <i class="fa " id="sort-i-first_name" [ngClass]="sortIcon"></i>
                                    </span>
                                </th>
                                <th>Last name
                                    <span class="pull-right" style="cursor: pointer;"
                                    (click)="sort($event,'last_name',sortOrder)">
                                    <i class="fa " id="sort-i-last_name" [ngClass]="sortIcon"></i>
                                </span>
                            </th>
                            <th *ngIf="isAdmin && !authService.isSimulating.value" class="text-center">Ghost User
                            </th>
                            <th>Email
                                <span class="pull-right" style="cursor: pointer;"
                                            (click)="sort($event,'email',sortOrder)">
                                            <i class="fa " id="sort-i-email" [ngClass]="sortIcon"></i>
                                        </span>
                                    </th>
                                    <th>Role
                                        <span class="pull-right" style="cursor: pointer;"
                                            (click)="sort($event,'user_role_labels',sortOrder)">
                                            <i class="fa " id="sort-i-user_role_labels" [ngClass]="sortIcon"></i>
                                        </span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody *ngIf="users">
                                <ng-container *ngIf="users.length>0">
                                    <tr *ngFor="let user of users">
                                        <td style="text-align: center;">
                                            <input type="checkbox" [checked]="user.checkStatus" id="delete"
                                            value="{{user.id}}" (change)="selectSelect($event)" />
                                        </td>
                                        <td><a [routerLink]="[user.id]">{{user.first_name}}</a></td>
                                        <td><a [routerLink]="[user.id]">{{user.last_name}}</a></td>
                                        <td *ngIf="isAdmin && !authService.isSimulating.value" class="text-center"><a class="link" (click)="simulateUser(user.id)">Log in as
                                            User</a></td>
                                        <td>{{user.email}}</td>
                                        <td>{{user.user_roles.length !==0 ? user.user_roles[0].label:null }}</td>
                                    </tr>
                                </ng-container>
                                <ng-container *ngIf="users.length==0">
                                    <tr>
                                        <td colspan="6" class="text-center">
                                            No Results.
                                        </td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
</div>