import { Component, EventEmitter, OnInit, Output } from "@angular/core";

import { AuthenticationService } from "src/app/services/authentication.service";
import { environment } from "src/environments/environment.prod";
import { UserService } from "src/app/services/app/user/user.service";

import { User } from "../../../data-structures/user";

@Component({
  selector: "app-user-notification-table",
  templateUrl: "./user-notification-table.component.html",
  styleUrls: ["./user-notification-table.component.scss"],
})
export class UserNotificationTableComponent implements OnInit {
  users: any;
  usersForChecks: any;
  user: any;
  userId: any;
  loading: any;

  @Output() clickCheckEvent: EventEmitter<any> = new EventEmitter();

  constructor(
    private authService: AuthenticationService,
    private userService: UserService
  ) {
    this.authService.currentUser.subscribe((user: User) => {
      this.userId = user.id;
    });
  }

  ngOnInit() {
    let page = 1;
    let sortBy = environment.DEFAULT_SORT.USERS.SORT_BY;
    let sortOrder = environment.DEFAULT_SORT.USERS.SORT_ORDER;
    let count = environment.DEFAULT_SORT.USERS.PAGE_COUNT[0];

    const params = {
      count: count,
      page: 1,
      sort_by: sortBy,
      sort_order: sortOrder,
      search: '{"keyword":"' + '"}',
    };

    this.loading = true;
    this.userService.getUserList(params).subscribe((data) => {
      this.users = data.body.data;
      this.usersForChecks = data.body.data;
      this.createCheckboxObjs();
      this.selectDefaultUser();
      this.loading = false;
    });
  }

  selectDefaultUser() {
    let userForCheck = this.usersForChecks.filter((u) => this.userId == u.id);

    userForCheck[0].send_email = true;
    userForCheck[0].send_sms = true;

    this.clickCheckEvent.emit(this.usersForChecks);
  }

  createCheckboxObjs() {
    this.usersForChecks = [];

    for (var i = 0; i < this.users.length; i++) {
      let userObjForBackend = {
        email: this.users[i].email,
        phone_number: this.users[i].phone_number,
        send_email: false,
        send_sms: false,
        first_name: this.users[i].first_name,
        last_name: this.users[i].last_name,
        notification_start_time: this.users[i].notification_start_time,
        notification_end_time: this.users[i].notification_end_time,
        id: this.users[i].id,
      };

      this.usersForChecks.push(userObjForBackend);
    }
  }

  public clickCheck(user = null, str = null) {
    if (str == "preferred") {
      let getPreferences = this.users.filter((u) => u.id == user.id);
      user.send_email = getPreferences[0].notification_preferred_email;
      user.send_sms = getPreferences[0].notification_preferred_sms;
    }

    console.log("EMIT: ");
    console.log(this.usersForChecks);
    this.clickCheckEvent.emit(this.usersForChecks);
  }
}
