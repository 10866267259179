<!-- <div class="content-wrapper"> -->
<section class="content">
  <div class="row">
    <div class="col-md-12">
      <div class="box box-solid">
        <div class="box-body">
          <div class="col-md-4 col-md-offset-4" style="margin-top: 100px; height: 70vh;">
            <div class="col-md-12 row">
              <h4 class="text-center"><b>Create new password</b></h4>
              <br>
              <p>Select a new password. Passwords must be a minimum of six characters long and contain one uppercase
                letter, one lower case letter, and one number or symbol.</p>
            </div>

            <form [formGroup]="updatePasswordForm">
              <div class="col-md-12 row">
                <label>Password</label>
                <div class="input-group"
                  [ngClass]="submitted && updatePasswordForm.controls.password.errors?'invalid-form':''">
                  <input type="{{showPassword?'text':'password'}}" class="form-control" placeholder="Password"
                    formControlName="password">
                  <div class="input-group-btn">
                    <button class="btn btn-default" (click)="showHidePassword()">
                      <i *ngIf="!showPassword" class="glyphicon glyphicon-eye-open"></i>
                      <i *ngIf="showPassword" class="glyphicon glyphicon-eye-close"></i>
                    </button>
                  </div>
                </div>
                <div *ngIf="updatePasswordForm.controls.password.errors">
                  <span *ngIf="submitted && updatePasswordForm.controls.password.errors.required"
                    class="text-validation-error">Required</span>
                  <span *ngIf="submitted && updatePasswordForm.controls.password.errors.pattern"
                    class="text-validation-error">Password does not meet the minimum requirements</span>
                </div>
                <br>
                <label>Confirm Password</label>
                <div class="input-group"
                  [ngClass]="submitted && updatePasswordForm.controls.confirmPassword.errors?'invalid-form':''">
                  <input type="{{showPasswordConfirm?'text':'password'}}" class="form-control"
                    placeholder="Confirm Password" formControlName="confirmPassword" name="con">
                  <div class="input-group-btn">
                    <button class="btn btn-default" (click)="showHidePasswordConfirm()">
                      <i *ngIf="!showPasswordConfirm" class="glyphicon glyphicon-eye-open"></i>
                      <i *ngIf="showPasswordConfirm" class="glyphicon glyphicon-eye-close"></i>
                    </button>
                  </div>
                </div>
                <div *ngIf="updatePasswordForm.controls.confirmPassword.errors">
                  <span *ngIf="submitted && updatePasswordForm.controls.confirmPassword.errors.required"
                    class="text-validation-error">Required</span>
                </div>
                <div *ngIf="updatePasswordForm.errors">
                  <span *ngIf="submitted && updatePasswordForm.errors.notSame" class="text-validation-error">The
                    Passwords do not match</span>
                </div>
                <br>
                <button class="btn btn-success" (click)="onSubmit()" style="width: 100%;">Update Password</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- </div> -->
