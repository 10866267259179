export enum HeaderFilterType {
  Default,
  Date,
  FilteredList,
}

export enum DataTableColumnDisplayType {
  Date,
  Default,
  Currency,
  Percentage,
  Ticker,
}

export enum DataTableColumnFooterDisplayType {
  Average,
  Hidden,
  MinMax,
  Sum,
}

export interface SortBy {
  sortBy: string;
  sortOrder: string;
}
export interface DataTableColumn {
  columnName: string;
  dbName: string;
  displayType: DataTableColumnDisplayType;
  filteredList: boolean;
  filters: {
    contains?: string;
    greaterThan?: string;
    lessThan?: string;
  };
  footerDisplayType: DataTableColumnFooterDisplayType;
  footerValue: string; // TODO: Michael - see if this is where we want to store the footer values.
  headerFilterType: HeaderFilterType;
  headerHoverText: string;
  hidden: boolean;
  isFiltered: boolean;
  sort: SortBy;
}
