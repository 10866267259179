<div class="table-container">
  <div
    class="table-responsive"
    [ngClass]="{
      'table-responsive-standard':
        filtersCollapsed == false && !this.fullScreenService.isFullScreen,
      'table-responsive-fullscreen':
        filtersCollapsed == false && this.fullScreenService.isFullScreen,
      'table-responsive-pagecontrols-container':
        filtersCollapsed == true && !this.fullScreenService.isFullScreen,
      'table-responsive-fullscreen-pagecontrols-container':
        filtersCollapsed == true && this.fullScreenService.isFullScreen,
      'table-responsive-pagecontrols-container-filters':
        filtersCollapsed == true &&
        !this.fullScreenService.isFullScreen &&
        bubbles == true,
      'table-responsive-fullscreen-pagecontrols-container-filters':
        filtersCollapsed == true &&
        this.fullScreenService.isFullScreen &&
        bubbles == true
    }"
  >
    <table class="table table-striped">
      <thead>
        <tr>
          <!-- <th>Select</th> TODO: Michael, hook up the selectable option-->
          <th *ngFor="let column of columns" [hidden]="column.hidden">
            <app-table-header-date
              *ngIf="column.headerFilterType == headerFilterTypes.Date"
              [loading]="loading"
              [column]="column"
            ></app-table-header-date>

            <app-table-header-filtered-list
              *ngIf="column.headerFilterType == headerFilterTypes.FilteredList"
              [loading]="loading"
              [column]="column"
              [tableName]="tableName"
            >
            </app-table-header-filtered-list>
            <app-table-header
              *ngIf="column.headerFilterType == headerFilterTypes.Default"
              [column]="column"
              [loading]="loading"
            ></app-table-header>
            <i class="fa blue-icon fa-info-circle hide-display">
              <span class="show-display-on-hover">
                {{ column.headerHoverText }}
              </span>
            </i>
          </th>
        </tr>
      </thead>

      <tbody>
        <ng-container *ngIf="tableData && tableData.length > 0 && !loading">
          <tr class="table-data" *ngFor="let tableRow of tableData">
            <!-- TODO: Hook up selectable option by adding a selected field and checkbox here. 
                 Then an output method to alert whoever is using this table that the selection has changed.-->

            <td
              *ngFor="let column of columns"
              [hidden]="column.hidden"
              [ngClass]="{
                usd: column.displayType == dataTableColumnDisplayTypes.Currency,
                percent:
                  column.displayType == dataTableColumnDisplayTypes.Percentage,
                'text-align-center':
                  column.displayType !== dataTableColumnDisplayTypes.Currency &&
                  column.displayType != dataTableColumnDisplayTypes.Percentage
              }"
            >
              <ng-container
                *ngIf="
                  column.displayType == dataTableColumnDisplayTypes.Default &&
                  tableRow[column.dbName] != null
                "
              >
                {{ tableRow[column.dbName] }}
              </ng-container>
              <ng-container
                *ngIf="
                  column.displayType == dataTableColumnDisplayTypes.Date &&
                  tableRow[column.dbName] != null
                "
              >
                {{ tableRow[column.dbName] | date: "mediumDate" }}
              </ng-container>
              <ng-container
                *ngIf="
                  column.displayType == dataTableColumnDisplayTypes.Currency &&
                  tableRow[column.dbName] != null
                "
              >
                ${{ tableRow[column.dbName] | number: "1.2-2" }}
              </ng-container>
              <ng-container
                *ngIf="
                  column.displayType ==
                    dataTableColumnDisplayTypes.Percentage &&
                  tableRow[column.dbName] != null
                "
              >
                {{ tableRow[column.dbName] }}%
              </ng-container>
              <ng-container
                *ngIf="
                  column.displayType == dataTableColumnDisplayTypes.Ticker &&
                  tableRow[column.dbName] != null
                "
              >
                <a
                  class="link"
                  (click)="showTickerLiveData(content, tableRow[column.dbName])"
                  >{{ tableRow[column.dbName] }}</a
                >
              </ng-container>
            </td>
          </tr>
        </ng-container>
      </tbody>

      <tfoot>
        <ng-container
          *ngIf="tableData && tableData.length > 0 && !loading && !footerHidden"
        >
          <tr class="custom-color totals-table-rows">
            <td
              *ngFor="let column of columns"
              [hidden]="column.hidden"
              [ngClass]="{
                usd: column.displayType == dataTableColumnDisplayTypes.Currency,
                percent:
                  column.displayType == dataTableColumnDisplayTypes.Percentage,
                'text-align-center':
                  column.displayType !== dataTableColumnDisplayTypes.Currency &&
                  column.displayType != dataTableColumnDisplayTypes.Percentage
              }"
            >
              <b>
                <ng-container
                  *ngIf="
                    column.footerDisplayType ==
                    dataTableColumnFooterDisplayTypes.Sum
                  "
                >
                  Sum: {{ sumColumn(column.dbName) | number: "1.2-2" }}
                </ng-container>
                <ng-container
                  *ngIf="
                    column.footerDisplayType ==
                    dataTableColumnFooterDisplayTypes.MinMax
                  "
                >
                  <div>
                    <b
                      >High: {{ maxColumn(column.dbName) | number: "1.2-2" }}</b
                    >
                  </div>
                  <div>
                    <b>Low: {{ minColumn(column.dbName) | number: "1.2-2" }}</b>
                  </div>
                </ng-container>
                <ng-container
                  *ngIf="
                    column.footerDisplayType ==
                    dataTableColumnFooterDisplayTypes.Average
                  "
                >
                  Avg: {{ averageColumn(column.dbName) | number: "1.2-2" }}
                </ng-container>
              </b>
            </td>
          </tr>
        </ng-container>
      </tfoot>
    </table>

    <div *ngIf="loading" class="spinner-container">
      <div role="status" class="spinner-border">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div
      *ngIf="tableData && tableData.length == 0 && !loading"
      class="no-results no-results-container"
    >
      <h2 class="no-results">No Results</h2>
    </div>
  </div>
</div>
<ng-template #content let-modal>
  <div class="modal-header">
    <h2 class="modal-title" id="modal-basic-title">Current Data</h2>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <div class="input-group">
        <div class="input-group-append">
          <iframe
            id="ticker-live-data"
            width="770px"
            height="500px"
            src=""
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</ng-template>
