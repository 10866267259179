<app-filter-header
  pageTitle="Domek Web Trader - Exposure Report"
  (onFullScreen)="fullScreen()"
  (onFiltersToggle)="toggleFilters()"
  (onClearAllFilters)="clearAllFilters()"
  (onHideFooter)="hideFooter()"
  [tableColumns]="columns"
></app-filter-header>

<div [hidden]="!filtersCollapsed" class="filter-collector">
  <div class="filter-criteria">
    <div class="page-filters">
      <div>
        &nbsp;
        <button (click)="exportToExcel()" class="custom-btn">
          Export to Excel
        </button>
      </div>
    </div>
  </div>
  <div
    class="bubble-list"
    *ngFor="let filter of bubbleList | keyvalue: returnZero"
  >
    <app-filter-bubble
      class="bubble-list"
      [filterName]="filter.key"
      [filterValue]="filter.value"
    ></app-filter-bubble>
  </div>
</div>

<app-data-table
  [columns]="columns"
  [tableData]="tableData"
  [filtersCollapsed]="filtersCollapsed"
  [loading]="loading"
  tableName="exposure-report"
  [footerHidden]="footerHidden"
></app-data-table>
