import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class FullScreenService {
  public isFullScreen = false;

  constructor() {}
}
