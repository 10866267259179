<!-- <div class="content-wrapper"> -->
<section class="content-header">
  <h1>
    Create New User
  </h1>
  <a routerLink="/user-list">&#60;&#60;Back to Users</a>
</section>



<section class="content">
  <div class="row">
    <div class="col-md-12">
      <div class="box box-solid">
        <div class="box-body">
          <div class="d-flex justify-content-center">
            <div class="common-loader" *ngIf="loading">
              <img class="imgWidth" src="./assets/public_img_load.gif" />
            </div>
          </div>
          <!-- <div class="row"> -->
          <div class="col-md-12 col-lg-6 col-xl-6">
            <form *ngIf="true" [formGroup]="userCreateForm">
              <!-- <div class="container-fluid"> -->
              <div class=" row">
                <div class="col-12 col-sm-4 col-lg-4 col-md-4 col-xl-3">
                  <div class="avatar-container">
                    <div class="source">
                      <img *ngIf="!selectedImageSrc" [src]="profileImg" alt="" class="image-display img-circle hW">
                      <img *ngIf="selectedImageSrc" [src]="profileImg" alt="" class="image-display img-circle hW">
                    </div>

                    <div class="edit-icon">
                      <app-image-selector (imageValue)="imageSelect($event)"></app-image-selector>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-lg-12 col-md-8 col-xl-9">
                  <h4><b><i>Enter user name</i></b></h4>
                  <!-- <div class="row" style="margin-left: auto;margin-right: auto;"> -->
                  <!-- <div class="row"> -->
                  <div class="row rowFontSize" style="padding: inherit;">User Role: &nbsp;<input value="1"
                      type="checkbox" (click)="checkAdminChecked($event)" [disabled]="adminDisable">
                    Admin&nbsp;<span>
                      <input value="2" type="checkbox" (click)="checkEmployeeChecked($event)"
                        [disabled]="employeeDisable" id="employee">
                      Employee
                    </span>
                  </div>
                  <div class="invalid-feedback" *ngIf="roleRequired">
                    <div *ngIf="roleRequired">required</div>
                  </div>
                  <div class="row" style="padding: inherit;">
                    <div class="rowFontSize" style="display: flex;flex-direction: row;align-items: center;">
                      Password:&nbsp;&nbsp;<input type="text" class="form-control" id="password"
                        formControlName="password" [ngClass]="{'red-border-class':
                            (f.password.errors && submitted)}" style="margin-top: 5px;">

                      <button (click)="generatePW()" class="btn btn-primary"
                        style="margin-left: 10px;">Generate</button>
                    </div>
                    <div class="invalid-feedback" *ngIf="submitted && f.password.errors">
                      <div *ngIf="f.password.errors.minlength">invalid</div>
                    </div>
                  </div>
                  <!-- </div> -->
                </div>
              </div>
              <!-- </div> -->
              <div class="form-group" style="margin-top: 10px;">
                <div class="form-group has-feedback row">
                  <div class="col-md-6 col-sm-12">
                    <label for="firstName">First Name</label>
                    <input type="text" class="form-control form-control-lg rounded-0 has-feedback" id="firstName"
                      placeholder="First Name" formControlName="firstName" [ngClass]="{'red-border-class':
                      (f.firstName.errors && submitted)}" required>
                    <div class="invalid-feedback" *ngIf="submitted && f.firstName.errors">
                      <div *ngIf="f.firstName.errors.required">required
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 col-sm-12">
                    <label for="firstName">Last Name</label>
                    <input type="text" class="form-control form-control-lg rounded-0 has-feedback" id="lastName"
                      placeholder="Last Name" formControlName="lastName" [ngClass]="{'red-border-class':
                      (f.lastName.errors && submitted)}" required>
                    <div class="invalid-feedback" *ngIf="submitted && f.lastName.errors">
                      <div *ngIf="f.lastName.errors.required">required</div>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-12">
                    <label for="firstName">Email</label>
                    <input type="text" class="form-control form-control-lg rounded-0 has-feedback" id="firstName"
                      placeholder="Email" formControlName="email" [ngClass]="{'red-border-class':
                      (f.email.errors && submitted)}" required>
                    <div class="invalid-feedback" *ngIf="submitted && f.email.errors">
                      <div *ngIf="f.email.errors.required">required</div>
                      <div *ngIf="f.email.errors.pattern">invalid</div>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-12">
                    <label for="firstName">Address Line 1</label>
                    <input type="text" class="form-control" id="firstName" placeholder="Address Line 1"
                      formControlName="address1">
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-12">
                    <label for="firstName">Address Line 2</label>
                    <input type="text" class="form-control" id="firstName" placeholder="Address Line 2"
                      formControlName="address2">
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-5 col-sm-12">
                    <label for="city">City</label>
                    <input type="text" class="form-control" id="city" placeholder="City" formControlName="city">
                  </div>
                  <div class="col-md-4 col-sm-12">
                    <label for="state">State</label>
                    <p> <span *ngIf="viewSelectText" class='pretend-option'>Select a state</span>
                      <select class="form-control" formControlName="state" id="state" (change)="isClickState($event)">
                        <option *ngFor="let state of statesList" value="{{state}}">{{state}}
                        </option>
                      </select>
                    </p>
                  </div>
                  <div class="col-md-3 col-sm-12">
                    <label for="zip">ZIP</label>
                    <input type="text" class="form-control form-control-lg rounded-0 has-feedback" id="zip"
                      placeholder="ZIP" formControlName="zip" [ngClass]="{'red-border-class':
                        (f.zip.errors && submitted)}">
                    <div class="invalid-feedback" *ngIf="submitted && f.zip.errors">
                      <div *ngIf="f.zip.errors && submitted">invalid</div>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-6 col-sm-12">
                    <label for="city">Phone 1</label>
                    <input type="text" class="form-control form-control-lg rounded-0 has-feedback" id="phone1"
                      placeholder="Phone" formControlName="phone1" [ngClass]="{'red-border-class':
                      (f.phone1.errors && submitted)}">
                    <div class="invalid-feedback" *ngIf="submitted && f.phone1.errors">
                      <div *ngIf="f.phone1.errors && submitted">invalid</div>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-12">
                    <label for="city">Notes</label>
                    <textarea class="form-control" rows="4" formControlName="notes"></textarea>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-12">
                    <input type="button" (click)="onSubmit()" class=" btn btn-success" value="Create User"
                      style="float: left;">
                    <input type="button" routerLink="/user-list" class=" btn btn-danger" value="Cancel"
                      style="float: right;">
                  </div>
                </div>
              </div>
            </form>
          </div>
          <!-- </div> -->
        </div>
      </div>
    </div>
  </div>
</section>
<!-- </div> -->
