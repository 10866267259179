import { Injectable } from "@angular/core";
import { RuntimeEnvLoaderService } from "./runtime-env-loader.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class AppService {
  public scheduledNotificationTableLoadedGlobal: boolean = false;

  private options = {
    headers: new HttpHeaders().set("Content-Type", "application/json"),
  };
  constructor(
    private envService: RuntimeEnvLoaderService,
    private httpClient: HttpClient
  ) {}

  getAWSPresignedUrl() {
    return this.httpClient.get<any>(
      `${this.envService.config.API_BASE_URL}${this.envService.config.APP_KEY}/aws-s3-presigned-urls`,
      this.options
    );
  }

  uploadFileToAWS(url, file) {
    return this.httpClient.put<any>(url, file);
  }

  createFile(data) {
    return this.httpClient.post<any>(
      `${this.envService.config.API_BASE_URL}${this.envService.config.APP_KEY}/files`,
      data,
      this.options
    );
  }
}
