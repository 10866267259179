import { Component, OnInit } from '@angular/core';
import { forkJoin, Subscription } from 'rxjs';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ToastrService } from 'ngx-toastr';

import { UserService } from 'src/app/services/app/user/user.service';
import { environment } from 'src/environments/environment.prod';
import { AppService } from 'src/app/services/app/user/app.service';
import { UserRole } from 'src/app/constants/user/user-role';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {

  count: any;
  deleteList = new Array();
  getInstallersList: any;
  page: number;
  sortIcon: String;
  sortObj: any;
  sortOrder: string;
  totalCount: any;
  users: any;
  currentUserDetails: any;
  logUserData: any;
  userLog: any;
  logginUserRoleIds = [];
  checkDisableBtn = false;

  subGetEssentialData: Subscription;
  subGetLoggingData: Subscription;
  isAdmin = false;
  usersCount: any;
  searchValue = '';

  constructor(
    private appService: AppService,
    private toastr: ToastrService,
    private userService: UserService,
    public authService: AuthenticationService
  ) {
    this.sortIcon = 'fa-sort-amount-asc disabled';
    this.sortOrder = 'asc';
    this.sortObj = {
      sortBy: null,
      sortOrder: null
    };
    this.currentUserDetails = localStorage.getItem('currentUser');
  }

  ngOnInit() {
    const logUser = localStorage.getItem('currentUser');
    if (logUser != null) {
      if (JSON.parse(logUser).userRoles[0].id == UserRole.admin) {
        this.isAdmin = true;
      } else {
        this.isAdmin = false;
      }
    }
    this.count = localStorage.getItem('page-count');
    this.page = 1;
    this.getData();
  }

  ngOnDestroy() {
    if (this.subGetEssentialData != undefined) {
      this.subGetEssentialData.unsubscribe();
    }
    if (this.subGetLoggingData != undefined) {
      this.subGetLoggingData.unsubscribe();
    }
  }

  pageLength(count) {
    this.count = count;
    this.getData();
  }

  next(value) {
    this.page = value;
    this.getData();
  }

  sort(event, field, sort) {
    const result = this.appService.sort(event, field, sort);
    this.sortOrder = result.sortOrder;
    this.sortObj.sortBy = result.sortField;
    this.sortObj.sortOrder = result.sortOrder;
    this.getData();
  }

  getData(search = null) {
    this.checkLogUserId();

    if (search != null) {
      this.page = 1;
    }

    let sortBy = this.sortObj.sortBy;
    let sortOrder = this.sortObj.sortOrder;

    if (sortBy == null) {
      sortBy = environment.DEFAULT_SORT.USERS.SORT_BY;
    }

    if (sortOrder == null) {
      sortOrder = environment.DEFAULT_SORT.USERS.SORT_ORDER;
    }

    if (this.count == null) {
      this.count = environment.DEFAULT_SORT.USERS.PAGE_COUNT[0];
    }

    const params = {
      count: this.count,
      page: this.page,
      sort_by: sortBy,
      sort_order: sortOrder,
      search: search == null ? '{"keyword":"' + this.searchValue + '"}' : '{"keyword":"' + this.searchValue + '"}'
    };

    this.subGetEssentialData = this.userService.getUserList(params).subscribe(
      result => {
        console.log("RESULT IS: !");
        console.log(result);
        

        const resultCount = result.headers.get('app-content-full-count');
        this.usersCount = resultCount;
        result.body.data.forEach(user => {
          user.checkStatus = false;
        });
        this.users = result.body.data;
        
        this.totalCount = resultCount;
        const sendParams = {
          totalCount: this.totalCount,
          countPerPage: this.count,
          page: this.page
        };
        this.totalCount = sendParams;

        
        

      });
  }


  checkLogUserId() {
    this.logUserData = JSON.parse(this.currentUserDetails);

    const getloggedUser = this.userService.getUserDetails(this.logUserData.id);
    this.subGetLoggingData = forkJoin([getloggedUser]).subscribe((logUser) => {
      this.userLog = logUser[0];

      this.userLog.user_roles.forEach(element => {
        if (element.id == UserRole.admin) {
          this.logginUserRoleIds.push(UserRole.admin);
        } else if (element.id == UserRole.employee) {
          this.logginUserRoleIds.push(UserRole.employee);
        }
      });
      const idxOfAdmin = this.logginUserRoleIds.indexOf(UserRole.admin);
      const idxOfEmployee = this.logginUserRoleIds.indexOf(UserRole.employee);

      if (idxOfAdmin != -1) {
        this.checkDisableBtn = false;
      } else if (idxOfEmployee != -1) {
        this.checkDisableBtn = true;
      } else if (idxOfEmployee != -1 && idxOfAdmin != -1) {
        this.checkDisableBtn = false;
      }
    });
  }

  selectSelect(event) {
    this.users.forEach(user => {
      if (user.id == event.target.value) {
        user.checkStatus = !user.checkStatus;
        if (user.checkStatus == true) {
          this.deleteList.push(user.id);
        } else {
          const idx = this.deleteList.indexOf(user.id);
          if (idx != -1) {
            this.deleteList.splice(idx, 1);
            user.checkStatus = false;
          }
        }
      }
    });
  }

  selectAllUsers() {
    this.deleteList = [];
    this.users.forEach(user => {
      user.checkStatus = true;
      this.deleteList.push(user.id);
    });
  }

  DeselectAllUsers() {
    this.users.forEach(user => {
      user.checkStatus = false;
      this.deleteList = [];
    });
  }

  deleteSelectedUsers() {
    let totalRow = this.deleteList.length;
    Swal.fire({
      title: 'Are you sure?',
      text: 'This will delete the ' + totalRow + ' selected records.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ok'
    }).then((result) => {
      if (result.value) {
        const userIds = [];
        this.users.forEach(user => {
          if (user.checkStatus == true) {
            const logUser = JSON.parse(localStorage.getItem('currentUser'));
            if (logUser.id != user.id) {
              userIds.push(this.userService.deleteUser(Number(user.id)));
            } else {
              totalRow -= 1;
            }
          }
        });
        this.subGetEssentialData = forkJoin(userIds).subscribe(
          response => {
            if (response[0] == '200') {
              this.deleteList = [];
              this.toastr.success(totalRow + ' rows', 'Deleted Successfully');
              this.getData();
            }
          }, err => {
            this.toastr.error(totalRow + ' rows', 'Deleted Unsuccessful');
            
          });
      }
    });
  }

  searchBox(searchValue) {
    if (searchValue) {
      this.searchValue = searchValue;
    } else {
      this.searchValue = '';
    }
    this.getData(this.searchValue);
  }

  simulateUser(userId) {
    this.authService.simulateUser(userId).subscribe((response: any) => {
      window.location.reload();
    });
  }
}
