import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  submitted = false;
  remember: boolean;
  email: string;
  password: string;
  loading = false;

  constructor(private formBuilder: FormBuilder, private authService: AuthenticationService, public router: Router) {

   }

  get f() { return this.loginForm.controls; }

  ngOnInit() {
    this.getRememberMe();
    this.loginForm = this.formBuilder.group({
      email: [this.email, [Validators.required, Validators.email]],
      password: [this.password, [Validators.required]],
      remember: []
    });
  }

  onSubmit() {
    this.submitted = true;
    if (!this.loginForm.invalid) {

      const email = this.loginForm.value.email;
      const password = this.loginForm.value.password;
      const rememberMe = this.loginForm.value.remember;
      this.loading = true;
      this.authService.login(email, password).subscribe(res => {
        if (rememberMe) {
          this.setRememberMe(email, password);
        }
        this.loading = false;
        this.router.navigate(['/']);

        this.authService.userId = res.id;

        
        

      }, () => {
        this.loading = false;
        Swal.fire({
          text: 'Login failed!',
          icon: 'error'
        }).then(() => {
          this.router.navigate(['/login']);
        });
      });

    } else {
      return false;
    }

  }

  getRememberMe() {
    this.email = localStorage.getItem('email');
    this.password = localStorage.getItem('password');
  }

  setRememberMe(email: string, password: string) {
      localStorage.setItem('email', email);
      localStorage.setItem('password', password);
    }

    checkRemember(event) {

    }
}
