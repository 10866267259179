import { NotificationsService } from "src/app/services/notifications.service";
import { ToastrService } from "ngx-toastr";
import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { UserService } from "src/app/services/app/user/user.service";

@Component({
  selector: "app-spread-notifications",
  templateUrl: "./spread-notifications.component.html",
  styleUrls: ["./spread-notifications.component.scss"],
})
export class SpreadNotificationsComponent implements OnInit {
  spreadUsers: any;
  users: any;
  loading: any;
  spreadNoteDate: any;
  spreadNoteMessage: any = "";
  usersLoading: boolean;

  spreadNoteDateError: boolean = false;
  spreadNoteMessageError: boolean = false;

  public _rightClicked: any;
  @Input() set rightClicked(value: any) {
    this._rightClicked = value;
  }
  get rightClicked(): any {
    return this._rightClicked;
  }

  public _cellValueArr: any;
  @Input() set cellValueArr(value: any) {
    this._cellValueArr = value;
  }

  get cellValArr(): any {
    return this._cellValueArr;
  }

  constructor(
    private notificationsService: NotificationsService,
    private toastr: ToastrService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.getUsers();
  }

  clickUserTable($event) {
    this.spreadUsers = $event;
  }

  getUsers() {
    this.loading = true;
    this.userService.getUserList().subscribe((data) => {
      this.users = data.body.data;
      this.loading = false;
    });
  }

  /*

     "ACCOUNT" => $jobDetails['account'],
      "EXPIRATION" => $expiration,
      "NOTIFICATION_DATE" => $date,
      "NOTIFICATION_MESSAGE" => $jobDetails["message"],
      "PRICE_1" => $jobDetails['price_1'],
      "PRICE_2" => $jobDetails['price_2'],
      "SECURITY_TYPE_1" => $jobDetails['security_type_1'],
      "SECURITY_TYPE_2" => $jobDetails['security_type_2'],
      "TICKER" => $jobDetails['ticker'],
      "USER_NAME" =>  $user['first_name'] . " " . $user['last_name']

  */

  createSpreadNotification() {
    this.spreadNoteDateError = false;
    this.spreadNoteMessageError = false;

    if (!this.spreadUsers) {
      this.spreadUsers = this.users;
    }

    if (!this.spreadNoteDate) {
      this.spreadNoteDateError = true;
      return;
    }

    if (!this.spreadNoteMessage) {
      this.spreadNoteMessageError = true;
      return;
    }

    this.loading = true;
    let date =
      this.spreadNoteDate.year +
      "-" +
      this.spreadNoteDate.month +
      "-" +
      this.spreadNoteDate.day;

    let jsonOfJob = {
      message: this.spreadNoteMessage,
      time_span: date,
      account: this._rightClicked.trade_account_name,
      portfolio_item_id: this._rightClicked.portfolio_item_id,
      users: this.spreadUsers,
      strike_1: this._rightClicked.legs[0].strike,
      strike_2: this._rightClicked.legs[1].strike,
      security_type_1: this._rightClicked.legs[0].security_type,
      security_type_2: this._rightClicked.legs[1].security_type,
      expiration: this._rightClicked.legs[0].expiration_date,
      ticker: this._rightClicked.symbol
    };

    var params = {
      details_long: JSON.stringify(jsonOfJob),
      app_id: 1,
      remaining: "1",
      every_minutes: "1",
      frequency: "every_minutes",
      next_run: date,
      name: "create_notification_by_spread",
    };

    this.loading = true;
    this.notificationsService.newScheduledJob(params).subscribe((result) => {
      this.loading = false;
      this.toastr.success("Created New Notification");
    });
  }
}
