    <div class="container-fluid custom-layout-container">
        <div class="row">
            <div class="col-md-offset-4 col-md-4 col-sm-5">
                
                <div class="login-container">
                    <div class="loader" *ngIf="loading">
                        <img src="./assets/public_img_load.gif" />
                    </div>
                    <img src="../../../assets/Domek - wht - transparent.png" class="img-responsive" />
                    <div class="card card-white">
                    <div class="card-header">
                        <h4>Sign in to start your session</h4>
                    </div>
                    <div class="c-body">
                        <form class="form" role="form" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                            <div class="form-group has-feedback">
                                <input formControlName="email" autocomplete="true" type="text" class="form-control form-control-lg rounded-0 has-feedback" placeholder="Email">
                                <span class="glyphicon glyphicon-envelope form-control-feedback"></span>
                                <div class="invalid-feedback" *ngIf="submitted && f.email.errors">
                                    <div *ngIf="f.email.errors.required">User email is required</div>
                                    <div *ngIf="f.email.errors.email">User email is invalid</div>
                                </div>
                            </div>
                            <div class="form-group has-feedback">
                                <input type="password" formControlName="password"  class="form-control form-control-lg rounded-0" id="pwd1" placeholder="Password">
                                <span class="glyphicon glyphicon-lock form-control-feedback"></span>
                                <div class="invalid-feedback" *ngIf="submitted && f.password.errors">
                                    <div *ngIf="f.password.errors.required">Password is required</div>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="checkbox">
                                    <label>
                                        <input type="checkbox" formControlName="remember" (change)="checkRemember($event)"> Remember Me
                                    </label>
                                </div>
                            </div>
                            <div class="form-footer">
                                <!-- <div class="left">
                                    <a [routerLink]="['/password-recovery']" class="reset-link">Forgot password?</a>
                                </div> -->
                                <div class="row">
                                    <div class="col-xs-12">
                                        <button type="submit" class="btn btn-primary " id="btnLogin">Login</button>
                                        <a [routerLink]="['/password-recovery']" class="reset-link">Forgot password?</a>
                                    </div>
                                </div>
                                    
                                
                            </div>
                            
                        </form>
                        
                    </div>
                    <!--/card-block-->
                </div>
                <!-- /form card login -->
                
                
                </div>
            </div>
        </div>
    </div>