import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

import {
  DataTableColumn,
  DataTableColumnDisplayType,
  DataTableColumnFooterDisplayType,
  HeaderFilterType,
} from "src/app/interfaces/data-table-column";
import { FullScreenService } from "src/app/services/full-screen.service";
import { RuntimeEnvLoaderService } from "src/app/services/runtime-env-loader.service";

import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-data-table",
  templateUrl: "./data-table.component.html",
  styleUrls: ["./data-table.component.scss"],
})
export class DataTableComponent implements OnInit {
  @Input() columns: Array<DataTableColumn> = [];
  @Input() filtersCollapsed = true;
  @Input() tableName = "";
  @Input() bubbles = false;
  @Input() tableData = [];
  @Input() loading = false;
  @Input() footerHidden = false;

  headerFilterTypes = HeaderFilterType;
  dataTableColumnDisplayTypes = DataTableColumnDisplayType;
  dataTableColumnFooterDisplayTypes = DataTableColumnFooterDisplayType;
  closeResult = "";

  constructor(
    private envService: RuntimeEnvLoaderService,
    public fullScreenService: FullScreenService,
    private modalService: NgbModal
  ) {}

  ngOnInit() {}

  showTickerLiveData(content, symbol) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title", size: "lg" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
    var url =
      this.envService.config.API_BASE_URL +
      "/url-framer.php?url=https://finance.yahoo.com/quote/" +
      symbol +
      "/";

    document.getElementById("modal-basic-title").innerHTML =
      "Live Data: " + symbol;
    document.getElementById("ticker-live-data").setAttribute("src", url);
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  public sumColumn(columnName: string): number {
    let sum = 0;

    this.tableData.forEach((row) => {
      sum += row[columnName];
    });

    return sum;
  }

  public maxColumn(columnName: string): number {
    let max = 0;

    this.tableData.forEach((row) => {
      if (row[columnName] && row[columnName] > max) {
        max = row[columnName];
      }
    });

    return max;
  }

  public minColumn(columnName: string): number {
    let min = this.maxColumn(columnName);

    this.tableData.forEach((row) => {
      if (row[columnName] && row[columnName] < min) {
        min = row[columnName];
      }
    });

    return min;
  }

  public averageColumn(columnName: string): number {
    let sum = this.sumColumn(columnName);
    let count = this.tableData.length;

    return sum / count;
  }
}
