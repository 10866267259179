<html>
  <ng-template #content let-modal>
    <div class="modal-header">
      <h2 class="modal-title" id="modal-basic-title">
        {{ activeRightClickRecord.trade_account_name }}
      </h2>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="form-container">
        <form>
          <div class="form-group close-form">
            <label>Close Date: </label>
            <div class="rc-dropdown">
              <div>
                <input
                  class="form-control close-date-input"
                  placeholder="mm-dd-yyyy"
                  #date
                  value="{{ closeResult }}"
                  name="dp"
                  ngbDatepicker
                  #closeDate="ngbDatepicker"
                />
              </div>
              <div class="input-group-append">
                <button
                  class="date btn-outline-secondary calendar fa fa-calendar rc-datepicker-btn"
                  (click)="closeDate.toggle()"
                  type="button"
                  value="{{ closeResult }}"
                ></button>
              </div>
            </div>
          </div>

          <div class="form-group">
            <label for="rightClickLegQuantity">Quantity: </label>
            <input
              type="rightClickLegQuantity"
              class="form-control"
              id="rightClickLegQuantity"
              aria-describedby="rightClickLegQuantity"
              [(ngModel)]="rightClickQuantityVal"
              [ngModelOptions]="{ standalone: true }"
            />
          </div>
          <div class="rc-form-group-btns">
            <button
              disabled="{{ activeRightClickLeg.closed }}"
              class="btn btn-primary"
              (click)="closeLeg()"
            >
              Close
            </button>
            <button
              disabled="{{ activeRightClickLeg.deleted }}"
              class="btn btn-secondary btn-delete"
              (click)="deleteLeg()"
            >
              Delete
            </button>
          </div>
          <div style="margin-top: 15px" *ngIf="rcModalLoading">
            Submitting Request...
          </div>

          <div style="margin-top: 15px" *ngIf="rcModalDateError">
            Please specify a date.
          </div>
        </form>
      </div>
    </div>
  </ng-template>

  <div *ngIf="this.fullScreenService.isFullScreen" class="full-screen-head">
    <div class="full-screen-info full-screen-page-title">
      <p>Domek Web Trader - Portfolio</p>
    </div>
    <div class="full-screen-info full-screen-show-filters-btn">
      <p *ngIf="!filtersCollapsed && this.fullScreenService.isFullScreen">
        <i class="fa fa-angle-double-right"></i
        ><a (click)="toggleFiltersCollapse()"> View Tools & Filters </a
        ><i class="fa fa-angle-double-left"></i>
      </p>
      <p *ngIf="filtersCollapsed && this.fullScreenService.isFullScreen">
        <i class="fa fa-angle-double-down"></i
        ><a (click)="toggleFiltersCollapse()"> View Tools & Filters </a
        ><i class="fa fa-angle-double-down"></i>
      </p>
    </div>
    <div class="full-screen-info full-screen-controls">
      <p class="full-screen-head-button">
        <a
          ><span (click)="fullScreen()"
            ><i class="fa fa-compress"></i> Exit Full Screen</span
          ></a
        >
      </p>
      <p class="full-screen-head-button">
        <a
          ><span (click)="logout()"
            ><i class="fa fa-sign-out"></i> Sign out</span
          ></a
        >
      </p>
    </div>
  </div>

  <div
    *ngIf="
      !this.fullScreenService.isFullScreen ||
      (filtersCollapsed && this.fullScreenService.isFullScreen)
    "
    [ngClass]="{
      'filter-head': !this.fullScreenService.isFullScreen,
      'filter-head-full-screen':
        filtersCollapsed && this.fullScreenService.isFullScreen
    }"
  >
    <div class="headerContainer">
      <div class="removeAllFilterOptionsButtons">
        <!-- <h2>Historical Orders</h2> -->

        <!-- <span class="fill-incoming" *ngIf="incomingFill"> Incoming Fill Detected... </span> -->

        <span
          class="port-edit"
          style="margin-right: 10px"
          *ngIf="userMakingEdit"
        >
          <span style="margin-left: 3px"
            >Auto Data reload disabled while editing or setting filters!</span
          >

          <!-- <div style="display: flex">
            <button
              (click)="getPortfolioTopMessage()"
              class="btn btn-primary top-msg-btn"
            >
              Get Data
            </button>
          </div> -->
        </span>

        <a
          *ngIf="!this.fullScreenService.isFullScreen"
          class="clearAllFiltersBtn"
          (click)="fullScreen()"
          ><i class="fa fa-expand"></i> Full Screen
        </a>
        <button class="clearAllFiltersBtn" (click)="removeAllFilters()">
          <i class="fa fa-eraser"></i> Clear All Filters
        </button>

        <!-- <a
          type="button"
          class="clearAllFiltersBtn close-footer"
          aria-label="Close"
          (click)="closeFooter()"
          >Hide Footer</a
        > -->

        <div ngbDropdown>
          <a id="dropdownBasic1" class="clearAllFiltersBtn" ngbDropdownToggle
            >Show Columns <i class="fa fa-caret-down"></i
          ></a>
          <div
            class="dropdown-container"
            ngbDropdownMenu
            aria-labelledby="dropdownBasic1"
          >
            <div class="fixed-button">
              <a
                class="clearAllFiltersBtn show-all-columns-button"
                (click)="showAllColumns()"
              >
                Show All Columns</a
              >
            </div>
            <div class="overflow">
              <ng-container *ngFor="let column of allColumns">
                <p *ngIf="column.columnName.length">
                  <a (click)="showSpecificColumns(column.dbName)">
                    <span>
                      <i
                        class="fa fa-check"
                        [ngClass]="{ 'check-icon': column.hidden == false }"
                      ></i>
                    </span>
                    {{ column.columnName }}
                  </a>
                </p>
                <p *ngIf="column.dbName === 'at_10k'">
                  <a (click)="showSpecificColumns(column.dbName)">
                    <span>
                      <i
                        class="fa fa-check"
                        [ngClass]="{ 'check-icon': column.hidden == false }"
                      ></i>
                    </span>
                    <!-- At {{ atThisPrice }} -->
                  </a>
                </p>
                <hr class="hr-list-bottom" />
              </ng-container>
            </div>
          </div>
        </div>

        <div
          *ngIf="!filtersCollapsed && !this.fullScreenService.isFullScreen"
          class="hideAllFiltersBtn"
        >
          <i
            class="clearAllFiltersBtn"
            (click)="toggleFiltersCollapse()"
            class="fa fa-caret-left fa-2x"
          ></i>
        </div>
        <div
          *ngIf="filtersCollapsed && !this.fullScreenService.isFullScreen"
          class="hideAllFiltersBtn"
        >
          <i
            class="clearAllFiltersBtn"
            (click)="toggleFiltersCollapse()"
            class="fa fa-caret-down fa-2x"
          ></i>
        </div>
      </div>
    </div>
  </div>

  <!-- <button class="btn btn-primary" (click)="onContextMenu($event, item)">Hello</button> -->

  <!-- <ul>
    <li *ngFor="let item of items" [contextMenu]="basicMenu" [contextMenuSubject]="item">{{item?.name}}</li>
</ul> -->

  <ng-template #portfoliohistory let-modal>
    <div *ngIf="historyLoading == true" class="loading-history">
      <p>Spread History Loading ...</p>
      <img
        src="../../../assets/loading-buffering.gif"
        class="load-img img-fluid"
      />
    </div>
    <div *ngIf="historyLoading != true" class="spread-history-container">
      <div class="history-header">
        <h2 class="modal-title" id="modal-basic-title">Spread History</h2>
        <div class="spread-data">
          <h4>
            Ticker : <b>{{ spreadHistory.symbol }}</b>
          </h4>
          <h4>
            Trade Account : <b>{{ spreadHistory.trade_account_name }}</b>
          </h4>
        </div>
      </div>
      <div class="spread-history">
        <div class="spread-history-data-container">
          <ng-container *ngFor="let history of portfolioRecordHistoryData">
            <div class="history-data-container">
              <h5 class="history-log-title">
                Log Id - Leg Number - {{ history?.portfolio_leg?.leg_num }} |
                {{ formatSpreadDate(history.created_at) | date: "mediumDate" }}
              </h5>
              <h5>
                <b>{{ history.source }}</b> - <b>{{ history.type }}</b>
              </h5>
              <table
                *ngIf="history.type != 'EDIT'"
                class="history-log-data-table table"
              >
                <tr>
                  <th
                    *ngIf="history.purchased_at != null"
                    class="spread-history-table-header"
                  >
                    <p>Purchased At</p>
                  </th>
                  <th
                    *ngIf="history.expiration != null"
                    class="spread-history-table-header"
                  >
                    <p>Expiration</p>
                  </th>
                  <th
                    *ngIf="history.security_type != null"
                    class="spread-history-table-header"
                  >
                    <p>Security Type</p>
                  </th>
                  <th
                    *ngIf="history.quantity != null"
                    class="spread-history-table-header"
                  >
                    <p>Quantity</p>
                  </th>
                  <th
                    *ngIf="history.strike != null"
                    class="spread-history-table-header"
                  >
                    <p>Strike</p>
                  </th>
                  <th
                    *ngIf="history.price_at_fill != null"
                    class="spread-history-table-header"
                  >
                    <p>Price At Fill</p>
                  </th>
                  <th
                    *ngIf="history.exit != null"
                    class="spread-history-table-header"
                  >
                    <p>Exit</p>
                  </th>
                  <th
                    *ngIf="history.bid != null"
                    class="spread-history-table-header"
                  >
                    <p>Bid</p>
                  </th>
                  <th
                    *ngIf="history.mid != null"
                    class="spread-history-table-header"
                  >
                    <p>Mid</p>
                  </th>
                  <th
                    *ngIf="history.ask != null"
                    class="spread-history-table-header"
                  >
                    <p>Ask</p>
                  </th>
                  <th
                    *ngIf="history.paid != null"
                    class="spread-history-table-header"
                  >
                    <p>Paid</p>
                  </th>
                </tr>

                <tr>
                  <td
                    *ngIf="history.purchased_at != null"
                    class="spread-history-table-data"
                  >
                    <p>{{ history.purchased_at | date: "mediumDate" }}</p>
                  </td>
                  <td
                    *ngIf="history.expiration != null"
                    class="spread-history-table-data"
                  >
                    <p>{{ history.expiration | date: "mediumDate" }}</p>
                  </td>
                  <td
                    *ngIf="history.security_type != null"
                    class="spread-history-table-data"
                  >
                    <p>{{ history.security_type }}</p>
                  </td>
                  <td
                    *ngIf="history.quantity != null"
                    class="spread-history-table-data"
                  >
                    <p>{{ history.quantity }}</p>
                  </td>
                  <td
                    *ngIf="history.strike != null"
                    class="spread-history-table-data"
                  >
                    <p>{{ history.strike }}</p>
                  </td>
                  <td
                    *ngIf="history.price_at_fill != null"
                    class="spread-history-table-data"
                  >
                    <p>{{ history.price_at_fill }}</p>
                  </td>
                  <td
                    *ngIf="history.exit != null"
                    class="spread-history-table-data"
                  >
                    <p>{{ history.exit }}</p>
                  </td>
                  <td
                    *ngIf="history.bid != null"
                    class="spread-history-table-data"
                  >
                    <p>{{ history.bid }}</p>
                  </td>
                  <td
                    *ngIf="history.mid != null"
                    class="spread-history-table-data"
                  >
                    <p>{{ history.mid }}</p>
                  </td>
                  <td
                    *ngIf="history.ask != null"
                    class="spread-history-table-data"
                  >
                    <p>{{ history.ask }}</p>
                  </td>
                  <td
                    *ngIf="history.paid != null"
                    class="spread-history-table-data"
                  >
                    <p>{{ history.paid }}</p>
                  </td>
                </tr>
              </table>
              <div *ngIf="history.type == 'EDIT'">
                <p
                  *ngIf="history.purchased_at != null"
                  class="spread-history-data"
                >
                  Purchased At:
                  <b>{{ history.purchased_at | date: "mediumDate" }}</b>
                </p>
                <p
                  *ngIf="history.expiration != null"
                  class="spread-history-data"
                >
                  Expiration:
                  <b>{{ history.expiration | date: "mediumDate" }}</b>
                </p>
                <p
                  *ngIf="history.security_type != null"
                  class="spread-history-data"
                >
                  Security Type: <b>{{ history.security_type }}</b>
                </p>
                <p *ngIf="history.quantity != null" class="spread-history-data">
                  Quantity: <b>{{ history.quantity }}</b>
                </p>
                <p *ngIf="history.strike != null" class="spread-history-data">
                  Strike: <b>{{ history.strike }}</b>
                </p>
                <p
                  *ngIf="history.price_at_fill != null"
                  class="spread-history-data"
                >
                  Price At Fill: <b>{{ history.price_at_fill }}</b>
                </p>
                <p *ngIf="history.exit != null" class="spread-history-data">
                  Exit: <b>{{ history.exit }}</b>
                </p>
                <p *ngIf="history.bid != null" class="spread-history-data">
                  Bid: <b>{{ history.bid }}</b>
                </p>
                <p *ngIf="history.mid != null" class="spread-history-data">
                  Mid: <b>{{ history.mid }}</b>
                </p>
                <p *ngIf="history.ask != null" class="spread-history-data">
                  Ask: <b>{{ history.ask }}</b>
                </p>
                <p *ngIf="history.paid != null" class="spread-history-data">
                  Paid: <b>{{ history.paid }}</b>
                </p>
              </div>
              <p *ngIf="history.notes != null">Notes : {{ history.notes }}</p>
              <p class="user" *ngIf="history.user != null">
                - Edited by - {{ history?.user?.first_name }}
                {{ history.user.last_name }}
              </p>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-container *ngFor="let leg of portfolio">
    <div *ngIf="leg.active">
      <app-popover
        [text]="leg.notes"
        [active]="leg.active"
        [mouseEvent]="mouseEvent"
      ></app-popover>
    </div>
  </ng-container>
  <div [hidden]="!filtersCollapsed" class="filter-collector">
    <div *ngIf="filterCriteria" class="filter-criteria">
      <div class="portfolio-toolkit">
        <button
          [hidden]="addNewRecord"
          (click)="addNewCustomPortfolioTableRow()"
          class="custom-btn custom-btn-on-hover"
        >
          <i class="fa fa-plus fa-2x"></i>
          <span class="btn-text">Add New Portfolio Record</span>
        </button>

        <ng-container *ngIf="numNewRowsExisting >= 1">
          &nbsp;
          <button (click)="submitNewLegs()" class="custom-btn success-btn">
            Submit Record
          </button>
          &nbsp;
          <button (click)="cancelAddNewLegs()" class="custom-btn dngr">
            Cancel
          </button>
          &nbsp;
        </ng-container>

        <ng-container *ngIf="addNewRecord">
          &nbsp;
          <button
            (click)="sumbitNewPortfolioRecord()"
            class="custom-btn success-btn"
          >
            Submit Record
          </button>
          &nbsp;
          <button
            (click)="cancelAddNewPortfolioRecord()"
            class="custom-btn dngr"
          >
            Cancel
          </button>
          &nbsp;
        </ng-container>

        <div style="display: inline-block" ngbDropdown>
          <button
            id="dropdownBasic2"
            class="custom-btn archive-btn"
            ngbDropdownToggle
          >
            {{ closedOpenStatus }} <i class="fa fa-caret-down"></i>
          </button>
          <div
            class="archive-dropdown"
            ngbDropdownMenu
            aria-labelledby="dropdownBasic1"
          >
            <div class="archive-btn">
              <a ngbDropdownToggle (click)="changeClosedOpenView('show_all')"
                >Show All</a
              >
            </div>
            <div class="archive-btn">
              <a ngbDropdownToggle (click)="changeClosedOpenView('show_closed')"
                >Show Closed</a
              >
            </div>
            <div class="archive-btn">
              <a ngbDropdownToggle (click)="changeClosedOpenView('show_open')"
                >Show Open</a
              >
            </div>
          </div>
        </div>

        <button (click)="exportToExcel()" class="custom-btn export-btn">
          Export to Excel
        </button>

        <input
          [disabled]="loading"
          (change)="setTVDollarDiscount($any($event.target).value)"
          type="text"
          class="tv-input"
          id="gender"
          placeholder="TV$ Discount"
        />
      </div>

      <div
        class="bubble-list"
        *ngFor="let filter of bubbleList | keyvalue: returnZero"
      >
        <app-filter-bubble
          [filterName]="filter.key"
          [filterValue]="filter.value"
        ></app-filter-bubble>
      </div>
    </div>
  </div>

  <div class="table-container">
    <ng-container *ngIf="portfolio">
      <div
        class="table-responsive"
        [ngClass]="{
          'table-responsive-statndard':
            filtersCollapsed == false && !this.fullScreenService.isFullScreen,
          'table-responsive-fullscreen':
            filtersCollapsed == false && this.fullScreenService.isFullScreen,
          'table-responsive-pagecontrols-container':
            filtersCollapsed == true && !this.fullScreenService.isFullScreen,
          'table-responsive-fullscreen-pagecontrols-container':
            filtersCollapsed == true && this.fullScreenService.isFullScreen,
          'table-responsive-pagecontrols-container-filters':
            filtersCollapsed == true &&
            !this.fullScreenService.isFullScreen &&
            bubbleList &&
            bubbleList.length > 0,
          'table-responsive-fullscreen-pagecontrols-container-filters':
            filtersCollapsed == true &&
            this.fullScreenService.isFullScreen &&
            bubbleList &&
            bubbleList.length > 0
        }"
      >
        <cdk-virtual-scroll-viewport
          itemSize="25"
          class="table-viewport"
          minBufferPx="1000"
          maxBufferPx="1000"
        >
          <div *ngIf="loading" class="spinner-container">
            <div role="status" class="spinner-border">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <table
            class="table table-striped"
            [style.visibility]="loading ? 'hidden' : 'visible'"
            cdkFixedHeader
          >
            <thead style="visibility: hidden">
              <tr>
                <th [hidden]="dateColumn.hidden">
                  <app-table-header-date
                    [loading]="loading"
                    [column]="dateColumn"
                  ></app-table-header-date>
                  <i class="fa blue-icon fa-info-circle hide-display">
                    <span class="show-display-on-hover"> Purchase Date </span>
                  </i>
                </th>
                <th [hidden]="acctColumn.hidden" class="historicalTableHead">
                  <app-table-header-filtered-list
                    [loading]="loading"
                    [tableName]="tableName"
                    [column]="acctColumn"
                  >
                  </app-table-header-filtered-list>

                  <i class="fa blue-icon fa-info-circle hide-display">
                    <span class="show-display-on-hover">
                      Trading Account holding the position
                    </span>
                  </i>
                </th>

                <th [hidden]="legColumn.hidden" class="historicalTableHead">
                  Leg
                  <i class="fa blue-icon fa-info-circle hide-display">
                    <span class="show-display-on-hover"> Stock Symbol </span>
                  </i>
                </th>

                <th [hidden]="tickerColumn.hidden" class="historicalTableHead">
                  <app-table-header-filtered-list
                    [column]="tickerColumn"
                    [loading]="loading"
                    [tableName]="tableName"
                  ></app-table-header-filtered-list>

                  <i class="fa blue-icon fa-info-circle hide-display">
                    <span class="show-display-on-hover"> Stock Symbol </span>
                  </i>
                </th>

                <th [hidden]="daysColumn.hidden" class="historicalTableHead">
                  <app-table-header
                    [column]="daysColumn"
                    [loading]="loading"
                  ></app-table-header>

                  <i class="fa blue-icon fa-info-circle hide-display">
                    <span class="show-display-on-hover">
                      Number of days until expiration
                    </span>
                  </i>
                </th>

                <th
                  [hidden]="expirationColumn.hidden"
                  class="historicalTableHead"
                >
                  <app-table-header-filtered-list
                    [column]="expirationColumn"
                    [loading]="loading"
                    [tableName]="tableName"
                  ></app-table-header-filtered-list>

                  <i class="fa blue-icon fa-info-circle hide-display">
                    <span class="show-display-on-hover"> Stock Symbol </span>
                  </i>
                </th>

                <th
                  [hidden]="quantityColumn.hidden"
                  class="historicalTableHead"
                >
                  <app-table-header
                    [column]="quantityColumn"
                    [loading]="loading"
                  ></app-table-header>

                  <i class="fa blue-icon fa-info-circle hide-display">
                    <span class="show-display-on-hover">
                      Number of days until expiration
                    </span>
                  </i>
                </th>

                <th [hidden]="strikeColumn.hidden" class="historicalTableHead">
                  <app-table-header
                    [column]="strikeColumn"
                    [loading]="loading"
                  ></app-table-header>

                  <i class="fa blue-icon fa-info-circle hide-display">
                    <span class="show-display-on-hover">
                      Number of days until expiration
                    </span>
                  </i>
                </th>

                <th
                  [hidden]="optionTypeColumn.hidden"
                  class="historicalTableHead"
                >
                  <app-table-header-filtered-list
                    [column]="optionTypeColumn"
                    [tableName]="tableName"
                    [loading]="loading"
                  ></app-table-header-filtered-list>

                  <i class="fa blue-icon fa-info-circle hide-display">
                    <span class="show-display-on-hover">
                      Number of days until expiration
                    </span>
                  </i>
                </th>

                <th [hidden]="UDLColumn.hidden" class="historicalTableHead">
                  <app-table-header
                    [column]="UDLColumn"
                    [loading]="loading"
                  ></app-table-header>

                  <i class="fa blue-icon fa-info-circle hide-display">
                    <span class="show-display-on-hover">
                      Number of days until expiration
                    </span>
                  </i>
                </th>

                <th [hidden]="ITMcurColumn.hidden" class="historicalTableHead">
                  <app-table-header
                    [column]="ITMcurColumn"
                    [loading]="loading"
                  ></app-table-header>
                  <i class="fa blue-icon fa-info-circle hide-display">
                    <span class="show-display-on-hover">
                      Number of days until expiration
                    </span>
                  </i>
                </th>

                <th [hidden]="moveColumn.hidden" class="historicalTableHead">
                  <app-table-header
                    [column]="moveColumn"
                    [loading]="loading"
                  ></app-table-header>

                  <i class="fa blue-icon fa-info-circle hide-display">
                    <span class="show-display-on-hover">
                      Number of days until expiration
                    </span>
                  </i>
                </th>

                <th [hidden]="costColumn.hidden" class="historicalTableHead">
                  <app-table-header
                    [column]="costColumn"
                    [loading]="loading"
                  ></app-table-header>

                  <i class="fa blue-icon fa-info-circle hide-display">
                    <span class="show-display-on-hover">
                      Number of days until expiration
                    </span>
                  </i>
                </th>

                <th [hidden]="priceColumn.hidden" class="historicalTableHead">
                  <app-table-header
                    [column]="priceColumn"
                    [loading]="loading"
                  ></app-table-header>

                  <i class="fa blue-icon fa-info-circle hide-display">
                    <span class="show-display-on-hover">
                      Number of days until expiration
                    </span>
                  </i>
                </th>

                <th
                  [hidden]="percentGainColumn.hidden"
                  class="historicalTableHead"
                >
                  <app-table-header
                    [column]="percentGainColumn"
                    [loading]="loading"
                  ></app-table-header>

                  <i class="fa blue-icon fa-info-circle hide-display">
                    <span class="show-display-on-hover">
                      Number of days until expiration
                    </span>
                  </i>
                </th>

                <th [hidden]="maxGainColumn.hidden" class="historicalTableHead">
                  <app-table-header
                    [column]="maxGainColumn"
                    [loading]="loading"
                  ></app-table-header>

                  <i class="fa blue-icon fa-info-circle hide-display">
                    <span class="show-display-on-hover">
                      Number of days until expiration
                    </span>
                  </i>
                </th>

                <th
                  [hidden]="percentMaxColumn.hidden"
                  class="historicalTableHead"
                >
                  <app-table-header
                    [column]="percentMaxColumn"
                    [loading]="loading"
                  ></app-table-header>

                  <i class="fa blue-icon fa-info-circle hide-display">
                    <span class="show-display-on-hover">
                      Number of days until expiration
                    </span>
                  </i>
                </th>

                <th [hidden]="exitColumn.hidden" class="historicalTableHead">
                  <app-table-header
                    [column]="exitColumn"
                    [loading]="loading"
                  ></app-table-header>

                  <i class="fa blue-icon fa-info-circle hide-display">
                    <span class="show-display-on-hover">
                      Number of days until expiration
                    </span>
                  </i>
                </th>

                <th
                  [hidden]="exitPriceColumn.hidden"
                  class="historicalTableHead"
                >
                  <app-table-header
                    [column]="exitPriceColumn"
                    [loading]="loading"
                  ></app-table-header>

                  <i class="fa blue-icon fa-info-circle hide-display">
                    <span class="show-display-on-hover">
                      Number of days until expiration
                    </span>
                  </i>
                </th>

                <th [hidden]="notesColumn.hidden" class="historicalTableHead">
                  <app-table-header
                    [column]="notesColumn"
                    [loading]="loading"
                  ></app-table-header>

                  <i class="fa blue-icon fa-info-circle hide-display">
                    <span class="show-display-on-hover"> Stock Symbol </span>
                  </i>
                </th>

                <th
                  [hidden]="strategyColumn.hidden"
                  class="historicalTableHead"
                >
                  <app-table-header-filtered-list
                    [column]="strategyColumn"
                    [tableName]="tableName"
                    [loading]="loading"
                  ></app-table-header-filtered-list>

                  <i class="fa blue-icon fa-info-circle hide-display">
                    <span class="show-display-on-hover"> Stock Symbol </span>
                  </i>
                </th>

                <th [hidden]="typeColumn.hidden" class="historicalTableHead">
                  <app-table-header-filtered-list
                    [column]="typeColumn"
                    [tableName]="tableName"
                    [loading]="loading"
                  ></app-table-header-filtered-list>

                  <i class="fa blue-icon fa-info-circle hide-display">
                    <span class="show-display-on-hover"> Stock Symbol </span>
                  </i>
                </th>

                <th
                  [hidden]="categoryColumn.hidden"
                  class="historicalTableHead"
                >
                  <app-table-header-filtered-list
                    [column]="categoryColumn"
                    [tableName]="tableName"
                    [loading]="loading"
                  ></app-table-header-filtered-list>

                  <i class="fa blue-icon fa-info-circle hide-display">
                    <span class="show-display-on-hover"> Stock Symbol </span>
                  </i>
                </th>

                <th
                  [hidden]="exposureCostColumn.hidden"
                  class="historicalTableHead"
                >
                  <app-table-header
                    [column]="exposureCostColumn"
                    [loading]="loading"
                  ></app-table-header>

                  <i class="fa blue-icon fa-info-circle hide-display">
                    <span class="show-display-on-hover">
                      Number of days until expiration
                    </span>
                  </i>
                </th>

                <th
                  [hidden]="exposureCurValColumn.hidden"
                  class="historicalTableHead"
                >
                  <app-table-header
                    [column]="exposureCurValColumn"
                    [loading]="loading"
                  ></app-table-header>

                  <i class="fa blue-icon fa-info-circle hide-display">
                    <span class="show-display-on-hover">
                      Number of days until expiration
                    </span>
                  </i>
                </th>

                <th [hidden]="$GainColumn.hidden" class="historicalTableHead">
                  <app-table-header
                    [column]="$GainColumn"
                    [loading]="loading"
                  ></app-table-header>

                  <i class="fa blue-icon fa-info-circle hide-display">
                    <span class="show-display-on-hover">
                      Number of days until expiration
                    </span>
                  </i>
                </th>

                <th
                  [hidden]="percentOfFVColumn.hidden"
                  class="historicalTableHead"
                >
                  <app-table-header
                    [column]="percentOfFVColumn"
                    [loading]="loading"
                  ></app-table-header>

                  <i class="fa blue-icon fa-info-circle hide-display">
                    <span class="show-display-on-hover">
                      Number of days until expiration
                    </span>
                  </i>
                </th>

                <th
                  [hidden]="priceAtFillColumn.hidden"
                  class="historicalTableHead"
                >
                  <app-table-header
                    [column]="priceAtFillColumn"
                    [loading]="loading"
                  ></app-table-header>

                  <i class="fa blue-icon fa-info-circle hide-display">
                    <span class="show-display-on-hover">
                      Number of days until expiration
                    </span>
                  </i>
                </th>

                <th [hidden]="paidColumn.hidden" class="historicalTableHead">
                  <app-table-header
                    [column]="paidColumn"
                    [loading]="loading"
                  ></app-table-header>

                  <i class="fa blue-icon fa-info-circle hide-display">
                    <span class="show-display-on-hover">
                      Number of days until expiration
                    </span>
                  </i>
                </th>

                <th [hidden]="bidColumn.hidden" class="historicalTableHead">
                  <app-table-header
                    [column]="bidColumn"
                    [loading]="loading"
                  ></app-table-header>

                  <i class="fa blue-icon fa-info-circle hide-display">
                    <span class="show-display-on-hover">
                      Number of days until expiration
                    </span>
                  </i>
                </th>

                <th [hidden]="midColumn.hidden" class="historicalTableHead">
                  <app-table-header
                    [column]="midColumn"
                    [loading]="loading"
                  ></app-table-header>

                  <i class="fa blue-icon fa-info-circle hide-display">
                    <span class="show-display-on-hover">
                      Number of days until expiration
                    </span>
                  </i>
                </th>

                <th [hidden]="askColumn.hidden" class="historicalTableHead">
                  <app-table-header
                    [column]="askColumn"
                    [loading]="loading"
                  ></app-table-header>

                  <i class="fa blue-icon fa-info-circle hide-display">
                    <span class="show-display-on-hover">
                      Number of days until expiration
                    </span>
                  </i>
                </th>

                <th
                  [hidden]="totalPaidColumn.hidden"
                  class="historicalTableHead"
                >
                  <app-table-header
                    [column]="totalPaidColumn"
                    [loading]="loading"
                  ></app-table-header>

                  <i class="fa blue-icon fa-info-circle hide-display">
                    <span class="show-display-on-hover">
                      Number of days until expiration
                    </span>
                  </i>
                </th>

                <th [hidden]="valueColumn.hidden" class="historicalTableHead">
                  <app-table-header
                    [column]="valueColumn"
                    [loading]="loading"
                  ></app-table-header>

                  <i class="fa blue-icon fa-info-circle hide-display">
                    <span class="show-display-on-hover">
                      Number of days until expiration
                    </span>
                  </i>
                </th>

                <th
                  [hidden]="totalGainColumn.hidden"
                  class="historicalTableHead"
                >
                  <app-table-header
                    [column]="totalGainColumn"
                    [loading]="loading"
                  ></app-table-header>

                  <i class="fa blue-icon fa-info-circle hide-display">
                    <span class="show-display-on-hover">
                      Number of days until expiration
                    </span>
                  </i>
                </th>

                <th
                  [hidden]="minCashValueColumn.hidden"
                  class="historicalTableHead"
                >
                  <app-table-header
                    [column]="minCashValueColumn"
                    [loading]="loading"
                  ></app-table-header>

                  <i class="fa blue-icon fa-info-circle hide-display">
                    <span class="show-display-on-hover">
                      Number of days until expiration
                    </span>
                  </i>
                </th>

                <th
                  [hidden]="maxValueColumn.hidden"
                  class="historicalTableHead"
                >
                  <app-table-header
                    [column]="maxValueColumn"
                    [loading]="loading"
                  ></app-table-header>

                  <i class="fa blue-icon fa-info-circle hide-display">
                    <span class="show-display-on-hover">
                      Number of days until expiration
                    </span>
                  </i>
                </th>

                <th
                  [hidden]="targetValueColumn.hidden"
                  class="historicalTableHead"
                >
                  <app-table-header
                    [column]="targetValueColumn"
                    [loading]="loading"
                  ></app-table-header>

                  <i class="fa blue-icon fa-info-circle hide-display">
                    <span class="show-display-on-hover">
                      Number of days until expiration
                    </span>
                  </i>
                </th>

                <th
                  [hidden]="targetProfitColumn.hidden"
                  class="historicalTableHead"
                >
                  <app-table-header
                    [column]="targetProfitColumn"
                    [loading]="loading"
                  ></app-table-header>

                  <i class="fa blue-icon fa-info-circle hide-display">
                    <span class="show-display-on-hover">
                      Number of days until expiration
                    </span>
                  </i>
                </th>

                <th
                  [hidden]="paidExposureDashboardColumn.hidden"
                  class="historicalTableHead"
                >
                  <app-table-header
                    [column]="paidExposureDashboardColumn"
                    [loading]="loading"
                  >
                  </app-table-header>

                  <i class="fa blue-icon fa-info-circle hide-display">
                    <span class="show-display-on-hover">
                      Number of days until expiration
                    </span>
                  </i>
                </th>

                <th
                  [hidden]="maxValueExposureDashboardColumn.hidden"
                  class="historicalTableHead"
                >
                  <app-table-header
                    [column]="maxValueExposureDashboardColumn"
                    [loading]="loading"
                  >
                  </app-table-header>

                  <i class="fa blue-icon fa-info-circle hide-display">
                    <span class="show-display-on-hover">
                      Number of days until expiration
                    </span>
                  </i>
                </th>
                <th [hidden]="ITM100Column.hidden" class="historicalTableHead">
                  <app-table-header
                    [column]="ITM100Column"
                    [loading]="loading"
                  ></app-table-header>

                  <i class="fa blue-icon fa-info-circle hide-display">
                    <span class="show-display-on-hover">
                      Number of days until expiration
                    </span>
                  </i>
                </th>
                <th
                  [hidden]="ITMNeg25Column.hidden"
                  class="historicalTableHead"
                >
                  <app-table-header
                    [column]="ITMNeg25Column"
                    [loading]="loading"
                  ></app-table-header>

                  <i class="fa blue-icon fa-info-circle hide-display">
                    <span class="show-display-on-hover">
                      Number of days until expiration
                    </span>
                  </i>
                </th>
                <th
                  [hidden]="ITMNeg25to0Column.hidden"
                  class="historicalTableHead"
                >
                  <app-table-header
                    [column]="ITMNeg25to0Column"
                    [loading]="loading"
                  ></app-table-header>

                  <i class="fa blue-icon fa-info-circle hide-display">
                    <span class="show-display-on-hover">
                      Number of days until expiration
                    </span>
                  </i>
                </th>
                <th
                  [hidden]="ITM0to25Column.hidden"
                  class="historicalTableHead"
                >
                  <app-table-header
                    [column]="ITM0to25Column"
                    [loading]="loading"
                  ></app-table-header>

                  <i class="fa blue-icon fa-info-circle hide-display">
                    <span class="show-display-on-hover">
                      Number of days until expiration
                    </span>
                  </i>
                </th>
                <th [hidden]="ITM25Column.hidden" class="historicalTableHead">
                  <app-table-header
                    [column]="ITM25Column"
                    [loading]="loading"
                  ></app-table-header>

                  <i class="fa blue-icon fa-info-circle hide-display">
                    <span class="show-display-on-hover">
                      Number of days until expiration
                    </span>
                  </i>
                </th>
                <th
                  [hidden]="useTableColumn.hidden"
                  class="historicalTableHead"
                >
                  <app-table-header-filtered-list
                    [column]="useTableColumn"
                    [tableName]="tableName"
                    [loading]="loading"
                  ></app-table-header-filtered-list>

                  <i class="fa blue-icon fa-info-circle hide-display">
                    <span class="show-display-on-hover"> Stock Symbol </span>
                  </i>
                </th>
                <th
                  [hidden]="portfolioIdColumn.hidden"
                  class="historicalTableHead"
                >
                  <app-table-header
                    [column]="portfolioIdColumn"
                    [loading]="loading"
                  ></app-table-header>

                  <i class="fa blue-icon fa-info-circle hide-display">
                    <span class="show-display-on-hover">
                      Number of days until expiration
                    </span>
                  </i>
                </th>
              </tr>
            </thead>

            <tbody class="portfolio-table-data">
              <ng-container *ngIf="addNewRecord">
                <tr
                  *ngFor="
                    let row of numNewRows | times;
                    last as isLast;
                    first as isFirst;
                    let i = index
                  "
                  style="background-color: white !important"
                >
                  <ng-container>
                    <!-- <td >{{leg.purchased_at}}</td> -->
                    <td>
                      <!-- <div class="dropdown">
                                    <div>
                                        <input class="form-control" placeholder="mm-dd-yyyy" #date value="{{exactDate}}"
                                        name="dp" ngbDatepicker #d1="ngbDatepicker" >
                                    </div>
                                    <div class="input-group-append">
                                        <button class="date btn-outline-secondary calendar fa fa-calendar" (click)="d1.toggle()" type="button" value="{{exactDate}}"></button>
                                    </div>
                                    </div> -->
                      <input
                        [ngClass]="{
                          required:
                            requiredPortfolioData[row]?.purchased_at == true
                        }"
                        class="new-standard-input-area"
                        (change)="changeInput($event, row)"
                        maxlength="10"
                        name="purchased_at"
                        placeholder="Purchased At YYYY/MM/DD"
                      />
                      <!-- onkeyup="this.value=this.value.replace(/^(\d\d)(\d)$/g,'$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g,'$1/$2').replace(/[^\d\/]/g,'')"-->
                    </td>
                    <td>
                      <select
                        *ngIf="isFirst"
                        [ngClass]="{
                          required:
                            requiredPortfolioData[row]?.trade_account_id == true
                        }"
                        (click)="changeInput($event, row)"
                        name="trade_account_id"
                      >
                        <option
                          value="{{
                            requiredPortfolioData[row]?.trade_account_id
                          }}"
                          disabled
                          selected
                          hidden
                        >
                          Select
                        </option>
                        <option
                          *ngFor="let tradeAcct of tradeAccts"
                          value="{{ tradeAcct.id }}"
                        >
                          {{ tradeAcct.name }}
                        </option>
                      </select>
                    </td>
                    <td class="add-rmv-btns">
                      <ng-container *ngIf="isLast">
                        <button
                          class="custom-btn add-btn"
                          (click)="addLeg(row)"
                        >
                          <i class="fa fa-plus"></i>
                        </button>
                        <button
                          *ngIf="!isFirst"
                          class="custom-btn rmv-btn"
                          (click)="removeLeg()"
                        >
                          <i class="fa fa-minus"></i>
                        </button>
                      </ng-container>
                    </td>
                    <td>
                      <input
                        *ngIf="isFirst"
                        [ngClass]="{
                          required: requiredPortfolioData[row]?.symbol == true
                        }"
                        class="new-standard-input-area"
                        placeholder="Symbol"
                        (change)="changeInput($event, row)"
                        type="text"
                        name="symbol"
                        placeholder="Symbol"
                      />
                    </td>
                    <td></td>
                    <td>
                      <input
                        [ngClass]="{
                          required:
                            requiredPortfolioData[row]?.expiration == true
                        }"
                        class="new-standard-input-area"
                        placeholder="Expiration Date YYYY/MM/DD"
                        (change)="changeInput($event, row)"
                        maxlength="10"
                        type="text"
                        name="expiration"
                      />
                      <!-- onkeyup="this.value=this.value.replace(/^(\d\d)(\d)$/g,'$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g,'$1/$2').replace(/[^\d\/]/g,'')" -->
                    </td>
                    <td>
                      <input
                        [ngClass]="{
                          required: requiredPortfolioData[row]?.quantity == true
                        }"
                        class="new-standard-input-area"
                        placeholder="Quantity"
                        (change)="changeInput($event, row)"
                        type="text"
                        name="quantity"
                      />
                    </td>
                    <td>
                      <input
                        [ngClass]="{
                          required: requiredPortfolioData[row]?.strike == true
                        }"
                        class="new-standard-input-area"
                        placeholder="Strike"
                        (change)="changeInput($event, row)"
                        type="text"
                        name="strike"
                      />
                    </td>
                    <td>
                      <select
                        [ngClass]="{
                          required:
                            requiredPortfolioData[row]?.security_type == true
                        }"
                        (click)="changeInput($event, row)"
                        name="security_type"
                      >
                        <option value="null" disabled selected hidden>
                          Select
                        </option>
                        <option>Call</option>
                        <option>Put</option>
                        <option>Stock</option>
                      </select>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      <input
                        [ngClass]="{
                          required: requiredPortfolioData[row]?.exit == true
                        }"
                        class="new-standard-input-area"
                        placeholder="Select/Input"
                        (change)="changeInput($event, row)"
                        list="exit"
                        name="exit"
                      />
                      <datalist id="exit">
                        <option value="Default"></option>
                        <option value="Manual"></option>
                      </datalist>
                    </td>
                    <td>
                      <textarea
                        class="new-text-area"
                        (change)="changeInput($event, row)"
                        placeholder="Notes"
                        type="text"
                        name="notes"
                      ></textarea>
                    </td>
                    <td>
                      <select
                        [ngClass]="{
                          required:
                            requiredPortfolioData[row]?.trade_strategy_id ==
                            true
                        }"
                        [(ngModel)]="selectedStrat"
                        (click)="changeInput($event, row)"
                        name="strategy_id"
                      >
                        <option value="undefined" disabled selected hidden>
                          Select
                        </option>
                        <option
                          *ngFor="let strat of strategies"
                          value="{{ strat.id }}"
                        >
                          {{ strat.name }}
                        </option>
                      </select>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      <input
                        [ngClass]="{
                          required:
                            requiredPortfolioData[row]?.price_at_fill == true
                        }"
                        class="new-standard-input-area"
                        name="price_at_fill"
                        placeholder="Price @ Fill"
                        (change)="changeInput($event, row)"
                        type="text"
                      />
                    </td>
                    <td>
                      <input
                        [ngClass]="{
                          required: requiredPortfolioData[row]?.paid == true
                        }"
                        class="new-standard-input-area"
                        name="paid"
                        placeholder="Paid"
                        (change)="changeInput($event, row)"
                        type="text"
                      />
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </ng-container>
                </tr>
              </ng-container>
              <ng-container
                *cdkVirtualFor="
                  let portfolioLeg of portfolio;
                  let j = index;
                  templateCacheSize: 50
                "
              >
                <tr
                  [ngStyle]="{
                    'background-color': portfolioLeg.backgroundColor
                  }"
                  (contextmenu)="onRightClick($event, portfolioLeg); (false)"
                >
                  <ng-container *ngIf="!portfolioLeg.editpurchased_at">
                    <td
                      [hidden]="dateColumn.hidden"
                      [ngClass]="{
                        err: portfolioLeg.purchased_at_err == true
                      }"
                      (click)="editCell($event, 'purchased_at', portfolioLeg)"
                      class="numDataCell text-align-center highlight-cell-on-hover"
                    >
                      {{ portfolioLeg.purchased_at | date: "mediumDate" }}
                    </td>
                  </ng-container>
                  <ng-container *ngIf="portfolioLeg.editpurchased_at">
                    <td
                      [hidden]="dateColumn.hidden"
                      [ngClass]="{
                        err: portfolioLeg.purchased_at_err == true
                      }"
                    >
                      <input
                        size="10"
                        maxlength="10"
                        class="floating-input-area date-input"
                        type="text"
                        value="{{ portfolioLeg.purchased_at }}"
                        (change)="
                          saveDateCell(
                            'purchased_at',
                            $any($event.target).value,
                            portfolioLeg.portfolio_leg_id
                          )
                        "
                      />
                    </td>
                  </ng-container>
                  <td
                    id="{{ portfolioLeg.trade_account_name }}"
                    [hidden]="acctColumn.hidden"
                  >
                    <ng-container *ngIf="portfolioLeg.firstLegDisplayed">
                      <select
                        (change)="
                          saveDropdownCell(
                            'trade_account_id',
                            $any($event.target).value,
                            portfolioLeg.portfolio_item_id
                          )
                        "
                        name="trade_account_id"
                      >
                        <option value="null" disabled selected hidden>
                          {{ portfolioLeg.trade_account_name }}
                        </option>
                        <option
                          *ngFor="let tradeAcct of tradeAccts"
                          value="{{ tradeAcct.id }}"
                        >
                          {{ tradeAcct.name }}
                        </option>
                      </select>
                    </ng-container>
                  </td>
                  <td [hidden]="legColumn.hidden" class="text-align-center">
                    {{ portfolioLeg.leg_num }}
                  </td>
                  <ng-container *ngIf="portfolioLeg.firstLegDisplayed">
                    <ng-container *ngIf="!portfolioLeg.editsymbol">
                      <td
                        [hidden]="tickerColumn.hidden"
                        (click)="editCell($event, 'symbol', portfolioLeg)"
                        class="numDataCell text-align-center highlight-cell-on-hover"
                      >
                        {{ portfolioLeg.symbol }}
                      </td>
                    </ng-container>
                    <ng-container *ngIf="portfolioLeg.editsymbol">
                      <td [hidden]="tickerColumn.hidden">
                        <input
                          class="floating-input-area"
                          type="text"
                          value="{{ portfolioLeg.symbol }}"
                          (change)="
                            saveTextCell(
                              'symbol',
                              $any($event.target).value,
                              portfolioLeg.portfolio_item_id
                            )
                          "
                        />
                      </td>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="!portfolioLeg.firstLegDisplayed">
                    <td [hidden]="tickerColumn.hidden"></td>
                  </ng-container>
                  <td
                    id="leg:{{ j }}:expiration_days_away:{{
                      portfolioLeg.expiration_days_away
                    }}:format:num"
                    [hidden]="daysColumn.hidden"
                    class="text-align-center"
                  >
                    {{ portfolioLeg.expiration_days_away }}
                  </td>
                  <ng-container *ngIf="!portfolioLeg.editexpiration">
                    <td
                      [hidden]="expirationColumn.hidden"
                      [ngClass]="{
                        err: portfolioLeg.expiration_err == true
                      }"
                      (click)="editCell($event, 'expiration', portfolioLeg)"
                      class="numDataCell text-align-center highlight-cell-on-hover"
                    >
                      {{ portfolioLeg.expiration_date | date: "mediumDate" }}
                    </td>
                  </ng-container>
                  <ng-container *ngIf="portfolioLeg.editexpiration">
                    <td
                      [hidden]="expirationColumn.hidden"
                      [ngClass]="{
                        err: portfolioLeg.expiration_err == true
                      }"
                    >
                      <input
                        size="10"
                        maxlength="10"
                        class="floating-input-area date-input"
                        type="text"
                        value="{{
                          portfolioLeg.expiration_date | date: 'shortDate'
                        }}"
                        (change)="
                          saveDateCell(
                            'expiration',
                            $any($event.target).value,
                            portfolioLeg.portfolio_leg_id
                          )
                        "
                      />
                    </td>
                  </ng-container>
                  <ng-container *ngIf="!portfolioLeg.editquantity">
                    <td
                      id="leg:{{ j }}:quantity:{{
                        portfolioLeg.quantity
                      }}:format:num"
                      [hidden]="quantityColumn.hidden"
                      [ngClass]="{
                        err: portfolioLeg.quantity_err == true
                      }"
                      (click)="editCell($event, 'quantity', portfolioLeg)"
                      class="numDataCell text-align-center highlight-cell-on-hover"
                    >
                      {{ portfolioLeg.quantity }}
                    </td>
                  </ng-container>
                  <ng-container *ngIf="portfolioLeg.editquantity">
                    <td
                      [hidden]="quantityColumn.hidden"
                      [ngClass]="{
                        err: portfolioLeg.quantity_err == true
                      }"
                    >
                      <input
                        class="floating-input-area"
                        type="text"
                        value="{{ portfolioLeg.quantity }}"
                        (change)="
                          saveNumericalCell(
                            'quantity',
                            $any($event.target).value,
                            portfolioLeg.portfolio_leg_id
                          )
                        "
                      />
                    </td>
                  </ng-container>
                  <ng-container *ngIf="!portfolioLeg.editstrike">
                    <td
                      id="leg:{{ j }}:strike:{{ portfolioLeg.strike }}:format:$"
                      [hidden]="strikeColumn.hidden"
                      [ngClass]="{
                        err: portfolioLeg.strike_err == true
                      }"
                      (click)="editCell($event, 'strike', portfolioLeg)"
                      class="numDataCell usd highlight-cell-on-hover"
                    >
                      ${{ portfolioLeg.strike | number: "1.2-2" }}
                    </td>
                  </ng-container>
                  <ng-container *ngIf="portfolioLeg.editstrike">
                    <td
                      id="leg:{{ j }}:strike:{{ portfolioLeg.strike }}"
                      [hidden]="strikeColumn.hidden"
                      [ngClass]="{
                        err: portfolioLeg.strike_err == true
                      }"
                    >
                      <input
                        class="floating-input-area"
                        type="text"
                        value="{{ portfolioLeg.strike }}"
                        (change)="
                          saveNumericalCell(
                            'strike',
                            $any($event.target).value,
                            portfolioLeg.portfolio_leg_id
                          )
                        "
                      />
                    </td>
                  </ng-container>
                  <td [hidden]="optionTypeColumn.hidden">
                    <ng-container>
                      <select
                        class="option-type-dropdown"
                        (change)="
                          saveDropdownCell(
                            'security_type',
                            $any($event.target).value,
                            portfolioLeg.portfolio_leg_id
                          )
                        "
                        name="trade_account_id"
                      >
                        <option value="null" disabled selected hidden>
                          {{ portfolioLeg.security_type }}
                        </option>
                        <option>CALL</option>
                        <option>PUT</option>
                        <option>STOCK</option>
                      </select>
                    </ng-container>
                  </td>
                  <td
                    id="leg:{{ j }}:udl:{{ portfolioLeg.udl }}:format:$"
                    [hidden]="UDLColumn.hidden"
                    class="usd txt-bold"
                  >
                    ${{ portfolioLeg.udl | number: "1.2-2" }}
                  </td>
                  <td
                    [ngClass]="{
                      'txt-green': portfolioLeg.itm_curr > 0,
                      'txt-red': portfolioLeg.itm_curr < 0
                    }"
                    id="leg:{{ j }}:itm_curr:{{
                      portfolioLeg.itm_curr
                    }}:format:%"
                    [hidden]="ITMcurColumn.hidden"
                    class="percent"
                  >
                    <ng-container
                      *ngIf="
                        portfolioLeg.leg_num == 2 &&
                        portfolioLeg.is_manual == null &&
                        portfolioLeg.security_type != 'STOCK'
                      "
                    >
                      {{ portfolioLeg.itm_curr | number: "1.1-1" }}%
                    </ng-container>
                    <ng-container
                      *ngIf="
                        portfolioLeg.is_manual != null ||
                        portfolioLeg.security_type == 'STOCK'
                      "
                    >
                      {{ portfolioLeg.itm_curr | number: "1.1-1" }}%
                    </ng-container>
                  </td>
                  <td
                    id="leg:{{ j }}:move:{{ portfolioLeg.move }}:format:%"
                    [hidden]="moveColumn.hidden"
                    class="percent"
                  >
                    <ng-container
                      *ngIf="
                        portfolioLeg.leg_num == 1 &&
                        portfolioLeg.is_manual == null &&
                        portfolioLeg.security_type != 'STOCK'
                      "
                    >
                      {{ portfolioLeg.move | number: "1.0-0" }}%
                    </ng-container>
                    <ng-container
                      *ngIf="
                        portfolioLeg.is_manual != null ||
                        portfolioLeg.security_type == 'STOCK'
                      "
                    >
                      {{ portfolioLeg.move | number: "1.0-0" }}%
                    </ng-container>
                  </td>
                  <td
                    id="leg:{{ j }}:cost:{{ portfolioLeg.cost }}:format:$"
                    [hidden]="costColumn.hidden"
                    class="usd"
                  >
                    <ng-container
                      *ngIf="
                        portfolioLeg.leg_num == 1 &&
                        portfolioLeg.is_manual == null &&
                        portfolioLeg.security_type != 'STOCK'
                      "
                    >
                      ${{ portfolioLeg.cost | number: "1.2-2" }}
                    </ng-container>
                    <ng-container
                      *ngIf="
                        portfolioLeg.is_manual != null ||
                        portfolioLeg.security_type == 'STOCK'
                      "
                    >
                      ${{ portfolioLeg.cost | number: "1.2-2" }}
                    </ng-container>
                  </td>
                  <td
                    id="leg:{{ j }}:price:{{ portfolioLeg.price }}:format:$"
                    [hidden]="priceColumn.hidden"
                    class="usd"
                  >
                    <ng-container
                      *ngIf="
                        portfolioLeg.leg_num == 1 &&
                        portfolioLeg.is_manual == null &&
                        portfolioLeg.security_type != 'STOCK'
                      "
                    >
                      ${{ portfolioLeg.price | number: "1.2-2" }}
                    </ng-container>
                    <ng-container
                      *ngIf="
                        portfolioLeg.is_manual != null ||
                        portfolioLeg.security_type == 'STOCK'
                      "
                    >
                      ${{ portfolioLeg.price | number: "1.2-2" }}
                    </ng-container>
                  </td>
                  <td
                    [ngClass]="{
                      'highlight-green':
                        portfolioLeg.gain_percent >= 100 &&
                        portfolioLeg.leg_num == 1,
                      'highlight-red':
                        portfolioLeg.gain_percent <= 50 &&
                        portfolioLeg.leg_num == 1
                    }"
                    id="leg:{{ j }}:gain_percent:{{
                      portfolioLeg.gain_percent
                    }}:format:%"
                    [hidden]="percentGainColumn.hidden"
                    class="percent"
                  >
                    <ng-container
                      *ngIf="
                        portfolioLeg.leg_num == 1 &&
                        portfolioLeg.is_manual == null &&
                        portfolioLeg.security_type != 'STOCK'
                      "
                    >
                      {{ portfolioLeg.gain_percent | number: "1.0-0" }}%
                    </ng-container>
                    <ng-container
                      *ngIf="
                        portfolioLeg.is_manual != null ||
                        portfolioLeg.security_type == 'STOCK'
                      "
                    >
                      {{ portfolioLeg.gain_percent | number: "1.0-0" }}%
                    </ng-container>
                  </td>
                  <td
                    id="leg:{{ j }}:gain_max:{{
                      portfolioLeg.gain_max
                    }}:format:%"
                    [hidden]="maxGainColumn.hidden"
                    class="usd"
                  >
                    <ng-container *ngIf="portfolioLeg.leg_num == 1">
                      {{ portfolioLeg.gain_max | number: "1.0-0" }}%
                    </ng-container>
                  </td>
                  <td
                    [ngClass]="{
                      'highlight-green':
                        portfolioLeg.max_percent >= 75 &&
                        portfolioLeg.leg_num == 1
                    }"
                    id="leg:{{ j }}:max_percent:{{
                      portfolioLeg.max_percent
                    }}:format:%"
                    [hidden]="percentMaxColumn.hidden"
                    class="percent"
                  >
                    <ng-container *ngIf="portfolioLeg.leg_num == 1">
                      {{ portfolioLeg.max_percent | number: "1.0-0" }}%
                    </ng-container>
                  </td>
                  <td
                    [ngClass]="{
                      'highlight-yellow':
                        portfolioLeg.exit == 'MANUAL' &&
                        portfolioLeg.leg_num == 1
                    }"
                    id="leg:{{ j }}:exit:{{ portfolioLeg.exit }}"
                    [hidden]="exitColumn.hidden"
                    [ngClass]="{
                      err: portfolioLeg.exit_err == true
                    }"
                  >
                    <ng-container *ngIf="portfolioLeg.leg_num == 1">
                      <input
                        *ngIf="
                          portfolioLeg.exit != 'DEFAULT' &&
                          portfolioLeg.exit != 'MANUAL' &&
                          portfolioLeg.exit != null
                        "
                        (change)="
                          saveExit(
                            $any($event.target).value,
                            portfolioLeg.portfolio_leg_id
                          )
                        "
                        value="{{ portfolioLeg.exit }}%"
                        list="exit"
                        name="ExitSelect"
                        class="exit"
                      />
                      <input
                        *ngIf="
                          portfolioLeg.exit == 'DEFAULT' ||
                          portfolioLeg.exit == 'MANUAL' ||
                          portfolioLeg.exit == null
                        "
                        (change)="
                          saveExit(
                            $any($event.target).value,
                            portfolioLeg.portfolio_leg_id
                          )
                        "
                        value="{{ portfolioLeg.exit }}"
                        list="exit"
                        name="ExitSelect"
                        class="exit"
                      />
                      <datalist id="exit">
                        <option value="DEFAULT"></option>
                        <option value="MANUAL"></option>
                      </datalist>
                    </ng-container>
                  </td>
                  <ng-container *ngIf="portfolioLeg.exit != 'MANUAL'">
                    <td
                      id="leg:{{ j }}:exit_price:{{
                        portfolioLeg.exit_price
                      }}:format:$"
                      [hidden]="exitPriceColumn.hidden"
                      class="usd"
                    >
                      <ng-container *ngIf="portfolioLeg.leg_num == 1">
                        ${{ portfolioLeg.exit_price | number: "1.2-2" }}
                      </ng-container>
                    </td>
                  </ng-container>
                  <ng-container *ngIf="portfolioLeg.exit == 'MANUAL'">
                    <ng-container *ngIf="!portfolioLeg.editexit_price">
                      <td
                        id="leg:{{ j }}:exit_price:{{
                          portfolioLeg.exit_price
                        }}:format:$"
                        [hidden]="exitPriceColumn.hidden"
                        [ngClass]="{
                          err: portfolioLeg.exit_price_err == true
                        }"
                        (click)="editCell($event, 'exit_price', portfolioLeg)"
                        class="numDataCell usd highlight-cell-on-hover"
                      >
                        <ng-container *ngIf="portfolioLeg.leg_num == 1">
                          ${{ portfolioLeg.exit_price | number: "1.2-2" }}
                        </ng-container>
                      </td>
                    </ng-container>
                    <ng-container *ngIf="portfolioLeg.editexit_price">
                      <td
                        [hidden]="exitPriceColumn.hidden"
                        [ngClass]="{
                          err: portfolioLeg.exit_price_err == true
                        }"
                      >
                        <ng-container *ngIf="portfolioLeg.leg_num == 1">
                          <input
                            class="floating-input-area"
                            type="text"
                            value="${{ portfolioLeg.exit_price }}"
                            (change)="
                              saveNumericalCell(
                                'exit_price',
                                $any($event.target).value,
                                portfolioLeg.portfolio_leg_id
                              )
                            "
                          />
                        </ng-container>
                      </td>
                    </ng-container>
                  </ng-container>

                  <ng-container *ngIf="!portfolioLeg.editnotes">
                    <td
                      id="leg:{{ j }}:notes:{{ portfolioLeg.notes }}"
                      [hidden]="notesColumn.hidden"
                      (mouseenter)="showHoverNotes($event, portfolioLeg.notes)"
                      (mouseleave)="hideHoverNotes()"
                      class="highlight-notes-cell-on-hover"
                      (click)="editCell($event, 'notes', portfolioLeg)"
                    >
                      <div class="notes">
                        {{ portfolioLeg.notes }}
                      </div>
                    </td>
                  </ng-container>
                  <ng-container
                    *ngIf="portfolioLeg.editnotes"
                    (mouseenter)="
                      showHoverNotes($event, portfolioLeg.editnotes)
                    "
                    (mouseleave)="hideHoverNotes()"
                  >
                    <td
                      id="leg:{{ j }}:notes:{{ portfolioLeg.notes }}"
                      [hidden]="notesColumn.hidden"
                      (mouseout)="
                        setPopoverNote(portfolioLeg, portfolioLeg.notes, false)
                      "
                    >
                      <textarea
                        class="floating-text-area"
                        type="text"
                        (change)="
                          saveTextCell(
                            'notes',
                            $any($event.target).value,
                            portfolioLeg.portfolio_leg_id
                          )
                        "
                        >{{ portfolioLeg.notes }}</textarea
                      >
                    </td>
                  </ng-container>
                  <td
                    id="leg:{{ j }}:strategy_name:{{
                      portfolioLeg.strategy_name
                    }}"
                    [hidden]="strategyColumn.hidden"
                  >
                    <ng-container>
                      <select
                        [(ngModel)]="selectedStratGroup[j]"
                        (change)="saveStrat(portfolioLeg.portfolio_leg_id, j)"
                      >
                        <option value="undefined" disabled selected hidden>
                          {{ portfolioLeg.strategy_name }}
                        </option>
                        <option
                          *ngFor="let strat of strategies"
                          [ngValue]="strat"
                        >
                          {{ strat.name }}
                        </option>
                      </select>
                    </ng-container>
                    <!-- <input type="submit"  name="SUBMITBUTTON" value="Save" click="saveStrat(op)"/> -->
                  </td>
                  <td
                    id="leg:{{ j }}:symbol_type:{{ portfolioLeg.symbol_type }}"
                    [hidden]="typeColumn.hidden"
                    class="text-align-center"
                  >
                    {{ portfolioLeg.symbol_type }}
                  </td>
                  <td
                    id="leg:{{ j }}:symbol_category:{{
                      portfolioLeg.symbol_category
                    }}"
                    [hidden]="categoryColumn.hidden"
                    class="text-align-center"
                  >
                    {{ portfolioLeg.symbol_category }}
                  </td>
                  <td
                    id="leg:{{ j }}:exp_cost:{{
                      portfolioLeg.exp_cost
                    }}:format:$"
                    [hidden]="exposureCostColumn.hidden"
                    class="usd"
                  >
                    <ng-container
                      *ngIf="
                        portfolioLeg.leg_num == 1 &&
                        portfolioLeg.security_type == 'PUT' &&
                        portfolioLeg.security_type != 'STOCK' &&
                        portfolioLeg.is_manual == null
                      "
                    >
                      <ng-container *ngIf="portfolioLeg.exp_cost != null">
                        $
                      </ng-container>
                      {{ portfolioLeg.exp_cost | number: "1.2-2" }}
                    </ng-container>
                    <ng-container
                      *ngIf="
                        portfolioLeg.security_type == 'CALL' ||
                        portfolioLeg.security_type == 'STOCK' ||
                        portfolioLeg.is_manual != null
                      "
                    >
                      <ng-container *ngIf="portfolioLeg.exp_cost != null">
                        $
                      </ng-container>
                      {{ portfolioLeg.exp_cost | number: "1.2-2" }}
                    </ng-container>
                  </td>
                  <td
                    id="leg:{{ j }}:exp_curr:{{
                      portfolioLeg.exp_curr
                    }}:format:$"
                    [hidden]="exposureCurValColumn.hidden"
                    class="usd"
                  >
                    <ng-container
                      *ngIf="
                        portfolioLeg.leg_num == 1 &&
                        portfolioLeg.security_type == 'PUT' &&
                        portfolioLeg.security_type != 'STOCK' &&
                        portfolioLeg.is_manual == null
                      "
                    >
                      <ng-container *ngIf="portfolioLeg.exp_curr != null">
                        $
                      </ng-container>
                      {{ portfolioLeg.exp_curr | number: "1.2-2" }}
                    </ng-container>
                    <ng-container
                      *ngIf="
                        portfolioLeg.security_type == 'CALL' ||
                        portfolioLeg.security_type == 'STOCK' ||
                        portfolioLeg.is_manual != null
                      "
                    >
                      <ng-container *ngIf="portfolioLeg.exp_curr != null">
                        $
                      </ng-container>
                      {{ portfolioLeg.exp_curr | number: "1.2-2" }}
                    </ng-container>
                  </td>
                  <td
                    id="leg:{{ j }}:gain_dollars:{{
                      portfolioLeg.gain_dollars
                    }}:format:$"
                    [hidden]="$GainColumn.hidden"
                    class="usd"
                  >
                    <ng-container
                      *ngIf="
                        portfolioLeg.leg_num == 1 &&
                        portfolioLeg.security_type != 'STOCK' &&
                        portfolioLeg.is_manual == null
                      "
                    >
                      ${{ portfolioLeg.gain_dollars | number: "1.2-2" }}
                    </ng-container>
                    <ng-container
                      *ngIf="
                        portfolioLeg.security_type == 'STOCK' ||
                        portfolioLeg.is_manual != null
                      "
                    >
                      ${{ portfolioLeg.gain_dollars | number: "1.2-2" }}
                    </ng-container>
                  </td>
                  <td
                    [ngClass]="{
                      'highlight-red': portfolioLeg.fv_percent <= 25
                    }"
                    id="leg:{{ j }}:fv_percent:{{
                      portfolioLeg.fv_percent
                    }}:format:%"
                    [hidden]="percentOfFVColumn.hidden"
                    class="percent"
                  >
                    {{ portfolioLeg.fv_percent | number: "1.0-0" }}%
                  </td>
                  <ng-container *ngIf="!portfolioLeg.editprice_at_fill">
                    <td
                      id="leg:{{ j }}:price_at_fill:{{
                        portfolioLeg.price_at_fill
                      }}:format:$"
                      [hidden]="priceAtFillColumn.hidden"
                      [ngClass]="{
                        err: portfolioLeg.price_at_fill_err == true
                      }"
                      (click)="editCell($event, 'price_at_fill', portfolioLeg)"
                      class="numDataCell usd highlight-cell-on-hover"
                    >
                      <ng-container *ngIf="portfolioLeg.price_at_fill != null">
                        $
                      </ng-container>
                      {{ portfolioLeg.price_at_fill | number: "1.2-2" }}
                    </td>
                  </ng-container>
                  <ng-container *ngIf="portfolioLeg.editprice_at_fill">
                    <td
                      id="leg:{{ j }}:price_at_fill:{{
                        portfolioLeg.price_at_fill
                      }}:format:$"
                      [hidden]="priceAtFillColumn.hidden"
                      [ngClass]="{
                        err: portfolioLeg.price_at_fill_err == true
                      }"
                    >
                      <input
                        class="floating-input-area"
                        type="text"
                        value="{{ portfolioLeg.price_at_fill }}"
                        (change)="
                          saveNumericalCell(
                            'price_at_fill',
                            $any($event.target).value,
                            portfolioLeg.portfolio_leg_id
                          )
                        "
                      />
                    </td>
                  </ng-container>
                  <ng-container *ngIf="!portfolioLeg.editpaid">
                    <td
                      id="leg:{{ j }}:paid:{{ portfolioLeg.paid }}:format:$"
                      [hidden]="paidColumn.hidden"
                      [ngClass]="{
                        err: portfolioLeg.paid_err == true
                      }"
                      (click)="editCell($event, 'paid', portfolioLeg)"
                      class="numDataCell usd highlight-cell-on-hover"
                    >
                      ${{ portfolioLeg.paid | number: "1.2-2" }}
                    </td>
                  </ng-container>
                  <ng-container *ngIf="portfolioLeg.editpaid">
                    <td
                      id="leg:{{ j }}:paid:{{ portfolioLeg.paid }}:format:$"
                      [hidden]="paidColumn.hidden"
                      [ngClass]="{
                        err: portfolioLeg.paid_err == true
                      }"
                    >
                      <input
                        class="floating-input-area"
                        type="text"
                        value="{{ portfolioLeg.paid }}"
                        (change)="
                          saveNumericalCell(
                            'paid',
                            $any($event.target).value,
                            portfolioLeg.portfolio_leg_id
                          )
                        "
                      />
                    </td>
                  </ng-container>
                  <td
                    id="leg:{{ j }}:bid:{{ portfolioLeg.bid }}:format:$"
                    [hidden]="bidColumn.hidden"
                    class="usd"
                  >
                    ${{ portfolioLeg.bid | number: "1.2-2" }}
                  </td>
                  <td
                    id="leg:{{ j }}:mid:{{ portfolioLeg.mid }}:format:$"
                    [hidden]="midColumn.hidden"
                    class="usd"
                  >
                    ${{ portfolioLeg.mid | number: "1.2-2" }}
                  </td>
                  <td
                    id="leg:{{ j }}:ask:{{ portfolioLeg.ask }}:format:$"
                    [hidden]="askColumn.hidden"
                    class="usd"
                  >
                    ${{ portfolioLeg.ask | number: "1.2-2" }}
                  </td>
                  <td
                    id="leg:{{ j }}:paid_total:{{
                      portfolioLeg.paid_total
                    }}:format:$"
                    [hidden]="totalPaidColumn.hidden"
                    class="usd"
                  >
                    ${{ portfolioLeg.paid_total | number: "1.2-2" }}
                  </td>
                  <td
                    id="leg:{{ j }}:value:{{ portfolioLeg.value }}:format:$"
                    [hidden]="valueColumn.hidden"
                    class="usd"
                  >
                    ${{ portfolioLeg.value | number: "1.2-2" }}
                  </td>
                  <td
                    id="leg:{{ j }}:gain_total:{{
                      portfolioLeg.gain_total
                    }}:format:$"
                    [hidden]="totalGainColumn.hidden"
                    class="usd"
                  >
                    ${{ portfolioLeg.gain_total | number: "1.2-2" }}
                  </td>
                  <td
                    id="leg:{{ j }}:value_min:{{
                      portfolioLeg.value_min
                    }}:format:$"
                    [hidden]="minCashValueColumn.hidden"
                    class="usd"
                  >
                    <ng-container *ngIf="portfolioLeg.leg_num == 1">
                      <ng-container *ngIf="portfolioLeg.value_min != null">
                        $
                      </ng-container>
                      {{ portfolioLeg.value_min | number: "1.2-2" }}
                    </ng-container>
                    <ng-container *ngIf="portfolioLeg.is_manual != null">
                      <ng-container *ngIf="portfolioLeg.value_min != null">
                        $
                      </ng-container>
                      {{ portfolioLeg.value_min | number: "1.2-2" }}
                    </ng-container>
                  </td>
                  <td
                    id="leg:{{ j }}:value_max:{{
                      portfolioLeg.value_max
                    }}:format:$"
                    [hidden]="maxValueColumn.hidden"
                    class="usd"
                  >
                    <ng-container
                      *ngIf="
                        portfolioLeg.leg_num == 1 &&
                        portfolioLeg.security_type != 'STOCK' &&
                        portfolioLeg.is_manual == null
                      "
                    >
                      ${{ portfolioLeg.value_max | number: "1.2-2" }}
                    </ng-container>
                    <ng-container
                      *ngIf="
                        portfolioLeg.is_manual != null ||
                        portfolioLeg.security_type == 'STOCK'
                      "
                    >
                      ${{ portfolioLeg.value_max | number: "1.2-2" }}
                    </ng-container>
                  </td>
                  <td
                    id="leg:{{ j }}:value_target:{{
                      portfolioLeg.value_target
                    }}:format:$"
                    [hidden]="targetValueColumn.hidden"
                    class="usd"
                  >
                    <ng-container
                      *ngIf="
                        portfolioLeg.leg_num == 1 &&
                        portfolioLeg.security_type != 'STOCK' &&
                        portfolioLeg.is_manual == null
                      "
                    >
                      ${{ portfolioLeg.value_target | number: "1.2-2" }}
                    </ng-container>
                    <ng-container
                      *ngIf="
                        portfolioLeg.security_type == 'STOCK' ||
                        portfolioLeg.is_manual != null
                      "
                    >
                      ${{ portfolioLeg.value_target | number: "1.2-2" }}
                    </ng-container>
                  </td>
                  <td
                    id="leg:{{ j }}:profit_target:{{
                      portfolioLeg.profit_target
                    }}:format:$"
                    [hidden]="targetProfitColumn.hidden"
                    class="usd"
                  >
                    <ng-container
                      *ngIf="
                        portfolioLeg.leg_num == 1 &&
                        portfolioLeg.security_type != 'STOCK' &&
                        portfolioLeg.is_manual == null
                      "
                    >
                      ${{ portfolioLeg.profit_target | number: "1.2-2" }}
                    </ng-container>
                    <ng-container
                      *ngIf="
                        portfolioLeg.security_type == 'STOCK' ||
                        portfolioLeg.is_manual != null
                      "
                    >
                      ${{ portfolioLeg.profit_target | number: "1.2-2" }}
                    </ng-container>
                  </td>
                  <td
                    id="leg:{{ j }}:paid_for_exposure_dashboard:{{
                      portfolioLeg.paid_for_exposure_dashboard
                    }}:format:$"
                    [hidden]="paidExposureDashboardColumn.hidden"
                    class="usd"
                  >
                    <ng-container
                      *ngIf="
                        portfolioLeg.leg_num == 1 &&
                        portfolioLeg.security_type == 'PUT' &&
                        portfolioLeg.security_type != 'STOCK' &&
                        portfolioLeg.is_manual == null
                      "
                    >
                      ${{
                        portfolioLeg.paid_for_exposure_dashboard
                          | number: "1.2-2"
                      }}
                    </ng-container>
                    <ng-container
                      *ngIf="
                        portfolioLeg.security_type == 'CALL' ||
                        portfolioLeg.security_type == 'STOCK' ||
                        portfolioLeg.is_manual != null
                      "
                    >
                      ${{
                        portfolioLeg.paid_for_exposure_dashboard
                          | number: "1.2-2"
                      }}
                    </ng-container>
                  </td>
                  <td
                    id="leg:{{ j }}:max_value_for_exposure_dashboard:{{
                      portfolioLeg.max_value_for_exposure_dashboard
                    }}:format:$"
                    [hidden]="maxValueExposureDashboardColumn.hidden"
                    class="usd"
                  >
                    <ng-container
                      *ngIf="
                        portfolioLeg.leg_num == 1 &&
                        portfolioLeg.security_type != 'STOCK' &&
                        portfolioLeg.is_manual == null
                      "
                    >
                      ${{
                        portfolioLeg.max_value_for_exposure_dashboard
                          | number: "1.2-2"
                      }}
                    </ng-container>
                    <ng-container
                      *ngIf="
                        portfolioLeg.security_type == 'STOCK' ||
                        portfolioLeg.is_manual != null
                      "
                    >
                      ${{
                        portfolioLeg.max_value_for_exposure_dashboard
                          | number: "1.2-2"
                      }}
                    </ng-container>
                  </td>
                  <td
                    id="leg:{{ j }}:itm_1:{{ portfolioLeg.itm_1 }}"
                    [hidden]="ITM100Column.hidden"
                    class="text-align-center"
                  >
                    <ng-container
                      *ngIf="
                        portfolioLeg.leg_num == 2 &&
                        portfolioLeg.is_manual == null &&
                        portfolioLeg.security_type != 'STOCK'
                      "
                    >
                      {{ portfolioLeg.itm_1 }}
                    </ng-container>
                    <ng-container
                      *ngIf="
                        portfolioLeg.is_manual != null ||
                        portfolioLeg.security_type == 'STOCK'
                      "
                    >
                      {{ portfolioLeg.itm_1 }}
                    </ng-container>
                  </td>
                  <td
                    id="leg:{{ j }}:itm_2:{{ portfolioLeg.itm_2 }}"
                    [hidden]="ITMNeg25Column.hidden"
                    class="text-align-center"
                  >
                    <ng-container
                      *ngIf="
                        portfolioLeg.leg_num == 2 &&
                        portfolioLeg.is_manual == null &&
                        portfolioLeg.security_type != 'STOCK'
                      "
                    >
                      {{ portfolioLeg.itm_2 }}
                    </ng-container>
                    <ng-container
                      *ngIf="
                        portfolioLeg.is_manual != null ||
                        portfolioLeg.security_type == 'STOCK'
                      "
                    >
                      {{ portfolioLeg.itm_2 }}
                    </ng-container>
                  </td>
                  <td
                    id="leg:{{ j }}:itm_3:{{ portfolioLeg.itm_3 }}"
                    [hidden]="ITMNeg25to0Column.hidden"
                    class="text-align-center"
                  >
                    <ng-container
                      *ngIf="
                        portfolioLeg.leg_num == 2 &&
                        portfolioLeg.is_manual == null &&
                        portfolioLeg.security_type != 'STOCK'
                      "
                    >
                      {{ portfolioLeg.itm_3 }}
                    </ng-container>
                    <ng-container
                      *ngIf="
                        portfolioLeg.is_manual != null ||
                        portfolioLeg.security_type == 'STOCK'
                      "
                    >
                      {{ portfolioLeg.itm_3 }}
                    </ng-container>
                  </td>
                  <td
                    id="leg:{{ j }}:itm_4:{{ portfolioLeg.itm_4 }}"
                    [hidden]="ITM0to25Column.hidden"
                    class="text-align-center"
                  >
                    <ng-container
                      *ngIf="
                        portfolioLeg.leg_num == 2 &&
                        portfolioLeg.is_manual == null &&
                        portfolioLeg.security_type != 'STOCK'
                      "
                    >
                      {{ portfolioLeg.itm_4 }}
                    </ng-container>
                    <ng-container
                      *ngIf="
                        portfolioLeg.is_manual != null ||
                        portfolioLeg.security_type == 'STOCK'
                      "
                    >
                      {{ portfolioLeg.itm_4 }}
                    </ng-container>
                  </td>
                  <td
                    id="leg:{{ j }}:itm_5:{{ portfolioLeg.itm_5 }}"
                    [hidden]="ITM25Column.hidden"
                    class="text-align-center"
                  >
                    <ng-container
                      *ngIf="
                        portfolioLeg.leg_num == 2 &&
                        portfolioLeg.is_manual == null &&
                        portfolioLeg.security_type != 'STOCK'
                      "
                    >
                      {{ portfolioLeg.itm_5 }}
                    </ng-container>
                    <ng-container
                      *ngIf="
                        portfolioLeg.is_manual != null ||
                        portfolioLeg.security_type == 'STOCK'
                      "
                    >
                      {{ portfolioLeg.itm_5 }}
                    </ng-container>
                  </td>
                  <td
                    id="leg:{{ j }}:use_table:{{ portfolioLeg.use_table }}"
                    [hidden]="useTableColumn.hidden"
                    class="text-align-center"
                  >
                    {{ portfolioLeg.use_table }}
                  </td>
                  <td
                    id="leg:{{ j }}:portfolio_item_id:{{
                      portfolioLeg.portfolio_item_id
                    }}"
                    [hidden]="portfolioIdColumn.hidden"
                    class="text-align-center"
                  >
                    {{ portfolioLeg.portfolio_item_id }}
                  </td>
                </tr>

                <ng-container *ngIf="portfolioLeg?.addNewRecordToExisting">
                  <tr
                    *ngFor="
                      let row of numNewRowsExisting | times;
                      last as isLast;
                      first as isFirst;
                      let i = index
                    "
                    style="background-color: white !important"
                  >
                    <ng-container>
                      <!-- <td >{{leg.purchased_at}}</td> -->
                      <td>
                        <!-- <div class="dropdown">
                                        <div>
                                            <input class="form-control" placeholder="mm-dd-yyyy" #date value="{{exactDate}}"
                                            name="dp" ngbDatepicker #d1="ngbDatepicker" >
                                        </div>
                                        <div class="input-group-append">
                                            <button class="date btn-outline-secondary calendar fa fa-calendar" (click)="d1.toggle()" type="button" value="{{exactDate}}"></button>
                                        </div>
                                        </div> -->
                        <input
                          [ngClass]="{
                            required:
                              requiredPortfolioData[row]?.purchased_at == true
                          }"
                          class="new-standard-input-area"
                          (change)="changeInputExisting($event, i)"
                          maxlength="10"
                          name="purchased_at"
                          placeholder="Purchased At"
                        />
                      </td>
                      <td>
                        <!-- Trade Account -->
                      </td>
                      <td class="add-rmv-btns">
                        <ng-container *ngIf="isLast">
                          <button
                            class="custom-btn add-btn"
                            (click)="addLegExisting(row)"
                          >
                            <i class="fa fa-plus"></i>
                          </button>
                          <button
                            *ngIf="!isFirst"
                            class="custom-btn rmv-btn"
                            (click)="removeLegExisting()"
                          >
                            <i class="fa fa-minus"></i>
                          </button>
                        </ng-container>
                      </td>
                      <td>
                        <!-- Symbol -->
                      </td>
                      <td></td>
                      <td>
                        <input
                          [ngClass]="{
                            required:
                              requiredPortfolioData[row]?.expiration == true
                          }"
                          class="new-standard-input-area"
                          placeholder="Expiration Date"
                          (change)="changeInputExisting($event, i)"
                          maxlength="10"
                          type="text"
                          name="expiration"
                        />
                      </td>
                      <td>
                        <input
                          [ngClass]="{
                            required:
                              requiredPortfolioData[row]?.quantity == true
                          }"
                          class="new-standard-input-area"
                          placeholder="Quantity"
                          (change)="changeInputExisting($event, i)"
                          type="text"
                          name="quantity"
                        />
                      </td>
                      <td>
                        <input
                          [ngClass]="{
                            required: requiredPortfolioData[row]?.strike == true
                          }"
                          class="new-standard-input-area"
                          placeholder="Strike"
                          (change)="changeInputExisting($event, i)"
                          type="text"
                          name="strike"
                        />
                      </td>
                      <td>
                        <select
                          [ngClass]="{
                            required:
                              requiredPortfolioData[row]?.security_type == true
                          }"
                          (click)="changeInputExisting($event, i)"
                          name="security_type"
                        >
                          <option value="null" disabled selected hidden>
                            Select
                          </option>
                          <option>Call</option>
                          <option>Put</option>
                          <option>Stock</option>
                        </select>
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        <input
                          [ngClass]="{
                            required: requiredPortfolioData[row]?.exit == true
                          }"
                          class="new-standard-input-area"
                          placeholder="Select/Input"
                          (change)="changeInputExisting($event, i)"
                          list="exit"
                          name="exit"
                        />
                        <datalist id="exit">
                          <option value="DEFAULT"></option>
                          <option value="MANUAL"></option>
                        </datalist>
                      </td>
                      <td>
                        <textarea
                          class="new-text-area"
                          (change)="changeInputExisting($event, i)"
                          placeholder="Notes"
                          type="text"
                          name="notes"
                        ></textarea>
                      </td>
                      <td>
                        <select
                          [ngClass]="{
                            required:
                              requiredPortfolioData[row]?.trade_strategy_id ==
                              true
                          }"
                          [(ngModel)]="selectedStrat"
                          (click)="changeInputExisting($event, i)"
                          name="strategy_id"
                        >
                          <option value="undefined" disabled selected hidden>
                            Select
                          </option>
                          <option
                            *ngFor="let strat of strategies"
                            value="{{ strat.id }}"
                          >
                            {{ strat.name }}
                          </option>
                        </select>
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        <input
                          [ngClass]="{
                            required:
                              requiredPortfolioData[row]?.price_at_fill == true
                          }"
                          class="new-standard-input-area"
                          name="price_at_fill"
                          placeholder="Price @ Fill"
                          (change)="changeInputExisting($event, i)"
                          type="text"
                        />
                      </td>
                      <td>
                        <input
                          [ngClass]="{
                            required: requiredPortfolioData[row]?.paid == true
                          }"
                          class="new-standard-input-area"
                          name="paid"
                          placeholder="Paid"
                          (change)="changeInputExisting($event, i)"
                          type="text"
                        />
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </ng-container>
                  </tr>
                </ng-container>
              </ng-container>
            </tbody>
            <tfoot style="height: 53px; visibility: hidden">
              <tr>
                <td [hidden]="dateColumn.hidden"></td>
                <td [hidden]="acctColumn.hidden"></td>
                <td [hidden]="legColumn.hidden"></td>
                <td [hidden]="tickerColumn.hidden"></td>
                <td [hidden]="daysColumn.hidden"></td>
                <td [hidden]="expirationColumn.hidden"></td>
                <td [hidden]="quantityColumn.hidden"></td>
                <td [hidden]="strikeColumn.hidden"></td>
                <td [hidden]="optionTypeColumn.hidden"></td>
                <td [hidden]="UDLColumn.hidden"></td>
                <td [hidden]="ITMcurColumn.hidden"></td>
                <td [hidden]="moveColumn.hidden"></td>
                <td [hidden]="costColumn.hidden"></td>
                <td [hidden]="priceColumn.hidden"></td>
                <td [hidden]="percentGainColumn.hidden"></td>
                <td [hidden]="maxGainColumn.hidden"></td>
                <td [hidden]="percentMaxColumn.hidden"></td>
                <td [hidden]="exitColumn.hidden"></td>
                <td [hidden]="exitPriceColumn.hidden"></td>
                <td [hidden]="notesColumn.hidden"></td>
                <td [hidden]="strategyColumn.hidden"></td>
                <td [hidden]="typeColumn.hidden"></td>
                <td [hidden]="categoryColumn.hidden"></td>
                <td [hidden]="exposureCostColumn.hidden"></td>
                <td [hidden]="exposureCurValColumn.hidden"></td>
                <td [hidden]="$GainColumn.hidden"></td>
                <td [hidden]="percentOfFVColumn.hidden"></td>
                <td [hidden]="priceAtFillColumn.hidden"></td>
                <td [hidden]="paidColumn.hidden"></td>
                <td [hidden]="bidColumn.hidden"></td>
                <td [hidden]="midColumn.hidden"></td>
                <td [hidden]="askColumn.hidden"></td>
                <td [hidden]="totalPaidColumn.hidden"></td>
                <td [hidden]="valueColumn.hidden"></td>
                <td [hidden]="totalGainColumn.hidden"></td>
                <td [hidden]="minCashValueColumn.hidden"></td>
                <td [hidden]="maxValueColumn.hidden"></td>
                <td [hidden]="targetValueColumn.hidden"></td>
                <td [hidden]="targetProfitColumn.hidden"></td>
                <td [hidden]="paidExposureDashboardColumn.hidden"></td>
                <td [hidden]="maxValueExposureDashboardColumn.hidden"></td>
                <td [hidden]="ITM100Column.hidden"></td>
                <td [hidden]="ITMNeg25Column.hidden"></td>
                <td [hidden]="ITMNeg25to0Column.hidden"></td>
                <td [hidden]="ITM0to25Column.hidden"></td>
                <td [hidden]="ITM25Column.hidden"></td>
                <td [hidden]="useTableColumn.hidden"></td>
                <td [hidden]="portfolioIdColumn.hidden"></td>
              </tr>
            </tfoot>
          </table>
        </cdk-virtual-scroll-viewport>
        <div *ngIf="loading" class="spinner-container">
          <div role="status" class="spinner-border">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div
          *ngIf="portfolio.length == 0 && !loading"
          class="no-results no-results-container"
        >
          <h2 class="no-results">No Results</h2>
        </div>
      </div>
    </ng-container>

    <nav
      #contextMenu
      class="menu-index"
      [ngClass]="this.showHoverForNotes ? 'context-active' : 'context-menu'"
      [ngStyle]="{ left: this.menuPositionX, top: this.menuPositionY }"
    >
      <div class="show-notes-container">
        {{ noteText }}
      </div>
    </nav>

    <nav
      #contextMenu
      class="menu-index"
      [ngClass]="this.rightClickMenuState ? 'context-active' : 'context-menu'"
      [ngStyle]="{ left: this.menuPositionX, top: this.menuPositionY }"
    >
      <div class="right-click-main-menu">
        <div class="right-click-header">
          {{ rightClicked?.symbol }}
          <i (click)="closeRightClickWindow()" class="fa fa-window-close"></i>
        </div>
        <div style="margin-top: 10px"></div>
        <button
          (click)="menuState = true; rightClickMenuState = false"
          class="btn btn-primary close-delete-btn"
        >
          Close, Delete Legs
        </button>

        <button
          (click)="
            rightClickMenuState = false;
            rightClicked.addNewRecordToExisting = true;
            addNewLegToExisting($event)
          "
          class="btn btn-primary close-delete-btn"
        >
          Add Leg
        </button>

        <button
          (click)="
            viewPortfolioRecordHistory(
              rightClicked?.portfolio_item_id,
              portfoliohistory
            )
          "
          class="btn btn-primary close-delete-btn"
        >
          View History
        </button>

        <button
          (click)="viewTicker(content, rightClicked?.symbol)"
          class="btn btn-primary close-delete-btn"
        >
          View Ticker
        </button>

        <button
          (click)="addNotification(percentValueNotification, rightClicked)"
          class="btn btn-primary close-delete-btn"
        >
          Add Notification
        </button>
      </div>
    </nav>

    <nav
      #contextMenu
      class="menu-index"
      [ngClass]="this.menuState ? 'context-active' : 'context-menu'"
      [ngStyle]="{ left: this.menuPositionX, top: this.menuPositionY }"
    >
      <div
        *ngIf="
          !showRightClickConfirmation &&
          !showRightClickCloseConfirmation &&
          !loading
        "
        class="right-click-menu"
      >
        <div class="right-click-header">
          {{ rightClicked?.symbol }}
          <i
            (click)="menuState = false"
            class="close-notifications fa fa-window-close"
          ></i>
        </div>

        <hr style="margin-top: 13px; margin-bottom: 20px" />

        <div class="right-click-leg-container">
          <div
            class="right-click-single-leg"
            *ngFor="let leg of rightClicked?.legs; let i = index"
          >
            <div class="right-click-leg-header">
              <span>Leg {{ i + 1 }}</span>
              <button
                (click)="confirmDeleteLeg(i)"
                class="btn btn-primary right-click-btn"
              >
                Delete Leg {{ i + 1 }}
              </button>
            </div>

            <div class="right-click-price-quantity-inputs">
              <label>Price</label>
              <input
                class="form-control right-click-input"
                value="{{ leg.price }}"
                name="leg-price"
                (change)="rightClickPrice($any($event.target).value, leg)"
              />

              <label> Quantity</label>
              <input
                class="form-control right-click-input"
                value="{{ leg.quantity }}"
                name="leg-quantity"
                (change)="rightClickQuantity($any($event.target).value, leg)"
              />

              <small
                *ngIf="userQuantityEntered < 0"
                class="right-click-quantity-error"
              >
                Quantity cannot be below zero.
              </small>
            </div>

            <button
              (click)="confirmCloseLeg(i)"
              [disabled]="rightClicked.legs[i].closed"
              class="btn btn-primary right-click-btn"
            >
              Close Leg {{ i + 1 }}
            </button>
          </div>
        </div>
      </div>

      <div
        class="right-click-delete-confirmation-container"
        *ngIf="showRightClickCloseConfirmation && !loading"
      >
        <p class="right-click-delete-confirmation-header">Are You Sure?</p>

        <div class="right-click-btn-confirmation">
          <button (click)="closeLeg()" class="btn btn-primary">
            Yes, Close Leg {{ rightClickLegIndex + 1 }}
          </button>
          <button
            (click)="showRightClickCloseConfirmation = false"
            class="btn btn-primary"
          >
            Cancel
          </button>
        </div>
      </div>

      <div
        class="right-click-delete-confirmation-container"
        *ngIf="showRightClickConfirmation && !loading"
      >
        <p class="right-click-delete-confirmation-header">Are You Sure?</p>

        <div class="right-click-btn-confirmation">
          <button (click)="deleteLeg()" class="btn btn-primary">
            Yes, Delete Leg {{ rightClickLegIndex + 1 }}
          </button>
          <button
            (click)="showRightClickConfirmation = false"
            class="btn btn-primary"
          >
            Cancel
          </button>
        </div>
      </div>

      <div
        *ngIf="loading"
        class="spinner-container right-click-loading-container"
      >
        <div role="status" class="spinner-border">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </nav>
  </div>

  <ng-template #spreadNotification let-modal>
    <div class="modal-header">
      <h2 class="modal-title" id="modal-basic-title">Spread Notification</h2>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p class="spread-note-header">
        {{ rightClicked.trade_account_name }}
        {{ rightClicked.symbol }}
        {{ rightClicked.legs[0].expiration_date[0] | date: "mediumDate" }}
        {{ rightClicked.legs[0].price }} / {{ rightClicked.legs[1].price }}
        {{ rightClicked.legs[0].security_type }} /
        {{ rightClicked.legs[1].security_type }}
      </p>
      <app-spread-notifications
        [cellValueArr]="cellValueArr"
        [rightClicked]="rightClicked"
      ></app-spread-notifications>
    </div>
  </ng-template>

  <!-- MODAL FOR PERCENT AND VALUE NOTIFICATIONS -->
  <ng-template #percentValueNotification let-modal>
    <div class="modal-header">
      <h2 class="modal-title" id="modal-basic-title">Notification</h2>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <app-percent-value-notifications
        [cellValueArr]="cellValueArr"
        [rightClicked]="rightClicked"
        [noteModal]="notificationModal"
      ></app-percent-value-notifications>
    </div>
  </ng-template>

  <ng-template #content let-modal>
    <div class="modal-header">
      <h2 class="modal-title" id="modal-basic-title">Current Data</h2>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="form-group">
        <div class="input-group">
          <div class="input-group-append">
            <iframe
              id="ticker-live-data"
              width="770px"
              height="500px"
              src=""
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</html>
