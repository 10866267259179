import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { RuntimeEnvLoaderService } from "./runtime-env-loader.service";

@Injectable({
  providedIn: "root",
})
export class HistoricalOrdersService {
  adminUserGroupId: any;
  basePath: string;
  errorHandler: any;

  constructor(
    private http: HttpClient,
    private envLoader: RuntimeEnvLoaderService
  ) {
    this.basePath = envLoader.config.API_BASE_URL + envLoader.config.APP_KEY;
  }

  getHistoricalOrders(params = null): Observable<any> {
    console.log("About to call GET:");
    console.log(params);
    const headers = new HttpHeaders().set(
      "security-token",
      JSON.parse(localStorage.getItem("currentUser")).token
    );
    return this.http
      .get<any>(this.basePath + "/historical-orders", {
        headers,
        observe: "response",
        params,
      })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError(this.handleError)
      );
  }

  get10k(params = null): Observable<any> {
    const headers = new HttpHeaders().set(
      "security-token",
      JSON.parse(localStorage.getItem("currentUser")).token
    );
    return this.http
      .get<any>(this.basePath + "/get10k", {
        headers,
        observe: "response",
        params,
      })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError(this.handleError)
      );
  }

  getHTMLForTicker(symbol) {
    var url =
      "/url-framer.php?url=https://finance.yahoo.com/quote/" + symbol + "/";

    //http://localhost:8000//url-framer.php?url=https://finance.yahoo.com/quote/NUE/

    const headers = new HttpHeaders().set(
      "security-token",
      JSON.parse(localStorage.getItem("currentUser")).token
    );
    return this.http
      .get<any>("http://localhost:8000" + url, { headers, observe: "response" })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError(this.handleError)
      );
  }

  handleError(error) {
    // let errorMessage = {};
    // if (error.error instanceof ErrorEvent) {
    //   // client-side error
    //   errorMessage = {
    //     error: `${error.error.message}`
    //   };
    // } else {
    //   // server-side error
    //   errorMessage = {
    //     code: `${error.status}`,
    //     message: `${error.statusText}`,
    //     errors: error.error.errors
    //   };
    // }
    // window.alert(errorMessage);
    return throwError("Error");
  }

  archiveHistoricalOrders(id): Observable<any> {
    const headers = new HttpHeaders().set(
      "security-token",
      JSON.parse(localStorage.getItem("currentUser")).token
    );
    return this.http
      .patch<any>(this.basePath + "/historical-orders/" + id + "/archive", {
        headers,
      })
      .pipe(
        map((response) => {
          return 200;
        }),
        catchError(this.handleError)
      );
  }

  unarchiveHistoricalOrders(id): Observable<any> {
    const headers = new HttpHeaders().set(
      "security-token",
      JSON.parse(localStorage.getItem("currentUser")).token
    );
    return this.http
      .patch<any>(this.basePath + "/historical-orders/" + id + "/unarchive", {
        headers,
      })
      .pipe(
        map((response) => {
          return 200;
        }),
        catchError(this.handleError)
      );
  }
}
