<div class="container-fluid custom-layout-container">
    <div class="row">
        <div class="col-md-offset-4 col-md-4">
            <div class="login-container">
                <div class="loader" *ngIf="loading">
                    <img src="./assets/public_img_load.gif" />
                </div>
                <img src="./assets/PRISM_Logo_Hz_-_white.png" class="img-responsive" />
              <div class="card card-white">
                <div class="card-header">
                    <h4 class="strong">Set new password ?</h4>
                </div>
                <div class="card-body">
                    <form class="form" role="form" [formGroup]="resetForm" (ngSubmit)="onSubmit()">
                        <div class="form-group has-feedback">
                            <input formControlName="password" autocomplete="true" type="password" class="form-control form-control-lg rounded-0" placeholder="New Password">
                            <span class="glyphicon glyphicon-lock form-control-feedback"></span>
                            <div class="invalid-feedback" *ngIf="submitted && f.password.errors">
                                <div *ngIf="f.password.errors.required">New password is required</div>
                                <div *ngIf="f.password.errors.minlength">Password must have minimum 6 characters</div>
                            </div>
                        </div>
                        <div class="form-group has-feedback">
                            <input type="password" formControlName="passwordConfirm"  class="form-control form-control-lg rounded-0" id="pwd1" required="" autocomplete="new-password" placeholder="Confirm Password">
                            <span class="glyphicon glyphicon-lock form-control-feedback"></span>
                            <div class="invalid-feedback" *ngIf="submitted && f.passwordConfirm.errors">
                                <div *ngIf="f.passwordConfirm.errors.required">Confirm Password is required</div>
                            </div>
                            <div class="invalid-feedback" *ngIf="passwordMismatch">
                                Confirm password does not match with new password
                            </div>
                        </div>
                        
                        <div class="form-footer">
                            <div class="row">
                                <div class="col-xs-12">
                                    <button type="submit" class="btn btn-success " id="btnLogin">Set new Password</button>
                                </div>
                            </div>
                            
                        </div>
                        
                    </form>
                    
                </div>
                <!--/card-block-->
            </div>
            <!-- /form card login -->
            
            
            </div>
        </div>
    </div>
</div>