<table class="table table-responsive table-striped table-list">
  <div *ngIf="loading" class="spinner-container">
    <div role="status" class="spinner-border">
      <span class="sr-only">Loading...</span>
    </div>
  </div>

  <ng-container *ngIf="!loading">
    <thead>
      <tr>
        <th>Name</th>
        <th>Send Email</th>
        <th>Send SMS</th>
        <th>Preferred</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let user of usersForChecks">
        <td class="job-row">{{ user.first_name }} {{ user.last_name }}</td>
        <td>
          <input
            [disabled]="user.preferred"
            type="checkbox"
            name="email"
            [(ngModel)]="user.send_email"
            (click)="clickCheck()"
          />
        </td>
        <td>
          <input
            [disabled]="user.preferred"
            type="checkbox"
            name="sms"
            [(ngModel)]="user.send_sms"
            (click)="clickCheck()"
          />
        </td>
        <td>
          <input
            type="checkbox"
            name="user.preferred"
            [(ngModel)]="user.preferred"
            (click)="clickCheck(user, 'preferred')"
          />
        </td>
      </tr>
    </tbody>
  </ng-container>
</table>
