import { Component, OnInit, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { PortfolioService } from 'src/app/services/portfolio.service';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-right-click',
  templateUrl: './right-click.component.html',
  styleUrls: ['./right-click.component.scss']
})
export class RightClickComponent implements OnInit {
  public _text: any;
  public _mouseEvent: any;
  public _leg: any;
  public _record: any;
  public close: any;
  public quantity: any;
  public loading: any;

  private topPos: any;
  private leftPos: any;
  

  constructor(
    private toastr: ToastrService,
    private portfolioService: PortfolioService) {
  }

  ngOnInit() {
    this._leg = null;
  }

  ngOnDestroy() {
    this._leg = null;
  }
  
  delete() {
    let params = new HttpParams()
    .set('legId', this._leg.portfolio_leg_id);
    
    this.loading = true;

    this.portfolioService.deletePortfolioItem(params).subscribe(
      result => {
        this.toastr.success("Record Closed Successfully");
        this.loading = false;    
    });
  }

  cancel() {
    this._leg = null;
  }

  closeLeg(date) {
    date = date._inputValue.replace(/(\d\d)\/(\d\d)\/(\d{4})/, "$3-$1-$2");

    let params = new HttpParams()

    .set('closeIds', this._leg.portfolio_leg_id)
    .set('close', date)
    .set('quantity', this.quantity)

    this.portfolioService.closePortfolioItem(params).subscribe(
      result => {
        this.toastr.success("Record Closed Successfully");
        
    });


      this._leg.rightClickActive = false;
  }


  @Input() set leg(value: any) { 
    this.leftPos = null;
    this.topPos = null; 

    this._leg = value; 
  }
 
  
  get leg(): any {
     return this._leg;
  }

  @Input() set record(value: any) { 
    this._record = value; 
  }
 
  get record(): any {
     return this._record;
  }

  @Input() set text(value: any) {  
    this._text = value; 
  }
 
  get text(): any {
     return this._text;
  }
}
