import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { AppComponent } from '../../../app.component';
import { UserService } from 'src/app/services/app/user/user.service';

@Component({
    selector: 'app-user-update-password',
    templateUrl: './update-password.component.html',
    styleUrls: ['./update-password.component.scss']
})
export class UpdatePasswordComponent implements OnInit, OnDestroy {

    showPassword = false;
    showPasswordConfirm = false;
    submitted = false;
    updatePasswordForm: FormGroup;
    userId: any;

    subUpdateUserDetails: Subscription;
    subRoute: Subscription;

    constructor(
        private userService: UserService,
        public appComponent: AppComponent,
        private route: ActivatedRoute,
        private toastr: ToastrService,
        private formBuilder: FormBuilder,
        private router: Router,
    ) {
        this.userId = localStorage.getItem('resetUI');
    }

    ngOnInit() {
        this.updatePasswordForm = this.formBuilder.group({
            password: ['', [
                Validators.required,
                Validators.pattern('^(?=.*[A-Z])(?=.*[a-z])((?=.*\\d)|(?=.*\\W)).{6,}$')]// one uppercase letter, one lowercase letter, symbol or number, lenght should not less than 6
            ],
            confirmPassword: ['', Validators.required]
        }, {
            validator: this.checkPasswords
        });
    }

    ngOnDestroy() {
        if (this.subUpdateUserDetails != undefined) {
            this.subUpdateUserDetails.unsubscribe();
        }
        if (this.subRoute != undefined) {
            this.subRoute.unsubscribe();
        }
    }

    checkPasswords(group: FormGroup) {
        const pass = group.get('password').value;
        const confirmPass = group.get('confirmPassword').value;
        if (pass && confirmPass) {
            return pass === confirmPass ? null : { notSame: true };
        }
    }

    showHidePassword() {
        this.showPassword = (this.showPassword) ? false : true;
    }

    showHidePasswordConfirm() {
        this.showPasswordConfirm = (this.showPasswordConfirm) ? false : true;
    }

    onSubmit() {
        this.submitted = true;
        if (this.updatePasswordForm.invalid) {
            return false;
        }

        const data = {
            password: this.updatePasswordForm.controls.password.value
        };

        this.userService.updateUserDetails(this.userId, data).subscribe((result: any) => {
            Swal.fire({
                text: 'Password updated successfully.',
                confirmButtonColor: '#45c0a6',
                confirmButtonText: 'OK',
                showConfirmButton: true,
            });
            this.router.navigate(['user-list/' + this.userId]);
            localStorage.removeItem('resetUI');
        });
    }

}
