<!-- <div class="content-wrapper"> -->
<section class="content-header">
  <h1>
    User Details
  </h1>
  <a routerLink="/user-list">&#60;&#60;Back to Users</a>
</section>

<section class="content">
  <div class="row">
    <div class="col-12">
      <div class="box box-solid">
        <div class="box-body">
          <!-- <div class="row"> -->
          <div class="d-flex justify-content-center">
            <div class="common-loader" *ngIf="loading">
              <img class="imgWidth" src="./assets/public_img_load.gif" />
            </div>
          </div>
          <div class="col-md-12 col-lg-6">
            <div *ngIf="test">
              <div class="row">
                <!-- <div class="col-md-5 col-xl-3 col-lg-4 col-sm-3"> -->
                <div class="col-12 col-sm-4 col-lg-4 col-md-5">
                  <!-- <div class="row"> -->
                  <div class="avatar-container">
                    <div class="source">
                      <img *ngIf="!selectedImageSrc" [src]="profileImg" alt="" class="image-display img-circle hW">
                      <img *ngIf="selectedImageSrc" [src]="profileImg" alt="" class="image-display img-circle hW">
                    </div>
                    <fieldset [disabled]="isEmployee">
                      <div class="edit-icon">
                        <app-image-selector (imageValue)="imageSelect($event)"></app-image-selector>
                      </div>
                    </fieldset>
                  </div>
                  <!-- </div> -->
                </div>

                <div class="col-12 col-sm-8 col-lg-8 col-md-7">
                  <h4>{{user.first_name}}&nbsp;{{user.last_name}}</h4>
                  <!-- <div class="row"> -->
                  <fieldset [disabled]="isEmployee">
                    <div>User Role : <input value="1" type="checkbox" (click)="checkAdminChecked($event)"
                        [checked]="adminCheck" [disabled]="adminCheck">
                      Admin&nbsp;
                      <input value="2" type="checkbox" [checked]="employeeCheck" (click)="checkEmployeeChecked($event)"
                        id="employee" [disabled]="isEmployee">
                      Employee</div>
                  </fieldset>
                  <div class="invalid-feedback" *ngIf="roleRequired">
                    <div *ngIf="roleRequired">required</div>
                  </div>
                  <!-- </div> -->
                  <div>User Group : &nbsp;
                    <span *ngIf="user.user_groups.length!=0"><u> <span
                          *ngFor="let group of user.user_groups;let lst=last"><a
                            [routerLink]="[ '/user-group-details', group.id ]" [class.disabled]="isEmployee"
                            [attr.href]="isEmployee ? null : '_blank'">{{group.label}}</a>
                          <span *ngIf="!lst">,
                          </span>
                        </span>
                      </u>
                    </span>
                    <span *ngIf="user.user_groups.length==0">
                      <span>None</span>
                    </span>
                  </div>
                  <div><u style="color: #3c8dbc;"><a [class.disabled]="isEmployee"
                        (click)="!isEmployee && resetPW()">Reset
                        Password</a></u></div>
                </div>
              </div>
              <form *ngIf="true" [formGroup]="userDetailForm" (ngSubmit)="onSubmit()">
                <div class="form-group" style="margin-top: 10px;">
                  <div class="form-group has-feedback row">
                    <div class="col-md-6 col-sm-12">
                      <label for="firstName">First Name</label>
                      <input type="text" class="form-control form-control-lg rounded-0 has-feedback" id="firstName"
                        placeholder="First Name" formControlName="firstName" [ngClass]="{'red-border-class':
                      (f.firstName.errors && submitted)}" required>
                      <div class="invalid-feedback" *ngIf="submitted && f.firstName.errors">
                        <div *ngIf="f.firstName.errors.required">required
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 col-sm-12">
                      <label for="firstName">Last Name</label>
                      <input type="text" class="form-control form-control-lg rounded-0 has-feedback" id="lastName"
                        placeholder="Last Name" formControlName="lastName" [ngClass]="{'red-border-class':
                      (f.lastName.errors && submitted)}" required>
                      <div class="invalid-feedback" *ngIf="submitted && f.lastName.errors">
                        <div *ngIf="f.lastName.errors.required">required</div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-md-12">
                      <label for="firstName">Email</label>
                      <input type="text" class="form-control form-control-lg rounded-0 has-feedback" id="firstName"
                        placeholder="Email" formControlName="email" [ngClass]="{'red-border-class':
                      (f.email.errors && submitted)}" required>
                      <div class="invalid-feedback" *ngIf="submitted && f.email.errors">
                        <div *ngIf="f.email.errors.required">required</div>
                        <div *ngIf="f.email.errors.pattern">invalid</div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-md-12">
                      <label for="firstName">Address Line 1</label>
                      <input type="text" class="form-control" id="firstName" placeholder="Address Line 1"
                        formControlName="address1">
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-md-12">
                      <label for="firstName">Address Line 2</label>
                      <input type="text" class="form-control" id="firstName" placeholder="Address Line 2"
                        formControlName="address2">
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-md-5 col-sm-12">
                      <label for="city">City</label>
                      <input type="text" class="form-control" id="city" placeholder="City" formControlName="city">
                    </div>
                    <div class="col-md-4 col-sm-12">
                      <label for="state">State</label>
                       <p> <span *ngIf="viewSelectText" class='pretend-option'>Select</span>
                      <select class="form-control " formControlName="state" id="state" (change)="isClickState($event)">
                        <option *ngFor="let state of statesList" value="{{state}}">{{state}}
                        </option>
                      </select>
                    </div>
                    <div class="col-md-3 col-sm-12">
                      <label for="zip">ZIP</label>
                      <input type="text" class="form-control form-control-lg rounded-0 has-feedback" id="zip"
                        placeholder="ZIP" formControlName="zip" [ngClass]="{'red-border-class':
                        (f.zip.errors && submitted)}">
                      <div class="invalid-feedback" *ngIf="submitted && f.zip.errors">
                        <div *ngIf="f.zip.errors && submitted">invalid</div>
                        <!-- <div *ngIf="f.phone1.errors && submitted && (f.phone1.dirty || f.phone1.touched)">invalid</div> -->
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-md-6 col-sm-12">
                      <label for="city">Phone 1</label>
                      <input type="text" class="form-control form-control-lg rounded-0 has-feedback" id="phone1"
                        placeholder="Phone" formControlName="phone1" [ngClass]="{'red-border-class':
                      (f.phone1.errors && submitted)}">
                      <div class="invalid-feedback" *ngIf="submitted && f.phone1.errors">
                        <div *ngIf="f.phone1.errors && submitted">invalid</div>
                        <!-- <div *ngIf="f.phone1.errors && submitted && (f.phone1.dirty || f.phone1.touched)">invalid</div> -->
                      </div>
                    </div>
                  </div>

                  <div class="form-group row notifications">
                    <div class="col-md-6 col-sm-12">
                      <label style="margin-right: 10px">Preferred Notification Type: </label>
                      <span>SMS: </span>
                      <input formControlName="prefer_sms" type="checkbox" style="margin-left: 5px; margin-right: 5px">
                      <span>Email: </span>
                      <input formControlName="prefer_email" type="checkbox" style="margin-left: 5px">
                    </div>
                  </div>

                  <div class="form-group row notifications">
                    <div class="col-md-6 col-sm-12">
                      <label style="margin-right: 10px">Turn Off SMS Notifications: </label>
                      <input formControlName="notifications_off" type="checkbox" style="margin-left: 5px; margin-right: 5px">
                    </div>
                  </div>

                  <div class="form-group row notifications">
                    <div class="col-md-6 col-sm-12">
                      <label for="city">Turn Off SMS Notifications From: </label>

                      <div class="am-pm-selects notifications">
                        <select class="form-control" formControlName="timeOne" id="timeOne" (change)="isClickTime($event)">
                          <option *ngFor="let time of timesList" value="{{time.hour}}">{{time.time}}
                          </option>
                        </select>

                        <!-- <p><span *ngIf="viewSelectText" class='pretend-option'>Select</span>
                        <select class="form-control" formControlName="timeTwo" id="timeTwo" (change)="isClickTimeTwo($event)">
                          <option *ngFor="let time of timeList" value="{{time}}">{{time}}
                          </option>
                        </select> -->
                    

                        <div style="display: flex; flex-direction: row; align-items: center">
                          <span style="margin-left: 5px; margin-right: 5px"> To: </span>
                        </div>

                        <select class="form-control" formControlName="timeTwo" id="timeTwo">
                          <option *ngFor="let time of secondTimesList" value="{{time.hour}}">{{time.time}}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
  
                  <!-- <div *ngIf="viewNote"> -->
                  <div class="form-group row">
                    <div class="col-md-12">
                      <label for="city">Notes</label>
                      <textarea class="form-control" rows="4" formControlName="notes"></textarea>
                    </div>
                  </div>
                  <!-- </div> -->
                  <div class="form-group row">
                    <div class="col-md-12">
                      <input [disabled]="isEmployee" type="button" type="submit" class=" btn btn-success"
                        value="Update User" style="float: left;">
                      <div *ngIf="isDeleteBtnVisible">
                        <input type="button" [disabled]="isEmployee" class=" btn btn-danger" value="Delete User"
                          style="float: right;" (click)="deleteUser()">
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <!-- </div> -->
        </div>
      </div>
    </div>
  </div>
</section>
<!-- </div> -->
