import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { AuthGuard } from "./guards/auth.guard";
import { CreateUserComponent } from "./dashboard/users/create-user/create-user.component";
import { ExposureReportComponent } from "./dashboard/reports/exposure-report/exposure-report.component";
import { FormulaManagementComponent } from "./dashboard/management/formula-management/formula-management.component";
import { HistoricalOrdersComponent } from "./dashboard/historical-orders/historical-orders.component";
import { HomeComponent } from "./dashboard/home/home.component";
import { ImageSelectorComponent } from "./core/image-selector/image-selector.component";
import { LoginComponent } from "./user/login/login.component";
import { MaxGainChartComponent } from "./dashboard/management/max-gain-chart/max-gain-chart/max-gain-chart.component";
import { NewPasswordComponent } from "./user/new-password/new-password.component";
import { NotificationsComponent } from "./dashboard/management/notifications/notifications.component";
import { PasswordResetComponent } from "./user/password-reset/password-reset.component";
import { PortfolioComponent } from "./dashboard/portfolio/portfolio.component";
import { ReportsComponent } from "./dashboard/reports/reports.component";
import { ScheduledMessagesComponent } from "./dashboard/scheduled-messages/scheduled-messages.component";
import { StrategiesComponent } from "./dashboard/management/strategies/strategies.component";
import { SymbolsComponent } from "./dashboard/management/symbols/symbols.component";
import { UpdatePasswordComponent } from "./dashboard/users/update-password/update-password.component";
import { UserDetailsComponent } from "./dashboard/users/user-details/user-details.component";
import { UserListComponent } from "./dashboard/users/user-list/user-list.component";

const routes: Routes = [
  {
    path: "login",
    component: LoginComponent,
    data: {
      customLayout: true,
    },
  },
  {
    path: "",
    component: HomeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "reports",
    canActivate: [AuthGuard],
    children: [
      { path: "", component: ReportsComponent },
      {
        path: "exposure-report",
        component: ExposureReportComponent,
      },
    ],
  },
  {
    path: "formula-management",
    component: FormulaManagementComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "max-gain-chart",
    component: MaxGainChartComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "user-list",
    component: UserListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "password-recovery",
    component: PasswordResetComponent,
    data: {
      customLayout: true,
    },
  },
  {
    path: "set-new-password",
    component: NewPasswordComponent,
    data: {
      customLayout: true,
    },
  },
  {
    path: "historical-orders",
    component: HistoricalOrdersComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "portfolio",
    component: PortfolioComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "symbols",
    component: SymbolsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "scheduled-messages",
    component: ScheduledMessagesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "strategies",
    component: StrategiesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "messages",
    component: NotificationsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "max-gain-settings",
    component: MaxGainChartComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "user-list/user-create",
    component: CreateUserComponent,
    data: {
      customLayout: false,
    },
  },
  {
    path: "formula-management",
    component: FormulaManagementComponent,
    data: {
      customLayout: false,
    },
  },
  {
    path: "image-upload",
    component: ImageSelectorComponent,
  },
  {
    path: "user-list/:id",
    component: UserDetailsComponent,
    canActivate: [AuthGuard],
  },
  { path: "update-password", component: UpdatePasswordComponent },
  { path: "**", redirectTo: "" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
