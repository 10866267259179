<html>
  <div *ngIf="this.fullScreenService.isFullScreen" class="full-screen-head">
    <div class="full-screen-info full-screen-page-title">
      <p>Domek Web Trader - Historical Orders</p>
    </div>
    <div class="full-screen-info full-screen-show-filters-btn">
      <p *ngIf="!filtersCollapsed && this.fullScreenService.isFullScreen">
        <i class="fa fa-angle-double-right"></i
        ><a (click)="toggleFiltersCollapse()"> View Tools & Filters </a
        ><i class="fa fa-angle-double-left"></i>
      </p>
      <p *ngIf="filtersCollapsed && this.fullScreenService.isFullScreen">
        <i class="fa fa-angle-double-down"></i
        ><a (click)="toggleFiltersCollapse()"> View Tools & Filters </a
        ><i class="fa fa-angle-double-down"></i>
      </p>
    </div>
    <div class="full-screen-info full-screen-controls">
      <p class="full-screen-head-button">
        <a
          ><span (click)="fullScreen()"
            ><i class="fa fa-compress"></i> Exit Full Screen</span
          ></a
        >
      </p>
      <p class="full-screen-head-button">
        <a
          ><span (click)="logout()"
            ><i class="fa fa-sign-out"></i> Sign out</span
          ></a
        >
      </p>
    </div>
  </div>

  <div
    *ngIf="
      !this.fullScreenService.isFullScreen ||
      (filtersCollapsed && this.fullScreenService.isFullScreen)
    "
    [ngClass]="{
      'filter-head': !this.fullScreenService.isFullScreen,
      'filter-head-full-screen':
        filtersCollapsed && this.fullScreenService.isFullScreen
    }"
  >
    <div class="headerContainer">
      <div class="removeAllFilterOptionsButtons">
        <!-- <h2>Historical Orders</h2> -->

        <span class="fill-incoming" *ngIf="incomingFill">
          Incoming Fill Detected...
        </span>

        <a
          *ngIf="!this.fullScreenService.isFullScreen"
          class="clearAllFiltersBtn"
          (click)="fullScreen()"
          ><i class="fa fa-expand"></i> Full Screen</a
        >
        <button class="clearAllFiltersBtn" (click)="removeAllFilters()">
          <i class="fa fa-eraser"></i> Clear All Filters
        </button>

        <!-- Commenting this out until we figure out how to auto size the virtual scroll viewport <a
          type="button"
          class="clearAllFiltersBtn close-footer"
          aria-label="Close"
          (click)="closeFooter()"
          >Hide Footer</a
        > -->

        <div ngbDropdown>
          <a id="dropdownBasic1" class="clearAllFiltersBtn" ngbDropdownToggle
            >Show Columns <i class="fa fa-caret-down"></i
          ></a>
          <div
            class="dropdown-container"
            ngbDropdownMenu
            aria-labelledby="dropdownBasic1"
          >
            <div class="fixed-button">
              <a
                class="clearAllFiltersBtn show-all-columns-button"
                (click)="showAllColumns()"
              >
                Show All Columns</a
              >
            </div>
            <div class="overflow">
              <ng-container *ngFor="let column of allColumns">
                <p *ngIf="column.columnName.length">
                  <a (click)="showSpecificColumns(column.dbName)">
                    <span>
                      <i
                        class="fa fa-check"
                        [ngClass]="{ 'check-icon': column.hidden == false }"
                      ></i>
                    </span>
                    {{ column.columnName }}
                  </a>
                </p>
                <p *ngIf="column.dbName === 'at_10k'">
                  <a (click)="showSpecificColumns(column.dbName)">
                    <span>
                      <i
                        class="fa fa-check"
                        [ngClass]="{ 'check-icon': column.hidden == false }"
                      ></i>
                    </span>
                    At {{ atThisPrice }}
                  </a>
                </p>
                <hr class="hr-list-bottom" />
              </ng-container>
            </div>
          </div>
        </div>

        <div
          *ngIf="!filtersCollapsed && !this.fullScreenService.isFullScreen"
          class="hideAllFiltersBtn"
        >
          <i
            class="clearAllFiltersBtn"
            (click)="toggleFiltersCollapse()"
            class="fa fa-caret-left fa-2x"
          ></i>
        </div>
        <div
          *ngIf="filtersCollapsed && !this.fullScreenService.isFullScreen"
          class="hideAllFiltersBtn"
        >
          <i
            class="clearAllFiltersBtn"
            (click)="toggleFiltersCollapse()"
            class="fa fa-caret-down fa-2x"
          ></i>
        </div>
      </div>
    </div>
  </div>

  <div [hidden]="!filtersCollapsed" class="filter-collector">
    <div *ngIf="filterCriteria" class="filter-criteria">
      <div class="page-filters">
        <div>
          &nbsp;
          <button
            class="custom-btn"
            [disabled]="loading"
            (click)="selectAllLoadedHistoricalOrders()"
          >
            Select All
          </button>
          &nbsp;
          <button
            class="custom-btn"
            [disabled]="loading"
            (click)="deselectAllLoadedHistoricalOrders()"
          >
            Deselect All
          </button>
          &nbsp;
          <button
            class="custom-btn dngr"
            [disabled]="archiveList.length == 0 || loading"
            (click)="archiveSelectedHistoricalOrders()"
          >
            Archive
          </button>
          &nbsp;
          <div style="display: inline-block" ngbDropdown>
            <button
              id="dropdownBasic2"
              class="custom-btn archive-btn"
              ngbDropdownToggle
            >
              {{ selectedArchiveTypeName }} <i class="fa fa-caret-down"></i>
            </button>
            <div
              class="archive-dropdown"
              ngbDropdownMenu
              aria-labelledby="dropdownBasic1"
            >
              <div class="archive-btn">
                <a
                  ngbDropdownToggle
                  (click)="setSelectedArchiveType('show_all')"
                  >Show All</a
                >
              </div>
              <div class="archive-btn">
                <a
                  ngbDropdownToggle
                  (click)="setSelectedArchiveType('show_archived')"
                  >Show Archived</a
                >
              </div>
              <div class="archive-btn">
                <a
                  ngbDropdownToggle
                  (click)="setSelectedArchiveType('show_current')"
                  >Show Current</a
                >
              </div>
            </div>
          </div>
          &nbsp;
          <button (click)="exportToExcel()" class="custom-btn export-btn">
            Export to Excel
          </button>
          <input
            [disabled]="loading"
            (change)="getAt10k($any($event.target).value)"
            type="text"
            class="total-exposure-btn"
            placeholder="Total Exposure"
          />
        </div>
      </div>
    </div>
    <div
      class="bubble-list"
      *ngFor="let filter of bubbleList | keyvalue: returnZero"
    >
      <app-filter-bubble
        class="bubble-list"
        [filterName]="filter.key"
        [filterValue]="filter.value"
      ></app-filter-bubble>
    </div>
  </div>

  <div class="table-container">
    <div
      class="table-responsive"
      [ngClass]="{
        'table-responsive-statndard':
          filtersCollapsed == false && !this.fullScreenService.isFullScreen,
        'table-responsive-fullscreen':
          filtersCollapsed == false && this.fullScreenService.isFullScreen,
        'table-responsive-pagecontrols-container':
          filtersCollapsed == true && !this.fullScreenService.isFullScreen,
        'table-responsive-fullscreen-pagecontrols-container':
          filtersCollapsed == true && this.fullScreenService.isFullScreen,
        'table-responsive-pagecontrols-container-filters':
          filtersCollapsed == true &&
          !this.fullScreenService.isFullScreen &&
          bubbles == true,
        'table-responsive-fullscreen-pagecontrols-container-filters':
          filtersCollapsed == true &&
          this.fullScreenService.isFullScreen &&
          bubbles == true
      }"
    >
      <cdk-virtual-scroll-viewport
        itemSize="25"
        class="table-viewport"
        minBufferPx="1000"
        maxBufferPx="1000"
      >
        <div *ngIf="loading" class="spinner-container">
          <div role="status" class="spinner-border">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <table
          class="table table-striped"
          [style.visibility]="loading ? 'hidden' : 'visible'"
          cdkFixedHeader
        >
          <thead style="visibility: hidden">
            <tr>
              <th>Select</th>
              <th [hidden]="dateColumn.hidden" class="historicalTableHead">
                <app-table-header-date
                  [loading]="loading"
                  [column]="dateColumn"
                ></app-table-header-date>
                <i class="fa blue-icon fa-info-circle hide-display">
                  <span class="show-display-on-hover"> Purchase Date </span>
                </i>
              </th>

              <th [hidden]="acctColumn.hidden" class="historicalTableHead">
                <app-table-header-filtered-list
                  [loading]="loading"
                  [tableName]="tableName"
                  [column]="acctColumn"
                >
                </app-table-header-filtered-list>

                <i class="fa blue-icon fa-info-circle hide-display">
                  <span class="show-display-on-hover">
                    Trading Account holding the position
                  </span>
                </i>
              </th>
              <th [hidden]="tickerColumn.hidden" class="historicalTableHead">
                <app-table-header-filtered-list
                  [column]="tickerColumn"
                  [tableName]="tableName"
                  [loading]="loading"
                ></app-table-header-filtered-list>

                <i class="fa blue-icon fa-info-circle hide-display">
                  <span class="show-display-on-hover"> Stock Symbol </span>
                </i>
              </th>
              <th [hidden]="daysColumn.hidden" class="historicalTableHead">
                <app-table-header
                  [column]="daysColumn"
                  [loading]="loading"
                ></app-table-header>

                <i class="fa blue-icon fa-info-circle hide-display">
                  <span class="show-display-on-hover">
                    Number of days until expiration
                  </span>
                </i>
              </th>
              <th
                [hidden]="expirationColumn.hidden"
                class="historicalTableHead"
              >
                <app-table-header-filtered-list
                  [column]="expirationColumn"
                  [tableName]="tableName"
                  [loading]="loading"
                ></app-table-header-filtered-list>

                <i class="fa blue-icon fa-info-circle hide-display">
                  <span class="show-display-on-hover">
                    Date the option Expires
                  </span>
                </i>
              </th>

              <th [hidden]="quantityColumn.hidden" class="historicalTableHead">
                <app-table-header
                  [column]="quantityColumn"
                  [loading]="loading"
                ></app-table-header>

                <i class="fa blue-icon fa-info-circle hide-display">
                  <span class="show-display-on-hover">
                    Total number of contracts
                  </span>
                </i>
              </th>

              <th [hidden]="strikeOneColumn.hidden" class="historicalTableHead">
                <app-table-header
                  [loading]="loading"
                  [column]="strikeOneColumn"
                >
                </app-table-header>

                <i class="fa blue-icon fa-info-circle hide-display">
                  <span class="show-display-on-hover">
                    First Strike in an order
                  </span>
                </i>
              </th>

              <th [hidden]="strikeTwoColumn.hidden" class="historicalTableHead">
                <app-table-header
                  [loading]="loading"
                  [column]="strikeTwoColumn"
                >
                </app-table-header>

                <i class="fa blue-icon fa-info-circle hide-display">
                  <span class="show-display-on-hover">
                    Second Strike in an order
                  </span>
                </i>
              </th>

              <th
                [hidden]="optionTypeColumn.hidden"
                class="historicalTableHead"
              >
                <app-table-header-filtered-list
                  [loading]="loading"
                  [tableName]="tableName"
                  [column]="optionTypeColumn"
                ></app-table-header-filtered-list>
                <i class="fa blue-icon fa-info-circle hide-display">
                  <span class="show-display-on-hover">
                    CALL or PUT Option
                  </span>
                </i>
              </th>

              <th [hidden]="costColumn.hidden" class="historicalTableHead">
                <app-table-header
                  [column]="costColumn"
                  [loading]="loading"
                ></app-table-header>

                <i class="fa blue-icon fa-info-circle hide-display">
                  <span class="show-display-on-hover">
                    Date option purchase expires
                  </span>
                </i>
              </th>

              <th [hidden]="exposureColumn.hidden" class="historicalTableHead">
                <app-table-header
                  [column]="exposureColumn"
                  [loading]="loading"
                ></app-table-header>

                <i class="fa blue-icon fa-info-circle hide-display">
                  <span class="show-display-on-hover">
                    Date option purchase expires
                  </span>
                </i>
              </th>

              <th
                [hidden]="moveNeededColumn.hidden"
                class="historicalTableHead"
              >
                <app-table-header
                  [column]="moveNeededColumn"
                  [loading]="loading"
                ></app-table-header>

                <i class="fa blue-icon fa-info-circle hide-display">
                  <span class="show-display-on-hover">
                    Date option purchase expires
                  </span>
                </i>
              </th>

              <th [hidden]="maxGainColumn.hidden" class="historicalTableHead">
                <app-table-header
                  [loading]="loading"
                  [column]="maxGainColumn"
                ></app-table-header>

                <i class="fa blue-icon fa-info-circle hide-display">
                  <span class="show-display-on-hover">
                    Date option purchase expires
                  </span>
                </i>
              </th>
              <th [hidden]="tableNumColumn.hidden" class="historicalTableHead">
                <app-table-header-filtered-list
                  [loading]="loading"
                  [tableName]="tableName"
                  [column]="tableNumColumn"
                ></app-table-header-filtered-list>
                <i class="fa blue-icon fa-info-circle hide-display">
                  <span class="show-display-on-hover">
                    Date option purchase expires
                  </span>
                </i>
              </th>

              <th [hidden]="qualityColumn.hidden" class="historicalTableHead">
                <app-table-header
                  [column]="qualityColumn"
                  [loading]="loading"
                ></app-table-header>

                <i class="fa blue-icon fa-info-circle hide-display">
                  <span class="show-display-on-hover">
                    Date option purchase expires
                  </span>
                </i>
              </th>

              <th [hidden]="typeColumn.hidden" class="historicalTableHead">
                <app-table-header-filtered-list
                  #typeFilteredList
                  [loading]="loading"
                  [tableName]="tableName"
                  [column]="typeColumn"
                ></app-table-header-filtered-list>
                <i class="fa blue-icon fa-info-circle hide-display">
                  <span class="show-display-on-hover">
                    Date option purchase expires
                  </span>
                </i>
              </th>
              <th [hidden]="categoryColumn.hidden" class="historicalTableHead">
                <app-table-header-filtered-list
                  [column]="categoryColumn"
                  [tableName]="tableName"
                  [loading]="loading"
                ></app-table-header-filtered-list>
                <i class="fa blue-icon fa-info-circle hide-display">
                  <span class="show-display-on-hover">
                    Date option purchase expires
                  </span>
                </i>
              </th>

              <th [hidden]="at10kColumn.hidden" class="historicalTableHead">
                At {{ atThisPrice }}
                <i class="fa blue-icon fa-info-circle hide-display">
                  <span class="show-display-on-hover">
                    Date option purchase expires
                  </span>
                </i>
                <app-table-header
                  class="user-input"
                  [column]="at10kColumn"
                  [loading]="loading"
                >
                </app-table-header>
              </th>

              <th
                [hidden]="targetReturnFillColumn.hidden"
                class="historicalTableHead"
              >
                <app-table-header
                  [loading]="loading"
                  [column]="targetReturnFillColumn"
                ></app-table-header>

                <i class="fa blue-icon fa-info-circle hide-display">
                  <span class="show-display-on-hover">
                    Date option purchase expires
                  </span>
                </i>
              </th>

              <th
                [hidden]="targetReturnCurrentColumn.hidden"
                class="historicalTableHead"
              >
                <app-table-header
                  [loading]="loading"
                  [column]="targetReturnCurrentColumn"
                ></app-table-header>
                <i class="fa blue-icon fa-info-circle hide-display">
                  <span class="show-display-on-hover">
                    Date option purchase expires
                  </span>
                </i>
              </th>

              <th
                [hidden]="targetPercentColumn.hidden"
                class="historicalTableHead"
              >
                <app-table-header
                  [loading]="loading"
                  [column]="targetPercentColumn"
                ></app-table-header>
                <i class="fa blue-icon fa-info-circle hide-display">
                  <span class="show-display-on-hover">
                    Date option purchase expires
                  </span>
                </i>
              </th>

              <th
                [hidden]="at100PercentColumn.hidden"
                class="historicalTableHead"
              >
                <app-table-header
                  [loading]="loading"
                  [column]="at100PercentColumn"
                ></app-table-header>
                <i class="fa blue-icon fa-info-circle hide-display">
                  <span class="show-display-on-hover">
                    Date option purchase expires
                  </span>
                </i>
              </th>

              <th
                [hidden]="at95PercentColumn.hidden"
                class="historicalTableHead"
              >
                <app-table-header
                  [loading]="loading"
                  [column]="at95PercentColumn"
                ></app-table-header>
                <i class="fa blue-icon fa-info-circle hide-display">
                  <span class="show-display-on-hover">
                    Date option purchase expires
                  </span>
                </i>
              </th>

              <th
                [hidden]="at90PercentColumn.hidden"
                class="historicalTableHead"
              >
                <app-table-header
                  [loading]="loading"
                  [column]="at90PercentColumn"
                ></app-table-header>
                <i class="fa blue-icon fa-info-circle hide-display">
                  <span class="show-display-on-hover">
                    Date option purchase expires
                  </span>
                </i>
              </th>

              <th
                [hidden]="priceAtUploadColumn.hidden"
                class="historicalTableHead"
              >
                <app-table-header
                  [loading]="loading"
                  [column]="priceAtUploadColumn"
                ></app-table-header>
                <i class="fa blue-icon fa-info-circle hide-display">
                  <span class="show-display-on-hover">
                    Date option purchase expires
                  </span>
                </i>
              </th>

              <th
                [hidden]="priceAtFillColumn.hidden"
                class="historicalTableHead"
              >
                <app-table-header
                  [loading]="loading"
                  [column]="priceAtFillColumn"
                ></app-table-header>
                <i class="fa blue-icon fa-info-circle hide-display">
                  <span class="show-display-on-hover">
                    Date option purchase expires
                  </span>
                </i>
              </th>

              <th [hidden]="moveSinceColumn.hidden" class="historicalTableHead">
                <app-table-header
                  [loading]="loading"
                  [column]="moveSinceColumn"
                ></app-table-header>
                <i class="fa blue-icon fa-info-circle hide-display">
                  <span class="show-display-on-hover">
                    Date option purchase expires
                  </span>
                </i>
              </th>

              <th
                [hidden]="percentInterestColumn.hidden"
                class="historicalTableHead"
              >
                <app-table-header
                  [loading]="loading"
                  [column]="percentInterestColumn"
                ></app-table-header>
                <i class="fa blue-icon fa-info-circle hide-display">
                  <span class="show-display-on-hover">
                    Date option purchase expires
                  </span>
                </i>
              </th>

              <th
                [hidden]="partialFillColumn.hidden"
                class="historicalTableHead"
              >
                <app-table-header-filtered-list
                  [loading]="loading"
                  [tableName]="tableName"
                  [column]="partialFillColumn"
                  [optionalList]="getOptionalLists('partial_fill')"
                >
                </app-table-header-filtered-list>
                <i class="fa blue-icon fa-info-circle hide-display">
                  <span class="show-display-on-hover">
                    Date option purchase expires Option Type
                  </span>
                </i>
              </th>
            </tr>
          </thead>

          <tbody>
            <!-- <ng-container *ngIf="historicalOrders && !loading"> -->
            <tr
              [ngClass]="{
                'is-false': historicalOrder.is_partial == 'NO'
              }"
              *cdkVirtualFor="
                let historicalOrder of historicalOrders;
                templateCacheSize: 50
              "
            >
              <td style="text-align: center">
                <input
                  *ngIf="historicalOrder.archived_at == null"
                  type="checkbox"
                  [checked]="historicalOrder.checkStatus"
                  id="archive"
                  value="{{ historicalOrder.fill_id_1 }}"
                  (change)="selectSelect($event)"
                />
                <a
                  class="link"
                  *ngIf="historicalOrder.archived_at != null"
                  (click)="unarchiveHistoricalOrder(historicalOrder)"
                  >Unarchive</a
                >
              </td>
              <td
                id="historical-order-data-cell"
                [hidden]="dateColumn.hidden"
                class="text-align-center"
                title="{{ historicalOrder.date }}"
              >
                <ng-container *ngIf="historicalOrder.date != ''">
                  {{ historicalOrder.date | date: "mediumDate" }}
                </ng-container>
                <ng-container
                  *ngIf="
                    historicalOrder.date == '' || historicalOrder.date == null
                  "
                >
                </ng-container>
              </td>
              <td [hidden]="acctColumn.hidden" class="text-align-center">
                {{ historicalOrder.account_name }}
              </td>
              <td [hidden]="tickerColumn.hidden" class="text-align-center">
                <a
                  class="link"
                  (click)="openYahooData(content, historicalOrder.symbol)"
                  >{{ historicalOrder.symbol }}</a
                >
              </td>
              <td [hidden]="daysColumn.hidden">
                {{ historicalOrder.expiration_days_away }}
              </td>
              <td [hidden]="expirationColumn.hidden">
                {{ historicalOrder.expiration_date | date: "mediumDate" }}
              </td>
              <td [hidden]="quantityColumn.hidden" class="text-align-center">
                {{ historicalOrder.quantity }}
              </td>
              <td [hidden]="strikeOneColumn.hidden" class="text-align-center">
                {{ historicalOrder.strike_1 | number: "1.1-2" }}
              </td>
              <td [hidden]="strikeTwoColumn.hidden" class="text-align-center">
                {{ historicalOrder.strike_2 | number: "1.1-2" }}
              </td>
              <td [hidden]="optionTypeColumn.hidden" class="text-align-center">
                {{ historicalOrder.security_type }}
              </td>
              <td [hidden]="costColumn.hidden" class="usd">
                <span *ngIf="historicalOrder.cost">
                  ${{ historicalOrder.cost | number: "1.2-2" }}
                </span>
              </td>
              <td [hidden]="exposureColumn.hidden" class="usd">
                <span *ngIf="historicalOrder.exposure">
                  ${{ historicalOrder.exposure | number: "1.2-2" }}
                </span>
              </td>
              <td [hidden]="moveNeededColumn.hidden" class="percent">
                <ng-container *ngIf="historicalOrder.move_needed != 0">
                  <span *ngIf="historicalOrder.move_needed">
                    {{ historicalOrder.move_needed }}%
                  </span>
                </ng-container>
                <ng-container *ngIf="historicalOrder.move_needed == 0">
                </ng-container>
              </td>
              <td [hidden]="maxGainColumn.hidden" class="percent">
                <ng-container
                  *ngIf="
                    historicalOrder.max_gain != 0 ||
                    historicalOrder.max_gain != null
                  "
                >
                  <span *ngIf="historicalOrder.max_gain != 'Error'">
                    {{ historicalOrder.max_gain }}%
                  </span>
                </ng-container>
                <ng-container
                  *ngIf="
                    historicalOrder.max_gain == 0 ||
                    historicalOrder.max_gain == null
                  "
                >
                </ng-container>
              </td>
              <td [hidden]="tableNumColumn.hidden" class="text-align-center">
                <ng-container *ngIf="historicalOrder.table_number != 0">
                  {{ historicalOrder.table_number }}
                </ng-container>
                <ng-container *ngIf="historicalOrder.table_number == 0">
                </ng-container>
              </td>
              <td [hidden]="qualityColumn.hidden" class="text-align-center">
                {{ historicalOrder.target_return_quality }}
              </td>
              <td [hidden]="typeColumn.hidden">{{ historicalOrder.type }}</td>
              <td [hidden]="categoryColumn.hidden">
                {{ historicalOrder.category }}
              </td>
              <td [hidden]="at10kColumn.hidden" class="text-align-center">
                <span *ngIf="historicalOrder.at_10k">
                  {{ historicalOrder.at_10k }}
                </span>
              </td>
              <td [hidden]="targetReturnFillColumn.hidden" class="percent">
                <ng-container *ngIf="historicalOrder.target_return_fill != 0">
                  {{ historicalOrder.target_return_fill }}%
                </ng-container>
                <ng-container *ngIf="historicalOrder.target_return_fill == 0">
                </ng-container>
              </td>

              <td [hidden]="targetReturnCurrentColumn.hidden" class="percent">
                <ng-container
                  *ngIf="historicalOrder.target_return_current != 0"
                >
                  {{ historicalOrder.target_return_current }}%
                </ng-container>
                <ng-container
                  *ngIf="historicalOrder.target_return_current == 0"
                >
                </ng-container>
              </td>

              <td
                [ngClass]="{
                  'over-125': historicalOrder.target_percent > 124,
                  'over-100':
                    historicalOrder.target_percent > 99 &&
                    historicalOrder.target_percent < 125,
                  'under 90': historicalOrder.target_percent < 90
                }"
                [hidden]="targetPercentColumn.hidden"
                class="percent"
              >
                <ng-container *ngIf="historicalOrder.target_percent != 0">
                  <span *ngIf="historicalOrder.target_percent != 'error'">
                    {{ historicalOrder.target_percent }}%
                  </span>
                </ng-container>
                <ng-container
                  *ngIf="
                    historicalOrder.target_percent == 0 ||
                    historicalOrder.target_percent == '' ||
                    historicalOrder.target_percent == null
                  "
                >
                </ng-container>
              </td>

              <td
                [hidden]="at100PercentColumn.hidden"
                class="text-align-center"
              >
                <ng-container
                  *ngIf="historicalOrder.at_100_percent_percent != 0"
                >
                  {{ historicalOrder.at_100_percent_percent
                  }}<span *ngIf="historicalOrder.at_100_percent_percent"
                    >%</span
                  >
                </ng-container>
                <ng-container
                  *ngIf="historicalOrder.at_100_percent_percent == 0"
                >
                  N/A
                </ng-container>
                |
                <ng-container
                  *ngIf="historicalOrder.at_100_percent_percent != 0"
                >
                  <span *ngIf="historicalOrder.at_100_percent_usd">$</span
                  >{{ historicalOrder.at_100_percent_usd | number: "1.2-2" }}
                </ng-container>
                <ng-container *ngIf="historicalOrder.at_100_percent_usd == 0">
                  N/A
                </ng-container>
              </td>

              <td [hidden]="at95PercentColumn.hidden" class="text-align-center">
                <ng-container
                  *ngIf="historicalOrder.at_95_percent_percent != 0"
                >
                  {{ historicalOrder.at_95_percent_percent
                  }}<span *ngIf="historicalOrder.at_95_percent_percent">%</span>
                </ng-container>
                <ng-container
                  *ngIf="historicalOrder.at_95_percent_percent == 0"
                >
                  N/A
                </ng-container>
                |
                <ng-container *ngIf="historicalOrder.at_95_percent_usd != 0">
                  <span *ngIf="historicalOrder.at_95_percent_usd">$</span
                  >{{ historicalOrder.at_95_percent_usd | number: "1.2-2" }}
                </ng-container>
                <ng-container *ngIf="historicalOrder.at_95_percent_usd == 0">
                  N/A
                </ng-container>
              </td>

              <td [hidden]="at90PercentColumn.hidden" class="text-align-center">
                <ng-container
                  *ngIf="historicalOrder.at_90_percent_percent != 0"
                >
                  {{ historicalOrder.at_90_percent_percent
                  }}<span *ngIf="historicalOrder.at_90_percent_percent">%</span>
                </ng-container>
                <ng-container
                  *ngIf="historicalOrder.at_90_percent_percent == 0"
                >
                  N/A
                </ng-container>
                |
                <ng-container
                  *ngIf="historicalOrder.at_90_percent_percent != 0"
                >
                  <span *ngIf="historicalOrder.at_90_percent_usd">$</span
                  >{{ historicalOrder.at_90_percent_usd | number: "1.2-2" }}
                </ng-container>
                <ng-container *ngIf="historicalOrder.at_90_percent_usd == 0">
                  N/A
                </ng-container>
              </td>

              <td [hidden]="priceAtUploadColumn.hidden" class="usd">
                <ng-container *ngIf="historicalOrder.price_at_upload != 0">
                  ${{ historicalOrder.price_at_upload | number: "1.2-2" }}
                </ng-container>
                <ng-container *ngIf="historicalOrder.price_at_upload == 0">
                </ng-container>
              </td>
              <td [hidden]="priceAtFillColumn.hidden" class="usd">
                <ng-container *ngIf="historicalOrder.price_at_fill != 0">
                  ${{ historicalOrder.price_at_fill | number: "1.2-2" }}
                </ng-container>
                <ng-container *ngIf="historicalOrder.price_at_fill == 0">
                </ng-container>
              </td>

              <td [hidden]="moveSinceColumn.hidden" class="percent">
                <ng-container *ngIf="historicalOrder.price_at_fill != 0">
                  <span *ngIf="historicalOrder.move_since">
                    {{ historicalOrder.move_since }}%
                  </span>
                </ng-container>
                <ng-container *ngIf="historicalOrder.price_at_fill == 0">
                </ng-container>
              </td>

              <td
                [ngClass]="{
                  'highlight-blue':
                    historicalOrder.interest_percent < 5 &&
                    historicalOrder.security_type != 'CALL'
                }"
              >
                {{ historicalOrder.interest_percent }}%
              </td>

              <td [hidden]="partialFillColumn.hidden" class="text-align-center">
                <ng-container *ngIf="historicalOrder.is_partial == 'YES'"
                  >Y</ng-container
                >

                <ng-container *ngIf="historicalOrder.is_partial == 'NO'"
                  >N</ng-container
                >
              </td>
            </tr>

            <!-- <tr *ngIf="!noMoreData" colspan="100%">
              <td colspan="100%" style="text-align: center">
                <button
                  class="add-more-data"
                  (click)="addMoreData()"
                  [disabled]="moreDataLoading"
                >
                  Add More Records
                  <div
                    *ngIf="moreDataLoading"
                    role="status"
                    class="spinner-more-data spinner-border"
                  ></div>
                </button>
              </td>
            </tr> -->

            <!-- </ng-container> -->
          </tbody>
          <!-- <tbody>
                <div>
                    <button (click)="addMoreData()">Add More Data</button>
                </div>
            </tbody> -->
          <tfoot
            style="visibility: hidden"
            [hidden]="loading || historicalOrders.length == 0 || footerHidden"
          >
            <tr class="custom-color" class="totals-table-rows">
              <td></td>
              <td [hidden]="dateColumn.hidden">
                <div>
                  <b>Data</b>
                </div>
                <div>
                  <b>Summary</b>
                </div>
              </td>
              <td [hidden]="acctColumn.hidden"></td>
              <td [hidden]="tickerColumn.hidden"></td>
              <td [hidden]="daysColumn.hidden"></td>
              <td [hidden]="expirationColumn.hidden"></td>
              <td [hidden]="quantityColumn.hidden">
                <b>Sum: {{ footerSums.quantity | number: "1.0-0" }}</b>
              </td>
              <td [hidden]="strikeOneColumn.hidden"><b></b></td>
              <td [hidden]="strikeTwoColumn.hidden"><b></b></td>
              <td [hidden]="optionTypeColumn.hidden"></td>
              <td [hidden]="costColumn.hidden">
                <b>Avg: ${{ footerAvgs.cost | number: "1.1-2" }}</b>
              </td>
              <td [hidden]="exposureColumn.hidden">
                <b>Sum: ${{ footerSums.exposure | number: "1.0-0" }}</b>
              </td>
              <td [hidden]="moveNeededColumn.hidden">
                <div>
                  <b
                    >High:
                    {{ footerHighLow.moveNeededHigh | number: "1.1-2" }}%</b
                  >
                </div>
                <div>
                  <b
                    >Low:
                    {{ footerHighLow.moveNeededLow | number: "1.1-2" }}%</b
                  >
                </div>
              </td>
              <td [hidden]="maxGainColumn.hidden"></td>
              <td [hidden]="tableNumColumn.hidden"></td>
              <td [hidden]="qualityColumn.hidden"></td>
              <td [hidden]="typeColumn.hidden"></td>
              <td [hidden]="categoryColumn.hidden"></td>
              <td [hidden]="at10kColumn.hidden"></td>
              <td [hidden]="targetReturnFillColumn.hidden"></td>
              <td [hidden]="targetReturnCurrentColumn.hidden"></td>
              <td [hidden]="targetPercentColumn.hidden">
                <b>Avg: {{ footerAvgs.target_percent | number: "1.1-2" }}</b>
              </td>
              <td [hidden]="at100PercentColumn.hidden"></td>
              <td [hidden]="at95PercentColumn.hidden"></td>
              <td [hidden]="at90PercentColumn.hidden"></td>
              <td [hidden]="priceAtUploadColumn.hidden"></td>
              <td [hidden]="priceAtFillColumn.hidden"><b></b></td>
              <td [hidden]="moveSinceColumn.hidden"></td>
              <td [hidden]="moveSinceColumn.hidden"></td>
              <td [hidden]="percentInterestColumn.hidden"></td>
            </tr>
          </tfoot>

          <!-- <button>Hide Footer</button> -->
        </table>
      </cdk-virtual-scroll-viewport>
      <div
        *ngIf="historicalOrders.length == 0 && !loading"
        class="no-results no-results-container"
      >
        <h2 class="no-results">No Results</h2>
      </div>
    </div>
  </div>

  <ng-template #content let-modal>
    <div class="modal-header">
      <h2 class="modal-title" id="modal-basic-title">Current Data</h2>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="form-group">
        <div class="input-group">
          <div class="input-group-append">
            <iframe
              id="ticker-live-data"
              width="770px"
              height="500px"
              src=""
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</html>
