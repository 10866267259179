<div class="filter-collector">
  <section class="content-header">
    <h3>Messages</h3>

    <h1 class="btn-toolbar">
      <input
        [disabled]="loading"
        (input)="search($any($event.target).value)"
        type="text"
        class="search"
        placeholder="Search"
      />

      <button
        [disabled]="loading"
        (click)="selectAll()"
        class="btn btn-success btn-sm sel-all"
      >
        Select All
      </button>
      <button
        [disabled]="loading"
        (click)="deselectAll()"
        class="btn btn-success btn-sm"
      >
        Deselect All
      </button>
      <button
        [disabled]="loading"
        (click)="deleteNotes()"
        class="btn btn-success btn-sm"
      >
        Delete Message
      </button>

      <div class="day-dropdown" ngbDropdown>
        <button
          id="dropdownBasic2"
          class="btn btn-success btn-select-days"
          ngbDropdownToggle
        >
          Select Days
        </button>
        <div
          class="archive-dropdown"
          ngbDropdownMenu
          aria-labelledby="dropdownBasic1"
        >
          <div class="days-btn" (click)="selectDays(3)">
            <a class="days-select">3 days</a>
          </div>
          <div class="days-btn" (click)="selectDays(7)">
            <a class="days-select">7 days</a>
          </div>
          <div class="days-btn" (click)="selectDays(14)">
            <a class="days-select">14 days</a>
          </div>
          <div class="days-btn" (click)="selectDays(21)">
            <a class="days-select">21 days</a>
          </div>
          <div class="days-btn" (click)="selectDays(28)">
            <a class="days-select">28 days</a>
          </div>
          <div class="days-btn" (click)="selectDays('all')">
            <a class="days-select">All</a>
          </div>
        </div>
      </div>
    </h1>
  </section>
</div>

<div class="notifications-container">
  <table class="table table-responsive table-striped">
    <thead>
      <tr>
        <th></th>
        <th>Notification Date</th>
        <th>Notification Type</th>
        <th>Time Span</th>
        <th>Previous Value</th>
        <th>Value</th>
        <th>Column</th>
        <th>Message</th>
      </tr>
    </thead>
    <tbody>
      <tr
        class="table-row"
        *ngFor="let note of notifications | search: query"
        [ngClass]="{ 'row-selected': note.isSelected }"
      >
        <td>
          <input
            type="checkbox"
            id="delete"
            value="{{ note.id }}"
            (change)="select(note)"
            [(ngModel)]="note.check"
          />
        </td>
        <td>
          {{ note.created_at | date: "mediumDate" }}
        </td>
        <td>
          {{ note.name.name }}
        </td>
        <td>
          {{ note.name.time_span }}
        </td>
        <td>
          {{ note.name.previous_value }}
        </td>
        <td>
          {{ note.name.cell_value }}
        </td>
        <td>
          {{ note.name.db_column_name }}
        </td>
        <td>
          {{ note.name.message }}
        </td>
      </tr>
    </tbody>
  </table>
</div>
