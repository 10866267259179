<div class="spread-container">
  <div class="date-btn-container">
    <div class="col-flex-container">
      <p class="notification-date-header">Notification Date:</p>
      <input
        class="date-picker-input"
        placeholder="mm-dd-yyyy"
        name="dp"
        ngbDatepicker
        #d1="ngbDatepicker"
        [(ngModel)]="spreadNoteDate"
      />
      <button
        class="date btn-outline-secondary calendar fa fa-calendar note-cal"
        (click)="d1.toggle()"
        type="button"
        value="{{ spreadNoteDate }}"
      ></button>
    </div>
  </div>

  <div>
    <small *ngIf="spreadNoteDateError" class="user-enter-date-error"
      >Enter a date</small
    >
  </div>
</div>
