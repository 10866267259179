import {
  AfterContentChecked,
  AfterViewInit,
  Directive,
  ElementRef,
  Renderer2
} from "@angular/core";

// The timeout before allowing the header width to be recalculated
const MAX_WIDTH_CHECK_INTERVAL = 500;
@Directive({
  selector: "[cdkFixedHeader]",
})
export class FixedHeaderDirective
  implements AfterViewInit, AfterContentChecked
{
  cdkViewport: any;
  updatingHeaderWidth = false;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit() {
    this.cdkViewport = this.el.nativeElement.closest(
      "cdk-virtual-scroll-viewport"
    );

    // Check if table was already cloned
    let clonedHeader = this.cdkViewport.querySelectorAll(".cloned-header");

    if (clonedHeader.length > 0) {
      return;
    }

    // Clone the header and put it at the top of the table
    let table = this.el.nativeElement.closest("table");
    let clonedTable = table.cloneNode(true);
    clonedTable.style.position = "sticky";
    clonedTable.style.top = "0";
    clonedTable.style.zIndex = "100";

    this.renderer.addClass(clonedTable, "cloned-header");

    // Show table header - you have to get the one from the main table or it wont work with angular
    let thead = clonedTable.querySelector("thead");
    clonedTable.removeChild(thead);
    thead = table.querySelector("thead");
    clonedTable.appendChild(thead);
    thead.style.visibility = "visible";
    
    // Remove tbody from the clone
    let tbody = clonedTable.querySelector("tbody");

    // Add set the tfoot to visible
    let tfoot = clonedTable.querySelector("tfoot");
    clonedTable.removeChild(tfoot);
    tfoot = table.querySelector("tfoot");
    clonedTable.appendChild(tfoot);
    tfoot.style.visibility = "visible";

    // this.el.nativeElement.parentElement.parentElement.parentElement.appendChild(
    //   tfoot
    // );
    tfoot.style.position = "sticky";
    tfoot.style["overflow-x"] = "scroll";
    tfoot.style["white-space"] = "break-spaces";
    tfoot.style.width = "100%";
    tfoot.style.zIndex = "0";

    this.renderer.addClass(tfoot, "cloned-footer");

    clonedTable.removeChild(tbody);

    // Append header object to viewport
    this.cdkViewport.appendChild(clonedTable);

    // Move the table body down to show the hidden rows under the cloned footer
    // This is the fastest/best way I could do this without having to figure out horizontal scrolling and positioning
    table.style.top = "120px";
    table.style["margin-bottom"] = "120px";

    // Listen to the scroll on the table and move the footer with it
    // this.cdkViewport.addEventListener("scroll", () => {
    //   let tfoot =
    //     this.el.nativeElement.parentElement.parentElement.parentElement.querySelector(
    //       ".cloned-footer"
    //     );
    //   tfoot.scrollLeft = this.cdkViewport.scrollLeft;
    // });

    // tfoot.addEventListener("scroll", () => {
    //   this.cdkViewport.scrollLeft = tfoot.scrollLeft;
    // });
  }

  ngAfterContentChecked(): void {
    if (!this.cdkViewport || this.updatingHeaderWidth) {
      return;
    }

    this.updatingHeaderWidth = true;

    // Get the current max widths of the table cells and store them in an array
    let tdWidths = [];
    let tds = this.el.nativeElement.querySelectorAll("td");
    tdWidths = new Array(tds.length).fill(0);

    tds.forEach((item, index) => {
      const w = item.getBoundingClientRect().width;
      tdWidths[index] = Math.max(w, tdWidths[index]);
    });

    //  Get <th> and footer <td> elements and apply the max-width values
    let ths = this.cdkViewport.querySelectorAll(".cloned-header th");

    ths.forEach((item, index) => {
      if (item.width < tdWidths[index]) {
        this.renderer.setStyle(item, "min-width", tdWidths[index] + "px");
      }
    });

    let tf =
      this.el.nativeElement.parentElement.parentElement.parentElement.querySelectorAll(
        ".cloned-footer td"
      );

    tf.forEach((item, index) => {
      this.renderer.setStyle(item, "min-width", tdWidths[index] + "px");
    });

    // Set the min-width of the first td's to the getBoundingClientRect of the th's so they both have a min width going on
    let thWidths = [];
    ths = this.cdkViewport.querySelectorAll("thead th");
    ths.forEach((th) => {
      thWidths.push(th.getBoundingClientRect().width);
    });

    tds = this.el.nativeElement.querySelectorAll("td");
    let lasttds = this.el.nativeElement.querySelectorAll("tr:last-child td");

    tds.forEach((item, index) => {
      if (item.width < thWidths[index]) {
        this.renderer.setStyle(item, "min-width", thWidths[index] + "px");
      }
    });

    lasttds.forEach((item, index) => {
      if (item.width < thWidths[index]) {
        this.renderer.setStyle(item, "min-width", thWidths[index] + "px");
      }
    });

    // Prevent this method from being called too often. Check the MAX_WIDTH_CHECK_INTERVAL constant
    setTimeout(() => {
      this.updatingHeaderWidth = false;
    }, MAX_WIDTH_CHECK_INTERVAL); // Added a timeout to make sure we dont call this check too many times to prevent scrolling lag
  }
}
