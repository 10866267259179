<ng-container *ngIf= "!column.hidden" class="historicalTableHead">
    {{column.columnName}}
    <div>
        <div class = "d-inline-block" ngbDropdown>
            <div class="header-contents"> 
                <button 
                    [ngClass]="{'is-filtered' : isYellow}" 
                    class="fa fa-filter" id="dropdownBasic1" 
                    ngbDropdownToggle>
                </button>

                <app-sort 
                    [loading]="_loading" 
                    [column]="_column"
                ></app-sort>
            </div>
        <div 
            #drop 
            class= "dropdown-container" 
            ngbDropdownMenu 
            aria-labelledby="dropdownBasic1">
            <label class="search-label">Search</label> 
            <input class="search" type="text" [(ngModel)]="query">
            <div class="list-container">
                <div class="scroll-container">
                    <form #f2="ngForm" *ngIf="list" class="filter-list-item-wrap">
                        <div *ngFor="let c of list | fullTextSearch:query:'value'; let i = index" class="filter-list-item">
                            <div class="list-item"> 
                                <!-- Keep this code. -->
                                <div style="display: none"> {{c.check}} </div>

                                <label [htmlFor]="c.value"> 
                                    <input
                                        #check{{i}}
                                        class="list-check" 
                                        type="checkbox"
                                        name="check-box-{{c.value}}"
                                        [id]="c.value"
                                        (change)="checkChange(c)"
                                        [(ngModel)]="c.check"
                                        [attr.disabled]="_loading ? '' : null"
                                    >
                                    <ng-container *ngIf = "_column.dbName !== 'expiration_date'">
                                        {{c.value}}
                                    </ng-container>
                                </label>
                           
                                <ng-container *ngIf = "_column.dbName === 'expiration_date'">
                                    {{c.value | date: 'mediumDate'}}
                                </ng-container>

                             
                            </div>
                            <div *ngIf = "i == lastCheckedIndex">
                                <hr class="hr-recent">
                                <hr class="hr-recent">
                                <hr class="hr-recent">
                            </div>
                        </div>
                    </form>
                </div>
                <div>
                    <button ngbDropdownToggle class="filter-btn" type = "submit" (click)="hideColumn()" [attr.disabled]="_loading ? '' : null">Hide Column</button>
                    <button ngbDropdownToggle class="filter-btn apply-btn" type = "submit" [attr.disabled]="_loading ? '' : null" (click)="setFilters()">Apply Filter</button>
                </div>
            </div>
        </div>
    </div>
</div>
</ng-container>
