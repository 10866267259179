import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { ExposureReportData } from "src/app/interfaces/report/exposure-report-data";

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { RuntimeEnvLoaderService } from "../runtime-env-loader.service";

export interface ReportResponse<T> {
  data: {
    contentType?: string;
    base64FileData?: string;
    headers: any;
    original: {
      data: [T];
      filters: any;
      sorts: any;
      sql: string;
      sort_by: string;
      sort_order: string;
    };
    exception: string;
  };
}

@Injectable({
  providedIn: "root",
})
export class ReportService {
  basePath: string;

  constructor(
    private http: HttpClient,
    private envLoader: RuntimeEnvLoaderService
  ) {
    this.basePath = envLoader.config.API_BASE_URL + envLoader.config.APP_KEY;
  }

  getExposureReport(
    params = null
  ): Observable<ReportResponse<ExposureReportData>> {
    const headers = new HttpHeaders().set(
      "security-token",
      JSON.parse(localStorage.getItem("currentUser")).token
    );

    return this.http
      .get<ReportResponse<ExposureReportData>>(
        this.basePath + "/reports/exposure-report",
        {
          headers,
          observe: "response",
          params,
        }
      )
      .pipe(
        map((response) => {
          return response.body;
        }),
        catchError(this.handleError)
      );
  }

  handleError(error) {
    return throwError("Error");
  }
}
