import * as FileSaver from "file-saver";
import { ExposureReportData } from "src/app/interfaces/report/exposure-report-data";
import { FullScreenService } from "src/app/services/full-screen.service";
import { ReportService } from "src/app/services/report/report.service";
import { UtilsService } from "src/app/services/utils.service";

import { HttpParams } from "@angular/common/http";
import { Component, OnDestroy, OnInit } from "@angular/core";

import { SortService } from "../../historical-orders/sort.service";
import {
  FilterResult,
  FilterService
} from "../../table-components/filter.service";
import { ExposureReportTableConfiguration } from "./exposure-report-table-configuration";

@Component({
  selector: "app-exposure-report",
  templateUrl: "./exposure-report.component.html",
  styleUrls: ["./exposure-report.component.scss"],
})
export class ExposureReportComponent implements OnInit, OnDestroy {
  config: ExposureReportTableConfiguration;
  filtersCollapsed = true;
  columns = [];
  tableName = "exposure-report";
  bubbles = false;
  originalData: ExposureReportData[] = [];
  tableData: ExposureReportData[] = [];
  allFilters = [];
  bubbleList = [];
  filterAppliedSubscription: any;
  bubbleClosedSubscription: any;
  filterSortSub: any;
  loading = false;
  footerHidden = false;

  sortObj = {
    sortBy: "",
    sortOrder: "",
  };

  constructor(
    public fullScreenService: FullScreenService,
    private reportService: ReportService,
    public sortService: SortService,
    public filterService: FilterService,
    private utilsService: UtilsService
  ) {
    this.config = new ExposureReportTableConfiguration();
  }

  ngOnInit() {
    this.columns = this.config.getColumns();

    this.getData(true, true);

    this.subscribeToSortChangedObservable();
    this.subscribeToFilterAppliedObservable();
    this.subscribeToBubbleClosedObservable();
  }

  ngOnDestroy() {
    this.bubbleList = [];
    this.allFilters = [];
    this.bubbles = false;

    if (this.filterAppliedSubscription) {
      this.filterAppliedSubscription.unsubscribe();
    }

    if (this.filterSortSub) {
      this.filterSortSub.unsubscribe();
    }

    if (this.bubbleClosedSubscription) {
      this.bubbleClosedSubscription.unsubscribe();
    }

    this.sortService.clearLastSorted();
  }

  getData(showLoading = true, init = false) {
    if (showLoading) {
      this.loading = true;
    }

    let params = new HttpParams()
      .set("clear", "0")
      .set("init", init.toString());

    this.reportService.getExposureReport(params).subscribe((result) => {
      this.tableData = result.data.original.data;
      this.originalData = result.data.original.data;
      this.allFilters = JSON.parse(result.data.original.filters);

      this.sortObj = {
        sortBy: result.data.original.sort_by,
        sortOrder: result.data.original.sort_order,
      };

      this.sortAndFilter();

      this.filterService.setupFiltersForTable(this.tableData, this.allFilters);

      this.loading = false;
    });
  }

  fullScreen() {
    this.fullScreenService.isFullScreen = !this.fullScreenService.isFullScreen;

    if (this.fullScreenService.isFullScreen) {
      this.filtersCollapsed = false;
    }
  }

  toggleFilters() {
    this.filtersCollapsed = !this.filtersCollapsed;
  }

  exportToExcel() {
    let params = new HttpParams().set("format", "csv");
    this.reportService.getExposureReport(params).subscribe((result) => {
      let res = {
        contentType: result.data.contentType,
        base64FileData: result.data.base64FileData,
      };
      FileSaver.saveAs(
        new Blob([atob(res.base64FileData)], { type: res.contentType }),
        "exposure-report.csv"
      );
    });
  }

  subscribeToFilterAppliedObservable() {
    this.filterAppliedSubscription =
      this.filterService.filterAppliedObservable.subscribe(
        (data: FilterResult) => {
          if (!data) {
            this.allFilters = [];

            return;
          }

          this.bubbleList = data.bubbleList;
          this.allFilters = data.allFilters;
          this.bubbles = true;

          this.sortAndFilter();
        }
      );
  }

  subscribeToBubbleClosedObservable() {
    this.bubbleClosedSubscription =
      this.filterService.bubbleClosedObservable.subscribe(
        (data: FilterResult) => {
          if (!data) {
            return;
          }

          this.allFilters = data.allFilters;
          this.bubbleList = data.bubbleList;

          this.sortAndFilter();
        }
      );
  }

  subscribeToSortChangedObservable() {
    this.filterSortSub = this.sortService.sortChangedObservable.subscribe(
      (lastSorted) => {
        if (
          !lastSorted || // If this sort is the same as the one we have then dont do anything
          (this.sortObj.sortBy == lastSorted["sortBy"] &&
            this.sortObj.sortOrder == lastSorted["sortOrder"])
        ) {
          return;
        }

        this.sortObj.sortBy = lastSorted["sortBy"];
        this.sortObj.sortOrder = lastSorted["sortOrder"];

        this.sortAndFilter();
      }
    );
  }

  sortAndFilter() {
    const fields = {
      exposure_report_filters: JSON.stringify(this.allFilters),
      exposure_report_sort_by: this.sortObj.sortBy,
      exposure_report_sort_order: this.sortObj.sortOrder,
    };

    this.utilsService.saveUserFields(fields).subscribe();

    this.originalData = this.sortService.sort(this.originalData, this.sortObj);
    this.bubbleList = this.filterService.createFilterBubbleList(
      this.allFilters
    );

    this.tableData = this.filterService.filter(
      this.originalData,
      this.allFilters
    );
  }

  clearAllFilters() {
    this.allFilters = [];
    this.bubbleList = [];
    this.filterService.filters = [];
    this.filterService.bubbleList = [];
    this.tableData = this.originalData;
    this.sortService.clearLastSorted();
    this.sortObj.sortOrder = "asc";
    this.sortObj.sortBy = "ticker";
    this.sortService.sortColumnByDirection(
      this.sortObj.sortBy,
      this.sortObj.sortOrder
    );

    this.sortAndFilter();
    this.filterService.bubbleClosed([]);
  }

  hideFooter() {
    this.footerHidden = !this.footerHidden;
  }

  // See the comment for this function in historical-orders.component.ts
  returnZero(): number {
    return 0;
  }
}
