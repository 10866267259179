import { Component, Input } from '@angular/core';

/*
  Popover component that responds to margin-top screen events.
*/

@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss']
})
export class PopoverComponent {
  public _active: any;
  public _text: any;
  public _mouseEvent: any;

  getMarginTop(t) {
    return t - 250;
  }

  getMarginLeft(l) {
    return l - 1400;
  }

  @Input() set mouseEvent(value: any) {  
    this._mouseEvent = value; 
  }
 
  get mouseEvent(): any {
     return this._mouseEvent;
  }

  @Input() set active(value: any) {  
    this._active = value; 
  }
 
  get active(): any {
     return this._active;
  }

  @Input() set text(value: any) {  
    this._text = value; 
  }
 
  get text(): any {
     return this._text;
  }
}