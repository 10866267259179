<div class="modal-header">
  <h2 class="modal-title" id="modal-basic-title">Add Notification</h2>

  <div
    class="d-inline-block dropdown-note-type"
    ngbDropdown
    #mainTypeDrop="ngbDropdown"
  >
    <button
      class="btn btn-primary mr-2"
      id="dropdownManual"
      ngbDropdownAnchor
      (focus)="mainTypeDrop.open()"
    >
      {{ notificationType }}
    </button>
    <div ngbDropdownMenu aria-labelledby="dropdownManual">
      <button (click)="notificationType = 'Ticker'" ngbDropdownItem>
        Ticker
      </button>
      <button (click)="notificationType = 'Date'" ngbDropdownItem>Date</button>
    </div>
  </div>

  <button
    type="button"
    class="close"
    aria-label="Close"
    [mat-dialog-close]="true"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<mat-dialog-content class="mat-typography">
  <div class="dropdown-container">
    <div
      class="d-inline-block dropdown-note-type"
      ngbDropdown
      #tickerSubtypeDrop="ngbDropdown"
      *ngIf="notificationType == 'Ticker'"
    >
      <button
        class="btn btn-primary mr-2"
        id="dropdownManual"
        ngbDropdownAnchor
        (focus)="tickerSubtypeDrop.open()"
      >
        {{ tickerSubtype }}
      </button>
      <div ngbDropdownMenu aria-labelledby="dropdownManual">
        <button
          (click)="tickerSubtype = 'Percent Notification'"
          ngbDropdownItem
        >
          Percent Notification
        </button>
        <button (click)="tickerSubtype = 'Value Notification'" ngbDropdownItem>
          Value Notification
        </button>
      </div>
      <div>
        <button class="btn btn-primary" (click)="addNewTicker()">
          Add New Ticker
        </button>
      </div>
    </div>
  </div>

  <hr />

  <div class="percent-container" *ngIf="notificationType == 'Ticker'">
    <div class="ticker-container">
      <div>
        <label for="typeahead-basic">Ticker:</label>
        <input
          [disabled]="loading"
          id="typeahead-basic"
          type="text"
          class="form-control ticker-control"
          [(ngModel)]="userEnteredTickerStr"
          [ngbTypeahead]="search"
          (selectItem)="tickerSelectedEvent($event)"
        />
      </div>
      <!-- I am trying to get the MARK to show up when the user finishes entering their TICKER -->
      <ng-container
        *ngIf="
          userEnteredTicker &&
          !loading &&
          tickerSubtype == 'Percent Notification'
        "
      >
        <input
          type="value"
          name="sms"
          [disabled]="loading"
          [(ngModel)]="userEnteredTicker.mark"
          class="percent-moves-input ticker-margins"
        />
      </ng-container>

      <div
        *ngIf="loading && tickerSubtype == 'Percent Notification'"
        class="spinner-container spinner-margin"
      >
        <div role="status" class="spinner-border">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>

    <small class="user-entered-error" *ngIf="addTickerPercentError"
      >Enter a Ticker</small
    >

    <small class="user-entered-error" *ngIf="addTickerValueError"
      >Enter a Ticker</small
    >

    <small *ngIf="userTickerError" class="user-entered-error">
      {{ userTickerErrorMsg }}
    </small>

    <div *ngIf="tickerSubtype == 'Percent Notification'">
      <div class="btn-container">
        <div class="moves-up-down-container">
          <label>Moves Up</label>
          <input
            type="value"
            name="sms"
            [(ngModel)]="percentMovesUp"
            class="percent-moves-input"
          />
        </div>

        <span class="value-padding">%</span>

        <div class="moves-up-down-container ml-20">
          <label>Moves Down</label>
          <input
            type="value"
            name="sms"
            class="percent-moves-input"
            [(ngModel)]="percentMovesDown"
          />
        </div>
        <span class="value-padding">%</span>

        <div
          class="d-inline-block time-span-dropdown"
          ngbDropdown
          #timespandropdown="ngbDropdown"
        >
          <button
            class="btn btn-primary mr-2"
            id="dropdownManual"
            ngbDropdownAnchor
            (focus)="timespandropdown.open()"
          >
            {{ percentTimespan }}
          </button>
          <div ngbDropdownMenu aria-labelledby="dropdownManual">
            <button (click)="setPercentTimespan('Today Only')" ngbDropdownItem>
              Today Only
            </button>
            <button
              (click)="setPercentTimespan('Until Triggered')"
              ngbDropdownItem
            >
              Until Triggered
            </button>
            <button (click)="setPercentTimespan('Recurring')" ngbDropdownItem>
              Recurring
            </button>
            <button (click)="setPercentTimespan('Until Date')" ngbDropdownItem>
              Until Date
            </button>
          </div>
        </div>

        <div *ngIf="percentTimespan === 'Until Date'" class="percent-calendar">
          <div>
            <input
              class="form-control date-input"
              placeholder="mm-dd-yyyy"
              name="dp"
              ngbDatepicker
              #d1="ngbDatepicker"
              [(ngModel)]="noteUntilDate"
            />
          </div>
          <div class="percent-cal input-group-append">
            <button
              class="date btn-outline-secondary calendar fa fa-calendar note-cal"
              (click)="d1.toggle()"
              type="button"
            ></button>
          </div>
        </div>
      </div>

      <small class="user-entered-error" *ngIf="addMovesUpMovesDownPercentError">
        {{ addMovesUpMovesDownPercentErrorMsg }}
      </small>
    </div>

    <div
      *ngIf="tickerSubtype == 'Value Notification'"
      class="value-notification-container"
    >
      <div class="d-inline-block" ngbDropdown #mydrop1="ngbDropdown">
        <button
          class="btn btn-primary mr-2 note-equals-btn"
          id="dropdownManual"
          ngbDropdownAnchor
          (focus)="mydrop1.open()"
        >
          {{ selectedComparisonForValueNotification }}
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownManual">
          <button (click)="setSelected('Less Than')" ngbDropdownItem>
            Is Less Than
          </button>
          <button (click)="setSelected('Greater Than')" ngbDropdownItem>
            Is Greater Than
          </button>
        </div>
      </div>
      <input
        type="val"
        class="note-equals-input"
        [(ngModel)]="noteEqualsValue"
      />
    </div>
    <div>
      <small class="user-entered-error" *ngIf="addComparisonValueError"
        >Enter a value</small
      >
    </div>

    <div class="notification-add-message">
      <app-user-notification-table
        (clickCheckEvent)="clickUserTable($event)"
      ></app-user-notification-table>
      <textarea [(ngModel)]="message" class="text-area"></textarea>
    </div>

    <div class="right-click-btn-confirmation">
      <button
        [disabled]="loading"
        (click)="createTickerNotification()"
        class="btn btn-primary"
      >
        Create Notification
      </button>
    </div>
  </div>

  <div>
    <small *ngIf="usersUndefinedError" class="user-undefined-error">Assign at least one user for the notification</small>
  </div>

  <div *ngIf="notificationType == 'Date'" class="date-full-container">
    <h4 style="margin-top: 20px">Message:</h4>

    <div class="date-note-container">
      <div class="notify">
        <app-user-notification-table
          (clickCheckEvent)="clickUserTableForDate($event)"
        ></app-user-notification-table>
      </div>

      <div>
        <small *ngIf="usersUndefinedError" class="user-undefined-error">Assign at least one user for the notification</small>
      </div>

      <textarea [(ngModel)]="dateNoteMessage" class="new-note-body">
          {{ dateNoteMessage }}
        </textarea>
        <small class="user-entered-error" *ngIf="addDateNoteError"
          >Add a Message</small>
    </div>


    <div class="date-note-container-bottom">
      <div>
        <label>Notification Date: </label>
        <div class="date-container">
          <div>
            <input
              class="form-control date-input"
              placeholder="mm-dd-yyyy"
              name="dp"
              ngbDatepicker
              #d1="ngbDatepicker"
              [(ngModel)]="dateForDateNotification"
            />
          </div>
          <div class="input-group-append">
            <button
              class="date btn-outline-secondary calendar fa fa-calendar"
              (click)="d1.toggle()"
              type="button"
              value="{{ dateForDateNotification }}"
            ></button>
          </div>
        </div>

        <small class="user-entered-error" *ngIf="addDateError"
          >Add a Date</small
        >
      </div>
      <button
        [disabled]="loading"
        class="btn btn-success"
        (click)="createDateNotification()"
      >
        Create Notification
      </button>
    </div>
  </div>
</mat-dialog-content>
