import { Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Router } from "@angular/router";

import { AuthenticationService } from "src/app/services/authentication.service";
import { FullScreenService } from "src/app/services/full-screen.service";
import { RuntimeEnvLoaderService } from "src/app/services/runtime-env-loader.service";
import { SettingsManagementService } from "src/app/services/settings-management.service";
import { Subscription } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { UserService } from "src/app/services/app/user/user.service";

import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-max-gain-chart',
  templateUrl: './max-gain-chart.component.html',
  styleUrls: ['./max-gain-chart.component.scss']
})
export class MaxGainChartComponent implements OnInit {
  currentUserDetails: string;
  subGetEssentialData: Subscription;
  logUserData: any;
  userLog: any;
  logginUserRoleIds: any;
  allColumns: any;
  strategies: any;
  strategiesCreateForm: FormGroup;
  strategiesEditForm: FormGroup;

  createNew:boolean = true;
  details: boolean;
  edit: boolean;
  submitted: boolean;
  loading: boolean;
  userId: any;
  strategy: any;
  deleteList = [];
  err: boolean;
  maxGainTableValues: any;
  maxGainTableEdit: boolean = false;
  maxGainTableAddRowData: boolean = false;

  currentGain: any;
  newCurrentGain: any;
  targetPercentMaxGain: any;
  newTargetPercentMaxGain:any;


  constructor(
    private router: Router,
    private envService: RuntimeEnvLoaderService,
    private userService: UserService,
    private settingsService: SettingsManagementService,
    public authService: AuthenticationService,
    private modalService: NgbModal,
    public fullScreenService: FullScreenService,
    private toastr: ToastrService,
  ) { 
    this.currentUserDetails = localStorage.getItem('currentUser');
  }

  ngOnInit() {
    this.loading = true;
    console.log("hello from max gain");
    this.getMaxGainTableValues();
  }

  getMaxGainTableValues() {
    this.subGetEssentialData = this.settingsService.getMaxGainValues().subscribe(
      result => {
        console.log("getting max gain values");
        console.log(result);
        this.maxGainTableValues = result.body.data;
        this.loading = false;
        console.log("maxGainTableValues");
        console.log(this.maxGainTableValues);
      });
  }

  editMaxGainTable() {
    this.maxGainTableEdit = true;
  }

  addMaxGainTableRow() {
    this.maxGainTableAddRowData = !this.maxGainTableAddRowData;
  } 

  deleteMaxGainTableRow() {

  }

  saveMaxGain() {
    console.log(this.currentGain);
    console.log(this.targetPercentMaxGain);
  }

  saveMaxGainTable() {
    this.maxGainTableAddRowData = false;
    this.maxGainTableEdit = false
  }
}
