import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit
} from "@angular/core";

import { FilterResult, FilterService } from "../filter.service";

@Component({
  selector: "app-table-header-filtered-list",
  templateUrl: "./table-header-filtered-list.component.html",
  styleUrls: ["./table-header-filtered-list.component.scss"],
})
export class TableHeaderFilteredListComponent implements OnInit {
  public _column: any;

  @Input() set column(value: any) {
    this._column = value;
  }

  get column(): any {
    return this._column;
  }

  /* For Loading, we can disable checkmarks */
  public _loading: any;

  @Input() set loading(value: any) {
    this._loading = value;
  }

  get loading(): any {
    return this._loading;
  }

  public _tableName: any;

  @Input() set tableName(value: any) {
    this._tableName = value;
  }

  get tableName(): any {
    return this._tableName;
  }

  public _optionalList: any;

  @Input() set optionalList(value: any) {
    this._optionalList = value;
  }

  get optionalList(): any {
    return this._optionalList;
  }

  columnChange: EventEmitter<any> = new EventEmitter();
  rerender = false;
  query: any;
  checked: any = false;
  sort: any = {};
  isYellow = false;
  basePath: string;
  allFiltersParams = [];
  filterSub: any;
  list: any;
  allFilters: any;
  lastCheckedIndex: number = 1000000000;

  constructor(
    private cdRef: ChangeDetectorRef,
    private filterService: FilterService
  ) {}

  doRerender() {
    this.rerender = true;
    this.cdRef.detectChanges();
    this.rerender = false;
  }

  ngOnInit() {
    this.filterEventOccured();
  }

  ngOnDestroy() {
    this.isYellow = false;
    if (this.filterSub) {
      this.filterSub.unsubscribe();
    }
  }

  getDistinctRows() {
    /* If the optional list is set, we do not get the Distinct Lists from the Database */
    if (this._optionalList) {
      this.list = this._optionalList;
      return;
    }

    this.list = this.filterService.getDistinctValuesForFilteredLists(
      this._column.dbName
    );
  }

  addChecks(savedFilters) {
    if (!this.list) {
      return;
    }

    this.list.map((e) => (e.check = false));

    for (var s = 0; s < savedFilters.length; s++) {
      for (var i = 0; i < this.list.length; i++) {
        if (this.list[i].value === savedFilters[s].value) {
          this.list[i].check = true;
        }
      }
    }
  }

  checkIfFiltered() {
    for (var i = 0; i < this._column.length; i++) {
      if (this._column[i].check === true) {
        return true;
      }
    }

    return false;
  }

  /* No Longer Needed.  But Keep incase we need a function that is called when a checkmark is checked */
  checkChange(filter) {}

  clearChecks() {
    if (!this.list) {
      return;
    }

    for (var i = 0; i < this.list.length; i++) {
      this.list[i].check = false;
    }
  }

  /* Call this function when a change occurs in the column...changing any value in the object */
  onChange(column) {
    this.columnChange.emit(column);
  }

  hideColumn() {
    this.column.hidden = true;
    this.onChange(this._column);
  }

  addRecentlyChecked(list) {
    for (var i = 0; i < list.length; i++) {
      if (list[i].check) {
        list[i].recentlyChecked = true;
      }
    }
  }

  /* Move all the checked items to the front */
  moveCheckedToFront(list) {
    let checked = list.filter((f) => f.check);
    let unchecked = list.filter((f) => !f.check);
    unchecked.unshift(...checked);

    return unchecked;
  }

  getLastCheckedIndex(list) {
    this.lastCheckedIndex = list
      .map(function (e) {
        return e.recentlyChecked;
      })
      .lastIndexOf(true);
  }

  moveCheckedToFrontHelper() {
    this.list = this.moveCheckedToFront(this.list);
    this.getLastCheckedIndex(this.list);
  }

  setFilters() {
    this.column.filters = this.list.filter((f) => f.check);

    this.moveCheckedToFrontHelper();

    /* Formatting is necessary for some filtered lists, like Call Option to CALL, etc */
    this.column.filters = this.filterService.formatColumnNames(
      this.column.filters
    );

    this.filterService.filterList(this.column);
  }

  filterEventOccured() {
    this.filterSub = this.filterService.filtersSetupObservable.subscribe(
      (data: FilterResult) => {
        // Reload the new list of filters
        this.getDistinctRows();

        if (!data || !data.allFilters) {
          return;
        }

        this.addChecks(data.allFilters);
        let matchesMyCol = data.allFilters.filter(
          (d) => d.field == this._column.dbName
        );
        this.isYellow = matchesMyCol.length ? true : false;

        if (this.isYellow) {
          this.moveCheckedToFrontHelper();
        } else {
          this.clearChecks();
        }
      }
    );
  }

  trackByFn(index, treatment) {
    return index;
  }

  isFiltered() {
    return null;
  }
}
