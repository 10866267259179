import { ToastrService } from "ngx-toastr";
import { UserService } from "src/app/services/app/user/user.service";
import { AuthenticationService } from "src/app/services/authentication.service";
import { NotificationsService } from "src/app/services/notifications.service";
import { ScheduledJobsService } from "src/app/services/scheduled-jobs.service";
import { Component, OnInit } from "@angular/core";
import { DatePipe } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { AppService } from "src/app/services/app.service";

@Component({
  selector: "app-scheduled-messages",
  templateUrl: "./scheduled-messages.component.html",
  styleUrls: ["./scheduled-messages.component.scss"],
})
export class ScheduledMessagesComponent implements OnInit {
  notificationDate: any;
  loading = false;
  jobs: any;
  selectedNote: any;
  lastSelected: any;
  selectedVal: any = "Select Operator";
  operationInput: any;
  emailAddress: any;
  notifications: any;
  subjectForNote: any;
  bodyForNote: any;
  users: any;
  sort: any = {};
  selectedJobId: any;
  selectedJob: any;
  createNote: any;
  editNote: any;
  deleteNote: any;
  bodyForEdit: any;
  editDate: any;
  jobSelected: any;
  multiJobSelected: any;
  allSelectedArr: any;

  userId: number;
  currentUserDetails: Object;

  constructor(
    public appService: AppService,
    private notificationsService: NotificationsService,
    private toastr: ToastrService,
    private userService: UserService,
    private sJobService: ScheduledJobsService,
    private authService: AuthenticationService,
    private route: ActivatedRoute
  ) {
    this.route.params.subscribe((result) => {
      this.userId = result.id;
    });
    this.currentUserDetails = JSON.parse(localStorage.getItem("currentUser"));
  }

  ngOnInit() {
    this.loading = true;
    this.appService.scheduledNotificationTableLoadedGlobal = true;
    this.userService.getUserList().subscribe((data) => {
      this.users = data.body.data;
      this.selectDefaultEmailSms();
      this.getScheduledJobs();
    });

    this.sort.sortOrder = "asc";
  }

  ngOnDestroy() {
    this.appService.scheduledNotificationTableLoadedGlobal = false;
  }

  getColumn(job) {
    let col = job.details_long.db_column_name;

    if (!col) {
      col = "N/A";
    }

    if (job.name === "create_notification_by_column_value") {
      return col;
    } else if (job.name === "create_notification_by_column_percent") {
      return col;
    } else if (job.name === "create_notification_by_ticker_value") {
      return col;
    } else if (job.name === "create_notification_by_ticker_percent") {
      return col;
    } else if (job.name === "create_notification_by_spread") {
      return col;
    } else if (job.name === "create_notification_by_date") {
      return col;
    }
  }

  getTimespan(job) {
    if (job.name === "create_notification_by_column_value") {
      return "Until Triggered";
    } else if (job.name === "create_notification_by_column_percent") {
      return job.details_long.time_span;
    } else if (job.name === "create_notification_by_ticker_value") {
      return "Until Triggered";
    } else if (job.name === "create_notification_by_ticker_percent") {
      return job.details_long.time_span;
    } else if (job.name === "create_notification_by_spread") {
      let pipe = new DatePipe("en-US"); // Use your own locale
      const dateFormatted = pipe.transform(
        new Date(job.details_long.time_span),
        "mediumDate"
      );
      return "Scheduled Notification: " + dateFormatted;
    } else if (job.name === "create_notification_by_date") {
      let pipe = new DatePipe("en-US");
      const dateFormatted = pipe.transform(
        new Date(job.next_run),
        "mediumDate"
      );
      return "Scheduled Notification: " + dateFormatted;
    }
  }

  getTicker(job) {
    if (
      job.name === "create_notification_by_column_value" ||
      job.name === "create_notification_by_column_percent" ||
      job.name === "create_notification_by_date"
    ) {
      return "";
    } else if (
      job.name === "create_notification_by_ticker_value" ||
      job.name === "create_notification_by_ticker_percent"
    ) {
      return job["details_long"]["symbol"];
    }
  }

  getJobName(job) {
    if (job.name === "create_notification_by_column_value") {
      return "Value Notification";
    } else if (job.name === "create_notification_by_column_percent") {
      return "Percent Notification";
    } else if (job.name === "create_notification_by_ticker_value") {
      return "Value Notification";
    } else if (job.name === "create_notification_by_ticker_percent") {
      return "Percent Notification";
    } else if (job.name === "create_notification_by_spread") {
      return "Spread Notification";
    } else if (job.name === "create_notification_by_date") {
      return "Date Notification";
    }
  }

  getRecipientList(job) {
    var recipentList: string = "";
    for (var recipent in job.details_long.users) {
      if (job.details_long.users.length == 1) {
        recipentList =
          job.details_long.users[recipent].first_name +
          " " +
          job.details_long.users[recipent].last_name;
        return recipentList;
      } else if (recipent == "0") {
        recipentList =
          job.details_long.users[recipent].first_name +
          " " +
          job.details_long.users[recipent].last_name;
      } else {
        recipentList +=
          ", " +
          job.details_long.users[recipent].first_name +
          " " +
          job.details_long.users[recipent].last_name;
      }
    }
    job.recipentList = recipentList;
    return job.recipentList;
  }

  getStartingValue(job) {
    if (job.name === "create_notification_by_column_value") {
      if (job.details_long.unit) {
        if (job.details_long.unit == "$") {
          return job.details_long.unit + job.details_long.initial_value;
        } else {
          return job.details_long.initial_value + job.details_long.unit;
        }
      } else {
        return job.details_long.cell_value;
      }
    } else if (job.name === "create_notification_by_column_percent") {
      if (job.details_long.unit) {
        if (job.details_long.unit == "$") {
          return job.details_long.unit + job.details_long.initial_value;
        } else {
          return job.details_long.initial_value + job.details_long.unit;
        }
      } else {
        return job.details_long.cell_value;
      }
    } else if (job.name === "create_notification_by_ticker_value") {
      return "$" + job.details_long.mark;
    } else if (job.name === "create_notification_by_ticker_percent") {
      return "$" + job.details_long.mark;
    } else if (job.name === "create_notification_by_spread") {
      return null;
    } else if (job.name === "create_notification_by_date") {
      return null;
    }
  }

  getTargetValue(job) {
    if (job.name === "create_notification_by_column_value") {
      return (
        this.convertComparison(job.details_long.comparison) +
        " " +
        job.details_long.target_value
      );
    } else if (job.name === "create_notification_by_column_percent") {
      let up = job.details_long.move_up_target;
      let down = job.details_long.move_down_target;
      if (!up) {
        up = "";
      }
      if (!down) {
        down = "";
      }
      let ret = "Target Value(s): " + up;
      if (down) {
        ret = ret + ", " + down;
      }

      return ret;
    } else if (job.name === "create_notification_by_ticker_value") {
      return (
        this.convertComparison(job.details_long.comparison) +
        " " +
        job.details_long.target_value
      );
    } else if (job.name === "create_notification_by_ticker_percent") {
      let up = job.details_long.move_up_target;
      let down = job.details_long.move_down_target;
      if (!up) {
        up = "";
      }
      if (!down) {
        down = "";
      }
      let ret = "Target Value(s): " + up;
      if (down) {
        ret = ret + ", " + down;
      }

      return ret;
    } else if (job.name === "create_notification_by_spread") {
      return "N/A";
    }
  }

  convertComparison(comp) {
    if (comp == "==") {
      return "=";
    } else {
      return comp;
    }
  }

  selectAll() {
    for (var i = 0; i < this.jobs.length; i++) {
      this.jobs[i].check = true;
    }
  }

  deselectAll() {
    for (var i = 0; i < this.jobs.length; i++) {
      this.jobs[i].check = false;
    }
  }

  selectSelect(job, id) {
    this.selectedJob = job;
    this.selectedJobId = id;
    this.getAllSelected();
  }

  getAllSelected() {
    this.allSelectedArr = [];
    for (var i = 0; i < this.jobs.length; i++) {
      if (this.jobs[i].check) {
        this.allSelectedArr.push(this.jobs[i].id);
      }
    }
  }

  toolbarBtn(btn) {
    this.loading = true;

    if (btn === "delete") {
      this.deleteScheduledJob(this.selectedJobId);
      return;
    }

    this.createNote = false;
    this.editNote = false;
    this.deleteNote = false;

    if (btn === "create") {
      this.createNote = true;
    } else if (btn === "edit") {
      this.editNote = true;
    }
  }

  deleteScheduledJob(id) {
    this.loading = true;
    for (var i = 0; i < this.allSelectedArr.length; i++) {
      this.sJobService
        .deleteScheduledJob(this.allSelectedArr[i])
        .subscribe((result) => {
          this.loading = false;
          this.getScheduledJobs();
          this.toastr.success("Notification Deleted");
        });
    }
  }

  createNewNoteBtn() {
    this.createNote = true;
  }

  selectDefaultEmailSms() {
    let userId = this.authService.getUserId();

    for (var i = 0; i < this.users.length; i++) {
      if (this.users[i].id === userId) {
        this.users[i].emailSelected = true;
        this.users[i].smsSelected = true;
      }
    }
  }

  /*
    Converts the Details Long (which is a string in the job) to JSON
    If the job is null, we can add the empty string "{}" so we do not encounter errors on parse.
  */
  convertDetailsLongJSON() {
    for (var i = 0; i < this.jobs.length; i++) {
      if (!this.jobs[i].details_long) {
        this.jobs[i].details_long = "{}";
      }

      this.jobs[i].details_long = JSON.parse(this.jobs[i].details_long);
    }
  }

  getScheduledJobs() {
    this.loading = true;
    this.sJobService.getNotificationScheduledJobs().subscribe((result) => {
      this.loading = false;
      this.jobs = result.body.data;
      this.sortJobs();
      this.convertDetailsLongJSON();
      this.jobs.forEach((job) => {
        if (job.details_long.db_column_name) {
          if (
            job.details_long.db_column_name == "itm_curr" ||
            job.details_long.db_column_name == "move" ||
            job.details_long.db_column_name == "gain_percent" ||
            job.details_long.db_column_name == "gain_max" ||
            job.details_long.db_column_name == "max_percent" ||
            job.details_long.db_column_name == "fv_percent"
          ) {
            job.details_long.unit = "%";
          }
          if (
            job.details_long.db_column_name == "strike" ||
            job.details_long.db_column_name == "udl" ||
            job.details_long.db_column_name == "cost" ||
            job.details_long.db_column_name == "price" ||
            job.details_long.db_column_name == "exit_price" ||
            job.details_long.db_column_name == "exp_cost" ||
            job.details_long.db_column_name == "exp_curr" ||
            job.details_long.db_column_name == "gain_dollars" ||
            job.details_long.db_column_name == "price_at_fill" ||
            job.details_long.db_column_name == "paid" ||
            job.details_long.db_column_name == "bid" ||
            job.details_long.db_column_name == "mid" ||
            job.details_long.db_column_name == "ask" ||
            job.details_long.db_column_name == "paid_total" ||
            job.details_long.db_column_name == "value" ||
            job.details_long.db_column_name == "gain_total" ||
            job.details_long.db_column_name == "value_min" ||
            job.details_long.db_column_name == "value_max" ||
            job.details_long.db_column_name == "value_target" ||
            job.details_long.db_column_name == "profit_target" ||
            job.details_long.db_column_name == "paid_for_exposure_dashboard" ||
            job.details_long.db_column_name == "max_value_exposure_dashboard"
          ) {
            job.details_long.unit = "$";
          }
          if (job.details_long.db_column_name == "udl") {
            job.details_long.name = "UDL";
          } else if (job.details_long.db_column_name == "strike") {
            job.details_long.name = "Strike";
          } else if (job.details_long.db_column_name == "cost") {
            job.details_long.name = "Cost";
          } else if (job.details_long.db_column_name == "price") {
            job.details_long.name = "Price";
          } else if (job.details_long.db_column_name == "exit_price") {
            job.details_long.name = "Exit Price";
          } else if (job.details_long.db_column_name == "exp_cost") {
            job.details_long.name = "Exp(Cost)";
          } else if (job.details_long.db_column_name == "exp_curr") {
            job.details_long.name = "Exp(Curr Val)";
          } else if (job.details_long.db_column_name == "gain_dollars") {
            job.details_long.name = "$ Gain";
          } else if (job.details_long.db_column_name == "price_at_fill") {
            job.details_long.name = "Price @ Fill";
          } else if (job.details_long.db_column_name == "paid") {
            job.details_long.name = "Paid";
          } else if (job.details_long.db_column_name == "bid") {
            job.details_long.name = "Bid";
          } else if (job.details_long.db_column_name == "mid") {
            job.details_long.name = "Mid";
          } else if (job.details_long.db_column_name == "ask") {
            job.details_long.name = "Ask";
          } else if (job.details_long.db_column_name == "paid_total") {
            job.details_long.name = "Total Paid";
          } else if (job.details_long.db_column_name == "value") {
            job.details_long.name = "Value $";
          } else if (job.details_long.db_column_name == "gain_total") {
            job.details_long.name = "Total Gain";
          } else if (job.details_long.db_column_name == "value_min") {
            job.details_long.name = "Min Cash Value";
          } else if (job.details_long.db_column_name == "value_max") {
            job.details_long.name = "Max Value";
          } else if (job.details_long.db_column_name == "value_target") {
            job.details_long.name = "Target Value";
          } else if (job.details_long.db_column_name == "profit_target") {
            job.details_long.name = "Target Profit";
          } else if (
            job.details_long.db_column_name == "paid_for_exposure_dashboard"
          ) {
            job.details_long.name = "Paid For Exposure Dashboard";
          } else if (
            job.details_long.db_column_name ==
            "max_value_for_exposure_dashboard"
          ) {
            job.details_long.name = "Max Value for Exposure Dashboard";
          } else if (job.details_long.db_column_name == "gain_percent") {
            job.details_long.name = "% Gain";
          } else if (job.details_long.db_column_name == "itm_curr") {
            job.details_long.name = "ITM (Curr)";
          } else if (job.details_long.db_column_name == "move") {
            job.details_long.name = "Move";
          } else if (job.details_long.db_column_name == "gain_max") {
            job.details_long.name = "Max Gain";
          } else if (job.details_long.db_column_name == "max_percent") {
            job.details_long.name = "% Max";
          } else if (job.details_long.db_column_name == "fv_percent") {
            job.details_long.name = "% FV";
          } else if (
            job.details_long.db_column_name == "expiration_days_away"
          ) {
            job.details_long.name = "Days";
          }
        }
      });
      this.getJobCreator(this.jobs);
      this.isCurrUserRecipientJob(this.jobs);
    });
  }

  getJobCreator(jobs) {
    for (var job of jobs) {
      for (var user of this.users) {
        if (job.user_id == user.id) {
          job.created_by = user;
        }
      }
    }
  }

  isCurrUserRecipientJob(jobs) {
    for (var job of jobs) {
      for (var user of job.details_long.users) {
        if (user.id == this.currentUserDetails["id"]) {
          job.isRecipient = true;
        }
      }
    }
  }

  sortJobs() {
    this.jobs = this.jobs.sort(function (a: any, b: any) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return (
        new Date(b.created_at).valueOf() - new Date(a.created_at).valueOf()
      );
    });
  }

  optInNotification(jobId, job) {
    this.loading = true;
    var email =
      this.currentUserDetails["notification_preferred_email"] == "1"
        ? true
        : false;
    var sms =
      this.currentUserDetails["notification_preferred_sms"] == "1"
        ? true
        : false;

    var addUser = {
      email: this.currentUserDetails["email"],
      first_name: this.currentUserDetails["first_name"],
      last_name: this.currentUserDetails["last_name"],
      id: this.currentUserDetails["id"],
      notification_end_time: this.currentUserDetails["notification_end_time"],
      notification_start_time:
        this.currentUserDetails["notification_start_time"],
      phone_number: this.currentUserDetails["phone_number"],
      send_email: email,
      send_sms: sms,
    };

    job.details_long.users.push(addUser);

    var jobDetails = JSON.stringify(job.details_long);

    var params = {
      details_long: jobDetails,
    };

    this.notificationsService
      .editScheduledJob(jobId, params)
      .subscribe((result) => {
        this.loading = false;
        this.toastr.success("Successfully Opted In to Notification");
        this.getScheduledJobs();
      });
  }

  optOutNotification(jobId, job) {
    this.loading = true;

    for (var user in job.details_long.users) {
      if (job.details_long.users[user].id == this.currentUserDetails["id"]) {
        job.details_long.users.splice(user, 1);
      }
    }

    var jobDetails = JSON.stringify(job.details_long);

    var params = {
      details_long: jobDetails,
    };

    this.notificationsService
      .editScheduledJob(jobId, params)
      .subscribe((result) => {
        this.loading = false;
        this.toastr.success("Successfully Opted Out of Notification");
        this.getScheduledJobs();
      });
  }

  editScheduledJobs() {
    this.loading = true;

    let params = {
      id: this.selectedJob.id,
      details_long: this.selectedJob.details_long,
      app_id: 1,
      frequency: this.selectedJob.frequency,
      name: "create_notification_by_date",
    };

    this.sJobService
      .editScheduledJob(params, this.selectedJob.id)
      .subscribe((result) => {
        this.loading = false;

        this.getScheduledJobs();
        this.toastr.success("Notification was edited successfully");
      });
  }

  createScheduledJob() {
    this.loading = true;
    let params = null;
    this.sJobService.createScheduledJob(params).subscribe((result) => {
      this.loading = false;
      this.jobs = result.body.data;
    });
  }

  select(val) {
    this.selectedVal = val;
  }

  /* Sorting the object keys that are selected so they display alphabetically for the user */
  sortObjectKeys(selectedNote) {
    return Object.keys(selectedNote)
      .sort()
      .reduce(
        (acc, key) => ({
          ...acc,
          [key]: selectedNote[key],
        }),
        {}
      );
  }

  selectCol(x) {
    if (this.lastSelected) {
      this.lastSelected.clicked = false;
    }

    if (this.lastSelected == x) {
      this.lastSelected = null;
      return;
    }

    this.lastSelected = x;
    x.clicked = true;
  }

  formatDate(date) {
    if (!date) {
      return null;
    }
    return date.year + "/" + date.month + "/" + date.day;
  }

  createUserParams() {
    let phoneList = "";
    let emailList = "";

    for (var i = 0; i < this.users.length; i++) {
      if (this.users[i].emailSelected && this.users[i].smsSelected) {
        phoneList += this.users[i].phone_number + ",";
        emailList += this.users[i].email + ",";
      } else if (this.users[i].smsSelected) {
        phoneList += this.users[i].phone_number + ",";
      } else if (this.users[i].emailSelected) {
        emailList += this.users[i].email + ",";
      }
    }
  }

  parseOperationInput(opInput: any) {
    if (opInput == "Greater Than") {
      return "<";
    } else if (opInput == "Less Than") {
      return ">";
    } else if (opInput == "Equals") {
      return "=";
    }
  }

  saveEditNote() {
    this.editScheduledJobs();
  }

  createNewNote() {
    let date = null;
    let params = {};
    this.loading = true;

    /* User enters in a date.  Then we create the params based off date. */
    if (this.notificationDate) {
      date =
        this.notificationDate.year +
        "-" +
        this.notificationDate.month +
        "-" +
        this.notificationDate.day;

      params = {
        details_1: this.subjectForNote,
        details_2: date,
        details_5: this.emailAddress,
        details_long: this.bodyForNote,
        app_id: 1,
        remaining: "1",
        every_minutes: "1",
        frequency: "every_minutes",
        name: "create_notification_by_date",
      };

      /* User does not enter in a date.  Then we create params based off condition */
    } else {
      let selectVal = this.parseOperationInput(this.selectedVal);

      params = {
        details_1: this.subjectForNote,
        details_2: this.lastSelected.portfolio_item_id,
        details_3: selectVal + " " + this.operationInput, // The selected operation.
        details_4: this.lastSelected.key, // The value of operation.
        details_5: this.emailAddress,
        details_long: this.bodyForNote,
        app_id: 1,
        remaining: "1",
        every_minutes: "1",
        frequency: "every_minutes",
        name: "create_notification",
      };
    }

    this.notificationsService.newScheduledJob(params).subscribe((result) => {
      this.loading = false;
      this.toastr.success("Created New Notification");
      this.getScheduledJobs();
    });
  }
}
