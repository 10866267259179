<div class="sort">
  <i
    (click)="sortColumn('asc')"
    class="fa fa-caret-up fa-2x sort-button"
    [attr.disabled]="_loading ? '' : null"
    [ngClass]="{
      'fa-black': !this.sort,
      'fa-yellow': this.sort.sortOrder == 'asc',
      'fa-grey': this.sort.sortOrder == 'desc'
    }"
  ></i>
  <i
    (click)="sortColumn('desc')"
    class="fa fa-caret-down fa-2x sort-button"
    [attr.disabled]="_loading ? '' : null"
    [ngClass]="{
      'fa-black': !this.sort,
      'fa-yellow': this.sort.sortOrder == 'desc',
      'fa-grey': this.sort.sortOrder == 'asc'
    }"
  >
  </i>
</div>
