import { ToastrService } from "ngx-toastr";
import { forkJoin, Subscription } from "rxjs";
import { UserRole } from "src/app/constants/user/user-role";
import { UserService } from "src/app/services/app/user/user.service";
import { AuthenticationService } from "src/app/services/authentication.service";
import { FullScreenService } from "src/app/services/full-screen.service";
import { RuntimeEnvLoaderService } from "src/app/services/runtime-env-loader.service";
import { StrategiesService } from "src/app/services/strategies.service";
import Swal from "sweetalert2";
import { isString } from "util";

import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-strategies",
  templateUrl: "./strategies.component.html",
  styleUrls: ["./strategies.component.scss"],
})
export class StrategiesComponent implements OnInit {
  currentUserDetails: string;
  subGetEssentialData: Subscription;
  subGetLoggingData: Subscription;
  subGetEssentialStrategyData: Subscription;
  logUserData: any;
  userLog: any;
  logginUserRoleIds: any;
  allColumns: any;
  strategies: any;
  strategiesCreateForm: FormGroup;
  strategiesEditForm: FormGroup;

  createNew: boolean = true;
  details: boolean;
  edit: boolean;
  submitted: boolean;
  loading: boolean;
  userId: any;
  strategy: any;
  deleteList = [];
  err: boolean;

  constructor(
    private router: Router,
    private envService: RuntimeEnvLoaderService,
    private userService: UserService,
    private strategiesService: StrategiesService,
    public authService: AuthenticationService,
    private modalService: NgbModal,
    public fullScreenService: FullScreenService,
    private toastr: ToastrService
  ) {
    this.currentUserDetails = localStorage.getItem("currentUser");
  }

  ngOnInit() {
    this.loading = true;
    this.getEssentialData();
    this.getStrategiesData();
    this.err = false;
  }

  getStrategiesData() {
    // const sortBy = this.sortObj.sortBy;
    // const sortOrder = this.sortObj.sortOrder;

    // let search = null;
    // if (this.allFilters.fields.length) {
    //   search = JSON.stringify(this.allFilters.fields);
    // }

    // let params = new HttpParams()

    this.subGetEssentialData = this.strategiesService
      .getStrategies()
      .subscribe((result) => {
        result.body.data.forEach((user) => {
          user.checkStatus = false;
        });
        this.strategies = result.body.data;
        this.loading = false;
      });
  }

  getEssentialData() {
    this.strategiesCreateForm = new FormGroup({
      strategy: new FormControl("", [Validators.required, this.noWhitespace]),
      description: new FormControl(""),
      computeMin: new FormControl(""),
    });

    this.checkLogUserId();
  }

  checkLogUserId() {
    this.logUserData = JSON.parse(this.currentUserDetails);

    const getloggedUser = this.userService.getUserDetails(this.logUserData.id);
    this.subGetLoggingData = forkJoin([getloggedUser]).subscribe((logUser) => {
      this.userLog = logUser[0];

      this.userLog.user_roles.forEach((element) => {
        if (element.id == UserRole.admin) {
          this.logginUserRoleIds.push(UserRole.admin);
        } else if (element.id == UserRole.employee) {
          this.logginUserRoleIds.push(UserRole.employee);
        }
      });
      const idxOfAdmin = this.logginUserRoleIds.indexOf(UserRole.admin);
      const idxOfEmployee = this.logginUserRoleIds.indexOf(UserRole.employee);

      this.userService.setUser(logUser);
    });
  }

  public noWhitespace(control: FormControl) {
    return isString(control.value) && control.value.trim() == ""
      ? { required: true }
      : null;
  }

  fullScreen() {
    this.fullScreenService.isFullScreen = !this.fullScreenService.isFullScreen;
  }

  // closeFooter() {
  //   this.footerHidden = !this.footerHidden;
  // }

  showAllColumns() {
    for (const col of this.allColumns) {
      col.hidden = false;
    }
  }

  showSpecificColumns(dbName) {
    for (const col of this.allColumns) {
      if (col.dbName == dbName) {
        if (col.hidden == true) {
          col.hidden = false;
        } else {
          col.hidden = true;
        }
      }
    }
  }

  selectSelect(event) {
    this.strategies.forEach((strategy) => {
      if (strategy.id == event.target.value) {
        strategy.checkStatus = !strategy.checkStatus;
        if (strategy.checkStatus == true) {
          this.deleteList.push(strategy.id);
        } else {
          const idx = this.deleteList.indexOf(strategy.id);
          if (idx != -1) {
            this.deleteList.splice(idx, 1);
            strategy.checkStatus = false;
          }
        }
      }
    });
  }

  selectAllStrategies() {
    this.deleteList = [];
    this.strategies.forEach((strategy) => {
      strategy.checkStatus = true;
      this.deleteList.push(strategy.id);
    });
  }

  deselectAllStrategies() {
    this.strategies.forEach((strategy) => {
      strategy.checkStatus = false;
      this.deleteList = [];
    });
  }

  deleteSelectedStrategies() {
    this.loading = true;
    let totalRow = this.deleteList.length;
    Swal.fire({
      title: "Are you sure?",
      text: "This will delete the " + totalRow + " selected records.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ok",
    }).then((result) => {
      if (result.value) {
        const strategyIds = [];
        this.strategies.forEach((strategy) => {
          if (strategy.checkStatus == true) {
            const logUser = JSON.parse(localStorage.getItem("currentUser"));
            if (logUser.id != strategy.id) {
              strategyIds.push(
                this.strategiesService.deleteStrategy(Number(strategy.id))
              );
            } else {
              totalRow -= 1;
            }
          }
        });
        this.subGetEssentialData = forkJoin(strategyIds).subscribe(
          (response) => {
            if (response[0] == "200") {
              this.deleteList = [];
              this.toastr.success(totalRow + " rows", "Deleted Successfully");
              this.getStrategiesData();
            }
          },
          (err) => {
            this.toastr.error(totalRow + " rows", "Deleted Unsuccessful");
          }
        );
      }
    });
  }

  strategyCreateNew() {
    this.createNew = true;
    this.details = false;
    this.edit = false;
    this.strategy = null;
  }

  strategyDetails(strategyId) {
    if (strategyId == "cancel") {
      this.loading = false;
    } else {
      this.loading = true;
    }
    this.details = true;
    this.createNew = false;
    this.edit = false;

    let subGetEssentialData = this.strategiesService
      .getStrategyDetails(strategyId)
      .subscribe((result) => {
        this.strategy = result.body.data;
        this.loading = false;
      });
  }

  strategyEdit() {
    this.edit = true;
    this.createNew = false;
    this.details = false;

    this.strategiesEditForm = new FormGroup({
      strategy: new FormControl(
        { value: this.strategy.name, disabled: false },
        [Validators.required, this.noWhitespace]
      ),
      description: new FormControl({
        value: this.strategy.description,
        disabled: false,
      }),
      computeMin: new FormControl({
        value: this.strategy.compute_portfolio_value_min,
        disabled: false,
      }),
    });
  }

  onStrategyCreateSubmit() {
    this.loading = true;
    this.submitted = true;

    const form = this.strategiesCreateForm;
    // if (!form.valid || this.userRoleId.length == 0) {
    //     this.submitted = true;
    //     if (this.userRoleId.length == 0) {
    //         this.roleRequired = true;
    //     }
    //     return;
    // }
    const credentions = form.value;

    const submitData = {
      name: credentions.strategy,
      description: credentions.description,
      compute_portfolio_value_min: credentions.computeMin,
    };

    this.strategiesService.createStrategy(submitData).subscribe(
      (result: any) => {
        this.loading = true;
        this.userId = result.id;
        this.toastr.success(submitData.name + "strategy successfully created");
        this.err = false;
        this.getStrategiesData();
      },
      (err) => {
        if ((err = 400)) {
          this.toastr.error(
            "Save Unsuccessful - A strategy with this name already exists. Please change strategy name and try again"
          );
          this.err = true;
          this.loading = false;
        }
      }
    );
  }

  onStrategyEditSubmit(strategyId) {
    this.loading = true;
    this.submitted = true;

    const form = this.strategiesEditForm;
    // if (!form.valid || this.userRoleId.length == 0) {
    //     this.submitted = true;
    //     if (this.userRoleId.length == 0) {
    //         this.roleRequired = true;
    //     }
    //     return;
    // }
    this.loading = true;
    const credentions = form.value;

    const submitData = {
      name: credentions.strategy,
      description: credentions.description,
      compute_portfolio_value_min: credentions.computeMin,
    };

    this.strategiesService
      .updateStrategyDetails(strategyId, submitData)
      .subscribe((result: any) => {
        this.loading = true;
        this.userId = result.id;
        this.getStrategiesData();
      });
  }

  logout() {
    const isSimulating: boolean =
      localStorage.getItem("isSimulating") === "true";

    if (isSimulating) {
      this.authService.logoutFromSimulation();
      window.location.reload();
    } else {
      this.authService.logout();
      this.router.navigate(["/login"]);
    }
    this.fullScreenService.isFullScreen = false;
  }
}
